import React, {useState} from 'react'
import { connect } from 'react-redux'
//import { Redirect } from 'react-router-dom'
import { forgotPassword, signout } from '../actions/auth'

import graphql from '../graphql/client'
import {CURRENT_USER} from '../graphql/queries'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import favicon from '../images/favicon'
import right_arrow from '../../../assets/images/rightArrow.png'
import signin_img from '../../../assets/images/signinBack.png'
import linkedin from '../../../assets/images/linkedin.png'
import google from '../../../assets/images/googleIcon.png'
import {getCurrentUser} from '../actions/current_user'

import logo from '../images/logo'

import Snackbar from '../components/snackbar'

import { MuiThemeProvider } from '@material-ui/core/styles';

import lightTheme from '../themes/light/index'
import darkTheme from '../themes/dark/index'

import LoadingView from '../components/loadingView'
import queryString from 'query-string'
import FormHelperText from '@material-ui/core/FormHelperText';
import axios from 'axios'


class ForgotPasswordForm extends React.Component {

  handleSubmit = (password, passwordConfirmation, token) => {
  }

  showSuccess = () => {
    this.props.history.push('/')
  }

  getCurrentUser = () => {
    this.props.dispatch(getCurrentUser())
  }

  render() {
    const { isAuthenticated } = this.props

    if (isAuthenticated) return <GetUserDataButton onClick={this.getCurrentUser} />

    return (
      <div>
        <SignIn
          {...this.props}
          handleSubmit={this.handleSubmit.bind(this)}
          showSuccess={this.showSuccess.bind(this)}
        />
      </div>
    )
  }
}

const GetUserDataButton = props => {
  props.onClick()
  return <LoadingView onClick={props.onClick}/>
}

const MadeWithLove = () => (
  <Typography variant='body2' color='textSecondary' align='center'>
    {'Built with love by the '}
    <Link color='inherit' href='https://upsend.io/'>
      Upsend Team
    </Link>
  </Typography>
)

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.background.default,
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 50,
  },
  backgroundImg: {
    width: '50%', height: '55%', margin: '22%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    width: '60px',
    height: '60px',
    borderRadius: '0%'
  },
  logo: {
    height: '100%',
    width: '100%'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    '& input': {
      boxShadow: 'none',
    }
  },
  submit: {
    margin: '40px 0px 16px', borderRadius: 0, padding: '10px 80px',
  },
  border: {
    position: 'absolute', border: '1px solid #000', height: '100%', width: '100%', top: 4, left: 4,
  },
  rightArrow: {
    position: 'absolute', right: '-30px'
  },
  social: {
    background: '#F8F8FD', padding: 10, borderRadius: 7, marginRight: 10,
  }
}))

const SignIn = props => {
  const classes = useStyles()
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({})
  const [token, setToken] = useState( queryString.parse(props.location.search) )

  const handleSubmit = e => {
    e.preventDefault()
    if(passwordConfirmation !== password) {
      setErrors({'password': ["Password and Password Confirmation do not match"]})
      setErrors({'password_confirmation': ["Password and Password Confirmation do not match"]})
    }else{
      axios({
        url: '/password.json',
        method: 'PUT',
        data: {
          agent: {
            password: password,
            password_confirmation: passwordConfirmation,
            reset_password_token: token.reset_password_token
          }
        }
      }).then(function (response) {
        props.showSuccess()
      }).catch(function (response){
        setErrors(response.response.data.errors)
      });
    }
  }

  const theme = props.theme === 'light' ? lightTheme : darkTheme

  const errorsFor = (name ) => {
    console.log(errors)
    if (!errors[name])
      return null
    return errors[name].map((o) => o).join(", ")
  }

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <img className={classes.backgroundImg} src={signin_img} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <img className={classes.logo} src={favicon} />
            </Avatar>
            <Typography component='h1' variant='h1' style={{ fontSize: 64, fontWeight: 'bold', marginTop: 50, marginBottom: 100 }}>
              Add New Password
            </Typography>

            <form className={classes.form}
              noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="agent[password]"
                label="Password"
                type="password"
                id="password"
                autoFocus
                error={errorsFor('password')}
                value={password}
                onChange={(e)=> setPassword(e.target.value)}
                helperText={errorsFor('password') ?
                  <FormHelperText id="component-error-text">
                  {errorsFor('password')}
                  </FormHelperText> : null
                }
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="agent[password_confirmation]"
                label="Password confirmation"
                type="password"
                id="password_confirmation"
                autoComplete="current-password"
                error={errorsFor('password_confirmation')}
                value={passwordConfirmation}
                onChange={(e)=> setPasswordConfirmation(e.target.value)}
                helperText={errorsFor('password_confirmation') ?
                            <FormHelperText id="component-error-text">
                              {errorsFor('password_confirmation')}
                            </FormHelperText> : null
                        }
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Set my password
              </Button>

            </form>
          </div>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  )
}

const mapStateToProps = state => {
  const { auth, current_user, theme } = state
  const { loading, isAuthenticated } = auth

  return {
    current_user,
    loading,
    isAuthenticated,
    theme
  }
}

export default connect(mapStateToProps)(ForgotPasswordForm)
