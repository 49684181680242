import React, { Component } from 'react'
import ReplyIcon from '@material-ui/icons/Reply'

const appReply = () => <ReplyIcon />

export default class AppReply extends Component {
  render = () => {
    return (
      <span></span>
    )
  }
}

export const RepliesBlockConfig = ( options = {} ) => {
  let config =  {
    title: 'Add replies',
    type: 'AppReplies',
    icon: appReply,
    block: AppReply,
    editable: true,
    renderable: true,
    breakOnContinuous: false,
    wrapper_class: 'graf graf--AppPackage',
    selected_class: 'is-selected',
    widget_options: {
      displayOnInlineTooltip: true,
      insertion: 'func',
      funcHandler: options.handleFunc,
      insert_block: 'AppReply'
    }
  }

  return Object.assign(config, options)
}
