import React, { PureComponent } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import DataTable, { createTheme } from 'react-data-table-component'

import styles from './styles'

const selectProps = { indeterminate: isIndeterminate => isIndeterminate }

// createTheme('solarized', {
//   text: {
//     primary: '#000',
//     secondary: '#404040',
//   },
//   context: {
//     background: '#cb4b16',
//     text: '#FFFFFF',
//   },
//   divider: {
//     default: '#d2d6de',
//   },
//   button: {
//     default: '#616161',
//     hover: 'rgba(0,0,0,.08)',
//     focus: 'rgba(255,255,255,.12)',
//     disabled: 'rgba(0,0,0,.12)',
//   },
//   sortFocus: {
//     default: '#404040',
//   },
// });

// const styles = {
//   text: {
//     color: '#fff',
//   },
//   subHeader: {
//     style: {
//       padding: 0,
//     },
//   },
//   headCells: {
//     style: {
//       fontWeight: '500',
//       fontSize: 15,
//     },
//   },
//   rows: {
//     style: {
//       borderBottomStyle: 'solid !important',
//       borderBottomColor: '#F8F8F7 !important',
//       borderBottomWidth: '8px !important',
//       padding: '10px 15px 10px 0',
//       fontSize: 15,
//     }
//   },
//   cells: {
//     style: {
//       fontWeight: '500 !important',
//       backgroundClip: 'padding-box',
//       borderRightStyle: 'solid',
//       borderRightColor: 'transparent',
//       borderRightWidth: '1px',
//       '&:not(:first-of-type):not(:last-of-type)::before': {
//         content: '""',
//         position: 'absolute',
//         top: 0,
//         right: 0,
//         bottom: 0,
//         marginTop: '4px',
//         marginBottom: '4px',
//         width: '1px',
//         backgroundImage: 'linear-gradient(#FAF6F1)',
//       },
//     },
//   },

//   pagination: {
//     style: {
//       borderTop: 'none'
//     }
//   }
// };

export class Datatable extends PureComponent {

  render() {
    let { data, ...rest } = this.props

    return(
      <DataTable
        theme='solarized'
        customStyles={styles}
        noHeader
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={selectProps}
        subHeaderWrap={false}
        persistTableHead
        onSelectedRowsChange={s => this.props.onSelectedRowsChange(s)}
        data={data}
        {...rest}
      />
    )
  }
}

export const IconColName = ({ icon, children }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img src={icon} style={{ height: '16px' }} />
    &nbsp;&nbsp;
    { children }
  </div>
)
