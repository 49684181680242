import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AvatarIcon from '../../../assets/images/avatar.png'
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import TextEditor from '../textEditor'
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import MessageIcon from '@material-ui/icons/Message';
import moment from 'moment'
import 'moment-timezone';
import Pagination from 'react-js-pagination';
import styled from '@emotion/styled';
import { startCase } from 'lodash'
import './styles.css'
import { successMessage } from "../actions/status_messages";
import { APP_USERS, GOOGLE_MAPS_KEY } from '../graphql/queries'
import Snackbar from '../components/snackbar'
import { CREATE_CAMPAIGN, DELIVER_USER_AUTO_MESSAGE } from '../graphql/mutations'
import graphql from '../graphql/client'
import { setCurrentSection } from '../actions/navigation'
import { isEmpty } from "lodash"
import Progress from '../../src/shared/Progress'
import { countries } from 'country-data';
import SearchBar from 'material-ui-search-bar'

const MessageTextEditor = styled.div`
  .danteEditorControls {
    div.inlineTooltip:last-child {
      top: 350px !important;
      left: 0 !important;
      opacity: 1;
      .inlineTooltip-button.control {
        display: none !important;
      }
      .inlineTooltip-menu {
        pointer-events: none;
        width: 300px !important;
        button {
          transform: scale(1);
          border: 0;
        }
      }
    }
    div.inlineTooltip.is-active:last-child {
      .inlineTooltip-menu {
        pointer-events: auto;
      }
    }
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #555;
`;

const VisitorDiv = styled.div`
  padding-right: 10px;
  color: #f0c307;
  font-weight: 800;
  font-size: 15px;
`;

const LiveDiv = styled.div`
  background: #f0c307;
  font-weight: 700;
  padding: 2px 6px;
  border-radius: 2px;
  color: 24364c;
`;

const UserEmail = styled.div`
  color: #fff;
  font-weight: 800;
  font-size: 15px;
`

const UserDomain = styled.div`
  color: #a2c1d9;
  font-size: 15px;
`

const sidebarStyle = {
  width: '400px',
  height: '100vh',
  background: '#24364c',
  position: 'fixed',
  zIndex: 1,
  top: 0,
  left: '60px',
};


const userblockStyle = {
  padding: '15px',
  display: 'flex',
};

const useStyles = makeStyles(theme => ({
  avatarImage: {
    filter: "invert(1)"
  },
}))

export const FixedHeader = styled.div`
  padding:20px 10px 20px 5px;
  // border-bottom: 1px solid ${(props)=> props.theme.palette.primary.borders };
  display: flex;
  // background: ${(props)=> props.theme.palette.background.paper };
  justify-content: space-between;
  align-items: center;
  .searchContainer{
    width: 100%;
    margin-left: 5px;
    div, input{
      border: none;
      box-shadow: none;
    }
  }
`

const MapContainer = (props) => {
  const [appUsers, setAppUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalUser, setModalUser] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [currentUser, setCurrentUser] = useState(props.currentUser);
  const [googleMapsKey, setGoogleMapsKey] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0)
  const [mapObj, setMapObj] = useState({})
  const [mapsObj, setMapsObj] = useState({})
  const [markerArr, setMarkerArr] = useState([])
  const [appUserLoading, setAppUserLoading] = useState(false)
  const [canLoadMoreAppUsers, setCanLoadMoreAppUsers] = useState(true)
  const [searchValue, setSearchValue] = useState("")
  const [itemsCountPerPage, setItemsCountPerPage] = useState(20)
  const [readOnly, setReadOnly] = useState(false)
  const [content, setContent] = useState(null)
  const toDisplayItems = ['email', 'lat', 'lng', 'country']

  const classes = useStyles()

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  }

  useEffect(() => {
    fetchGoogleMapsKey();
  }, [])

  const fetchGoogleMapsKey = () => {
    graphql(GOOGLE_MAPS_KEY, { appKey: props.match.params.appId }, {
      success: (data) => {
        setGoogleMapsKey(data.googleMapsKey.key)
      },
      error: (error) => { console.error(error) },
    })
  }

  useEffect(() => {
    fetchAppUsers();
  }, [currentPage])

  useEffect(() => {
    if (Object.keys(mapObj).length !== 0) {
      renderMarkers(mapObj, mapsObj);
    }
  }, [appUsers])

  const fetchAppUsers = (search=false) => {
    graphql(APP_USERS, { appKey: props.match.params.appId, searchQuery: searchValue, page: currentPage, perPage: itemsCountPerPage }, {
      success: (data) => {
        const { appUsers: users, totalRecords: recordsCount } = data.appUsers
        if (search) {
          setAppUsers(users)
          setTotalRecords(recordsCount)
          setAppUserLoading(false)
          return;
        }
        if (isEmpty(users))
          setCanLoadMoreAppUsers(false)
        else
          setAppUsers([...appUsers, ...users])
        setTotalRecords(recordsCount)
        setAppUserLoading(false)
        
      },
      error: (error) => { console.error(error) },
    })
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const saveContent = (c) => {
    setContent({
      html: c.html,
      serialized: c.serialized
    })
  }

  const handleSend = () => {
    const data = {
      id: null,
      name: 'In app message',
      content_type: 'email',
      message_type: 'fullMessage',
      text_message: [],
      from_name: currentUser.firstName,
      from_email: currentUser.email,
      reply_email: currentUser.email,
      template: 'plain',
      html_content: content.html,
      serialized_content: content.serialized,
      scheduled_to: moment().format(),
      scheduled_at: moment().format(),
      timezone: moment.tz.guess(),
    }
    graphql(
      CREATE_CAMPAIGN,
      {
        appKey: props.match.params.appId,
        mode: 'user_auto_messages',
        operation: "create",
        campaignParams: data,
        email: modalUser.email,
        appUserId: modalUser.id
      },
      {
        success: (successData) => {
          const id = successData.campaignCreate.campaign.id

          if (successData.errors) {
            console.error("error updating campaign");
          } else {
            graphql(
              DELIVER_USER_AUTO_MESSAGE,
              {
                appKey: props.match.params.appId,
                id,
              }, {
                success: (success) => {
                  props.dispatch(successMessage('In-app mesage delivered successfully'));
                  handleDialogClose();
                  handleClose();
                }
              })
          }
        },
        error: (error) => {
          console.error('ERROR', error)
        }
      },
    );
  }

  const clearMarkers = () => {
    markerArr.forEach((marker) => {
      marker.setMap(null);
    })
  }

  const renderMarkers = (map, maps) => {
    map.setCenter({ lat: appUsers[0]?.lat, lng: appUsers[0]?.lng })
    clearMarkers();

    const newMarkers = appUsers.map((appUser) => {
      let marker = new maps.Marker({
        id: appUser.id,
        position: { lat: appUser.lat, lng: appUser.lng },
        map,
        title: appUser.displayName,
      });

      let content =
        '<div id="content">'+
          '<div id="siteNotice">'+'</div>'+
          `<div id="firstHeading" style="font-weight:800; font-size: 16px" class="firstHeading">${appUser.displayName}</div>`+
          `<div id="bodyContent" style="font-weight:800; font-size: 16px">${appUser.city}, ${appUser.country}</div>`+
        '</div>';

      let infowindow = new google.maps.InfoWindow({
        content,
      });

      maps.event.addListener(marker, 'click', () => {
        setModalUser(appUser)
        setModalOpen(true)
      });

      maps.event.addListener(marker, 'mouseover', () => {
        infowindow.open(map, marker);
      });

      marker.addListener('mouseout', () => {
        infowindow.close();
      });
      return marker
    })
    setMapObj(map);
    setMapsObj(maps);
    setMarkerArr(newMarkers);
  }

  const openInAppMessageDialog = () => {
    setDialogOpen(true);
  }

  useEffect(() => {
    props.dispatch(setCurrentSection(I18n.t("navigator.visitors")))
  }, [])

  const uploadHandler = ({serviceUrl, signedBlobId, imageBlock}) => {

  }

  const setAppUserAndOpenDialog = (appUser) => {
    setModalUser(appUser);
    setModalOpen(true)
  }

  const handleScroll = ({ target }) => {

    let element = target
    let currentValue = element.scrollHeight - element.scrollTop
    let minMargin = currentValue - 10
    let maxMargin = currentValue + 10

    if (minMargin <= element.clientHeight && maxMargin >= element.clientHeight) {
      if (canLoadMoreAppUsers && !appUserLoading) {
        setCurrentPage(currentPage + 1)
        fetchAppUsers()
      }
    }
  }

  return (
    <>
      <div style={sidebarStyle}>
        <SidebarHeader>
          <VisitorDiv>VISITORS</VisitorDiv>
          <LiveDiv>LIVE</LiveDiv>
        </SidebarHeader>
        <FixedHeader>
          <div className='searchContainer'>
            <SearchBar
              value={searchValue}
              onChange={(newValue) => setSearchValue(newValue)}
              placeholder='Search...'
              onRequestSearch={() => {
                setCurrentPage(1)
                setCanLoadMoreAppUsers(true)
                fetchAppUsers(true)
              }}
              onCancelSearch={(newValue) => setSearchValue("")}
              style={{
                boxShadow: 'none',
                border: 'none',
                borderRadius: 5,
                position: 'relative',
                addingLeft: 40,
              }}
              iconButtonStyle={{
                position: 'absolute',
                left: 10,
                top: 19,
              }}
            />
          </div>
        </FixedHeader>
        <div onScroll={handleScroll} style={{overflowY: 'auto', height: 'calc(100vh - 145px)'}}>
        {
          appUsers.map((appUser) => {
            return (
              <div style={userblockStyle} className="user-block">
                <Avatar src={appUser.avatarUrl} classes={{img: classes.avatarImage}} style={{width: "45px", height: "45px", background: "linear-gradient(to right, #c99bd7, #ab95e8)"}}/>
                <div style={{paddingLeft: "10px"}}>
                  <UserEmail>
                    { appUser.displayName }
                  </UserEmail>
                  <UserDomain>
                    {props.app.domainUrl}
                  </UserDomain>
                </div>
                <div className="message-icon" style={{color: "white"}} onClick={() => setAppUserAndOpenDialog(appUser)}>
                  <MessageIcon />
                </div>
              </div>
            )
          })
        }
        </div>
        {appUserLoading && <Progress/>}
      </div>
      <div style={{ height: '100vh', marginLeft: '410px' }}>
        <Snackbar />
        { googleMapsKey && googleMapsKey.length > 0 &&

          <GoogleMapReact
            bootstrapURLKeys={{ key: googleMapsKey }}
            defaultCenter={{lat: 0, lng: 0}}
            defaultZoom={0}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          >
          </GoogleMapReact>
        }
        <Dialog
          open={modalOpen}
          onClose={handleClose}
          maxWidth='sm'
          fullWidth={true}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <Typography variant="subtitle1" style={{ fontWeight: '600', padding: '20px 30px', borderBottom: '1px solid rgba(0,0,0,.1)', fontSize: 18 }}>In app</Typography>
          <Typography variant="body1" style={{ fontWeight: '400', padding: '10px 30px', borderBottom: '1px solid rgba(0,0,0,.1)', fontSize: 15 }}>
            To:
            <span>
              <Badge>
                <Avatar
                  name={modalUser.email}
                  size="medium"
                  style={{ background: '#f0b3fc', color: '#fff', fontSize: 14, textTransform: 'uppercase', margin: '0 8px' }}
                >
                  {modalUser.name?.substring(0, 2)}
                </Avatar>
              </Badge>
              {modalUser.name}
            </span>
          </Typography>
          <DialogContent style={{height: '200px', display: 'flex', overflow: 'hidden', padding: '8px 30px'}}>
            <DialogContentText id='alert-dialog-description' style={{ width: '100%', overflowY: 'auto' }}>
              <MessageTextEditor>
                <TextEditor
                  campaign={true}
                  uploadHandler={uploadHandler}
                  loaappUserLing={false}
                  read_only={readOnly}
                  toggleEditable={() => {
                    setReadOnly(!readOnly)
                  }}
                  serializedContent={content ? content.serialized : null}
                  data={{
                    serialized_content: content ? content.serialized : null,
                  }}
                  styles={{ lineHeight: '1.2em', fontSize: '1.2em' }}
                  updateState={({ status, statusButton, content }) => {
                    saveContent(content);
                  }}
                />
              </MessageTextEditor>
            </DialogContentText>
          </DialogContent>

          <DialogActions style={{ padding: '15px 40px', borderTop: '1px solid rgba(0,0,0,.1)' }}>
            <Button onClick={handleClose} color='secondary' variant="outlined" style={{ padding: '10px 15px' }}>
              Cancel
            </Button>
            <Button onClick={handleSend} color='primary' variant="contained" style={{ background: "#FFB600", color: "#000", padding: '11px 25px' }}>
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default MapContainer;
