import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import NewEditor from './newEditor'
import styled from "@emotion/styled";
import Avatar from "@material-ui/core/Avatar";

const BrowserSimulatorHeader = styled.div`
  background: rgb(250, 247, 242);
  border-bottom: 1px solid #ececec;
  /* border-radius: 6px; */
  padding: 22px;
  display: flex;
`;
const BrowserSimulatorButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 74px;

  .r {
    width: 30px;
    height: 6px;
    margin-right: 6px;
    border-radius: 4px;
    background-color: #fc635e;
    border: 1px solid #dc4441;
  }
  .y {
    width: 30px;
    height: 6px;
    margin-right: 6px;
    border-radius: 4px;
    background-color: #fdbc40;
    border: 1px solid #db9c31;
  }
  .g {
    width: 30px;
    height: 6px;
    margin-right: 6px;
    border-radius: 4px;
    background-color: #35cd4b;
    border: 1px solid #24a732;
  }
`;

const UserInfo = styled.div`
  display: flex;
  padding: 10px 22px;
  border-bottom: 1px solid #E5E5E5;

  span{
    font-size: 18px;
    font-weight: 400;
  }
`;

function DialogEditor(props) {
  const [open, setOpen] = React.useState(props.open);

  React.useEffect( () => {
    setOpen(props.open)
  }, [ props.open ] );

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    props.close()
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <div>
        <DialogTitle id="alert-dialog-title" style={{ padding: 0 }}>
          <BrowserSimulatorHeader>
            <BrowserSimulatorButtons>
              <div className={"circleBtn r"}></div>
              <div className={"circleBtn y"}></div>
              <div className={"circleBtn g"}></div>
            </BrowserSimulatorButtons>
          </BrowserSimulatorHeader>
          <h3
            style={{ padding: '4px 22px', margin: '0', fontSize: 25, fontWeight: '500', borderBottom: '1px solid #E5E5E5' }}
          >New Conversation</h3>
          <UserInfo style={{ padding: '10px 22px', display: 'flex', borderBottom: '1px solid #E5E5E5'}}>
            <Avatar
              alt="Remy Sharp"
              src={props.app_user.avatarUrl}
              style={{
                width: '30px',
                height: '30px',
                cursor: 'pointer',
                marginRight: 8,
              }}
            />
            <span>{props.app_user.displayName}</span>
          </UserInfo>
        </DialogTitle>

        <DialogContent style={{ border: '1px solid #E5E5E5', borderRadius: 8, margin: '20px 50px', padding: '30px' }}>
          <DialogContentText id="alert-dialog-description" style={{ border: '1px solid #E5E5E5', position: 'relative', borderRadius: '8px' }}>
            <NewEditor
              {...props}
              data={{}}
              submitData={props.handleSubmit}
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{margin: '10px 50px'}}>
          <Button onClick={handleClose} variant="outlined" color="secondary" style={{padding: '10px 20px'}}>
            Cancel
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}


function mapStateToProps(state) {
  const { app_user, app } = state
  return {
    app_user,
    app
  }
}

//export default ShowAppContainer

export default withRouter(connect(mapStateToProps)(DialogEditor))
