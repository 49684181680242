import React, { Component } from "react";
import { isEmpty } from "lodash";
import { keyframes } from '@emotion/core'
import graphql from "../../graphql/client";
import { CREATE_CAMPAIGN, UPDATE_CAMPAIGN, DELIVER_CAMPAIGN, TEST_CAMPAIGN } from "../../graphql/mutations";
import styled from "@emotion/styled";
import { ThemeProvider } from "emotion-theming";
import theme from "../../textEditor/theme";
import EditorContainer from "../../textEditor/editorStyles";
import BrowserSimulatorView from './BrowerSimulatorView';
import EmailSnippetView from './EmailSnippetView';
import TextMessageSnippet from './TextMessageSnippet';
import StatusBadge from '../../components/StatusBadge';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Box from '@material-ui/core/Box'
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'
import {TestEmailDialog} from './test_email';
import {errorMessage, successMessage} from '../../actions/status_messages'
import Logo_B from "../../../../assets/images/logo/Logo_B.png";
import { makeStyles } from '@material-ui/core/styles';
import {setCampaignsNeedToUpdateCount} from '../../actions/campaigns'
import {
  AGENTS
} from "../../graphql/queries"

import { Conversation } from '../../../client_messenger/conversation.js'

import { Picker } from "emoji-mart";
import { EmojiBlock } from "../../../client_messenger/styles/emojimart";
import GiphyPicker from "../../../client_messenger/giphy";
import themeDark from "../../../client_messenger/textEditor/darkTheme";

import cross_icon from '../../../../assets/images/xSquare.png'

import envelope_icon from '../../../../assets/images/wEnvelope.svg'
import text_icon from '../../../../assets/images/TextIcon.svg'
import badge_icon from '../../../../assets/images/badgeIcon.svg'
import min_detail_icon from '../../../../assets/images/minDetailIcon.svg'
import detail_icon from '../../../../assets/images/detailIcon.svg'
import emoji from "../../../../assets/images/emoji.png";
import gif from "../../../../assets/images/gif.png";
import attachment from "../../../../assets/images/attachment.png";
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  CloseIcon,
  LeftIcon,
  MessageIcon,
  SendInlinedIcon,
} from '../../components/icons'


const useStyles = theme => ({
  badge: {
    left: '-20px',
    right: 'unset'
  }
});

const ButtonsContainer = styled.div`
  display: flex;
  direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  float: right;
  margin: 4px 4px;
`;

const ButtonsRow = styled.div`
  align-self: flex-end;
  clear: both;
  margin: 0px;
  button {
    margin-right: 2px;
  }
`;

const UnsubscriptionLinkInfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  #unsubscriptionLinkInfoWrapper {
    margin-top: 10px;
    margin-right: 10px;
    #unsubscriptionLinkInfoHeading {
      color: grey;
    }
    .change-link {
      margin-left: 5px;
    }
    input[type='radio'] {
      display: inline;
      margin: 0 10px;
    }
  }
`;

const EditorContentWrapper = styled.div`
  padding: 0 50 50px;
  border: solid 1px rgba(0,0,0,0.12);
  border-radius: 4px;
`;

const EditorHead = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,.125);
  padding: 30px 25px !important;
  .MuiOutlinedInput-notchedOutline{
    border-radius: 7px;
    border-color: #E5E5E5;
  }
  .MuiButton-outlinedPrimary {
    padding: 10px 50px;
  }
  .MuiButton-label: {
    font-weight: 400;
  }
  .MuiFormControl-root, .MuiButton-outlinedPrimary {
    margin: 0 12px;
  }
  input, .MuiSelect-selectMenu{
    padding: 15px 25px;
    width: 197px;
    color: #000;
  }
  ${(props) => props.justify ? `justify-content: ${props.justify};` : `justify-content : space-between;`}
  justify-content: flex-start;
`;

const EditorFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 40px;
  padding-bottom: 0;
  ${(props) => props.justify ? `justify-content: ${props.justify};` : `justify-content: space-between;`}
`;

const EditorMessengerEmulator = styled.div`
  ${(props) =>
    props.mode === "user_auto_messages"
      ? `
  display:flex;
  justify-content: flex-end;`
      : ``}
`;

const EditorMessengerEmulatorWrapper = styled.div`
  ${(props) =>
    props.mode === "user_auto_messages"
      ? `width: 380px;
    background: #fff;
    border: 1px solid #f3efef;
    margin-bottom: 25px;
    margin-right: 20px;
    box-shadow: 3px 3px 4px 0px #b5b4b4;
    border-radius: 10px;
    padding: 12px;
    padding-top: 0px;
    .icon-add{
      margin-top: -2px;
      margin-left: -2px;
    }
    `
      : ``}
`;

const EditorMessengerEmulatorHeader = styled.div`
  ${(props) =>
    props.mode === "user_auto_messages"
      ? `
  padding: 1em;
  border-bottom: 1px solid #ccc;
  `
      : ``}
`;

const MailCompaignFooterContainer = styled.div`
  text-align: center;
  text-align: -webkit-center;
`;

const MailCompaignFooter = styled.div`
  .plain-template-footer {
    .heading1 {
      text-align: center;
      font-size: 13px;
      a {
        color: #777777;
      }
    }
  }
  .company-template-footer {
    font-size: 13px;
    .heading1 {
      color: grey;
      margin-bottom: 10px;
    }
    .heading2 a { color: grey; }
  }
`;

const MailCompaignFooterBottom = styled.div`
  margin-top: 60px;
  margin-bottom: 14px;
  color: grey;
  font-size: 10px;
`;

const rotate = keyframes`
  from {
    transform: rotate(-45deg);
    //transform: scale(0.5);
    translateY(-30);
  }

  to {
    transform: rotate(0deg);
    //transform: scale(1);
    transform: translateY(-8px);
  }
`;

export const Prime = styled.div`
  position: relative;
  display: block;
  width: 70px;
  height: 70px;
  line-height: 30px;
  border-radius: 10%;
  text-align: center;
  margin: 0 0;
  cursor: pointer;
  transition: all .1s ease-out;
  position: relative;
  z-index: 998;
  //overflow: hidden;
  color: black;
  background: #ffb600;
  float: right;
  /* margin: 14px 27px; */
  margin: 16px 8px;
  animation: ${rotate} 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`

const CardPadder = styled.div`
`

const CardContent = styled.div`
  padding: 0 1.3em;
`

const ButtonGroupWrapper = styled.div`
  display: inline-flex;

  .MuiTypography-subtitle1{
    font-size: 15px;
    color: #777;
    margin-left: 20px;
  }
  .MuiToggleButtonGroup-root{
    margin-left: 20px;
    border: 1px solid #000;
    border-radius: 5px;
  }
  .MuiToggleButton-root.Mui-selected{
    border-left: 1px solid;
    background-color: #FAF6F1;
  }
  .MuiToggleButton-root {
    padding: 10px 35px;
    border-right: 1px solid #000;

    .MuiTypography-body1 {
      font-size: 15px;
      color: #000;
      padding-left: 12px;
      text-transform: capitalize;
    }
  }
`
const EditorWrapper = styled.div`
  /*height: 100px;
  display: flex;*/
  width: 80vw;
`;

const TypingContainer = styled.div`
  bottom: 215px;
  left: 0;
  right: 0;
  border-radius: 8px;
  ${(props) =>
    props.footerClassName
      ? "min-height: 49px; max-height: 200px; border-top: none;"
      : "border-top: 1px solid #e6e6e6; min-height: 56px; max-height: 355px;"}
`;


const Input = styled.textarea`
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    left: 0;
    color: #000;
    resize: none;
    border: 0;
    padding: 30px 100px 20px 30px;
    width: 100%;
    height: 100%;
    font-family: "Helvetica Neue","Apple Color Emoji",Helvetica,Arial,sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.33;
    background-color: #fff;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: left;
    outline: transparent;
    &:focus {
      background-color: #fff;
      -webkit-box-shadow: 0 0 100px 0 rgba(0,0,0,.1);
      box-shadow: 0 0 100px 0 rgba(0,0,0,.1);
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: "#999";
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: "#999";
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: "#999";
    }
    :-moz-placeholder { /* Firefox 18- */
      color: "#999";
    }

    :disabled::-webkit-input-placeholder { /* WebKit browsers */
        color: "#f3f3f3";
    }
    :disabled:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: "#f3f3f3";
    }
    :disabled::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: "#f3f3f3";
    }
    :disabled:-ms-input-placeholder { /* Internet Explorer 10+ */
        color: "#f3f3f3";
    }
}
`;

const EditorButtons = styled.div`
    position: absolute;
    bottom: 10px;
    right: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;

    button {
      width: 35px;
      height: 55px;
      line-height: 31px;
      background: transparent;
      border: 1px solid;
      border-color: #999;
      //color: #999;
      border-radius: 999em;
      cursor: pointer;
      text-indent: -6px;

      border-width: 0px;
      outline:none;

      &:disabled{
        opacity: 0.5;
        pointer-events: none;
      }

      svg{
        width: 26px;
        height: 26px;
        color: #ccc;
        //path {
        //  fill: #555;
        //}

        fill: #555;

        &:hover{
          fill: #c1c1c1
        }
      }
    }
  }
`;

export const MessageItem = styled.div`
    position: relative;
    min-width: 25%;
    display: block;
    word-wrap: break-word;
    -webkit-animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
    animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
    clear: both;
    z-index: 999;
    margin: .7em 0;

    font-size: 15px;

    &.admin {
      align-self: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .message-content-wrapper {
        background: #F2E6D8;
        padding: 20px;
        color: #000;
        margin: 12px 20px 0px 10px;
        border-radius: 6px;
        min-width: 80px;

        line-height: 1.5;
        position: relative;
        border-radius: 8px 8px 8px 0px;
      }

      .text{
        p{
          color: #000;
        }
      }
    }

    &.user {

      .message-content-wrapper {
        background: linear-gradient(135deg,#121212 0%,#000 100%);
        color: #121212;

        min-width: 80px;
        padding: 16px;
        padding-left: 25px;
        margin: 5px 15px 5px 5px;
        border-radius: 6px;
        min-width: 80px;
        line-height: 1.5;
        height: 100%;
        position: relative;
        border-radius: 8px 8px 0px 8px;
      }


      .graf-image {
        position: inherit !important;
      }


      color: #eceff1;

      .text{
        p{
          color: #fff;
        }
      }
    }

    .text{
      margin-bottom: 1em;
      color: #eceff1;
      p{
        font-size: 0.86rem;
        margin: 0px;
        padding: 0px;
      }
    }

    .status {
      display: flex;
      justify-content: flex-end;
      color: #b1afaf;
      font-size: 10px;
      text-align: right;
    }
`;

class CampaignEditor extends Component {
  constructor(props) {
    super(props);

    this.ChannelEvents = null;
    this.conn = null;
    this.menuResizeFunc = null;
    this.state = {
      loading: true,
      currentContent: null,
      diff: "",
      videoSession: false,
      selectionPosition: null,
      incomingSelectionPosition: [],
      data: {},
      errors: {},
      status: "",
      read_only: false,
      statusButton: "inprogress",
      testEmailDialog: false,
      campaignStoryParams: {},
      replyType1: this.props.data.contentType || 'email',
      replyType2: this.props.data.messageType ||'fullMessage',
      html_content: this.props.data.htmlContent,
      serialized_content: this.props.data.serializedContent,
      template: props.data.template || 'plain',
      template_was: props.data.template || 'plain',
      subject: this.props.data.subject,
      canSendTestEmail: this.props.data.id != null,
      showUnsubscriptionOptions: false,
      includeUnsubscriptionLink: true,
      footerAddress: this.props.data.companyAddress || '3503 Jack Northrop Ave - Hawthorne, CA 90250',
      editModeFooterAddress: false,
      helpEmail: this.props.data.companyEmailAddress || 'help@projectxyz.com',
      editModeHelpEmail: false,
      helpContact: this.props.data.companyPhoneNumber || '1(800)232-90-26',
      editModeHelpContact: false,
      viewOnly: this.props.viewOnly || false,
      agents: [],
      agent: {email: props.data.fromEmail},
      reply_agent: {email: props.data.replyEmail},
      htmlEmailContent: "",
      openMessenger: true,
      emojiEnabled: false,
      giphyEnabled: false,
      submitable: false,
      msgs: this.props.data.textMessage || [],
    }

    this.fileUploader = React.createRef()
  }

  componentDidMount = () => {
    this.getAgents()
    this.updateParentComponent()
  }

  updateParentComponent = () => {
    let attr = {
      subject: this.state.subject,
      replyType1: this.state.replyType1,
      replyType2: this.state.replyType2,
      msgs: this.state.msgs,
      agent: this.state.agent,
      reply_agent: this.state.reply_agent,
      template: this.state.template,
      html_content: this.state.html_content,
      serialized_content: this.state.serialized_content,
      htmlEmailContent: this.state.htmlEmailContent
    }
    this.props.updateCampaignData(attr)
  }

  saveContent = content => {
    if (this.props.data.serializedContent === content.serialized) return

    this.setState({
      status: 'saving...',
      statusButton: 'success',
      html_content: content.html,
      serialized_content: content.serialized
    });
    this.props.updateCampaignData({html_content: content.html})
    this.props.updateCampaignData({serialized_content: content.serialized})
  }

  setReadState = (read_only) => {
    this.setState({ read_only: !read_only })
  }

  getAgents = () => {
    graphql(AGENTS, { appKey: this.props.app.key }, {
      success: data => {
        let agent = data.app.agents.filter(agent => agent.email === this.state.agent.email)[0]
        this.setState({
          agents: data.app.agents,
          agent: agent
        })
      },
      error: error => {}
    })
  }

  emptyHTMLContent = (e) => {
    e.stopPropagation()
    this.setState({ htmlEmailContent: null })
    graphql(UPDATE_CAMPAIGN, {
      appKey: this.props.app.key,
      id: this.props.data.id,
      operation: "update",
      campaignParams: {
        id: this.props.data.id,
        html_email_content: null
      }
    }, {
      success: data => {
        const result = data.campaignUpdate

        this.setState({ data: result.campaign, errors: result.errors })

        if (isEmpty(this.state.errors)) {
          this.props.dispatch(successMessage('Campaign updated'))
          this.props.dispatch(setCampaignsNeedToUpdateCount())
          this.props.updateData(data.campaignUpdate.campaign, null)
        } else {
          const errs = []
          Object.entries(this.state.errors).map((key) => {
            errs.push(key[0] + ' ' + key[1].join(', '))
          })
          this.props.dispatch(errorMessage(errs.join(', ')))
        }
      },
      error: error => {
        console.log(error)
      }
    })
  }

  updateOrCreate = () => {
    this.setcampaignParams()

    if (this.props.data.id == null) {
      graphql(
        CREATE_CAMPAIGN, {
          appKey: this.props.app.key,
          mode: this.props.mode,
          operation: "create",
          campaignParams: {
            id: this.props.data.id,
            name: this.props.data.name,
            subject: this.state.subject,
            content_type: this.state.replyType1,
            message_type: this.state.replyType2,
            text_message: this.state.msgs,
            from_name: this.state.agent ? this.state.agent.name : '',
            from_email: this.state.agent ? this.state.agent.email : '',
            reply_email: this.state.reply_agent.email,
            template: this.state.template,
            html_content: this.state.html_content,
            serialized_content: this.state.serialized_content,
            html_email_content: this.state.htmlEmailContent,
            company_address: this.state.footerAddress,
            company_email_address: this.state.helpEmail,
            company_phone_number: this.state.helpContact,
          }
        }, {
          success: data => {
            this.setState({
              data: data.campaignCreate.campaign,
              errors: data.campaignCreate.errors
            })

            if (isEmpty(this.state.errors)) {
              this.props.dispatch(successMessage('Email saved successfully!'))
              this.props.dispatch(setCampaignsNeedToUpdateCount())
              this.props.updateData(data.campaignCreate.campaign, null);
              this.props.handleTabChange(null, this.props.currentTab + 1)
              this.props.history.push(`/apps/${this.props.app.key}/messages/${this.props.mode}/${data.campaignCreate.campaign.id}/edit`)
            } else {
              const errs = []
              Object.entries(this.state.errors).map((key) => {
                errs.push(key[0] + ' ' + key[1].join(', '))
              })

              this.props.dispatch(errorMessage(errs.join(', ')))
            }
          }
        }
      )
    } else {
      graphql(UPDATE_CAMPAIGN, {
        appKey: this.props.app.key,
        id: this.props.data.id,
        operation: "update",
        campaignParams: {
          id: this.props.data.id,
          name: this.props.data.name,
          subject: this.state.subject,
          from_name: this.state.agent.name,
          from_email: this.state.agent.email,
          reply_email: this.state.reply_agent.email,
          content_type: this.state.replyType1,
          message_type: this.state.replyType2,
          text_message: this.state.msgs,
          template: this.state.template,
          html_content: this.state.html_content,
          serialized_content: this.state.serialized_content,
          html_email_content: this.state.htmlEmailContent,
          company_address: this.state.footerAddress,
          company_email_address: this.state.helpEmail,
          company_phone_number: this.state.helpContact,
        }
      }, {
        success: data => {
          const result = data.campaignUpdate

          this.setState({ data: result.campaign, errors: result.errors })

          if (isEmpty(this.state.errors)) {
            this.props.dispatch(successMessage('Email saved successfully!'))
            this.props.dispatch(setCampaignsNeedToUpdateCount())
            this.props.updateData(data.campaignUpdate.campaign, null)
            this.props.handleTabChange(null, this.props.currentTab + 1)
          } else {
            const errs = []

            Object.entries(this.state.errors).map((key) => {
              errs.push(key[0] + ' ' + key[1].join(', '))
            })

            this.props.dispatch(errorMessage(errs.join(', ')))
          }
        },
        error: error => {
          console.log(error)
        }
      })
    }
  }

  saveHandler = (html3, plain, serialized) => {}

  uploadHandler = ({ serviceUrl, imageBlock }) => {
    imageBlock.uploadCompleted(serviceUrl)
  }

  handleSend = e => {
    const params = {
      appKey: this.props.app.key,
      id: this.props.data.id
    }

    graphql(DELIVER_CAMPAIGN, params, {
      success: data => {
        this.props.updateData(data.campaignDeliver.campaign, null)
        this.setState({ status: 'saved' })
      },
      error: () => {}
    })
  }

  setcampaignParams = () => {
    this.setState({
      campaignParams: {
        id: this.props.data.id,
        name: this.props.data.name,
        subject: this.state.subject,
        from_name: this.state.agent ? this.state.agent.name : '',
        from_email: this.state.agent ? this.state.agent.email : '',
        reply_email: this.state.reply_agent.email,
        content_type: this.state.replyType1,
        message_type: this.state.replyType2,
        text_message: this.state.msgs,
        template: this.state.template,
        html_content: this.state.html_content,
        serialized_content: this.state.serialized_content,
        includeUnsubscriptionLink: this.state.includeUnsubscriptionLink,
        footerAddress: this.state.footerAddress,
        helpEmail: this.state.helpEmail,
        helpContact: this.state.helpContact,
        html_email_content: this.state.htmlEmailContent
      }
    })
  }

  testEmailDialogOpen = () => {
    this.setState({testEmailDialog: true})
  }

  onFileChangeHandler= (e) => {
    const reader = new FileReader()

    reader.onload = (e) => {
      const text = (e.target.result)
      this.setState({htmlEmailContent: text})
      this.props.updateCampaignData({htmlEmailContent: text})
    }

    reader.readAsText(e.target.files[0])
  }

  testEmailDialogClose = () => {
    this.setState({ testEmailDialog: false })
  }

  changeSubjectHandler = (e) => {
    this.setState({subject: e.target.value})

    this.props.updateCampaignData({subject: e.target.value})
  }

  selectClickHandler = (e) => {
    this.setState({template_was: this.state.template})
    this.setState({template: e.target.value})
    this.props.updateCampaignData({template: e.target.value})
  }

  selectAgentClickHandler = (e) => {
    if (e.target.value) {
      let agent = this.state.agents.filter(agent => agent.email === e.target.value)[0]
      this.setState({agent: agent})
      this.props.updateCampaignData({agent: agent})
    }
  }

  selectReplyAgentClickHandler = (e) => {
    if (e.target.value) {
      let agent = this.state.agents.filter(agent => agent.email === e.target.value)[0]
      this.setState({reply_agent: agent})
      this.props.updateCampaignData({reply_agent: agent})
    }
  }

  renderTestDialog = () => (
    <TestEmailDialog
      {...this.props}
      handleClose={this.testEmailDialogClose}
      subject={this.state.subject}
      content_type={this.state.replyType1}
      message_type={this.state.replyType2}
      text_message={this.state.msgs}
      from_name={this.state.agent ? this.state.agent.name : ''}
      from_email={this.state.agent ? this.state.agent.email : ''}
      reply_email={this.state.reply_agent.email}
      template={this.state.template}
      html_content={this.state.html_content}
      serialized_content={this.state.serialized_content}
      html_email_content={this.state.htmlEmailContent}
      company_address={this.state.footerAddress}
      company_email_addres={this.state.helpEmail}
      company_phone_number={this.state.helpContact}
    />
  )

  personalFooter = () => {
    const { app } = this.props
    return(
      <p className='mob_title2' style={{ color: '#777777', fontSize: '13px', lineHeight: '1.2', display: 'inline-flex' }}>
        <span>
          <Avatar
            src={this.state.agent && this.state.agent.avatarUrl}
          />
        </span>
        <span style={{ textTransform: 'capitalize', paddingTop: '10px', paddingLeft: '15px' }}>{ `${this.state.agent && this.state.agent.name ? this.state.agent.name : 'UpSend'}` } from {app && app.name}</span>
      </p>
    )
  }

  chagneFooterAddress = e => {
    this.setState({editModeFooterAddress: true})
  }

  changeHelpEmail = e => {
    this.setState({editModeHelpEmail: true})
  }

  changeHelpContact = e => {
    this.setState({editModeHelpContact: true})
  }

  updateFooterAddress = e => {
    this.setState({ editModeFooterAddress: false })
    this.setState({ footerAddress: e.target.value })
  }

  updateHelpEmail = e => {
    this.setState({ editModeHelpEmail: false })
    this.setState({ helpEmail: e.target.value })
  }

  updateHelpContact = e => {
    this.setState({ editModeHelpContact: false })
    this.setState({ helpContact: e.target.value })
  }

  handleFooterAddressInput = e => {
    this.setState({ footerAddress: e.target.footerAddress })
  }

  handleHelpEmailInput = e => {
    this.setState({ helpEmail: e.target.helpEmail })
  }

  handleHelpContactInput = e => {
    this.setState({ helpContact: e.target.helpContact })
  }

  mailCompaignFooter = () => {
    const template = this.state.template || this.state.template_was

    switch (template) {
      case 'plain':
        return (
          <div class='plain-template-footer'>
            {
              this.state.includeUnsubscriptionLink &&
                <div class='heading1'>
                  <a href='javascript:void(0)'>Unsubscribe from our emails</a>
                </div>
            }
          </div>
        )
      case 'personal':
        return ''
      case 'company':
        return (
          <div class='company-template-footer'>
            {
              !this.state.editModeFooterAddress ?
                <div class='heading1' onClick={this.chagneFooterAddress}>{this.state.footerAddress}</div> :
                <input
                  className='heading1'
                  style={{ marginTop: '0', paddingTop: '0', width: '75%' }}
                  value={this.state.footerAddress}
                  onChange={this.handleFooterAddressInput}
                  onBlur={this.updateFooterAddress}
                />
            }
            <div class='heading2'>
              {
                !this.state.editModeHelpEmail ?
                  <span class='help-email' onClick={this.changeHelpEmail}> {this.state.helpEmail} </span> :
                  <input
                    style={{ display: 'inline', marginTop: '0', paddingTop: '0', width: '50%' }}
                    value={this.state.helpEmail}
                    onChange={this.handleHelpEmailInput}
                    onBlur={this.updateHelpEmail}
                  />
              }
                  |
              {
                !this.state.editModeHelpContact ?
                  <span class='help-contact' onClick={this.changeHelpContact}> {this.state.helpContact} </span> :
                  <input
                    style={{ display: 'inline', marginTop: '0', paddingTop: '0', width: '50%'}}
                    value={this.state.helpContact}
                    onChange={this.handleHelpContactInput}
                    onBlur={this.updateHelpContact}
                  />
              }
              {
                this.state.includeUnsubscriptionLink &&
                  <span>| <span class='unsubscription-link'> <a href='javascript:void(0)'>Unsubscribe</a></span></span>
              }
            </div>
          </div>
        )
      default:
        return ''
        break
    }
  }

  changeIncludeUnsubscriptionOption = e => {
    let isAllowed = e.target.getAttribute('value') == 'yes'
    this.setState({includeUnsubscriptionLink: isAllowed})
  }

  unsubscriptionLinkInfoSection = () => (
    <UnsubscriptionLinkInfoSection>
      <div>
      </div>
      <div id='unsubscriptionLinkInfoWrapper'>
        <span class='new-line' id='unsubscriptionLinkInfoHeading'>Unsubscribe options</span>
        <span class='new-line'>This email includes an unsubscribe link
        <Link href='javascript:void(0)' color='primary' class='change-link' onClick={this.toggleUnsubscriptionOptions}>
          Change
        </Link>
        </span>
        {this.state.showUnsubscriptionOptions && this.unsubscriptionLinkOptions()}
      </div>
    </UnsubscriptionLinkInfoSection>
  )

  unsubscriptionLinkOptions = () => (
    <div>
      <span class='new-line'>
        <input type='radio' id='unsubscriptionLinkInclude' name='unsubscription_link' value='yes' checked={this.state.includeUnsubscriptionLink} onChange={this.changeIncludeUnsubscriptionOption} />
        <label for='unsubscriptionLinkInclude'>Include an unsubscribe link<small>(Recommended)</small></label>
      </span>
      <span class='new-line'>
        <input type='radio' id='unsubscriptionLinkExclude' name='unsubscription_link' value='no' checked={!this.state.includeUnsubscriptionLink} onChange={this.changeIncludeUnsubscriptionOption} />
        <label for='unsubscriptionLinkExclude'>Don't include an unsubscribe link</label>
      </span>
    </div>
  )

  toggleEmojiClick = e => {
    e.preventDefault()
    this.toggleEmoji()
    this.setState({ emojiEnabled: !this.state.emojiEnabled })
  };

  toggleEmoji = e => {
    this.setState({ emojiEnabled: !this.state.emojiEnabled })
  }

  toggleGiphy = e => {
    e.preventDefault()

    this.setState({ giphyEnabled: !this.state.giphyEnabled })
  }

  insertAtCursor = myValue => {
    const myField = this.input
    //IE support
    if (document.selection) {
      myField.focus()
      var sel = document.selection.createRange()
      sel.text = myValue
    }
    //MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart == '0') {
      var startPos = myField.selectionStart
      var endPos = myField.selectionEnd
      myField.value =
        myField.value.substring(0, startPos) +
        myValue +
        myField.value.substring(endPos, myField.value.length)
    } else {
      myField.value += myValue
    }
  }

  handleEmojiInsert = e => {
    this.toggleEmoji()

    this.insertAtCursor(e.native)
  }

  submitImage = (link, cb) => {
    const html = `<img width=100% src="${link}"/>`;
    // this.props.insertComment(
    //   {
    //     html_content: html,
    //     serialized_content: this.convertToDraft(html),
    //   },
    //   {
    //     before: () => {
    //       this.input.value = "";
    //     },
    //     sent: () => {
    //       this.input.value = "";
    //       cb && cb();
    //     },
    //   }
    // );
  }

  handleReturn = e => {
    if (this.input.value !== '') {
      this.setState({ submitable: true })

      if (e.key === 'Enter') {
        this.handleSubmit(e)
        return
      }
    } else {
      this.setState({ submitable: false })
    }
  }

  saveGif = data => {
    this.submitImage(data.images.downsized_medium.url, () => {
      this.setState({ giphyEnabled: false })
    })
  }

  handleInputClick = () => {
    // this.refs.upload_input.click();
  }

  handleUpload = () => {

  }

  handleSubmit = (e) => {
    // e.preventDefault();
    if (this.input.value === '') return
    let newMsgs = this.state.msgs
    newMsgs.push(this.input.value)
    this.setState({ msgs: newMsgs })
    this.props.updateCampaignData({ msgs: newMsgs })
  }

  renderMessage = o => {
    // const userClass = o.appUser.kind === "agent" ? "admin" : "user";
    // const isAgent = o.appUser.kind === "agent";
    // const themeforMessage = o.privateNote || isAgent ? theme : themeDark;
    // const { t } = this.props;

    const userClass = 'admin'
    const isAgent = true
    const themeforMessage = isAgent ? theme : themeDark

    return (
      <div>
        <MessageItem
          className={userClass}
        >
          <div className='message-content-wrapper'>{o}</div>
          {/*{!this.props.isUserAutoMessage(o) && isAgent ? (
            <ConversationSummaryAvatar>
              <img src={o.appUser.avatarUrl} />
            </ConversationSummaryAvatar>
          ) : null}*/}

          {/*<div className="message-content-wrapper">
            {this.props.isUserAutoMessage(o) ? (
              <UserAutoChatAvatar>
                <img src={o.appUser.avatarUrl} />
                <span>{o.appUser.name || "^"}</span>
              </UserAutoChatAvatar>
            ) : null}*/}

            {/*render light theme on user or private note*/}

            {/*<ThemeProvider theme={themeforMessage}>
              <DanteContainer>
                <DraftRenderer
                  key={i}
                  message={o}
                  domain={this.props.domain}
                  raw={JSON.parse(o.message.serializedContent)}
                />

                {<span className="status">
                  {o.readAt ? (
                    <Moment fromNow>{o.readAt}</Moment>
                  ) : (
                    <span>{t("not_seen")}</span>
                  )}
                </span>}
              </DanteContainer>
            </ThemeProvider>
          </div>*/}
        </MessageItem>
      </div>
    )
  }

  renderMessageTyping = () => (
    <TypingContainer>
      <div>
        <Input
          onKeyPress={this.handleReturn}
          placeholder='Start Typing...'
          style={{ position: 'relative', border: '1px solid #E5E5E5', height: '80px', borderRadius: '7px', boxShadow: '0px 2px 5px 1px rgba(0,0,0,.1)' }}
          ref={(comp) => (this.input = comp)}
        ></Input>

        <EditorButtons>
          {!this.state.submitable && (
            <button onClick={this.handleInputClick}>
              {/* <AttachIcon/> */}
              <img
                src={attachment}
                style={{ width: 18, height: 18, marginTop: 2 }}
              />
              <input
                type='file'
                ref='upload_input'
                style={{ display: 'none' }}
                onChange={this.handleUpload}
              />
            </button>
          )}

          <button onClick={this.toggleEmojiClick}>
            <img
              src={emoji}
              style={{ width: 18, height: 18, marginTop: 2 }}
            />
            {/* <EmojiIcon/> */}
          </button>
          {!this.state.submitable && (
            <button onClick={this.toggleGiphy}>
              <img
                src={gif}
                style={{ width: 15, height: 18, marginTop: 2 }}
              />
              {/* <GifIcon/> */}
            </button>
          )}

          {this.state.submitable && (
            <button
              onClick={this.handleSubmit}
              style={{
                borderRadius: 5,
                height: 50,
                width: 50,
                marginTop: -9,
                backgroundColor: '#ffb600',
                paddingTop: 10,
                paddingLeft: 15,
              }}
            >
              <SendOutlinedIcon style={{ fill: '#000' }} />
            </button>
          )}
        </EditorButtons>
      </div>
    </TypingContainer>
  )

  toggleUnsubscriptionOptions = () => {
    let newState = !this.state.showUnsubscriptionOptions
    this.setState({ showUnsubscriptionOptions: newState})
  }

  handleReplyType1 = (event, type) => {
    this.setState({ replyType1: type })
    this.props.updateCampaignData({ replyType1: type })
  };

  handleReplyType2 = (event, type) => {
    this.setState({ replyType2: type })
    this.props.updateCampaignData({ replyType2: type })
  };

  toggleMessenger = () => {
    this.setState({ openMessenger: !this.state.openMessenger })
  }

  renderTemplate = () => (
    <div style={{ position: 'relative' }}>
      <div style={{width: 430}}>
        <EmailSnippetView
          agents={this.state.agents}
          toggleMessenger={this.toggleMessenger}
          replyType1={this.state.replyType1}
          replyType2={this.state.replyType2}
          subject={this.state.subject}
          changeSubjectHandler={this.changeSubjectHandler}
          uploadHandler={this.uploadHandler}
          saveHandler={this.saveHandler}
          saveContent={this.saveContent}
          agent={this.state.agent}
          serializedContent={this.props.data.serializedContent}
          setReadState={this.setReadState}
          read_only={this.state.read_only}
          app={this.props.app}
          typing={this.renderMessageTyping()}
        />
        {
          this.state.replyType1 === 'text' &&
            <div style={{ width: 405, right: '-3%', position: 'relative', bottom: 0, zIndex: '90000000000000000' }}>
              { this.renderMessageTyping() }
            </div>
        }
      </div>
    </div>
  )

  render() {
    const palette = { primary: '#121212', secondary: '#121212' }
    const { agents } = this.state

    return (
      <React.Fragment>
        { this.state.testEmailDialog ? this.renderTestDialog() : null }
        <Grid
          alignContent='space-around'
          style={{ borderRadius: '7px' }}
          justify='space-around'
        >
          <EditorContentWrapper mode={this.props.mode}>
            {
              !this.state.viewOnly &&
              <React.Fragment>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E5E5E5', display: 'flex', justifyContent: 'space-between', padding: 20 }}>
                   <Box m={2}>
                    <Typography style={{ fontSize: '18px', marginTop: '10px'}} >
                      Create your message
                    </Typography>
                   </Box>
                   <Box m={2} align='right'>
                     <Button
                       color='primary'
                       size='large'
                       variant='contained'
                       style={{ background: '#FFB600', color: '#000' }}
                       onClick={this.updateOrCreate}>
                       Next
                     </Button>
                   </Box>
                </Grid>
                <Grid item xs={12}>
                  <EditorHead>
                    <TextField variant='outlined' label='From' value={this.state.agent && this.state.agent.email} onClick={this.selectAgentClickHandler} select>
                      {
                        agents.map(option => (
                          <MenuItem value={option.email}> {option.name} </MenuItem>
                        ))
                      }
                    </TextField>
                    <TextField variant='outlined' label='Replies assigned to' value={this.state.reply_agent.email} onClick={this.selectReplyAgentClickHandler} select>
                      {
                        agents.map(option => (
                          <MenuItem value={option.email}> {option.name} </MenuItem>
                        ))
                      }
                    </TextField>
                    {
                      this.props.mode !== 'user_auto_messages' ? (
                        <div>
                          <TextField variant='outlined' label = 'Email Template' value={this.state.template} onClick={this.selectClickHandler} select>
                            <MenuItem value='plain'>Plain</MenuItem>
                            <MenuItem value='personal'>Personal</MenuItem>
                            <MenuItem value='company'>Company</MenuItem>
                          </TextField>
                          <Button style={{ marginLeft: 70 }} variant='outlined' color='primary' onClick={this.testEmailDialogOpen}>
                            Send a test email
                          </Button>
                          <Button variant='outlined' color='primary' onClick={()=>{this.htmlUpload.click()}}>
                            <input type='file' name='file' ref={(ref) => this.htmlUpload = ref} style={{ display: 'none' }} onChange={this.onFileChangeHandler}/>
                            {this.props.data.htmlEmailContent === null
                            ? "Import HTML email"
                            : <>
                                Update HTML email
                                <DeleteIcon onClick={this.emptyHTMLContent} style={{cursor: "pointer", position: "absolute", right: "-30px"}}/>
                              </>}
                          </Button>
                        </div>
                      ) : (
                        <ButtonGroupWrapper>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {/*<Typography variant='subtitle1'>Reply Type</Typography>*/}
                            <ToggleButtonGroup value={this.state.replyType1} exclusive onChange={this.handleReplyType1}>
                              <ToggleButton value='text'>
                                <img src={text_icon} />
                                <Typography variant="body1">Text</Typography>
                              </ToggleButton>
                              <ToggleButton value='email' style={{ borderRight: 0 }} >
                                <img src={ envelope_icon } />
                                <Typography variant="body1">Email collector</Typography>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </div>

                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {/*<Typography variant="subtitle1">Reply Type</Typography>*/}
                            <ToggleButtonGroup value={this.state.replyType2} exclusive onChange={this.handleReplyType2}>
                              <ToggleButton value='badge'>
                                <img src={badge_icon} />
                                <Typography variant="body1">Badge</Typography>
                              </ToggleButton>
                              <ToggleButton value='snippet'>
                                <img src={ min_detail_icon } />
                                <Typography variant="body1">Snippet</Typography>
                              </ToggleButton>
                              <ToggleButton value='fullMessage' style={{ borderRight: 0 }}>
                                <img src={ detail_icon } />
                                <Typography variant="body1">Full Message</Typography>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </div>
                        </ButtonGroupWrapper>
                      )
                    }
                  </EditorHead>
                </Grid>
              </React.Fragment>
            }
            <Grid item xs={12} style={{ padding: 10, margin: '20px', minHeight: 600, position: 'relative', borderRadius: '8px' }}>
              {
                this.props.mode !== 'user_auto_messages' ? (
                <div>
                  { !this.state.viewOnly && ((['plain', 'company'].indexOf(this.state.template || this.state.template_was)) > -1) && this.unsubscriptionLinkInfoSection() }
                  <BrowserSimulatorView
                    mode={this.props.mode}
                    subject={this.state.subject}
                    changeSubjectHandler={this.changeSubjectHandler}
                    agent={this.state.agent}
                    app={this.props.app}
                    uploadHandler={this.uploadHandler}
                    read_only={this.state.read_only}
                    serializedContent={this.props.data.serializedContent}
                    saveContent={this.saveContent}
                    saveHandler={this.saveHandler}
                    template={this.state.template}
                    personalFooter={this.personalFooter}
                    mailCompaignFooter={this.mailCompaignFooter}
                    setReadState={this.setReadState}
                    template_was={this.state.template_was}
                  />
                </div>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', minHeight: 'inherit' }}>
                  { this.state.openMessenger && this.renderTemplate() }
                  <div style={{
                    width: 430,
                    alignSelf: 'center',
                  }}>
                    <div>
                      <Prime id='upsend-prime' onClick={this.toggleMessenger}>
                        <Badge
                          badgeContent={1}
                          color='error'
                          style={{ justifyContent: 'center', width: '100%' }}
                          invisible={true}
                          classes={{ badge: this.props.classes.badge }}
                        >
                          <div style={{ transition: 'all .2s ease-in-out', paddingTop: '7px', transform: '' }} >
                            <MessageIcon
                              palette={palette}
                              style={{ height: '43px', width: '36px', margin: '8px 0px' }}
                            />
                          </div>
                        </Badge>
                      </Prime>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </EditorContentWrapper>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withStyles(useStyles)(CampaignEditor)
