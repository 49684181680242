import React from 'react'
import styled from "@emotion/styled";
import { SendInlinedIcon } from '../../components/icons'
import AddIcon from '@material-ui/icons/Add'
import TextField from '@material-ui/core/TextField';

import bot_chat_icon from '../../../../assets/images/botChatIcon.png'
import cross_icon from '../../../../assets/images/xSquare.png'
import TextEditor from "../../textEditor";


const Card = styled.div`
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 7px;
  font-size: 14px;
  line-height: 1.4;
  color: #000;
  position: relative;
  //-webkit-box-shadow: 0 4px 15px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.1), inset 0 2px 0 0 rgba(48, 71, 236, 0.5);
  box-shadow: 0 4px 15px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.1);

  margin: 0.5em 1em;
  padding: 2em;

`
const ConversationInitiator = styled(Card)`
  padding: 0;
  position: relative;
  h2{
    font-size: 20px;
    font-weight: 600;
    padding: 1.3em;
    margin: .4em 0 0.4em 0em;
    border-bottom: 1px solid #E5E5E5;
  }
`
const CardPadder = styled.div`
.MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:after, .MuiInput-underline:before {
  border-bottom: none;
}
input{
  max-width: 800px;
  box-shadow: none;
  padding: 15px;
  padding-left: 25px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 0;
  border-bottom: 1px solid #E5E5E5;
}
`
const CardContent = styled.div`
  .inlineTooltip-menu {
    position: absolute;
    display: flex !important;
    flex-direction: column !important;
    left: -120px;
    top: -70px;
    padding: 10px;
    border-radius: 8px;

    .inlineTooltip-button.scale {
      margin-bottom: 10px;
    }
  }
  .inlineTooltip-button.control {
    background: #000;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    svg path {
      fill: #fff;
    }
  }
  .tooltip-black {
    svg {
      margin-top: 3px;
    }
  }
  .inlineTooltip.is-scaled {
    .inlineTooltip-menu {
      width: 60px !important;
      background: #fff;
      border: 1px solid #E5E5E5;
      /* :after {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 10px solid #333;
        content: ' ';
        position: absolute;
        top: 100%;
        left: 50%;
      } */
    }
    .inlineTooltip-button.scale{
      border: 0;
    }
  }
  .danteEditorControls div.inlineTooltip.is-active:last-child {
    top: 95px !important;
    left: 385px !important;
  }
`
const MessageInputContainer = styled.div`
  padding: 5px;
  border-radius: 6px;
  box-shadow: 0 4px 15px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.1);
  input {
    margin: 3px;
    width: 100%;
    display: block;
    padding: 12px 30px;
    font-size: 1rem;
    line-height: 1.5;
    font-family: inherit;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #E5E5E5;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    @media (min-width: 320px) and (max-width: 420px) {
      width: 225px;
    }
  }
`
const TextMessageTitle = styled.div`
  display: flex;
  background-color: black;
  color: white;
  font-size: 20px;
  font-weight: 600;
  padding: 2rem 1rem;
  border-radius: 6px;
`

const EmailSnippetView = (props) => {
  const {
    agents,
    toggleMessenger,
    replyType1,
    replyType2,
    agent,
    uploadHandler,
    changeSubjectHandler,
    saveHandler,
    saveContent,
    subject,
    serializedContent,
    setReadState,
    read_only,
    app
  } = props
  return (
    <React.Fragment>
      <ConversationInitiator>
        <TextMessageTitle>
          Fronter
        </TextMessageTitle>
        <CardPadder>
          <CardContent>
            <div style={{ padding: '2rem'}}>
              <TextEditor
                campaign={true}
                uploadHandler={uploadHandler}
                serializedContent={serializedContent}
                read_only={read_only}
                toggleEditable={() => setReadState(read_only)}
                data={{
                  serialized_content: serializedContent,
                }}
                styles={{
                  lineHeight: "1.7em",
                  position: "relative",
                  fontSize: "15px",
                  padding: "10px"
                }}
                saveHandler={saveHandler}
                updateState={({ status, statusButton, content }) => {
                  saveContent(content);
                }}
                app={app}
              />
            </div>
          </CardContent>

        </CardPadder>
      </ConversationInitiator>
      {replyType1 === 'email' &&
        <MessageInputContainer style={{ position: 'relative', margin: '10px' }} className={"form-group"}>
          <div>
          <input
            placeholder="email@example.com"
            type="text"
          />
          <button type={"submit"} style={{ background: "#000", padding: '10px 14px', borderRadius: 8, alignSelf: "flex-end", position: 'absolute', right: '6px', top: '8px'}}>
            <SendInlinedIcon
            >
              submit
            </SendInlinedIcon>
          </button>
        </div>
      </MessageInputContainer>
      }
    </React.Fragment>
  )
}

export default EmailSnippetView
