import React, { Component } from 'react'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import graphql from "../graphql/client"
import {
  UPDATE_CAMPAIGN,
  CREATE_URL_UPLOAD,
  CREATE_DIRECT_UPLOAD
} from '../graphql/mutations'

import { getFileMetadata, directUpload } from '../shared/fileUploader'

import DraftRenderer from '../textEditor/draftRenderer'
import DanteContainer from '../textEditor/editorStyles'
import theme from '../textEditor/theme'
import { ThemeProvider } from 'emotion-theming'
import DeleteForeverRounded  from '@material-ui/icons/DeleteForeverRounded'

import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import StyledFrame from '../../client_messenger/styledFrame'

// INTERNAL APP TOUR
const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  min-width: 0;
  min-height: 0;
`
const ConnectorStep = styled.div`
  margin-right: 11px;
  margin-left: -13px;
  border: 1px solid #ffb94f;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`

const StepBody = styled.div`
  background: #fff;
  max-height: 148px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.05);
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  max-width: 140px;
  padding: 10px;
  position: relative;
  .MuiIconButton-root{
    position:absolute;
    top: -7px;
    right: -7px;
  }
`

const StepHeader = styled.div`
  padding: 16px 24px;
`

const StepMessage = styled.div`
  .contentWrap{
    &:before {
      content:'';
      width:100%;
      height:100%;
      position:absolute;
      left:0;
      top:31px;
      background:linear-gradient(transparent 57px, white);
    }
  }

`
const StepsContainer = styled.div`
  //width: 50%;
  overflow:scroll;
  display: flex;
`

const Body = styled.div`
  padding: 30px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NewStepContainer = styled.div`
  border-radius: 4px;
  min-width: 205px;
  height: 150px;
  border: 1px solid #ccc;
`

const NewStepBody = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const HeadingDiv = styled.div`
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: -10px;
  text-align: center;
  width: 100%;
`

window.__CHILD_WINDOW_HANDLE_2 = null


class TourManager extends Component {
  state = {
    enabledTour: false
  }


  componentDidMount() {
    window.TourManagerEnabled = () => this.state.enabledTour

    window.TourManagerMethods = {
      update: this.updateData,
      getSteps: ()=> this.props.data.steps
    }

    // events received from child window & pingback
    window.addEventListener('message', event => {
      if (event.data.type === 'ENABLE_MANAGER_TOUR') {
        window.__CHILD_WINDOW_HANDLE_2.postMessage({
          tour: this.props.data,
          tourManagerEnabled: true
        }, '*')
      }

      if (event.data.type === 'GET_TOUR') {
        window.__CHILD_WINDOW_HANDLE_2.postMessage({
          type: 'GET_TOUR',
          data: this.props.data
        }, '*')
      }

      if (event.data.type === 'SAVE_TOUR') {
        this.updateData(event.data.steps, ()=>{
          window.__CHILD_WINDOW_HANDLE_2.postMessage({
            type: 'GET_TOUR',
            data: this.props.data
          }, '*')
        })
      }

      if (event.data.type === 'UPLOAD_IMAGE') {
        this.handleDirectUpload(event.data.file, event.data.input)
      }

      if (event.data.type === 'URL_IMAGE') {
        this.handleUrlUpload(event.data.file, event.data.input)
      }
    } , false)
  }

  componentWillUnmount() {
    window.TourManagerEnabled = null
    window.TourManagerMethods = null
  }

  updateData = (data, cb)=>{
    const params = {
      appKey: this.props.app.key,
      id: this.props.data.id,
      campaignParams: {
        steps: data
      }
    }

    graphql(UPDATE_CAMPAIGN, params, {
      success: (data) => {
        this.props.updateData(data.campaignUpdate.campaign, null)
        if (cb) cb()
        //this.setState({ status: "saved" })
      },
      error: () => { }
    })
  }

  handleUrlUpload = url => {
    graphql(CREATE_URL_UPLOAD, { url: url } , {
      success: data => {
        const { signedBlobId, headers, url, serviceUrl } = data.createUrlUpload.directUpload
        //imageBlock.uploadCompleted(serviceUrl)
        //this.props.uploadHandler({signedBlobId, headers, url, serviceUrl, imageBlock})
        //this.setDisabled(false)
        window.__CHILD_WINDOW_HANDLE_2.postMessage({
          type: 'URL_UPLOAD_COMPLETED',
          data: { signedBlobId, headers, url, serviceUrl }
        }, '*')
      },
      error: () => { }
    })
  }

  handleDirectUpload = (file, input) => {
    graphql(CREATE_DIRECT_UPLOAD, input, {
      success: data => {
        const { signedBlobId, headers, url, serviceUrl } = data.createDirectUpload.directUpload
        directUpload(url, JSON.parse(headers), file).then(
          () => {
            window.__CHILD_WINDOW_HANDLE_2.postMessage({
              type: 'UPLOAD_COMPLETED',
              data: {signedBlobId, headers, url, serviceUrl}
            }, '*')
            //this.setDisabled(false)
            //imageBlock.uploadCompleted(serviceUrl)
            //this.props.uploadHandler({signedBlobId, headers, url, serviceUrl, imageBlock})
        })
      },
      error: error => {
        this.setDisabled(false)
      }
    })
  }

  openTourManager = (value = []) => {
    this.setState({ enabledTour: true }, () => {
      const options = 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=500,left=200,top=100'
      let urlBuild = ""
      if(value.length == 0 || value.trim().length == 0){
        urlBuild = this.props.data.id
      }
      else if (value.startsWith("http")){
        urlBuild = value
      }else{

        urlBuild = "https://" + value
      }
      window.__CHILD_WINDOW_HANDLE_2 = window.open(
        urlBuild,
        'win',
        options
      )
    })
  }

  render() {
    const { match, data: { steps } } = this.props

    return (
      <Body>
        <StepsContainer>
          {
            steps && steps.map((step, index) => (
              <TourStep
                step={step}
                key={step.target}
                position={index}
              >
                // removeItem={this.removeItem}
                //enableEditMode={this.enableEditMode}>
              </TourStep>
            ))
          }

          <NewTourStep openTourManager={this.openTourManager} disabled={match.url.includes('/tours/new')}> </NewTourStep>
        </StepsContainer>
      </Body>
    )
  }
}

class NewTourStep extends Component {
  state = { openSelectWebsite: false }

  enableSelection = event => {
    this.setState({ openSelectWebsite: true })
  }

  handleOpenWebsiteClick = value => {
    this.setState({ openSelectWebsite: false })
    this.props.openTourManager(value == '' ? undefined : value)
  }

  render() {
    const { disabled } = this.props

    return (
      <NewStepContainer style={{ minWidth: 250, minHeight: 150 }}>
        <NewStepBody>
          <button style={{ background: '#ffb94f', border: '#ffb94f', padding: '15px 25px', borderRadius: 5 }} onClick={this.enableSelection} disabled={disabled}>+ Add steps</button>
          {
            this.state.openSelectWebsite && <UrlPage handleOpenWebsiteClick={this.handleOpenWebsiteClick} />
          }
        </NewStepBody>
      </NewStepContainer>
    )
  }
}

class UrlPage extends Component {
  state = { name: '' }

  handleNameChange = ({ target }) => {
    this.setState({ name: target.value })
  }

  handleSubmit = event => {
    event.preventDefault()

    this.props.handleOpenWebsiteClick(this.state.name)
  }

  render() {
    const { name } = this.state

    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'fixed', height: '100%', top: 0, left: 0, right: 0, bottom: 0, background: 'white', zIndex: 9999, textAlign: 'center' }}>
        <h1 style={{ fontSize: 60, marginBottom: 0 }}>Open your website</h1>
        <h1 style={{ fontSize: 60, marginTop: 0 }}>to design your tour</h1>

        <div style={{ display: 'flex', alignSelf: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', margin: 'auto' }}>
            <input style={{ height: 40, marginRight: 15, marginTop: 0, width: 400 }} onChange={this.handleNameChange}/>
            <button type='submit' style={{ height: 40, borderRadius: 5, width: 140, border: 'rgb(255, 185, 79)', background: 'rgb(255, 185, 79)', cursor: 'pointer' }} onClick={this.handleSubmit}>Open website</button>
          </div>

          <div style={{ textAlign: 'left', alignSelf: 'center', marginLeft: '-8rem'}}>
            <p style={{ marginBottom: 0 }}>Don’t worry about who can access this URL. You’ll be able to</p>
            <p style={{ marginTop: 0 }}>choose where people see your tour later.</p>
          </div>
        </div>
      </div>
    )
  }
}

class TourStep extends Component {

  removeItem = (e)=>{
    e.preventDefault()
  }

  enableEditMode = (e)=>{
    e.preventDefault()
  }

  render() {
    const { step: { serialized_content }, position } = this.props

    const serializedContent = serialized_content && JSON.parse(serialized_content)

    return (
      <StepContainer onClick={this.enableEditMode}>
        <StepBody style={{ minWidth: 250, minHeight: 150, display: 'flex', position: 'relative' }}>
          {
          // <IconButton onClick={this.removeItem} style={{ top: 0, right: 0 }}>
          //   <DeleteForeverRounded />
          // </IconButton>
          }
          <div style={{ textAlign: 'center', width: 21, height: 20, backgroundColor: '#ffb94f', borderRadius: 50, right: 10, position: 'absolute' }}> { position + 1 } </div>

          <div style={{ width: '100%', alignSelf: 'center', borderTop: '1px#d4d9dd solid' }}>
            <div className='contentWrap'>
              <ThemeProvider theme={theme}>
                <DanteContainer>
                  <HeadingDiv>
                    <DraftRenderer raw={serializedContent} childStyle={{ margin: '0 auto' }} />
                  </HeadingDiv>
                </DanteContainer>
              </ThemeProvider>
            </div>
          </div>
        </StepBody>
        <div style={{ width: 20, border: '1.5px solid #ffb94f', backgroundColor: '#ffb94f', marginLeft: 10 }}></div>
        <ConnectorStep />
      </StepContainer>
    )
  }
}

const mapStateToProps = state => {
  const { auth: { loading, isAuthenticated }, app } = state

  return {
    app,
    loading,
    isAuthenticated
  }
}

export default withRouter(connect(mapStateToProps)(TourManager))
