export const MAIL_CONVERSATION = `
  mutation mailConversation($appKey: String!, $id: Int!, $participantId: Int!){
    mailConversation(appKey: $appKey, id: $id, participantId: $participantId){
      status
    }
  }
`;

export const UPDATE_APP = `
  mutation AppsUpdate($appKey: String!, $appParams: Json!){
    appsUpdate(appKey: $appKey, appParams: $appParams){
      errors
      app{
        encryptionKey
        key
        name
        preferences
        configFields
        theme
        activeMessenger
        translations
        availableLanguages
        logo
        enableArticlesOnWidget
        inlineNewConversations
        teamSchedule
        timezone
        replyTime
        inboundSettings
        emailRequirement
        leadTasksSettings
        userTasksSettings
        gatherSocialData
        registerVisits
        domainUrl
        outgoingEmailDomain
        customizationColors
        customFields
        segments {
          name
          id
          properties
        }
        state
        stripePlanId
      }
    }
  }
`;

export const DESTROY_APP = `
  mutation AppsUpdate($appKey: String!){
    appsDestroy(appKey: $appKey){
      errors
      app{
        encryptionKey
        key
        name
        preferences
        configFields
        theme
        activeMessenger
        segments {
          name
          id
          properties
        }
        state
        stripePlanId
      }
    }
  }
`;

export const CREATE_APP = `
  mutation AppsCreate($appParams: Json!, $operation: String){
    appsCreate(appParams: $appParams, operation: $operation){
      errors
      app{
        encryptionKey
        key
        name
        preferences
        configFields
        theme
        state
        enableArticlesOnWidget
        inlineNewConversations
        activeMessenger
        teamSchedule
        logo
        timezone
        inboundSettings
        emailRequirement
        leadTasksSettings
        userTasksSettings
        customFields
        stripePlanId
      }
    }
  }
`;

export const APP_USER_UPDATE_STATE = `
  mutation AppUserUpdateData($appKey: String!, $id: Int!, $state: String!){
    appUserUpdateData(appKey: $appKey, id: $id, state: $state){
      appUser {
        id
        email
        avatarUrl
        lastVisitedAt
        referrer
        state
        ip
        city
        region
        country
        lat
        lng
        postal
        webSessions
        timezone
        browser
        browserVersion
        os
        osVersion
        browserLanguage
        online
        lang
        displayName
        name
        phone
        companyName
        companySize
        companyWebsite
        companyIndustry
        numberOfEmails
        externalProfiles {
          id
          provider
          data
        }
      }
    }
  }
`;

export const APP_USER_UPDATE = `
  mutation UpdateAppUser($appKey: String!, $id: Int!, $options: Json!, $phone: String!, $companyName: String!, $companySize: Int!, $companyWebsite: String!, $companyIndustry: String!, $numberOfEmails: Int!  ){
    updateAppUser(appKey: $appKey, id: $id, options: $options, phone: $phone, companyName: $companyName, companySize: $companySize, companyWebsite: $companyWebsite,companyIndustry: $companyIndustry, numberOfEmails: $numberOfEmails){
      appUser {
        id
        email
        avatarUrl
        lastVisitedAt
        referrer
        state
        ip
        city
        region
        country
        lat
        lng
        postal
        webSessions
        timezone
        browser
        browserVersion
        os
        osVersion
        browserLanguage
        online
        lang
        displayName
        name
        phone
        companyName
        companySize
        companyWebsite
        companyIndustry
        numberOfEmails
        externalProfiles {
          id
          provider
          data
        }
      }
    }
  }
`;

export const SYNC_EXTERNAL_PROFILE = `
  mutation SyncExternalProfile($appKey: String!, $id: Int!, $provider: String!){
    syncExternalProfile(appKey: $appKey, id: $id, provider: $provider){
      appUser {
        id
        email
        avatarUrl
        lastVisitedAt
        referrer
        state
        ip
        city
        region
        country
        lat
        lng
        postal
        webSessions
        timezone
        browser
        browserVersion
        os
        osVersion
        browserLanguage
        online
        lang
        displayName
        name
        externalProfiles {
          id
          provider
          data
        }
      }
    }
  }
`;

export const START_CONVERSATION = `
  mutation StartConversation($appKey: String!, $id: Int, $message: Json!){
    startConversation(appKey: $appKey, id: $id, message: $message){
      conversation{
        id
        key
        state
        readAt
        priority
        assignee {
          id
          email
          name
          avatarUrl
        }
        mainParticipant{
          id
          email
          properties
          avatarUrl
        }

        lastMessage{
          source
          createdAt
          message{
            htmlContent
            textContent
            serializedContent
            blocks
          }
          privateNote
          messageSource{
            id
            type
          }
          appUser {
            id
            email
            kind
            displayName
            avatarUrl
          }
        }


      }
    }
  }
`;

export const INSERT_COMMMENT = `
  mutation InsertComment($appKey: String!, $id: String!, $message: Json!){
    insertComment(appKey: $appKey, id: $id, message: $message){
      message{
        message{
          htmlContent
          textContent
          serializedContent
          blocks
        }
        readAt
        appUser{
          id
          email
          kind
          displayName
          avatarUrl
        }
        source
        messageSource {
          name
          state
          fromName
          fromEmail
          serializedContent
        }
        emailMessageId
      }
    }
  }
`;

export const TYPING_NOTIFIER = `
  mutation TypingNotifier($appKey: String!, $id: String!){
    typingNotifier(appKey: $appKey, id: $id, ){
      message
    }
  }
`;

export const INSERT_APP_BLOCK_COMMMENT = `
  mutation InsertAppBlockComment($appKey: String!, $id: String!, $controls: Json!){
    insertAppBlockComment(appKey: $appKey, id: $id, controls: $controls){
      message{
        message{
          htmlContent
          textContent
          serializedContent
          blocks
        }
        readAt
        appUser{
          id
          email
          kind
          displayName
          avatarUrl
        }
        source
        messageSource {
          name
          state
          fromName
          fromEmail
          serializedContent
        }
        emailMessageId
      }
    }
  }
`;

export const INSERT_NOTE = `
  mutation InsertNote($appKey: String!, $id: Int!, $message: Json!){
    insertNote(appKey: $appKey, id: $id, message: $message){
      message{
        message{
          htmlContent
          textContent
          serializedContent
        }
        readAt
        createdAt
        appUser{
          id
          email
          kind
          displayName
          avatarUrl
        }
        source
        messageSource {
          name
          state
          fromName
          fromEmail
          serializedContent
        }
        emailMessageId
      }
    }
  }
`;

export const ASSIGN_USER = `
  mutation AssignUser($appKey: String!, $conversationId: Int!, $appUserId: Int!){
    assignUser(appKey: $appKey, conversationId: $conversationId, appUserId: $appUserId){
      conversation{
        id
        state
        readAt
        priority
        assignee {
          id
          email
          name
          avatarUrl
        }
        mainParticipant{
          id
          email
          properties
          avatarUrl
        }
      }
    }
  }
`;

export const CREATE_ASSIGNMENT_RULE = `
  mutation CreateAssignmentRule($appKey: String!, $agentId: String!, $title: String!, $active: String!, $conditions: Json!){
    createAssignmentRule(appKey: $appKey, agentId: $agentId, title: $title, active: $active, conditions: $conditions){
      errors
      assignmentRule{
        id
        title
        conditions
        state
        agent{
          id
          email
          avatarUrl
        }
        state
      }
    }
  }
`;

export const UPDATE_RULE_PRIORITIES = `
  mutation UpdateRulePriorities($appKey: String!, $rules: [Json!]!){
    updateRulePriorities(appKey: $appKey, rules: $rules){
      errors
    }
  }
`;

export const EDIT_ASSIGNMENT_RULE = `
  mutation EditAssignmentRule($appKey: String!, $ruleId: Int!, $agentId: String!, $title: String!, $active: String!, $conditions: Json!){
    editAssignmentRule(appKey: $appKey, ruleId: $ruleId, agentId: $agentId, title: $title, active: $active, conditions: $conditions){
      errors
      assignmentRule{
        id
        title
        conditions
        state
        agent{
          id
          email
          avatarUrl
        }
        state
      }
    }
  }
`;

export const DELETE_ASSIGNMENT_RULE = `
  mutation DeleteAssignmentRule($appKey: String!, $ruleId: Int! ){
    deleteAssignmentRule(appKey: $appKey, ruleId: $ruleId){
      errors
      assignmentRule{
        id
        title
        conditions
        state
        agent{
          id
          email
          avatarUrl
        }
        state
      }
    }
  }
`;

export const UPDATE_CONVERSATION_STATE = `
  mutation UpdateConversationState($appKey: String!, $conversationId: Int!, $state: String!){
    updateConversationState(appKey: $appKey, conversationId: $conversationId, state: $state){
      conversation{
        id
        state
        readAt
        priority
        assignee {
          id
          email
          name
          avatarUrl
        }
        mainParticipant{
          id
          email
          properties
          avatarUrl
        }
      }
    }
  }
`;

export const TOGGLE_CONVERSATION_PRIORITY = `
  mutation ToggleConversationPriority($appKey: String!, $conversationId: Int!){
    toggleConversationPriority(appKey: $appKey, conversationId: $conversationId){
      conversation{
        id
        state
        readAt
        priority
        assignee {
          id
          email
          name
          avatarUrl
        }
        mainParticipant{
          id
          email
          properties
          avatarUrl
        }
      }
    }
  }
`;

export const UPDATE_CAMPAIGN = `
  mutation UpdateCampaign($appKey: String!, $id: Int!, $campaignParams: Json!){
    campaignUpdate(appKey: $appKey, id: $id, campaignParams: $campaignParams){
      errors
      campaign {
        name
        id
        type
        url
        serializedContent
        segments
        scheduledAt
        scheduledTo
        state
        subject
        timezone
        description
        statsFields
        configFields
        textMessage
        contentType
        messageType
        htmlContent
        fromName
        fromEmail
        replyEmail
        steps
        configTableColumns
        htmlEmailContent
        scheduleType
        sendCriteria
        template
        counts
        companyAddress
        companyEmailAddress
        companyPhoneNumber
        htmlEmailContent
        goalSegment
        urlSegmentRule
        stayTime
        paused
      }
    }
  }
`;

export const DELETE_CAMPAIGNS = `
  mutation CampaignsDelete($appKey: String!, $ids: [Int!]!) {
    campaignsDelete(appKey: $appKey, ids: $ids) {
      errors
      campaigns {
        id
      }
    }
  }
`;

export const DELETE_CAMPAIGN = `
  mutation DeleteCampaign($appKey: String!, $id: Int!){
    campaignDelete(appKey: $appKey, id: $id){
      errors
      campaign {
        id
      }
    }
  }
`;

export const DELIVER_CAMPAIGN = `
  mutation DeliverCampaign($appKey: String!, $id: Int!, ){
    campaignDeliver(appKey: $appKey, id: $id){
      errors
      campaign {
        name
        id
        type
        url
        serializedContent
        segments
        scheduledAt
        scheduledTo
        state
        subject
        timezone
        description
        statsFields
        configFields
        fromName
        fromEmail
        replyEmail
        steps
      }
    }
  }
`;

export const DELIVER_USER_AUTO_MESSAGE = `
  mutation DeliverUserAutoMessage($appKey: String!, $id: Int!, ){
    deliverUserAutoMessage(appKey: $appKey, id: $id){
      errors
      sent
    }
  }
`;

export const TEST_CAMPAIGN = `
  mutation TestCampaign($appKey: String!, $id: Int!, $fromEmail: String!, $toEmails: String!, $template: String!, $includeUnsubscriptionLink: Boolean!, $footerAddress: String!, $helpEmail: String!, $helpContact: String!){
    campaignTest(appKey: $appKey, id: $id, fromEmail: $fromEmail, toEmails: $toEmails, template: $template, includeUnsubscriptionLink: $includeUnsubscriptionLink, footerAddress: $footerAddress, helpEmail: $helpEmail, helpContact: $helpContact){
      errors
      campaign {
        name
        id
        type
        url
        serializedContent
        segments
        scheduledAt
        scheduledTo
        state
        subject
        timezone
        description
        statsFields
        configFields
        fromName
        fromEmail
        replyEmail
        steps
        template
        counts
        companyAddress
        companyEmailAddress
        companyPhoneNumber
        htmlEmailContent
        goalSegment
        urlSegmentRule
        stayTime
      }
    }
  }
`;

export const CACULATE_CAMPAIGN_GOAL =`
  mutation CalculateCampaignGoal($appKey: String!, $id: Int!){
    calculateCampaignGoal(appKey: $appKey, id: $id){
      average
    }
  }
`

export const PURGE_METRICS = `
  mutation PurgeMetrics($appKey: String!, $id: Int!, ){
    purgeMetrics(appKey: $appKey, id: $id){
      errors
      campaign {
        name
        id
        type
        serializedContent
        segments
        scheduledAt
        scheduledTo
        state
        subject
        timezone
        description
        statsFields
        configFields
        fromName
        fromEmail
        replyEmail
      }
    }
  }
`;

export const CREATE_CAMPAIGN = `
  mutation CreateCampaign($appKey: String!, $campaignParams: Json!, $operation: String, $mode: String!, $email: String, $appUserId: Int){
    campaignCreate(operation: $operation, appKey: $appKey, mode: $mode, campaignParams: $campaignParams, email: $email, appUserId: $appUserId) {
      errors
      campaign {
        name
        id
        type
        url
        serializedContent
        segments
        scheduledAt
        scheduledTo
        state
        subject
        timezone
        description
        statsFields
        configFields
        textMessage
        contentType
        messageType
        htmlContent
        fromName
        fromEmail
        replyEmail
        steps
        template
        htmlEmailContent
        counts
        companyAddress
        companyEmailAddress
        companyPhoneNumber
        htmlEmailContent
        goalSegment
        urlSegmentRule
        stayTime
      }
    }
  }
`;

export const PREDICATES_SEARCH = `
  mutation PredicatesSearch($appKey: String!, $search: Json!, $page: Int, $per: Int){
    predicatesSearch(appKey: $appKey, search: $search, page: $page, per: $per){
      appUsers{
        collection{
          id
          email
          avatarUrl
          os
          osVersion
          lastVisitedAt
          browser
          state
          displayName
          online
          referrer
          ip
          city
          region
          country
          lat
          lng
          postal
          webSessions
          timezone
          browser
          browserVersion
          browserLanguage
          lang
          properties
          firstSeen
          lastSeen
          createdAt
          lastContacted
          lastHeardFrom
          emailStatus
        }
        meta
      }
    }
  }
`;

export const PREDICATES_DELETE = `
  mutation PredicatesDelete($appKey: String!, $id: Int,){
    predicatesDelete(appKey: $appKey, id: $id){
      segment {
        name
      }
    }
  }
`;

export const PREDICATES_CREATE = `
  mutation PredicatesCreate($appKey: String!, $name: String!, $predicates: Json!){
    predicatesCreate(appKey: $appKey, name: $name, predicates: $predicates){
      segment {
        id
        name
        predicates{
          comparison
          type
          value
          attribute
        }
      }
    }
  }
`;


export const PREDICATES_UPDATE = `
  mutation PredicatesUpdate($appKey: String!, $predicates: Json!, $id: Int){
    predicatesUpdate(appKey: $appKey, predicates: $predicates, id: $id){
      segment {
        id
        name
        predicates {
          comparison
          type
          value
          attribute
        }
      }
    }
  }
`;


export const UPDATE_SEGMENET = `
  mutation UpdateSegment($appKey: String!, $segmentParams: Json!, $id: Int){
    updateSegment(appKey: $appKey, segmentParams: $segmentParams, id: $id){
      errors
      segment {
        id
        name
        predicates {
          comparison
          type
          value
          attribute
        }
        configTableColumns
      }
    }
  }
`;


export const WEBHOOK_DELETE = `
  mutation WebhookDelete($appKey: String!, $id: Int!){
    deleteWebhook(appKey: $appKey, id: $id){
      webhook
      errors
    }
  }
`;

export const WEBHOOK_CREATE = `
  mutation WebhookCreate($appKey: String!, $url: String!, $tags: Json!, $state: String!){
    createWebhook(appKey: $appKey, url: $url, tags: $tags, state: $state){
      webhook
      errors
    }
  }
`;

export const WEBHOOK_UPDATE = `
  mutation WebhookUpdate($appKey: String!, $url: String!, $state: String!, $tags: Json!, $id: Int!){
    updateWebhook(appKey: $appKey, url: $url, tags: $tags, id: $id, state: $state){
      webhook
      errors
    }
  }
`;

export const INVITE_AGENT = `
  mutation InviteAgent($appKey: String!, $email: String!){
    inviteAgent(appKey: $appKey, email: $email){
      agent {
        email
        avatarUrl
        name
      }
    }
  }
`;

export const INVITE_AGENTS = `
  mutation InviteAgents($appKey: String!, $agentEmails: [String!]!){
    inviteAgents(appKey: $appKey, agentEmails: $agentEmails){
      data
    }
  }
`;

export const DELETE_AGENT = `
  mutation DeleteAgent($appKey: String!, $id: Int!){
    deleteAgent(appKey: $appKey, id: $id){
      deleted
    }
  }
`;

export const UPDATE_AGENT = `
  mutation UpdateAgent($appKey: String!, $email: String!, $params: Json!){
    updateAgent(appKey: $appKey, email: $email, params: $params){
      agent {
        email
        avatarUrl
        name
      }
    }
  }
`;

export const CREATE_ARTICLE = `
  mutation CreateArticle($appKey: String!, $content: Json!, $title: String!, $lang: String){
    createArticle(appKey: $appKey, content: $content, title: $title, lang: $lang){
      article {
        id
        title
        slug
        content
        state
        author{
          email
          avatarUrl
          id
          name
        }
      }
    }
  }
`;

export const EDIT_ARTICLE = `
  mutation EditArticle($appKey: String!, $content: Json!, $id: String!, $title: String!, $description: String!, $lang: String){
    editArticle(appKey: $appKey, content: $content, id: $id, title: $title, description: $description, lang: $lang){
      article {
        id
        title
        slug
        content
        state
        author{
          email
          avatarUrl
          id
          name
        }
      }
    }
  }
`;

export const ARTICLE_BLOB_ATTACH = `
  mutation ArticleBlobAttach($appKey: String!, $id: Int!, $blobId: String!){
    articleBlobAttach(appKey: $appKey, id: $id, blobId: $blobId){
      article {
        id
        title
        slug
        content
        state
        author{
          email
          avatarUrl
          id
          name
        }
      }
    }
  }
`;

export const TOGGLE_ARTICLE = `
  mutation ToggleArticle($appKey: String!, $id: String!, $state: String!){
    toggleArticle(appKey: $appKey, id: $id, state: $state){
      article {
        id
        title
        slug
        content
        state
        author{
          email
          avatarUrl
          id
          name
        }
      }
    }
  }
`;

export const ARTICLE_ASSIGN_AUTHOR = `
  mutation AssignAuthor($appKey: String!, $id: String!, $authorId: String!){
    assignAuthor(appKey: $appKey, id: $id, authorId: $authorId){
      article {
        id
        title
        slug
        content
        state
        author{
          email
          avatarUrl
          id
          name
        }
      }
    }
  }
`;

export const DELETE_ARTICLE = `
  mutation DeleteArticle($appKey: String!, $id: String!){
    deleteArticle(appKey: $appKey, id: $id){
      article {
        id
        title
        slug
        content
        state
      }
    }
  }
`;

export const CREATE_DIRECT_UPLOAD = `
  mutation CreateDirectUpload($filename: String!, $contentType: String!, $checksum: String!, $byteSize: Int!){
    createDirectUpload( input: {
      filename: $filename,
      contentType: $contentType,
      checksum: $checksum,
      byteSize: $byteSize
    }){
      directUpload {
        signedBlobId
        url
        headers
        blobId
        serviceUrl
      }
    }
  }
`;

export const CREATE_URL_UPLOAD = `
  mutation CreateUrlUpload($url: String!){
    createUrlUpload( input: {
      url: $url,
    }){
      directUpload {
        signedBlobId
        url
        headers
        blobId
        serviceUrl
      }
    }
  }
`;

export const REORDER_ARTICLE = `
  mutation ReorderArticle( $appKey: String!, $id: String!, $position: Int!, $section: String, $collection: String){
    reorderArticle( appKey: $appKey, id: $id, position: $position, section: $section, collection: $collection ){
      article{
        id
        position
      }
    }
  }
`;


export const ARTICLE_COLLECTION_CREATE = `
  mutation ArticleCollectionCreate($appKey: String!, $title: String!, $description: String){
    articleCollectionCreate(
      appKey: $appKey,
      title: $title,
      description: $description
    ){
      collection{
        id
        title
        description
      }
      errors
    }
  }
`;

export const ARTICLE_COLLECTION_CHANGE = `
  mutation ChangeCollectionArticle($appKey: String!, $id: String!, $collectionId: Int!){
    changeCollectionArticle(
      appKey: $appKey,
      id: $id,
      collectionId: $collectionId
    ){
      article {
        id
        title
        slug
        content
        state
        author{
          email
          id
          name
        }
      }
    }
  }
`;

export const ARTICLE_COLLECTION_EDIT = `
  mutation ArticleCollectionEdit($appKey: String!, $id: Int!, $title: String!, $description: String, $lang: String){
    articleCollectionEdit(
      appKey: $appKey,
      title: $title,
      id: $id,
      description: $description,
      lang: $lang
    ){
      collection{
        id
        title
        description
      }
      errors
    }
  }
`;

export const ARTICLE_COLLECTION_DELETE = `
  mutation ArticleCollectionDelete($appKey: String!, $id: Int!){
    articleCollectionDelete(
      appKey: $appKey,
      id: $id,
    ){
      collection{
        id
      }
    }
  }
`;

export const ARTICLE_SECTION_CREATE = `
  mutation ArticleSectionCreate($appKey: String!, $title: String!, $collectionId: Int!, $lang: String){
    articleSectionCreate(
      appKey: $appKey,
      title: $title,
      collectionId: $collectionId,
      lang: $lang
    ){
      section{
        id
        title
        description
        articles {
          id
          title
        }
      }
    }
  }
`;

export const ARTICLE_SECTION_EDIT = `
  mutation ArticleSectionEdit($appKey: String!, $title: String!, $id: String!, $collectionId: Int!, $lang: String){
    articleSectionEdit(
      appKey: $appKey,
      title: $title,
      collectionId: $collectionId
      id: $id,
      lang: $lang
    ){
      section{
        id
        title
        description
      }
    }
  }
`;

export const ARTICLE_SECTION_DELETE = `
  mutation ArticleSectionDelete($appKey: String!, $id: String!){
    articleSectionDelete(
    appKey: $appKey,
    id: $id
    ){
      section{
        id
      }
    }
  }
`;

export const ADD_ARTICLES_TO_COLLECTION = `
  mutation AddArticlesToCollection($appKey: String!, $collectionId: Int!, $articlesId: [String!]!){
    addArticlesToCollection(
      appKey: $appKey,
      collectionId: $collectionId,
      articlesId: $articlesId
    ){
      collection{
        id
      }
    }
  }
`;

export const ARTICLE_SETTINGS_UPDATE = `
  mutation ArticleSettingsUpdate($appKey: String!, $settings: Json!){
    articleSettingsUpdate(
      appKey: $appKey,
      settings: $settings
    ){
      settings{
        id
        subdomain
        siteTitle
        siteDescription
        website
        googleCode
        color
        facebook
        twitter
        linkedin
        credits
        logo
        headerImage
        translations
        availableLanguages
        defaultUrl
        domain
      }
      errors
    }
  }
`;

export const ARTICLE_SETTINGS_DELETE_LANG = `
  mutation ArticleSettingsDeleteLan($appKey: String!, $langItem: String!){
    articleSettingsDeleteLang(
      appKey: $appKey,
      langItem: $langItem
    ){
      settings{
        id
        subdomain
        siteTitle
        siteDescription
        website
        googleCode
        color
        facebook
        twitter
        linkedin
        credits
        logo
        headerImage
        translations
        availableLanguages
      }
      errors
    }
  }
`;




export const CREATE_BOT_TASK = `
  mutation CreateBotTask($appKey: String!, $params: Json!){
    createBotTask(
      appKey: $appKey,
      params: $params,
    ){
      botTask{
        id
        title
        segments
        scheduling
        state
        urls
        paths {
          id
          title
          followActions
        }
      }
      errors
    }
  }
`;

export const UPDATE_BOT_TASK = `
  mutation UpdateBotTask($appKey: String!, $id: String!, $params: Json!){
    updateBotTask(
      appKey: $appKey,
      params: $params,
      id: $id
    ){
      botTask{
        id
        title
        scheduling
        segments
        state
        urls
        paths {
          id
          title
          steps
          followActions
        }
      }
      errors
    }
  }
`;

export const DELETE_BOT_TASK = `
  mutation DeleteBotTask($appKey: String!, $id: Int!){
    deleteBotTask(
      appKey: $appKey,
      id: $id
    ){
      botTask{
        id
      }
      errors
    }
  }
`;

export const CREATE_INTEGRATION = `
  mutation CreateIntegration($appKey: String!, $appPackage: String! , $params: Json!, $script: String) {
    integrationsCreate(appKey: $appKey, appPackage: $appPackage, params: $params, script: $script) {
      errors
      url
      integration {
        id
        name
        settings
        definitions
        icon
        state
        description
        importStatus
      }
    }
  }
`;

export const UPDATE_INTEGRATION = `
  mutation UpdateIntegration($appKey: String!, $id: Int!, $params: Json!){
    integrationsUpdate(appKey: $appKey, id: $id, params: $params){
      errors
      url
      integration {
        id
        name
        settings
        definitions
        icon
        state
        description
        importStatus
      }
    }
  }
`;

export const DELETE_INTEGRATION = `
  mutation DeleteIntegration($appKey: String!, $id: Int!){
    integrationsDelete(appKey: $appKey, id: $id){
      errors
      integration {
        id
        name
        settings
        definitions
        icon
        state
        description
      }
    }
  }
`;


export const SES_IDENTITY_CREATE = `
  mutation SesIdentityCreate($appKey: String!, $address: String!, $identityType: String!){
    createSesIdentity(appKey: $appKey, address: $address, identityType: $identityType){
      sesIdentity
      errors
    }
  }
`;
export const SES_IDENTITY_UPDATE = `
  mutation SesIdentityUpdate($appKey: String!, $id: String!, $status: String!, $address: String!){
    updateSesIdentity(appKey: $appKey, id: $id, status: $status, address: $address){
      sesIdentity
      errors
    }
  }
`;
export const SES_IDENTITY_DELETE = `
  mutation SesIdentityDelete($id: Int!){
    deleteSesIdentity(id: $id){
      sesIdentity
      errors
    }
  }
`;


export const IMPORT_CONTACT = `
  mutation App($appKey: String!, $params: Json!){
    importContact(appKey: $appKey, params: $params ){
      importContact
      errors
    }
  }
`;


export const UPGRADE_PLAN_PAYMENT = `
  mutation CreateSubscription($appKey: String!,  $token: String!, $plan: String!, $billingParams: Json!){
    createSubscription(appKey: $appKey, token: $token, plan: $plan, billingParams: $billingParams ){
      subscription
      plan
      errors
      app{
        encryptionKey
        key
        name
        preferences
        configFields
        theme
        activeMessenger
        translations
        availableLanguages
        logo
        enableArticlesOnWidget
        inlineNewConversations
        teamSchedule
        timezone
        replyTime
        inboundSettings
        emailRequirement
        leadTasksSettings
        userTasksSettings
        gatherSocialData
        registerVisits
        domainUrl
        outgoingEmailDomain
        customizationColors
        customFields
        segments {
          name
          id
          properties
        }
        state
        stripePlanId
        plan {
          id
          name
          amount
          seats
          stripePlanId
        }
      }
    }
  }
`;

export const UPDATE_ACCOUNT_DETAILS = `
  mutation UpdateAccountDetails($appKey: String!, $options: Json!){
    updateAccountDetails(appKey: $appKey, options: $options){
      errors
      currentUser {
        email
        firstName
        lastName
        avatarUrl
      }
    }
  }
`;

export const CREATE_NEW_LEAD = `
  mutation CreateNewLead($appKey: String!, $options: Json!){
    createNewLead(appKey: $appKey, options: $options){
      errors
      lead {
        name
        email
      }
    }
  }
`;

export const CREATE_NEW_USER = `
  mutation CreateNewUser($appKey: String!, $options: Json!){
    createAppUser(appKey: $appKey, options: $options){
      errors
      appUser {
        name
        email
      }
    }
  }
`;

export const ARCHIVED_USERS =`
  mutation ArchivedUsers($appKey: String!, $usersId: [Int!]!, $allSelected: Boolean!){
    archivedUsers(appKey: $appKey, usersId: $usersId, allSelected: $allSelected){
      errors
      appUsers {
        name
        email
      }
    }
  }
`;

export const UPDATE_ARTICLE_HITS_COUNT =`
  mutation UpdateArticleHitsCount($url: String!, $articleId: Int!){
    updateArticleHitsCount(url: $url, articleId: $articleId){
      errors
    }
  }
`;

export const CREATE_QUALIFICATION = `
  mutation CreateQualification($appKey: String!, $qualificationParams: Json!, $operation: String) {
    qualificationCreate(operation: $operation, appKey: $appKey, qualificationParams: $qualificationParams) {
      errors
      qualification {
        phone
      }
    }
  }
`;

export const CREATE_AUTO_REPLY = `
  mutation CreateAutoReply($appKey: String!, $title: String!, $htmlContent: String!, $serializedContent: String!) {
    createAutoReply(appKey: $appKey, title: $title, htmlContent: $htmlContent, serializedContent: $serializedContent) {
      autoReply {
        id
        title
        htmlContent
        serializedContent
      }
    }
  }
`

export const UPDATE_AUTO_REPLY = `
  mutation UpdateAutoReply($appKey: String!, $title: String!, $htmlContent: String!, $serializedContent: String!, $id: ID!) {
    updateAutoReply(appKey: $appKey, title: $title, htmlContent: $htmlContent, serializedContent: $serializedContent, id: $id) {
      autoReply {
        id
        title
        htmlContent
        serializedContent
      }
    }
  }
`

export const DELETE_AUTO_REPLY = `
  mutation deleteAutoReply($appKey: String!, $id: Int!) {
    deleteAutoReply(appKey: $appKey, id: $id) {
      deleted
    }
  }
`

export const DELETE_CONVERSATION = `
  mutation deleteConversation($conversationPartId: Int!) {
    deleteConversation(conversationPartId: $conversationPartId) {
      deleted
    }
  }
`
