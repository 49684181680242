const langsOptions = [
 {label: "Afrikaans",   value: "af"}, 	 
 {label: "Albanian",    value: "sq"}, 	 
 {label: "Arabic",      value: "ar"}, 	 
 {label: "Basque",      value: "eu"}, 	 
 {label: "Bulgarian",   value: "bg"}, 	 
 {label: "Byelorussian",value: "be"}, 
 {label: "Catalan",     value: "ca"}, 	
 {label: "Croatian",    value: "hr"}, 	
 {label: "Czech",       value: "cs"}, 	 
 {label: "Danish",      value: "da"}, 	 
 {label: "Dutch",       value: "nl"}, 	 
 {label: "English",     value: "en"}, 	
 {label: "Esperanto",   value: "eo"},
 {label: "Estonian",    value: "et"}, 	
 {label: "Faroese",     value: "fo"}, 	
 {label: "Finnish",     value: "fi"}, 	
 {label: "French",      value: "fr"}, 	 
 {label: "Galician",    value: "gl"}, 
 {label: "German",      value: "de"}, 	 
 {label: "Greek",       value: "el"}, 	 
 {label: "Hebrew",      value: "iw"}, 	 
 {label: "Hungarian",   value: "hu"},
 {label: "Icelandic",   value: "is"},
 {label: "Irish",       value: "ga"}, 	 
 {label: "Italian",     value: "it"}, 	
 {label: "Japanese",    value: "ja"}, 	
 {label: "Korean",      value: "ko"},
 {label: "Latvian",     value: "lv"}, 	 
 {label: "Lithuanian",  value: "lt"}, 
 {label: "Macedonian",  value: "mk"}, 
 {label: "Maltese",     value: "mt"}, 	 
 {label: "Norwegian",   value: "no"}, 
 {label: "Polish",      value: "pl"},
 {label: "Portuguese",  value: "pt"}, 
 {label: "Romanian",    value: "ro"}, 	 
 {label: "Russian",     value: "ru"}, 	 
 {label: "Scottish",    value: "gd"}, 	 
 {label: "Serbian",     value: "sr"},
 {label: "Serbian",     value: "sr"},
 {label: "Slovak",      value: "sk"},
 {label: "Slovenian",   value: "sl"}, 
 {label: "Spanish",     value: "es"}, 	 
 {label: "Swedish",     value: "sv"}, 	 
 {label: "Turkish",     value: "tr"}, 	 
 {label: "Ukrainian",   value: "uk"},
]

export default langsOptions