import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { AnchorLink } from "../shared/RouterLink";
import { UpgradeDialog } from "../shared/planUpgradeMessage";
import { connect } from "react-redux";
import ContentWrapper from "../components/ContentWrapper";
import PageTitle from "../components/PageTitle";
import Moment from "react-moment";
import styled from "@emotion/styled";
import serialize from "form-serialize";
import CampaignView from "./campaigns/show";
import CampaignSettings from "./campaigns/settings";
import CampaignEditor from "./campaigns/editor";
import SegmentManager from "../components/segmentManager";
import DeleteDialog from "../components/deleteDialog";
import CampaignStats from "../components/stats";
import AddIcon from "@material-ui/icons/Add";
import down_icon from '../../../assets/images/down-arrow.png'
import { isEmpty } from "lodash";
import { parseJwt, generateJWT } from "../components/segmentManager/jwt";
import TourManager from "../components/Tour";
import ContentHeader from "../components/ContentHeader";
import Content from "../components/Content";
import EmptyView from "../components/emptyView";
import ConversationHeader from '../components/ConversationHeader'
import graphql from "../graphql/client";
import { CAMPAIGN, CAMPAIGNS, CAMPAIGN_METRICS } from "../graphql/queries";
import {
  PREDICATES_SEARCH,
  UPDATE_CAMPAIGN,
  CREATE_CAMPAIGN,
  DELIVER_CAMPAIGN,
  PURGE_METRICS,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGNS,
} from "../graphql/mutations";

import { getAppUser } from "../actions/app_user";
import { toggleDrawer } from "../actions/drawer";

import Table from "../components/table/index";
import { Datatable } from "../components/datatable/";

import SelectMenu from "../components/selectMenu";

import EmailIcon from "@material-ui/icons/Email";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MessageIcon from "@material-ui/icons/Message";
import FilterFramesIcon from "@material-ui/icons/FilterFrames";
import ClearAll from "@material-ui/icons/ClearAll";
import DeleteOutlineRounded from "@material-ui/icons/DeleteOutlineRounded";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import CheckCircle from "@material-ui/icons/CheckCircle";
import Pause from "@material-ui/icons/Pause";
import VisibilityRounded from "@material-ui/icons/VisibilityRounded";
import SendRounded from "@material-ui/icons/SendRounded";
import DeleteForeverRounded from "@material-ui/icons/DeleteForeverRounded";

import { setCurrentSection, setCurrentPage } from "../actions/navigation";

import userFormat from "../components/table/userFormat";

import { errorMessage, successMessage } from "../actions/status_messages";
import StatusBadge from "../components/StatusBadge";
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';

const BtnTabContainer = styled.div`
  background-color: #fff;

  button {
    padding: 10px 20px;
    font-size: 15px;
    :hover {
      background-color: #FAF6F1;
    }
  }

  button.Mui-selected {
    font-weight: 600;
    background-color: #FAF6F1;
    border-radius: 6px;
    padding: 10px 35px;
  }
`;

const EditButtonsGroup = styled.div`
  flex-direction: row;
  display: flex;
  padding-top: 20px;
  .MuiButton-containedPrimary {
    background: rgb(255, 182, 0);
    color: rgb(0, 0, 0);
    font-size: 15px;
    padding: 15px 35px;
    margin: 10px;
  }
  .MuiButton-outlinedPrimary {
    font-size: 15px;
    padding: 15px 35px;
    margin: 10px;
  }
`

const TitleFieldContainer = styled.div`
  input {
    box-shadow: none;
    font-size: 35px;
    font-weight: 500;
  }
  .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #707070;
  }
`

const options = [
  {
    title: "Enable",
    description: "enables the campaign",
    icon: <CheckCircle />,
    id: "enabled",
    paused: false,
  },
  {
    title: "Pause",
    description: "pauses the campaign ",
    icon: <Pause />,
    id: "disabled",
    paused: true,
  },
];

class CampaignSegment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jwt: null,
      app_users: [],
      search: false,
      meta: {},
    };
  }
  componentDidMount() {
    this.search();
  }

  updateData = (data, cb) => {
    const newData = Object.assign({}, this.props.data, { segments: data.data });
    this.props.updateData(newData, cb ? cb() : null);
  };

  updatePredicate = (data, cb) => {
    const jwtToken = generateJWT(data);

    if (cb) cb(jwtToken);

    this.setState({ jwt: jwtToken }, () =>
      this.updateData(parseJwt(this.state.jwt), this.search)
    );
  };

  addPredicate = (data, cb) => {
    const pending_predicate = {
      attribute: data.name,
      comparison: null,
      type: data.type,
      value: data.value,
    };

    const new_predicates = this.props.data.segments.concat(pending_predicate);
    const jwtToken = generateJWT(new_predicates);
    if (cb) cb(jwtToken);

    this.setState({ jwt: jwtToken }, () =>
      this.updateData(parseJwt(this.state.jwt))
    );
  };

  deletePredicate(data) {
    const jwtToken = generateJWT(data);
    this.setState({ jwt: jwtToken }, () =>
      this.updateData(parseJwt(this.state.jwt), this.search)
    );
  }

  search = (page) => {
    this.setState({ searching: true });
    // jwt or predicates from segment
    const data = this.state.jwt
      ? parseJwt(this.state.jwt).data
      : this.props.data.segments;
    const predicates_data = {
      data: {
        source: "campaign",
        source_id: this.props.data.id,
        predicates: data.filter((o) => o.comparison),
      },
    };

    graphql(
      PREDICATES_SEARCH,
      {
        appKey: this.props.app.key,
        search: predicates_data,
        page: page || 1,
      },
      {
        success: (data) => {
          const appUsers = data.predicatesSearch.appUsers;
          this.setState({
            app_users: appUsers.collection,
            meta: appUsers.meta,
            searching: false,
          });
        },
        error: (error) => {
          debugger;
        },
      }
    );
  };

  showUserDrawer = (o) => {
    this.props.dispatch(
      toggleDrawer({ rightDrawer: true }, () => {
        this.props.dispatch(getAppUser(o.id));
      })
    );
  };

  render() {
    return (
      <SegmentManager
        {...this.props}
        loading={this.state.searching}
        predicates={this.props.data.segments}
        meta={this.state.meta}
        collection={this.state.app_users}
        updatePredicate={this.updatePredicate.bind(this)}
        addPredicate={this.addPredicate.bind(this)}
        deletePredicate={this.deletePredicate.bind(this)}
        search={this.search.bind(this)}
        loading={this.props.searching}
        columns={userFormat(this.showUserDrawer, this.props.app, this.props.data.configTableColumns)}
        handleTabChange={this.props.handleTabChange}
        currentTab={this.props.currentTab}
        defaultHiddenColumnNames={[
          "id",
          "state",
          "online",
          "lat",
          "lng",
          "postal",
          "browserLanguage",
          "referrer",
          "os",
          "osVersion",
          "lang",
        ]}
        tableColumnExtensions={[
          { columnName: "email", width: 250 },
          { columnName: "lastVisitedAt", width: 120 },
          { columnName: "os", width: 100 },
          { columnName: "osVersion", width: 100 },
          { columnName: "state", width: 80 },
          { columnName: "online", width: 80 },
        ]}
        leftColumns={["email"]}
        rightColumns={["online"]}
      />
    );
  }
}

class CampaignForm extends Component {
  state = {
    selected: 0,
    data: {},
    tabValue: 0,
    campaignTitle: '',
    replyType1: "",
    replyType2: "",
    html_content: "",
    serialized_content: "",
    template: "",
    subject: "",
    agent: "",
    reply_agent: "",
    htmlEmailContent: "",
    msgs: "",
  }

  url = () => {
    const id = this.props.match.params.id
    return `/apps/${this.props.app.key}/campaigns/${id}`
  };

  componentDidMount() {
    this.fetchCampaign()
  }

  deleteCampaign = cb => {
    graphql(
      DELETE_CAMPAIGN,
      {
        appKey: this.props.app.key,
        id: this.state.data.id,
      }, {
        success: data => {
          cb && cb()
        },
        error: (error) => {
        },
      }
    )
  }

  fetchCampaign = cb => {
    const id = this.props.match.params.id;

    if (!id) {
      graphql(
        CREATE_CAMPAIGN,
        {
          appKey: this.props.app.key,
          mode: this.props.mode,
          operation: 'new',
          campaignParams: {},
        }, {
          success: data => {
            this.setState({
              data: data.campaignCreate.campaign,
            }, cb && cb)
          },
        }
      )
    } else {
      graphql(
        CAMPAIGN,
        {
          appKey: this.props.app.key,
          mode: this.props.mode,
          id: id,
        }, {
          success: data => {
            this.setState({
              data: data.app.campaign,
            }, cb && cb)
          },
        }
      )
    }
  }

  updateData = (data, cb) => {
    this.setState({ data: data }, cb && cb())
  }

  updateColumnPreferences = (columns, cb) => {
    const params = {
      appKey: this.props.app.key,
      id: this.state.data.id,
      campaignParams: {
        config_table_columns: columns,
      },
    }

    graphql(UPDATE_CAMPAIGN, params, {
      success: data => {
        this.setState({
          data: data.campaignUpdate.campaign,
        })
      },
      error: () => {},
    })
  }

  renderEditorForCampaign = () => {
    switch (this.props.mode) {
      case 'tours':
        return (
          <TourManager
            {...this.props}
            url={this.url()}
            updateData={this.updateData}
            data={this.state.data}
          />
        )
        break

      default:
        return (
          <CampaignEditor
            {...this.props}
            url={this.url()}
            updateData={this.updateData}
            data={this.state.data}
            handleTabChange={this.handleTabChange}
            currentTab={this.state.tabValue}
            updateCampaignData={this.updateCampaignData}
          />
        )
        break
    }
  }

  handleTabChange = (e, i) => {
    this.setState({ tabValue: i })
  }

  tabsContent = () => {
    return (
      <BtnTabContainer>
        <Tabs
          value={this.state.tabValue}
          onChange={this.handleTabChange}
          textColor='inherit'
          style = {{ margin: '25px 50px 0', paddingBottom: 20, paddingTop: 25, borderTop: 'solid 1px rgba(0,0,0,0.12)' }}
        >
          <Tab textColor="inherit" label="Editor" />
          <Tab textColor="inherit" label="Audience" disabled={!this.state.data.id} />
          <Tab textColor="inherit" label="Settings" disabled={!this.state.data.id} />
          <Tab textColor="inherit" label="Statistics" disabled={!this.state.data.id} />
        </Tabs>
      </BtnTabContainer>
    )
  }

  getStats = (params, cb) => {
    graphql(CAMPAIGN_METRICS, params, {
      success: data => {
        const d = data.app.campaign
        cb(d)
      },
      error: (error) => {},
    })
  }

  renderTabcontent = () => {
    switch (this.state.tabValue) {
      case 0:
        return this.renderEditorForCampaign();
      case 1:
        return (
          <CampaignSegment
            {...this.props}
            data={this.state.data}
            url={this.url()}
            successMessage={() =>
              this.props.dispatch(successMessage("campaign updated"))
            }
            updateData={this.updateData}
            updateColumnPreferences={this.updateColumnPreferences}
            handleTabChange={this.handleTabChange}
            currentTab={this.state.tabValue}
          />
        );
      case 2:
        return (
          <CampaignSettings
            {...this.props}
            data={this.state.data}
            mode={this.props.mode}
            successMessage={() =>
              this.props.dispatch(successMessage("campaign updated"))
            }
            url={this.url()}
            updateData={this.updateData}
            handleTabChange={this.handleTabChange}
            currentTab={this.state.tabValue}
          />
        );
      case 3:
        return (
          <CampaignStats
            {...this.props}
            url={this.url()}
            data={this.state.data}
            fetchCampaign={this.fetchCampaign}
            updateData={this.updateData}
            getStats={this.getStats}
          />
        )
    }
  }

  isNew = () => this.props.match.params.id === 'new'

  handleSend = e => {
    const params = {
      appKey: this.props.app.key,
      id: this.state.data.id,
    }

    graphql(DELIVER_CAMPAIGN, params, {
      success: data => {
        this.updateData(data.campaignDeliver.campaign, null)
      },
      error: () => {},
    })
  }

  campaignName = name => {
    switch (name) {
      case 'campaigns':
        return 'Mailing Campaign'
        break;
      case 'user_auto_messages':
        return 'In app messages'

      default:
        return name
        break
    }
  }

  toggleCampaignState = () => {
    graphql(
      UPDATE_CAMPAIGN,
      {
        appKey: this.props.app.key,
        id: this.state.data.id,
        campaignParams: {
          paused: this.state.data.paused === true ? false : true,
        },
      }, {
        success: data => {
          this.setState({ data: data.campaignUpdate.campaign })
          this.props.dispatch(successMessage('campaign updated'))
        },
        error: () => {
          this.props.dispatch(errorMessage('error updating campaign'))
        },
      }
    )
  }

  iconMode = name => {
    switch (name) {
      case 'campaigns':
        return <EmailIcon />
      case 'user_auto_messages':
        return <MessageIcon />
      case 'tours':
        return <FilterFramesIcon />
      default:
        return name
        break
    }
  }

  optionsForMailing = () => ([
    {
      title: "Preview",
      description: "preview campaign's message",
      icon: <VisibilityRounded />,
      id: "preview",
      onClick: (e) => {
        window.open(
          `${window.location.origin}/apps/${this.props.app.key}/premailer/${this.state.data.id}`,
          "_blank"
        );
      },
    }, {
      title: "Deliver",
      description: "delivers the campaign",
      icon: <SendRounded />,
      id: "deliver",
      onClick: this.handleSend,
    },
  ])

  deleteOption = () => ({
    title: 'Delete',
    description: 'delete the campaign',
    icon: <DeleteOutlineRounded />,
    id: 'delete',
    onClick: this.openDeleteDialog,
  })

  optionsForData = () => {
    let newOptions = options

    if (this.props.mode === 'campaigns') {
      newOptions = this.optionsForMailing().concat(options)
    }

    newOptions = newOptions.filter((o) => o.paused != this.state.data.paused)

    newOptions = newOptions.concat(this.purgeMetricsOptions())
    if(this.props.mode === 'campaigns')
      newOptions.push(this.dublicateOptions())
    newOptions.push(this.deleteOption())
    return newOptions
  }

  purgeMetricsOptions = () => ({
    title: 'Purge Metrics',
    description: 'purges campaigns metrics',
    icon: <ClearAll />,
    id: 'deliver',
    onClick: this.purgeMetrics,
  })

  dublicateOptions = () => ({
    title: 'Duplicate',
    description: 'duplicate this campaign',
    icon: <FileCopyIcon  />,
    id: 'dublicate',
    onClick: this.dublicateCampaign,
  })

  openDeleteDialog = () => {
    this.setState({ deleteDialog: true })
  }

  updateCampaignData = (updateState) => {
    this.setState(updateState)
  }

  purgeMetrics = () => {
    graphql(
      PURGE_METRICS,
      {
        appKey: this.props.app.key,
        id: parseInt(this.props.match.params.id),
      }, {
        success: (data) => {
          this.fetchCampaign(() => {
            this.props.dispatch(successMessage("campaign updated"))
          });
        },
        error: () => {
          this.props.dispatch(errorMessage("error purging metrics"))
        },
      }
    )
  }

  dublicateCampaign = () => {
    let name = this.state.data.name
    graphql(
      CREATE_CAMPAIGN, {
        appKey: this.props.app.key,
        mode: this.props.mode,
        operation: 'create',
        campaignParams: { name: name ? name.concat(" (Copy)") : name,
                          subject: this.state.subject,
                          content_type: this.state.replyType1,
                          message_type: this.state.replyType2,
                          text_message: this.state.msgs,
                          from_name: this.state.agent ? this.state.agent.name : '',
                          from_email: this.state.agent ? this.state.agent.email : '',
                          reply_email: this.state.reply_agent.email,
                          template: this.state.template,
                          html_content: this.state.html_content,
                          serialized_content: this.state.serialized_content,
                          html_email_content: this.state.htmlEmailContent,
                          template: this.state.template,
                          company_address: this.state.data.footerAddress,
                          company_email_address: this.state.data.helpEmail,
                          company_phone_number: this.state.data.helpContact,
                          send_criteria: this.state.data.sendCriteria,
                          schedule_type: this.state.data.scheduleType,
                          scheduled_at: this.state.data.scheduledAt,
                          scheduled_to: this.state.data.scheduledTo,
                        },
      }, {
        success: data => {
          if (isEmpty(data.campaignCreate.errors)) {
            this.props.dispatch(successMessage('Campaign duplicated successfully!'))
            } else {
              const errs = []
              Object.entries(data.campaignCreate.errors).map((key) => {
                errs.push(key[0] + ' ' + key[1].join(', '))
              })
              this.props.dispatch(errorMessage(errs.join(', ')))
            }
        }
      }
    )
  }

  createCampaign = data => {
    graphql(
      CREATE_CAMPAIGN, {
        appKey: this.props.app.key,
        mode: this.props.mode,
        operation: 'create',
        campaignParams: { name: this.state.data.name,
                          subject: this.state.subject,
                          content_type: this.state.replyType1,
                          message_type: this.state.replyType2,
                          text_message: this.state.msgs,
                          from_name: this.state.agent ? this.state.agent.name : '',
                          from_email: this.state.agent ? this.state.agent.email : '',
                          reply_email: this.state.reply_agent.email,
                          template: this.state.template,
                          html_content: this.state.html_content,
                          serialized_content: this.state.serialized_content,
                          html_email_content: this.state.htmlEmailContent },
      }, {
        success: data => {
          this.setState({
            data: data.campaignCreate.campaign,
            errors: data.campaignCreate.errors,
          }, () => {
            const { data, errors } = this.state
            const { history, mode, app, currentTab } = this.props

            if (!isEmpty(errors)) return

            window.location = `/apps/${app.key}/messages/${mode}`
          })
        },
      }
    )
  }

  saveCampaign = () => {
    const params = {
      appKey: this.props.app.key,
      id: this.state.data.id,
      campaignParams: { name: this.state.data.name,
                          subject: this.state.subject,
                          content_type: this.state.replyType1,
                          message_type: this.state.replyType2,
                          text_message: this.state.msgs,
                          from_name: this.state.agent ? this.state.agent.name : '',
                          from_email: this.state.agent ? this.state.agent.email : '',
                          reply_email: this.state.reply_agent.email,
                          template: this.state.template,
                          html_content: this.state.html_content,
                          serialized_content: this.state.serialized_content,
                          html_email_content: this.state.htmlEmailContent },
    }

    if (this.props.match.url.includes('/new')) {
      this.createCampaign()
    } else {
      graphql(this.props.match.url.includes('/new') ? CREATE_CAMPAIGN : UPDATE_CAMPAIGN, params, {
        success: data => {
          const result = data.campaignUpdate
          this.setState({ data: result.campaign, errors: result.errors }, () => {
            this.updateData(result.campaign)
            this.props.dispatch(successMessage("campaign updated"))
            window.location = `/apps/${this.props.app.key}/messages/${this.props.mode}`
          })
        },
        error: (error) => {},
      })
    }
  }

  handleTitleChange = ({ target }) => {
    const title = target.value
    this.setState(prevState => ({ data: { ...prevState.data, name: title } }) )
  }

  previewState = (e) => {
    window.open(
      `${window.location.origin}/apps/${this.props.app.key}/premailer/${this.state.data.id}`,
      "_blank"
    );
  }

  render() {
    const { app: { key }, mode } = this.props
    const { data, deleteDialog } = this.state

    const title = data.name ? `${data.name}` : this.campaignName(mode)

    return (
      <div>
        {
          deleteDialog && (
            <DeleteDialog
              open={deleteDialog}
              title={`Delete campaign "${data.name}"`}
              deleteHandler={() => {
                this.deleteCampaign(() => {
                  this.setState({ deleteDialog: false },
                    () => {
                      this.props.history.push(`/apps/${key}/messages/${mode}`)
                    }
                  )
                })
              }}
            >
              <Typography variant='subtitle2'>
                we will destroy any content and related data
              </Typography>
            </DeleteDialog>
          )
        }
        <ContentHeader
          title={
            <Grid container alignContent='space-around' alignItems='center'>
              <Grid item style={{ display: 'flex', alignItems: 'flex-end', padding: 25, paddingLeft: 0, paddingBottom: 0 }}>
                <TitleFieldContainer>
                  {mode === 'tours' ?
                    <TextField value={data.name} onChange={this.handleTitleChange}
                      placeholder='Enter a title' fullWidth onBlur={this.createCampaign}/>
                    :
                    <TextField value={data.name} onChange={this.handleTitleChange}
                    placeholder='Enter a title' fullWidth/>
                  }
                </TitleFieldContainer>
                &nbsp;&nbsp;&nbsp;
                {
                  data.state &&
                    <StatusBadge type='success'>
                      { data.paused ? 'paused' : data.state }
                    </StatusBadge>
                }
              </Grid>
            </Grid>
          }
          items={
            <React.Fragment>
              <EditButtonsGroup>

              <Grid item>
                <SelectMenu
                  options={this.optionsForData()}
                  selected={data.state}
                  handleClick={(e) => this.toggleCampaignState(e.state)}
                  toggleButton={(clickHandler) => (
                    <Button
                      onClick={clickHandler}
                      variant='outlined'
                      color='primary'
                      size='large'
                    >
                      More
                    </Button>
                  )}
                />
              </Grid>

              <Grid item>
                <Button
                  m={2}
                  component='button'
                  variant='outlined'
                  color='primary'
                  onClick={() => {
                    this.props.history.push(`/apps/${key}/messages/${this.props.mode}`)
                  }}
                >
                Cancel
                </Button>
              </Grid>

              {
                !this.props.match.path.includes('/tours/new') &&
                <Grid item>
                  <Button
                    onClick={this.saveCampaign}
                    variant='contained'
                    color='primary'
                    size='large'
                  >
                    Save and Close
                  </Button>
                </Grid>
              }
              </EditButtonsGroup>
            </React.Fragment>
          }
        />
        { this.isNew() ? null : this.tabsContent() }

        <div style={{ padding: '0 50px 27px', backgroundColor: '#fff' }}>
          {
            !isEmpty(data) ? (
              this.isNew() ? (
                <CampaignSettings
                  {...this.props}
                  data={data}
                  mode={mode}
                  url={this.url()}
                  updateData={this.updateData}
                  successMessage={() =>
                    this.props.dispatch(successMessage('campaign updated'))
                  }
                />
              ) : this.renderTabcontent()
            ) : null
          }
        </div>
      </div>
    )
  }
}

class CampaignContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: [],
      meta: {},
      openDeleteDialog: null,
      visible_cols: ["name", "subject", "state", "actions", "send", "click", "open", "bounce", "replied"],
      selected_users: [],
      requiresUpgrade: false,
      showUpgradeDialog: false,
      showDeleteButton: false,
      perPage: 20,
    };
  }

  calculateStats = (counts, key) => {
    let totalCount = counts['send'] || 1
    let metric = counts[key] || 0
    if(key === 'send'){
      metric = counts['send'] || 0
    }else{
      metric = Math.round((metric/totalCount)*100)
    }
    return metric
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.match.params.message_type !==
      prevProps.match.params.message_type) || (this.props.getCampaignNeedToUpdateCount !==
      prevProps.getCampaignNeedToUpdateCount)
    ) {
      this.props.dispatch(setCurrentPage(this.props.match.params.message_type));
      this.props.dispatch(setCurrentSection("Campaigns"));
      this.init();
    }
  }

  componentDidMount() {
    this.props.dispatch(setCurrentSection('Campaigns'))
    this.props.dispatch(setCurrentPage(this.props.match.params.message_type))
    this.init()
  }

  init = page => {
    this.fetchCampaigns();
  }

  fetchCampaigns = (page) => {
    this.setState({ loading: true })
    graphql(
      CAMPAIGNS, {
        appKey: this.props.match.params.appId,
        mode: this.props.match.params.message_type,
        page: page || 1,
        perPage: this.state.perPage,
      },
      {
        success: (data) => {
          const { collection, meta } = data.app.campaigns

          this.setState({
            campaigns: collection,
            meta,
            loading: false,
            requiresUpgrade: data.app.upgradeToCreateCampaigns
          })
        },
      }
    )
  }

  deleteActionClick = (id, name) => {
    this.setState({ openDeleteDialog: { name: name, id: id } })
  }

  createNewCampaign = e => {
    this.props.history.push(`${this.props.match.url}/new`)
  }

  handleRowClick = (a, data, c) => {
    const row = this.state.campaigns[data.dataIndex]
    this.props.history.push(`${this.props.match.url}/${row.id}`)
  }

  openUpgradeDialog = () => {
    this.setState({ showUpgradeDialog: true })
  }

  closeUpgradeDialog = () => {
    this.setState({ showUpgradeDialog: false })
  }

  renderActions = style => (
    <Grid container style={style}>
      <Button
        variant='contained'
        style={{ background: '#FFB600', padding: '20px 40px', marginTop: 20, marginBottom: 20 }}
        onClick={this.state.requiresUpgrade ? this.openUpgradeDialog : this.createNewCampaign}
      >
        <AddIcon style={{ marginRight: 5 }} />
        Create new campaign
      </Button>
      {
        this.state.showDeleteButton &&
          <Button
            variant='contained'
            style={{ background: '#f30c3f', marginTop: 20, marginBottom: 20, paddingLeft: 20, paddingRight: 20,marginLeft: 20, color: 'white' }}
            onClick={this.handleDeleteClick}
          >
            Delete
          </Button>
      }
    </Grid>
  )

  handleDeleteClick = () => {
    const ids = this.state.selected_users.map((su) => su.id)
    this.deleteCampaigns(ids)
  }

  deleteCampaigns = (ids) => {
    graphql(
      DELETE_CAMPAIGNS,
      {
        appKey: this.props.app.key,
        ids,
      }, {
        success: data => {
          ids = data.campaignsDelete.campaigns.map((c) => c.id)
          const campaigns = this.state.campaigns.filter((c) => !(ids.includes(c.id)))
          this.setState({ campaigns, selected_users: [{}], showDeleteButton: false })
          this.props.dispatch(successMessage("Campaigns deleted successfully."))
        },
      }
    )
  }

  deleteCampaign = (id, cb) => {
    graphql(
      DELETE_CAMPAIGN,
      {
        appKey: this.props.app.key,
        id: id,
      }, {
        success: data => {
          cb && cb()
        },
        error: error => {
        },
      }
    )
  }

  url = () => {
    const id = this.props.match.params.id
    return `/apps/${this.props.app.key}/campaigns/${id}`
  }

  emptyViewContent = () => {
      const { message_type } = this.props.match.params;
      if(message_type === 'campaigns') {
        return {
          pageName: 'Email Automation',
          rephrase: "Swiftly manage & automate communication processes to master connection with your customers. Easily build and send more targeted, segmented emails with Workflows. Automate your communication processes and enhance your connections with your customers. Easily manage and build more targeted, segmented emails to send with Workflows.",
          howList: [
            'Identify your campaign objective',
            'Plan the sequence',
            'Develop your own content',
            'Test it before going live',
            'All set and publish',
          ]
        }
      }
      else if ( message_type === 'user_auto_messages' ) {
        return {
          pageName: 'In-app message',
          rephrase: "Design and deploy rich in-app guidance without excessively complex developer resources.",
          howList: [
            'Aim for a simple but intentionally planned campaign',
            'Prepare the content with a call-to-action',
            'Test it before going live',
            'All set and publish',
          ]
        }
      }
      else if ( message_type === 'tours' ) {
        return {
          pageName: 'guide tour',
          rephrase: "A great product tour will keep your sales team in productive conversations with quality prospects that are more likely to convert. A product your also shortens the sales cycle by providing relevant information upfront, and increasing conversions through a main call to action.",
          howList: [
            'List of most important features of your product',
            'Prepare the content accordingly in 3-4 sentences',
            'Create those content through our app',
            'Ready, set, publish!',
          ]
        }
      }
  }

  render() {
    const { app, currentUser, match } = this.props

    const columns = [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        width: "160px",
        omit: this.state.visible_cols.indexOf("name") === -1,
        cell: (row) => (
          <AnchorLink to={`${this.props.match.url.includes('/tours') ? this.props.match.url+'/'+row.id : this.props.match.url+'/'+row.id+'/edit'}`}>
            {row.name}
          </AnchorLink>
        ),
      },
      {
        name: "Subject",
        selector: "subject",
        sortable: true,
        width: "260px",
        omit: this.state.visible_cols.indexOf("subject") === -1,
      },
      {
        name: "State",
        selector: "state",
        sortable: true,
        width: "100px",
        omit: this.state.visible_cols.indexOf("state") === -1,
        cell: (row) => (
          row.paused ? 'paused' : row.state
        )
      },
      {
        name: "Actions",
        sortable: true,
        omit: this.state.visible_cols.indexOf("actions") === -1,
        cell: (row) => (
          <div>
            <AnchorLink to={`${this.props.match.url.includes('/tours') ? this.props.match.url+'/'+row.id : this.props.match.url+'/'+row.id+'/edit'}`}>Edit</AnchorLink>|
            <AnchorLink to = '#' onClick={() => this.deleteActionClick(row.id, row.name)} >Delete</AnchorLink>
          </div>
        )
      },
      {
        name: "Send",
        omit: this.state.visible_cols.indexOf("send") === -1 || this.props.match.params.message_type == 'tours',
        cell: (row) => (
          <div>
            <span>{this.calculateStats(row.counts, 'send')}</span>
          </div>
        )
      },
      {
        name: "Open",
        omit: this.state.visible_cols.indexOf("open") === -1 || this.props.match.params.message_type == 'tours',
        cell: (row) => (
          <div>
            <span>{this.calculateStats(row.counts, 'open')}%</span>
          </div>
        )
      },
      {
        name: "Click",
        omit: this.state.visible_cols.indexOf("click") === -1 || this.props.match.params.message_type == 'tours',
        cell: (row) => (
          <div>
            <span>{this.calculateStats(row.counts, 'click')}%</span>
          </div>
        )
      },
      {
        name: "Replied",
        omit: this.state.visible_cols.indexOf("replied") === -1 || this.props.match.params.message_type == 'tours',
        cell: (row) => (
          <div>
            <span>0%</span>
          </div>
        )
      },
      {
        name: "Bounce",
        omit: this.state.visible_cols.indexOf("bounce") === -1 || this.props.match.params.message_type == 'tours',
        cell: (row) => (
          <div>
            <span>{this.calculateStats(row.counts, 'bounce')}%</span>
          </div>
        )
      },
    ]

    const currentHeader = {
      id: I18n.t('navigator.campaigns'),
      url: `/apps/${app.key}/campaigns`,
      children: [
        {
          id: 'Mailing Campaigns',
          url: `/apps/${app.key}/messages/campaigns`,
        },
        {
          id: 'In App messages',
          url: `/apps/${app.key}/messages/user_auto_messages`,
        },
        {
          id: 'Guided tours',
          url: `/apps/${app.key}/messages/tours`,
        },
      ],
    }

    const supportingHeader = {
      id: I18n.t('navigator.campaigns'),
      url: `/apps/${app.key}/campaigns`,
      children: [
        {
          id: 'How to build a bot',
          url: `/apps/${app.key}/messages/campaigns`,
        },
        {
          id: 'How bots work',
          url: `/apps/${app.key}/messages/user_auto_messages`,
        },
        {
          id: 'Step-by-step guides',
          url: `/apps/${app.key}/messages/tours`,
        },
      ],
    }

    const handleSelectClick = (s) => {
      this.setState({ selected_users: s.selectedRows, showDeleteButton: s.selectedCount > 0 })
    }

    const onChangePage = (page, perPage) => {
      this.fetchCampaigns(page);
    }

    return (
      <div>
        <ConversationHeader
          history={this.props.history}
          appId={app.key}
          category={currentHeader}
          supportingHeader={supportingHeader}
        />

        <Switch>
          <Route
            exact
            path={`${match.url}/new`}
            render={(props) => (
              props.match.params.message_type == 'tours' ?
              <TourManager
                {...this.props}
                url={this.url()}
                updateData={this.updateData}
                data={this.state.data || {}}
              /> :
              <CampaignForm
                currentUser={this.props.currentUser}
                mode={this.props.match.params.message_type}
                {...this.props}
                {...props}
              />
            )}
          />

          <Route
            exact
            path={`${match.url}/:id/edit`}
            render={(props) => (
              props.match.path.includes('/tours')  ?
              <TourManager
                {...this.props}
                url={this.url()}
                updateData={this.updateData}
                data={this.state.data || {}}
              /> :
              <CampaignForm
                currentUser={currentUser}
                mode={match.params.message_type}
                {...this.props}
                {...props}
              />
            )}
          />


          <Route
            exact
            path={`${match.url}/:id`}
            render={(props) => (
              !props.match.path.includes('/tours') ?
              <TourManager
                {...this.props}
                url={this.url()}
                updateData={this.updateData}
                data={this.state.data || {}}
              /> :
              <CampaignView
                currentUser={currentUser}
                mode={match.params.message_type}
                {...this.props}
                {...props}
              />
            )}
          />

          <Route
            exact
            path={`${match.url}`}
            render={(props) => (
              <div>
                {this.state.openDeleteDialog && (
                  <DeleteDialog
                    open={this.state.openDeleteDialog}
                    title={`Delete campaign "${this.state.openDeleteDialog.name}"`}
                    closeHandler={() => {
                      this.setState({ openDeleteDialog: null });
                    }}
                    deleteHandler={() => {
                      this.deleteCampaign(
                        this.state.openDeleteDialog.id,
                        () => {
                          this.init();
                          this.setState({ openDeleteDialog: null });
                          this.props.dispatch(
                            successMessage("campaign removed")
                          );
                        }
                      );
                    }}
                  >
                    <Typography variant="subtitle2">
                      we will destroy any content and related data
                    </Typography>
                  </DeleteDialog>
                )}

                  <UpgradeDialog isOpen={this.state.showUpgradeDialog} handleClose={this.closeUpgradeDialog} />
                  <div
                    style={{
                      padding: "18px 26px",
                      fontSize: "26px",
                      fontWeight: "600",
                      borderBottom: "solid 1px rgba(0,0,0,.125)",
                    }}
                  >
                    Campaign
                  </div>
                <Content actions={this.state.campaigns.length > 0 ? this.renderActions({ justifyContent: 'flex-end' }) : null}>
                  {
                    !this.state.loading && this.state.campaigns.length > 0 ? (
                      <Container component="main" maxWidth="lg" style={{ paddingTop: 40, marginTop: 50, border: '1px solid #E5E5E5', borderRadius: 7,  }}>
                        <Datatable
                          columns={columns}
                          data={this.state.campaigns}
                          paginationRowsPerPageOptions={[20]}
                          paginationDefaultPage={this.state.meta.current_page}
                          sortServer={false}
                          pagination
                          paginationServer
                          paginationPerPage={this.state.perPage}
                          paginationTotalRows={this.state.meta.total_count}
                          onChangePage={onChangePage}
                          onSelectedRowsChange={(s) =>
                            handleSelectClick(s)
                          }
                        />
                      </Container>
                    ) : null
                  }
                  {
                    !this.state.loading && this.state.campaigns.length === 0 ? (
                      <EmptyView
                        page='campaign'
                        content={this.emptyViewContent()}
                        subtitle={
                          <div style={{ position: 'relative' }}>
                            <Typography variant='h4' style={{ fontSize: 30, fontWeight: 400 }}>create a new campaigns</Typography>
                            <Typography variant='h4' style={{ fontSize: 30, fontWeight: 400 }}>Here</Typography>
                            <img src={down_icon} style={{ position: 'absolute', bottom: 90, left: 110 }} />
                            { this.renderActions({ justifyContent: 'flex-start'}) }
                          </div>
                        }
                      />
                    ) : null
                  }

                  { this.state.loading ? <CircularProgress /> : null }
                </Content>
              </div>
            )}
          />
        </Switch>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { auth, app, campaigns } = state;
  const { getCampaignNeedToUpdateCount } = campaigns;
  const { loading, isAuthenticated } = auth;

  return {
    app,
    loading,
    isAuthenticated,
    getCampaignNeedToUpdateCount,
  };
}

export default withRouter(connect(mapStateToProps)(CampaignContainer));
