import React from "react"
import styled from "@emotion/styled";
import TextField from '@material-ui/core/TextField';
import TextEditor from "../../textEditor";
import moment from 'moment'
import Link from '@material-ui/core/Link';


const BrowserSimulator = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #dde1eb;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  max-width: 780px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  .MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:after, .MuiInput-underline:before {
    border-bottom: none;
  }
  input{
    max-width: 800px;
    box-shadow: none;
    padding: 15px;
    padding-left: 25px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 0;
    border-bottom: 1px solid #E5E5E5;
  }
  .mail-compaign-footer {
    padding: 0 30px;
  }
`;
const BrowserSimulatorHeader = styled.div`
  background: rgb(250, 247, 242);
  border-bottom: 1px solid #ececec;
  /* border-radius: 6px; */
  padding: 22px;
  display: flex;
`;
const BrowserSimulatorButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 74px;

  .r {
    width: 30px;
    height: 6px;
    margin-right: 6px;
    border-radius: 4px;
    background-color: #fc635e;
    border: 1px solid #dc4441;
  }
  .y {
    width: 30px;
    height: 6px;
    margin-right: 6px;
    border-radius: 4px;
    background-color: #fdbc40;
    border: 1px solid #db9c31;
  }
  .g {
    width: 30px;
    height: 6px;
    margin-right: 6px;
    border-radius: 4px;
    background-color: #35cd4b;
    border: 1px solid #24a732;
  }
`;
const EditorPad = styled.div`
  ${(props) =>
    props.mode === "user_auto_messages"
      ? ` display:flex;
      justify-content: flex-end;
      flex-flow: column;
      height: 90vh;

      .postContent {
        height: 440px;
        overflow: auto;
      }
    `
      : `
      padding: 1em 2em 3em;


      @media (max-width: 640px){
        margin: 2em;
      }

    `}
`;
const EditorContentWrapper1 = styled.div`
  margin: 20px 100px !important;
  border: solid 1px rgba(0,0,0,0.12);
  border-radius: 10px;
  position: relative;

`;
const EditorMessengerEmulator = styled.div`
  ${(props) =>
    props.mode === "user_auto_messages"
      ? `
  display:flex;
  justify-content: flex-end;`
      : ``}
`;
const EditorMessengerEmulatorWrapper = styled.div`
  ${(props) =>
    props.mode === "user_auto_messages"
      ? `width: 380px;
    background: #fff;
    border: 1px solid #f3efef;
    margin-bottom: 25px;
    margin-right: 20px;
    box-shadow: 3px 3px 4px 0px #b5b4b4;
    border-radius: 10px;
    padding: 12px;
    padding-top: 0px;
    .icon-add{
      margin-top: -2px;
      margin-left: -2px;
    }
    `
      : ``}

    .inlineTooltip-menu {
      position: absolute;
      display: flex !important;
      flex-direction: column !important;
      left: -120px;
      top: -70px;
      padding: 10px;
      border-radius: 8px;

      .inlineTooltip-button.scale {
        margin-bottom: 10px;
      }

    }
    .inlineTooltip-button.control {
      background: #000;
      border-radius: 10px;
      border-bottom-right-radius: 0;
      svg path {
        fill: #fff;
      }
    }
    .tooltip-black {
      svg {
        margin-top: 3px;
      }
    }
    .inlineTooltip.is-scaled {
        .inlineTooltip-menu {
          width: 60px !important;
          background: #fff;
          border: 1px solid #E5E5E5;
          /* :after {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 10px solid #333;
            content: ' ';
            position: absolute;
            top: 100%;
            left: 50%;
          } */
        }
        .inlineTooltip-button.scale{
          border: 0;
        }
      }
    }
`;
const EditorMessengerEmulatorHeader = styled.div`
  ${(props) =>
    props.mode === "user_auto_messages"
      ? `
  padding: 1em;
  border-bottom: 1px solid #ccc;
  `
      : ``}
`;
const EditorFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 40px;
  padding-bottom: 0;
  ${(props) => props.justify ? `justify-content: ${props.justify};` : `justify-content: space-between;`}
`;
const MailCompaignFooterContainer = styled.div`
  text-align: center;
  text-align: -webkit-center;
`;
const MailCompaignFooter = styled.div`
  .plain-template-footer {
    .heading1 {
      text-align: center;
      font-size: 13px;
      a {
        color: #777777;
      }
    }
  }
  .company-template-footer {
    font-size: 13px;
    .heading1 {
      color: grey;
      margin-bottom: 10px;
    }
    .heading2 a { color: grey; }
  }
`;
const MailCompaignFooterBottom = styled.div`
  margin-top: 60px;
  margin-bottom: 14px;
  color: grey;
  font-size: 12px;
`;

const BrowerSimulatorView = (props) => {

  const {
    subject,
    mode,
    changeSubjectHandler,
    uploadHandler,
    saveHandler,
    saveContent,
    serializedContent,
    mailCompaignFooter,
    personalFooter,
    setReadState,
    template_was,
    template,
    agent,
    read_only,
    app
  } = props
  return (
    <BrowserSimulator mode={mode}>
      <BrowserSimulatorHeader>
        <BrowserSimulatorButtons>
          <div className={"circleBtn r"}></div>
          <div className={"circleBtn y"}></div>
          <div className={"circleBtn g"}></div>
        </BrowserSimulatorButtons>
      </BrowserSimulatorHeader>
      <TextField placeholder = "Enter your email subject" value={subject} onChange={changeSubjectHandler}></TextField>
      <div style={{ padding: 15, paddingLeft: 25, display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #E5E5E5' }}>
        <div className="" style={{ color: '#65AB72'}}>
          <span style={{ textTransform: 'capitalize' }}>{ `${agent && agent.name ? agent.name : 'UpSend'}` }</span>
          { ` from ${app.name} <${agent && agent.email}>` }
        </div>
        <span className="" style={{ color: '#777777'}}>{moment().format('LL')}</span>
      </div>
      <EditorContentWrapper1 mode={mode}>
        <div style={{ margin: '30px', border: 'solid 1px #E5E5E5', borderRadius: 7, boxShadow: 'rgba(100, 100, 100, 0.2) 1px 3px 10px -3px' }}>
          <EditorPad mode={mode}>
            <EditorMessengerEmulator mode={mode}>
              <EditorMessengerEmulatorWrapper mode={mode}>
                <EditorMessengerEmulatorHeader mode={mode} />

                <TextEditor
                  campaign={true}
                  uploadHandler={uploadHandler}
                  serializedContent={serializedContent}
                  read_only={read_only}
                  toggleEditable={() => setReadState(read_only)}
                  data={{
                    serialized_content: serializedContent,
                  }}
                  styles={{
                    lineHeight: "1.7em",
                    fontSize: "15px",
                  }}
                  saveHandler={saveHandler}
                  updateState={({ status, statusButton, content }) => {
                    saveContent(content);
                  }}
                  app={app}
                />
              </EditorMessengerEmulatorWrapper>
            </EditorMessengerEmulator>
          </EditorPad>
        </div>
        {(template || template_was) === "personal" && <EditorFooter>
          {personalFooter()}
          </EditorFooter>
        }
        <MailCompaignFooterContainer>
          <MailCompaignFooter className='mail-compaign-footer'>
            {mailCompaignFooter()}
          </MailCompaignFooter>
          <MailCompaignFooterBottom>
           <Link href="https://upsend.io/">
             Powred by Upsend
           </Link>
          </MailCompaignFooterBottom>
        </MailCompaignFooterContainer>
      </EditorContentWrapper1>
    </BrowserSimulator>
  )
}

export default BrowerSimulatorView;
