import React from 'react'
import styled from "@emotion/styled";

const Avatar = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  align-self: center;
  img {
    width: 26px;
    height: 26px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #777777;
  }
`
const TextMessageContainer = styled.div`
  box-shadow: 0px 0px 10px lightgrey;
`
const TextMessageTitle = styled.div`
  display: flex;
  background-color: black;
  color: white;
  padding: 1.5rem 1rem;
  border-radius: 6px;
`
const AvatarName = styled.div`
  padding-left: 1rem;
  align-self: center;
`
const MessageText = styled.div`
  padding: 1rem 1.5rem 2rem 1.5rem;
`

const TextMessageSnippet = (props) => {

  const { agent } = props
  return (
    <TextMessageContainer>
      <TextMessageTitle>
      </TextMessageTitle>
      <MessageText>
        Hi 😀 Have a look around! Let us know if you have any questions.
      </MessageText>
    </TextMessageContainer>
  )
}

export default TextMessageSnippet
