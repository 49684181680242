import React from 'react';

export default () => (
  <section style={{marginBottom: '10px'}}>
    <p>
      UpsendApp.
    </p>
    <p>
      This gives a working navigation component you can start hacking on, see /src/components/StarterNavigation.js.
    </p>
  </section>
);
