import React, {useState} from 'react'
import { connect } from 'react-redux'
import { authenticate, signout } from '../actions/auth'

import graphql from '../graphql/client'
import {CURRENT_USER, APPS_KEY} from '../graphql/queries'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import favicon from '../images/favicon'
import right_arrow from '../../../assets/images/rightArrow.png'
import signin_img from '../../../assets/images/signinBack.png'
import linkedin from '../../../assets/images/linkedin.png'
import google from '../../../assets/images/googleIcon.png'
import {getCurrentUser} from '../actions/current_user'
import logo from '../images/logo'
import Snackbar from '../components/snackbar'
import { MuiThemeProvider } from '@material-ui/core/styles';
import lightTheme from '../themes/light/index'
import darkTheme from '../themes/dark/index'
import LoadingView from '../components/loadingView'



class Login extends React.Component {
  constructor(props) {
    super(props)
  }

  handleSubmit(data) {
    const { email, password } = data //this.state
    this.props.dispatch(authenticate(email, password, ()=>{
      //TODO Skip or set graphql
      // this.getCurrentUser()
      // if(window.location.pathname === '/'){
      //   graphql(APPS_KEY ,{} ,{
      //     success: (data)=>{
      //       if(data.apps.length > 0){
      //         let key = data.apps[0].key
      //         this.props.history.push("apps/"+key)
      //       }else{
      //         this.props.history.push("apps")
      //       }
      //     },
      //     error: (error)=>{
      //       console.log(error)
      //     }
      //   })
      // }
    }))
  }

  getCurrentUser = ()=>{
    this.props.dispatch(getCurrentUser())
  }

  render() {
    const { isAuthenticated } = this.props
    if (isAuthenticated) {
      return <GetUserDataButton onClick={this.getCurrentUser}/>
    }
    return (
      <div>
        <Snackbar />
        <SignIn
          {...this.props}
          handleSubmit={this.handleSubmit.bind(this)}
        />
      </div>
    )
  }
}

function GetUserDataButton(props){
  props.onClick()
  return <LoadingView onClick={props.onClick}/>
}

function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Built with love by the '}
      <Link color="inherit" href="https://upsend.io/">
        Upsend Team
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.background.default,
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 50,
  },
  backgroundImg: {
    width: '50%', height: '55%', margin: '22%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    width: '60px',
    height: '60px',
    borderRadius: '0%'
  },
  logo: {
    height: '100%',
    width: '100%'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    "& input": {
      boxShadow: 'none',
    }
  },
  submit: {
    margin: '40px 0px 16px', borderRadius: 0, padding: '10px 80px',
  },
  border: {
    position: 'absolute', border: '1px solid #000', height: '100%', width: '100%', top: 4, left: 4,
  },
  rightArrow: {
    position: 'absolute', right: '-30px'
  },
  social: {
    background: '#F8F8FD', padding: 10, borderRadius: 7, marginRight: 10,
  }
}));

function SignIn(props) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e)=>{
    e.preventDefault()
    props.handleSubmit({email, password})
  }

  const theme = props.theme === "light" ? lightTheme : darkTheme

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <img className={classes.backgroundImg} src={signin_img}/>
        </Grid>
        <Grid item xs={12} sm={6}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          {/*<LockOutlinedIcon />*/}
          <img className={classes.logo} src={favicon}/>
        </Avatar>
        <Typography component="h1" variant="h1"  style={{fontSize: 64, fontWeight: 'bold', marginTop: 50, marginBottom: 100}}>
          Welcome!
        </Typography>

        {/*<Grid container>
            <a href="/agents/auth/google_oauth2" variant="body2" className={classes.social}>
              <img src={google} style={{width: 54, height: 54}} />
            </a>
            <a href="/agents/auth/linkedin" variant="body2" className={classes.social}>
              <img src={linkedin} style={{width: 54, height: 54}} />
            </a>
        </Grid>
        <Snackbar/>

        <Grid container style={{flexDirection: 'row', padding: '20px 0'}}>
          <Grid item xs={12} sm={8}>
            <p style={{float: 'left'}}>or use your email to sign in</p>
            <hr style={{ margin: 26, marginRight: 0, border: '1px solid #E5E5E5' }}></hr>
          </Grid>
        </Grid>*/}
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e)=> setEmail(e.target.value)}
              />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e)=> setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
          </Grid>
          <Grid item xs={12} sm={8} style={{textAlign: 'right'}}>
            <Link href='/forgot_password' variant='body2'>
              Forgot password?
            </Link>
          </Grid>
          </Grid>
          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              <div className={classes.border}></div>
              <img src={right_arrow} className={classes.rightArrow} />
              Sign In
            </Button>
          </div>
        </form>
      </div>
    </Grid>
    </Grid>
    </MuiThemeProvider>
  );
}




function mapStateToProps(state) {
  const { auth, current_user, theme } = state
  const { loading, isAuthenticated } = auth

  return {
    current_user,
    loading,
    isAuthenticated,
    theme
  }
}

export default connect(mapStateToProps)(Login)
