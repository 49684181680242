import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Divider from "@material-ui/core/Divider";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import girl from '../../../assets/images/avarta/girl.png';
import userCircle_icon from '../../../assets/images/userCircle.png';
import at_icon from '../../../assets/images/at.png';
import people_icon from '../../../assets/images/peopleGroup.png';
import menuAltLeft_icon from '../../../assets/images/menuAltLeft.png';


const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    color: '#000',
    backgroundColor: '#fff',
    padding: '20px 26px'
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    //borderColor: lightColor,
  },
  main: {
    display: 'flex', flexDirection: 'row',
    marginBottom: 4,
    boxShadow: '1px 3px 10px -3px rgba(100, 100, 100, 0.2)',
  },

  textColorPrimary: {
    color: '#777777',
    fontWeight: 800,

    // MuiSelected: {
    //   color: '#000',
    // }
  },
  selected: {
    color: '#000',
    fontWeight: 800,
    fontSize: 18,
    padding: '20px 10px',
  },

  tabContent: {
    display: 'flex', justifyContent: 'space-between',
  },

});

function ConversationHeader(props) {
  const { classes } = props;

  const [value, setValue] = React.useState(props.category.children.findIndex((e) => e.url === document.location.pathname));

  const handleChange = (event, newValue) => {
    const { appId } = props;

    props.history.push(props.category.children[newValue].url)
    setValue(newValue);
  };

  return (
    <>
    <div className={ classes.main } style={{position: 'fixed', zIndex: '100', background: 'white', width: '100%'}}>
      <Typography style={{ fontSize: 40, margin: '20px 350px 4px 30px' }} variant="h2">{ props.category.id }</Typography>
      <Paper square style={{ boxShadow: 'none' }}>
        <Tabs value={value} TabIndicatorProps={{style: {background:'#FFB600', height: 5 }}} onChange={handleChange}>
          {
            props.category.children.map((tab) => {
              return (
                <Tab
                  className={ [classes.selected] }
                  label={ tab.id }>
                </Tab>
              )
            })
          }
        </Tabs>
      </Paper>
      </div>
    </>
  );
}

ConversationHeader.propTypes = {
  // title: PropTypes.string.isRequired,
  tabsContent: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func,
};

export default withStyles(styles)(ConversationHeader);
