import React, { Component } from "react";

import PieChartIcon from "@material-ui/icons/PieChart";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ExposurePlus2Icon from "@material-ui/icons/ExposurePlus2";
import FormDialog from "../FormDialog";
// import DataTable from "../table/index";

import SegmentItemButton from "./itemButton";
import { Map, List, fromJS } from "immutable";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import Container from '@material-ui/core/Container';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import { Datatable, IconColName } from "../../components/datatable/";
import Moment from 'react-moment';
import defaultFields from "../../shared/defaultFields";
import styled from "@emotion/styled";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { uniqBy } from "lodash";
import { green } from '@material-ui/core/colors';

import icon_plus from "../../icons/bx-plus.svg";
import icon_save from "../../icons/bx-save.svg";
import icon_delete from "../../icons/bxs-trash.svg";
import icon_search from "../../icons/bx-search-alt.svg";
import icon_face from "../../icons/bxs-face.svg";
import icon_email from "../../icons/bxs-envelope.svg";
import icon_state from "../../icons/bx-check.svg";
import icon_map from "../../icons/bxs-map-alt.svg";
import icon_calc from "../../icons/bxs-calendar.svg";
import icon_col from "../../icons/bx-columns.svg";

const ContentMatchTitle = styled.h5`
  margin: 15px;
  border-bottom: 2px solid #6f6f6f;
`;

const ContentMatch = styled.div`
  overflow: auto;
  width: 300px;
  height: 121px;
  margin-bottom: 25px;
  padding-left: 14px;
`;

const ContentMatchFooter = styled.div`
  /* position: absolute; */
  bottom: 0px;
  width: 100%;
  left: 0px;
  padding: 9px;
  /* background: #ccc; */
  margin-top: 10px;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: baseline;
`;

const ButtonGroup = styled.div`
  //display: inline-flex;
  //display: -webkit-box;

  margin-bottom: 2em;

  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  button {
    margin-right: 5px !important;
    margin: 2px;
    padding: 15px 25px;
  }
  span {
    fontWeight: 400;
  }
`;

export class SaveSegmentModal extends Component {
  state = {
    isOpen: false,
    action: "update",
    loading: false,
    input: null,
  };
  open = () => this.setState({ isOpen: true });
  close = () => this.setState({ isOpen: false });
  input_ref = null;

  secondaryAction = ({ target }) => {
    this.props.savePredicates(
      {
        action: this.state.action,
        input: this.input_ref ? this.input_ref.value : null,
      },
      () => {
        this.close();
        if (this.props.predicateCallback) this.props.predicateCallback();
      }
    );
  };

  deleteAction = ({ target }) => {
    this.props.deleteSegment(this.props.segment.id, this.close);
  };

  handleChange = ({ target }) => {
    this.setState({
      action: target.value,
    });
  };

  equalPredicates = () => {
    return fromJS(this.props.segment.predicates).equals(
      fromJS(this.props.segment.initialPredicates)
    );
  };

  incompletePredicates = () => {
    return this.props.segment.predicates.find((o) => !o.comparison || !o.value);
  };

  render() {
    const { isOpen, loading } = this.state;

    return (
      <React.Fragment>
        <div>
          <Button
            isLoading={false}
            color="primary"
            onClick={this.open}
            disabled={this.equalPredicates() || this.incompletePredicates()}
          >
            &nbsp;&nbsp;&nbsp;
            <img src={icon_save} style={{ height: "20px" }} />
            &nbsp;&nbsp;Save segment&nbsp;&nbsp;&nbsp;
          </Button>

          <Button
            isLoading={false}
            color="primary"
            onClick={this.deleteAction.bind(this)}
          >
            &nbsp;&nbsp;&nbsp;
            <img src={icon_delete} style={{ height: "20px" }} />
            &nbsp;&nbsp;Delete segment&nbsp;&nbsp;&nbsp;
          </Button>
        </div>

        {isOpen && (
          <FormDialog
            open={isOpen}
            //contentText={"lipsum"}
            titleContent={"Save Segment"}
            formComponent={
              !loading ? (
                <div>
                  <RadioGroup
                    aria-label="options"
                    name="options"
                    onChange={this.handleChange.bind(this)}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value="update"
                      label={`Save changes to the segment ‘${this.props.segment.name}’`}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value="new"
                      label="Create new segment"
                    />
                  </RadioGroup>

                  {this.state.action === "new" ? (
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      name="name"
                      label="name"
                      type="email"
                      ref={"input"}
                      fullWidth
                      inputRef={(input) => (this.input_ref = input)}
                    />
                  ) : null}

                  {/*
                      actions.map((o, i)=> (
                        <Button key={i}
                          onClick={o.onClick}>
                          {o.text}
                        </Button>
                       )
                      )
                      */}
                </div>
              ) : (
                <Spinner />
              )
            }
            dialogButtons={
              <React.Fragment>
                <Button onClick={this.close} color="secondary">
                  Cancel
                </Button>

                <Button
                  onClick={this.secondaryAction.bind(this)}
                  color="primary"
                >
                  {this.state.action === "update" ? "Save" : "Save New"}
                </Button>
              </React.Fragment>
            }
            //actions={actions}
            //onClose={this.close}
            //heading={this.props.title}
          ></FormDialog>
        )}
      </React.Fragment>
    );
  }
}

export class InlineFilterDialog extends Component {
  state = {
    dialogOpen: false,
    filterSearch: "",
  };

  _my_field = null;

  toggleDialog = (e) => {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
      filterSearch: "",
    });
  }
  handleClick = (e, o) => {
    this.setState(
      {
        dialogOpen: !this.state.dialogOpen,
      },
      () => {
        this.props.addPredicate(o, (token) => {
          //this.props.handleClick(token)
        });
      }
    );
  };

  toggleDialog2 = () => {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  };

  availableFields = () => {
    if (!this.props.app.customFields) return uniqBy(defaultFields.concat(this.props.searchFilterColumn), 'name');
    return uniqBy(this.props.app.customFields.concat(defaultFields).concat(this.props.searchFilterColumn), 'name');
  };

  handleFilterSearchChange = (e) => {
    this.setState({ filterSearch: e.target.value });
  };

  filterMenuItem = (fieldObject) => {
    let IconTag = FormatQuoteIcon;
    if (fieldObject.type == "date") {
      IconTag = DateRangeIcon;
    } else if (fieldObject.type == "number" ||fieldObject.type == "integer") {
      IconTag = ExposurePlus2Icon;
    }

    return (
      <span style={{ display: "inline-flex", alignItems: "center" }}>
        <IconTag variant="small" style={{ marginRight: "10px" }} />
        {fieldObject.name == 'type' ? 'people' : fieldObject.name}
      </span>
    );
  };

  render() {
    const fields = this.availableFields();

    const content = (
      <ClickAwayListener onClickAway={this.toggleDialog2.bind(this)}>
        <div>
          <Input
            placeholder="Search people and company data."
            onChange={this.handleFilterSearchChange}
            endAdornment={
              <InputAdornment position="start">
                <img src={icon_search} style={{ width: 20 }} />
              </InputAdornment>
            }
          />

          <div className="list-scroll-menu" style={{ height: "500px", width: "360px", overflow: "auto" }}>
            {fields
              .filter((v) => {
                return (
                  v && v.name
                    .toLowerCase()
                    .indexOf(this.state.filterSearch.toLowerCase()) > -1
                );
              })
              .map((o) => (
                <MenuItem
                  key={o.name}
                  onClick={(e) => this.handleClick.bind(this)(e, o)}
                >
                  {this.filterMenuItem(o)}
                </MenuItem>
              ))}
          </div>

          <Button
            className="btn-bottom-new"
            variant="contained"
          >
            <img src={icon_plus} style={{ height: "20px" }} />
            &nbsp;&nbsp;New attribute
          </Button>
        </div>
      </ClickAwayListener>
    );

    return (
      <div>
        <Button
          innerRef={(ref) => (this._my_field = ref)}
          isLoading={false}
          variant="contained"
          style={{ padding: '15px 35px' }}
          onClick={this.toggleDialog}
        >
          &nbsp;&nbsp;&nbsp;
          <img src={icon_plus} style={{ height: "20px" }} />
          &nbsp;&nbsp;Add filter&nbsp;&nbsp;&nbsp;
        </Button>

        <Menu
          anchorEl={this._my_field}
          getContentAnchorEl={null}
          open={this.state.dialogOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {content}
        </Menu>
      </div>
    );
  }
}

function EditColumnList(props) {
  const [columnSearch, setColumnSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  //const [options, setOptions] = React.useState(props.options);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChange(o) {
    const item = Object.assign({}, o, { hidden: !o.hidden });
    const columns = props.options.map((o) => {
      return o.title === item.title ? item : o;
    });
    //setOptions(columns)
    //console.log(item)
    props.handleChange(columns);
    //setOptions()
  }

  function handleColumnSearchChange(e) {
    setColumnSearch(e.target.value);
  }

  //React.useEffect(() => console.log('value changed!'), [props.options]);

  return (
    <div  style={{ display: "flex", alignItems: 'flex-start', width: '30%', justifyContent: 'flex-end' }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClick}
        aria-controls="simple-menu"
        aria-haspopup="true"
        justifyContent="flex-end"

      >
        &nbsp;&nbsp;&nbsp;
        <img src={icon_col} style={{ height: "20px" }} />
        &nbsp;&nbsp;Edit columns&nbsp;&nbsp;&nbsp;
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Input
          placeholder="Search column"
          onChange={handleColumnSearchChange}
        />
        <div className="list-scroll-menu" style={{ height: "500px", width: '360px', overflow: 'auto' }}>
          {props.options
            .filter((o) => {
              return (
                o.title.toLowerCase().indexOf(columnSearch.toLowerCase()) > -1
              );
            })
            .map((o) => (
              <MenuItem
                key={`simple-menu-${o.title}`}
                style={{ paddingLeft: "7px 20px" }}
              >
                <Checkbox
                  checked={props.visible_cols.indexOf(o.field) !== -1}
                  inputProps={{
                    "aria-label": "primary checkbox",
                  }}
                  onChange={() => props.onChange(o.field)}
                />
                {o.title}
              </MenuItem>
            ))}
        </div>
      </Menu>
    </div>
  );
}

class SegmentManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_users: [],
      visible_cols: [
        "displayName",
        "type",
        "email",
        "phone",
        "id",
        "firstSeen",
        "lastSeen",
      ]
    };
  }

  handleClickOnSelectedFilter = (jwtToken) => {
    const url = `/apps/${this.props.app.key}/segments/${this.props.store.segment.id}/${jwtToken}`;
    //this.props.history.push(url)
  };

  getTextForPredicate = (o) => {
    if (o.type === "match") {
      return `Match ${o.value === "and" ? "all" : "any"} criteria`;
    } else {
      return `Match: ${o.attribute == 'type' ? 'people' : o.attribute} ${o.comparison ? o.comparison : ""} ${
        o.value ? o.value : ""
      }`;
    }
  };

  // updateSegmentState=(columns) =>{
  //   this.props.updateColumnPreferences(columns,null);
  // }

  handleToggleColumnVisible(v) {
    const vc = this.getVisibleColumns();
    let i = vc.indexOf(v);
    if (i === -1) vc.push(v);
    else vc.splice(i, 1);
    this.setState({
      visible_cols: vc,
    });
    this.saveSegment(vc)
  }

  getVisibleColumns() {
    return (this.props.segment && this.props.segment.configTableColumns) ? this.props.segment.configTableColumns : this.state.visible_cols
  }

  moveNext = () => {
    this.props.handleTabChange(null, this.props.currentTab + 1)
  }

  handlePageChange = async ( page, perPage) => this.props.search(page)

  render() {
    // this.props.actions.getPredicates()
    const visible_cols = this.getVisibleColumns();
    const columns = [
      {
        name: <IconColName icon={icon_face}>Name</IconColName>,
        selector: "displayName",
        sortable: true,
        width: "200px",
        grow: 2,
        omit: visible_cols.indexOf("displayName") === -1,
        cell: (row) => (
          <>
            <Avatar
              alt="Remy Sharp"
              src={row.avatarUrl}
              style={{
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                marginRight: 8,
              }}
              onClick={() => this.showUserDrawer(row)}
            />
            &nbsp;
            <a href="#" onClick={() => this.showUserDrawer(row)} style={{ color: '#000', fontSize: 15, textTransform: 'capitalize' }}>{row.displayName}</a>
          </>
        ),
      },
      {
        name: <IconColName icon={icon_email}>Email</IconColName>,
        selector: "email",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("email") === -1,
      },
      {
        name: <IconColName icon={icon_state}>State</IconColName>,
        selector: "state",
        sortable: true,
        width: "120px",
        omit: visible_cols.indexOf("state") === -1,
      },
      {
        name: "Online",
        selector: "online",
        sortable: true,
        width: "80px",
        omit: visible_cols.indexOf("online") === -1,
        cell: (row) =>
          row.online === true ? (
            <RadioButtonCheckedIcon
              fontSize="small"
              style={{ color: green[500] }}
            />
          ) : (
            <RadioButtonUncheckedIcon fontSize="small" color="disabled" />
          ),
      },
      {
        name: <IconColName icon={icon_map}>Lat</IconColName>,
        selector: "lat",
        sortable: true,
        width: "100px",
        omit: visible_cols.indexOf("lat") === -1,
      },
      {
        name: <IconColName icon={icon_map}>Lng</IconColName>,
        selector: "lng",
        sortable: true,
        width: "100px",
        omit: visible_cols.indexOf("lng") === -1,
      },
      {
        name: "Postal",
        selector: "postal",
        sortable: true,
        width: "100px",
        omit: visible_cols.indexOf("postal") === -1,
      },
      {
        name: "Browser Lang",
        selector: "browserLanguage",
        sortable: true,
        width: "130px",
        omit: visible_cols.indexOf("browserLanguage") === -1,
      },
      {
        name: "Referrer",
        selector: "referrer",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("referrer") === -1,
      },
      {
        name: "OS",
        selector: "os",
        sortable: true,
        width: "100px",
        omit: visible_cols.indexOf("os") === -1,
      },
      {
        name: "OS version",
        selector: "osVersion",
        sortable: true,
        width: "120px",
        omit: visible_cols.indexOf("osVersion") === -1,
      },
      {
        name: "Lang",
        selector: "lang",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("lang") === -1,
      },
      {
        name: "Web sessions",
        selector: "webSessions",
        sortable: true,
        width: "130px",
        omit: visible_cols.indexOf("webSessions") === -1,
      },
      {
        name: "City",
        selector: "city",
        sortable: true,
        width: "100px",
        omit: visible_cols.indexOf("city") === -1,
      },
      {
        name: "Country",
        selector: "country",
        sortable: true,
        width: "100px",
        omit: visible_cols.indexOf("country") === -1,
      },
      {
        name: <IconColName icon={icon_calc}>First seen</IconColName>,
        selector: "firstSeen",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("firstSeen") === -1,
        cell: (row) => (row && row.firstSeen) ? <Moment utc fromNow>{row.firstSeen}</Moment> : undefined,
      },
      {
        name: <IconColName icon={icon_calc}>Last seen</IconColName>,
        selector: "lastSeen",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("lastSeen") === -1,
        cell: (row) => (row && row.lastSeen) ? <Moment utc fromNow>{row.lastSeen}</Moment> : undefined,
      },
      {
        name: <IconColName icon={icon_calc}>Created at</IconColName>,
        selector: "createdAt",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("createdAt") === -1,
        cell: (row) => (row && row.createdAt) ? <Moment utc fromNow>{row.createdAt}</Moment> : undefined,
      },
      {
        name: <IconColName icon={icon_calc}>Last visited at</IconColName>,
        selector: "lastVisitedAt",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("lastVisitedAt") === -1,
        cell: (row) => (row && row.lastVisitedAt) ? <Moment utc fromNow>{row.lastVisitedAt}</Moment> : undefined,
      },
    ]
    const screenWidth = window.innerWidth - 150;

    this.props.collection.map((appUser, index) => {
      for (let [key, value] of Object.entries(appUser.properties)) {
        this.props.collection[index][key] = value
      }
    })

    visible_cols.map((column) => {
      if(!find(columns, {"selector": column })) {
        columns.push({
          name: column,
          selector: column,
          sortable: true,
          width: "180px",
          omit: visible_cols.indexOf(column) === -1,
          cell: (row) => row[column]
        })
      }
    })

    return (
      <Grid container
        alignContent={'space-around'}
        style={{ border: "1px solid #E5E5E5", borderRadius: "7px", }}
        justify={'space-around'}>
        <Grid item xs={12}  style={{ borderBottom: "1px solid #E5E5E5", display: "flex", justifyContent: "space-between", padding: 20}}>
          <Box m={2}>
            <Typography style={{"fontSize": "18px", "marginTop": "10px"}} >
              Choose your audience
            </Typography>
          </Box>
          <Box m={2} align={"right"}>
            <Button
             color={"primary"}
             size="large"
             variant={"contained"}
             style={{ background: "#FFB600", color: "#000" }}
             onClick={this.moveNext}>
              Next
            </Button>
         </Box>
        </Grid>
        <Grid item xs={12} style={{ padding: "10px", margin: "20px", minHeight: 600, borderRadius: "8px"}} >
        <Box mt={2}>
          <ButtonGroup>
            <div style={{ display: "flex", flexWrap: 'wrap', width: '70%', justifyContent: 'flex-start' }}>
              {this.props.predicates.map((o, i) => {
                return (
                  <SegmentItemButton
                    key={i}
                    index={i}
                    predicates={this.props.predicates}
                    predicate={o}
                    open={!o.comparison}
                    appearance={o.comparison ? "primary" : "default"}
                    text={this.getTextForPredicate(o)}
                    updatePredicate={this.props.updatePredicate}
                    deletePredicate={this.props.deletePredicate}
                    { ...this.props }
                  />
                );
              })}

              <InlineFilterDialog
                {...this.props}
                app={this.props.app}
                addPredicate={this.props.addPredicate}
                handleClick={this.handleClickOnSelectedFilter.bind(this)}
              />

              {this.props.children}
            </div>
            <EditColumnList
              visible_cols={visible_cols}
              options={this.props.columns}
              onChange={(v) => this.handleToggleColumnVisible(v)}
            />
          </ButtonGroup>

          {
            // <DataTable
            //   updateSegmentState={this.updateSegmentState}
            //   title={"Segment"}
            //   meta={this.props.meta}
            //   data={this.props.collection}
            //   search={this.props.search}
            //   loading={this.props.loading}
            //   columns={this.props.columns}
            //   defaultHiddenColumnNames={this.props.defaultHiddenColumnNames}
            //   tableColumnExtensions={this.props.tableColumnExtensions}
            //   leftColumns={this.props.leftColumns}
            //   rightColumns={this.props.rightColumns}
            //   toggleMapView={this.props.toggleMapView}
            //   map_view={this.props.map_view}
            //   enableMapView={this.props.enableMapView}
            // />
            <Container component="main" style={{ maxWidth: screenWidth, padding: 0 }}>
              <Datatable
                columns={columns}
                data={this.props.collection}
                onSelectedRowsChange={(s) =>
                  this.setState({ selected_users: s.selectedRows })
                }
                pagination
                paginationServer
                paginationPerPage={20}
                paginationRowsPerPageOptions={[20]}
                paginationDefaultPage={this.props.meta.current_page}
                paginationTotalRows={this.props.meta.total_count}
                onChangePage={this.handlePageChange}
                sortServer={false}
              />
            </Container>
          }
        </Box>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { app } = state;
  return {
    app,
  };
}

export default withRouter(connect(mapStateToProps)(SegmentManager));
