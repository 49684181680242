
import React, { useState } from 'react'


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

export default function NewLead({ isOpen, handleSubmit, handleClose }) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
      >
        <div style={{ padding: '40px', borderRadius: '6', }}>
          <DialogTitle id="scroll-dialog-title" style={{ paddingBottom: 0, fontSize: 15, }}>
            Create New Lead
          </DialogTitle>
          <DialogContent style={{ paddingTop: 0, width: 450 }}>
            <Grid>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{paddingRight: 24}}>
            <Button autoFocus onClick={handleClose} variant="outlined" style={{ padding: '15px 30px' }} color="primary">
              Cancel
            </Button>
            <Button autoFocus onClick={() => handleSubmit(name, email)} variant="contained" style={{ padding: '15px 30px', backgroundColor: '#FFD300', color: '#000' }} >
              Create Lead
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
