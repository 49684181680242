import React from "react";

import { Bar } from "react-chartjs-2";
import moment from 'moment'

var numberWithCommas = function(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


const getCategoriesData = ({app}) => {
  var dates = [];
  var userDataPack = [];
  var leadDataPack = [];

  var lastMonthThisDay = moment().startOf('day').subtract(1,'month');
  var today =  moment().endOf('day')

  // filter
  var filteredObjects = _.filter(app.appUsers,
    (each)=>{
       return moment(each.createdAt)
         .isBetween(lastMonthThisDay, today) ;
    });

  var sortedData = _.orderBy(filteredObjects, ['createdAt'])
  _.map(_.groupBy(sortedData, 'createdAt'), (records, date)=>{
    var usersCount = 0
    var leadsCount = 0
    var formattedDate = moment(date, 'YYYY-MM-DD', true).format("DD/MM")
    dates.push(formattedDate)
    _.map(
      _.groupBy(records, 'type'),(records, type) => {

        var usersDataset = _.filter(records, (record) => ( record.type == 'User'));
        var leadsDataset = _.filter(records, (record) => ( record.type == 'Lead'));

        usersCount += usersDataset.length,
        leadsCount += leadsDataset.length
      }
    )

    userDataPack.push(usersCount)
    leadDataPack.push(leadsCount)

  })

  let data ={ 
    datasets: [
      {
          label: 'User',
          data: userDataPack,
          backgroundColor: "rgba(55, 160, 225, 0.7)",
          hoverBackgroundColor: "rgba(55, 160, 225, 0.7)",
          hoverBorderWidth: 2,
          hoverBorderColor: 'lightgrey'
      },
      {
          label: 'Lead',
          data: leadDataPack,
          backgroundColor: "rgba(225, 58, 55, 0.7)",
          hoverBackgroundColor: "rgba(225, 58, 55, 0.7)",
          hoverBorderWidth: 2,
          hoverBorderColor: 'lightgrey'
      },
      ],
    labels: _.uniq(dates)
  }

  const options = {
    title: {
      text: 'New People',
      display: true,
      fontSize: 25
    },
    animation: {
     duration: 10,
   },
   tooltips: {
     mode: 'label',
     callbacks: {
     label: function(tooltipItem, data) { 
       return data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.yLabel);
     }
     }
    },
   scales: {
     xAxes: [{ 
       stacked: true, 
       gridLines: { display: false },
       }],
     yAxes: [{ 
       stacked: true, 
       ticks: {
         callback: function(value) { return numberWithCommas(value); },
        }, 
       }],
   }, // scales
   legend: {display: true}
  }

  return {data, options}
}

export default function App(props) {
  var {data, options} = getCategoriesData(props);
  return (
    <div className="App">
      <Bar data={data} options={options} />
    </div>
  );
}