import React from "react"
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import email_automation from '../../../assets/images/emailAutomation.svg'
import guided_tour from '../../../assets/images/guidedTour.svg'
import in_app_messaging from '../../../assets/images/inAppMessaging.svg'
import empty_leads from '../../../assets/images/botForLeads.svg'
import empty_users from '../../../assets/images/botForUsers.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function EmptyView({content, page, title, subtitle, icon}){
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);

  return (
    <React.Fragment>
      <Container maxWidth="lg" style={{ padding: 0 }}>
      <div style={{alignSelf: 'center'}}>
        <Paper style={{padding: '2em', paddingLeft: 0, boxShadow: 'none', width: '55%'}}>

          {icon && icon}
          <Typography variant="h1" style={{ fontSize: 96, fontWeight: 600 }}>
            {title}
          </Typography>

          <Typography component="p">
            {subtitle}
          </Typography>

        </Paper>
      </div>
      <div style={{ background: '#FAF6F1',
          padding: 10,
          borderRadius: 3,
          fontSize: 14,
          borderTop: '3px solid rgb(255, 182, 0)'
        }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h2" style={{ fontWeight: 400, fontSize: 30,  marginBottom: 20 }}>
              How does the <span style={{fontWeight: 600}}>{content.pageName}</span> works?
            </Typography>
            <p>{content.rephrase}</p>
            <Typography variant="h3" style={{ fontWeight: 600, fontSize: 19, marginTop: 30 }}>
              Here is how:
            </Typography>
            <List style={{ paddingLeft: 0 }} dense={dense}>
              { content.howList.map((item, index) =>(
                <ListItem style={{ paddingLeft: 0 }}>
                  <ListItemAvatar style={{ minWidth: 45 }}>
                    <Avatar style= {{ background: '#222222',
                      fontSize: 12,
                      height: 25,
                      width: 25 }}
                    >
                      {index + 1}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    style={{ fontSize: 16 }}
                    primary={item}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
          <div style={{ marginTop: 30, height: 350, overflow: 'hidden' }}>
          { content.pageName === 'Email Automation' && (
            <img src={email_automation} height="350" width= "550" />
          )}
          { content.pageName === 'In-app message' && (
            <img src={in_app_messaging} height="450" width= "550" style={{ marginTop: '-50px' }} />
          )}
          { content.pageName === 'guide tour' && (
            <img src={guided_tour} height="600" width= "550" />
          )}
          { content.tab && content.tab === 'users' && (
            <img src={empty_users} height="500" width= "550" />
          )}
          { content.tab && content.tab === 'leads' && (
            <img src={empty_leads} height="600" width= "550" />
          )}
          </div>
          { content.pageName === 'Bot1' && (<iframe
              height="350"
              width= "550"
              style={{ marginTop: 30 }}
              src="https://www.youtube.com/embed/fDidIgmYApw">
            </iframe>
          )}
          </Grid>
        </Grid>
      </div>
      </Container>
    </React.Fragment>
  )
}

export default EmptyView
