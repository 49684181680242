import React, {Component} from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import Box from "@material-ui/core/Box";
import ContentHeader from '../../../components/ContentHeader'
import {AnchorLink} from '../../../shared/RouterLink'
import { withStyles } from '@material-ui/core/styles';

import FormDialog from '../../../components/FormDialog'
import {setCurrentSection, setCurrentPage} from '../../../actions/navigation'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ScrollableTabsButtonForce from '../../../components/scrollingTabs'
import langs from '../../../shared/langsOptions'
import hand_icon from '../../../../../assets/images/bxs-hand-right.svg'
import graphql from '../../../graphql/client'
import {
  ARTICLE_COLLECTION_CREATE,
  ARTICLE_COLLECTION_EDIT,
  ARTICLE_COLLECTION_DELETE
} from '../../../graphql/mutations'

import {
  ARTICLE_COLLECTIONS,
  ARTICLE_SETTINGS
} from '../../../graphql/queries'

const styles = theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4),
    },

    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  button: {
    margin: 0,
    minWidth: 30,
    paddingLeft: 2,
  },
  list: {
    width: '50%',
    margin: '0 auto',
    background: '#fff',
    "& li": {
      background: '#fff',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 8,
      marginTop: 10,
      padding: '15px 40px',
      "&:hover": {
        background: 'transparent linear-gradient(90deg, #FAF6F1 0%, #fff 100%) 0% 0% no-repeat padding-box',
        borderRadius: 6,
        borderLeft: '6px solid #FFD300',
        boxShadow: '1px 3px 10px -3px rgba(100, 100, 100, 0.2)',
      }
    },
    "& .MuiListItemText-multiline": {
      marginLeft: 15,
    }
  },
  appSettingTitleContainer: {
    paddingBottom: 25,
    paddingTop: 25,
    margin: '0px 50px',
    backgroundColor: 'transparent',
    color: '#000000',
    width: 'calc(100% - 75px)',
    transform: 'none',
  },
  appSettingTitle: {
    fontWeight: 'normal',
    fontSize: 42,
  },
});

class Collections extends Component {

  state = {
    isOpen: false,
    article_collections: [],
    editCollection: null,
    openConfirm: false,
    languages: [],
    lang: 'en'
  }
  titleRef = null
  descriptionRef = null

  componentDidMount(){
    this.getCollections()
    this.props.dispatch(
      setCurrentSection('HelpCenter')
    )

    this.props.dispatch(
      setCurrentPage('Collections')
    )
  }

  submitAssignment = ()=>{

  }

  close = ()=>{
    this.setState({isOpen: false})
  }

  displayDialog = (e)=>{
    this.setState({isOpen: true})
  }

  submitCreate = (e)=>{
    graphql(ARTICLE_COLLECTION_CREATE, {
      appKey: this.props.app.key,
      title: this.titleRef.value,
      description: this.descriptionRef.value
    }, {
      success: (data)=>{
        const col = data.articleCollectionCreate.collection
        this.setState({
          article_collections: this.state.article_collections.concat(col),
          isOpen: false
        })
      },
      error: ()=>{
        debugger
      }
    })

  }

  submitEdit = (e)=>{
    graphql(ARTICLE_COLLECTION_EDIT, {
      appKey: this.props.app.key,
      title: this.titleRef.value,
      description: this.descriptionRef.value,
      id: this.state.editCollection.id,
      lang: this.state.lang
    }, {
      success: (data)=>{
        const col = data.articleCollectionEdit.collection
        const newArticleCollection = this.state.article_collections.map((o, i)=>{
          if(o.id === col.id){
            return col
          }else{
            return o
          }
        })

        this.setState({
          article_collections: newArticleCollection,
          isOpen: false,
          editCollection: null
        })

      },
      error: ()=>{
        debugger
      }
    })
  }

  handleRemove = (item)=>{
    confirm
  }

  getCollections = (e)=>{
    graphql(ARTICLE_COLLECTIONS, {
      appKey: this.props.app.key,
      lang: this.state.lang
    }, {
      success: (data)=>{
        this.setState({
          article_collections: data.app.collections,
        })
      },
      error: ()=>{

      }
    })
  }

  openEdit = (collection)=>{
    this.setState({
      editCollection: collection,
      isOpen: true
    })
  }

  requestDelete = (item)=>{
    this.setState({
      itemToDelete: item
    })
  }

  submitDelete = ()=>{

    graphql(ARTICLE_COLLECTION_DELETE, {
      appKey: this.props.app.key,
      id: this.state.itemToDelete.id
    }, {
      success: (data)=>{
        const col = data.articleCollectionDelete.collection
        const newCollection = this.state.article_collections.filter(
          (o)=> o.id != col.id
        )

        this.setState({
          openConfirm: false,
          itemToDelete: null,
          article_collections: newCollection
        })

      }
    })


  }

  handleLangChange = (o)=>{
    this.setState({
      lang: o
    }, this.getCollections)
  }

  render(){
    const {isOpen, editCollection, itemToDelete} = this.state
    const {classes, app} = this.props
    return <React.Fragment>

          <ContentHeader
            title={"Collections"}
            headerClass={classes.appSettingTitleContainer}
            headerTitleClass={classes.appSettingTitle}
            items={
              <React.Fragment>
                 <Button
                  className={classes.button}
                  variant="outlined" color="primary"
                  style={{ padding: '12px 25px', background: '#FAF6F1', border: 0 }}
                  onClick={this.displayDialog}>
                  New Collection
                </Button>
              </React.Fragment>
            }
        />
        <Grid
               alignContent={'space-around'}
               style={{ border: "1px solid #E5E5E5", borderRadius: "7px", padding: 30, margin: '0px 50px 50px'}}
               justify={'space-around'}>
               <Grid item xs={12} style={{ minHeight: 600, borderRadius: "8px"}}>
            <Box mt={2}>

            <FormDialog
                open={isOpen}
                //contentText={"lipsum"}
                titleContent={"New Article Collection"}
                formComponent={
                  <form ref="form">

                    <TextField
                      id="collection-title"
                      //label="Name"
                      placeholder={"Type collection's title"}
                      inputProps={{
                          style: {
                            fontSize: "1.4em"
                          }
                        }
                      }
                      //helperText="Full width!"
                      fullWidth
                      inputRef={ref => { this.titleRef = ref; }}
                      defaultValue={ editCollection ? editCollection.title : null }
                      margin="normal"
                    />


                    <TextField
                      id="collection-description"
                      //label="Description"
                      placeholder={"Describe your collection to help it get found"}
                      //helperText="Full width!"
                      fullWidth
                      multiline
                      inputRef={ref => { this.descriptionRef = ref; }}
                      defaultValue={ editCollection ? editCollection.description : null }
                      margin="normal"
                    />

                  </form>
                }

                dialogButtons={
                  <React.Fragment>
                    <Button onClick={this.close} color="secondary">
                      Cancel
                    </Button>

                    <Button

                      onClick={ editCollection ?
                      this.submitEdit.bind(this) :
                      this.submitCreate.bind(this)
                    }
                      color="primary">
                      {editCollection ? 'Update' : 'Create'}
                    </Button>

                  </React.Fragment>
                }
            />


            {
              itemToDelete ?

            <FormDialog
                open={true}
                //contentText={"lipsum"}
                titleContent={"Confirm deletion ?"}
                formComponent={
                  <p>are you ready to delete ?</p>
                }

                dialogButtons={
                  <React.Fragment>
                    <Button onClick={this.close} color="secondary">
                      Cancel
                    </Button>

                    <Button onClick={ this.submitDelete }
                      color="primary">
                      Remove
                    </Button>

                  </React.Fragment>
                }
            /> : null
            }

            {/*<ScrollableTabsButtonForce
              //tabs={this.props.settings.availableLanguages}
              tabs={this.props.settings.availableLanguages.map((o)=> langs.find((lang)=> lang.value === o) )}
              changeHandler={(index)=> this.handleLangChange( this.props.settings.availableLanguages[index])}
            />*/}

            <List
              className={classes.list}
              >

              {
                this.state.article_collections.map((item)=>{
                  return  <ListItem key={item.id}>
                            <ListItemAvatar>
                                <img src={hand_icon} />
                            </ListItemAvatar>
                            <ListItemText primary={
                              <AnchorLink to={`/apps/${this.props.app.key}/articles/collections/${item.id}`}>
                                {item.title}
                              </AnchorLink>
                            }
                              secondary={item.description}
                            />
                            <p style={{ width: 100, fontSize: 13, color: '#777777', display: 'flex', height: 50, justifyContent: 'flex-end', flexDirection: 'column' }}>
                              {
                                item.baseArticles && item.baseArticles.length > 0 && (
                                  <span>{item.baseArticles.length} Articles</span>
                                )
                              }
                              <div style={{ width: 'inherit' }}>
                                <Button className={classes.button}
                                  variant="text" color="primary" onClick={()=> this.openEdit(item)}>
                                  <EditIcon />
                                </Button>

                                <Button className={classes.button}
                                  variant="text" color="primary" onClick={()=> this.requestDelete(item)}>
                                  <DeleteIcon />
                                </Button>
                              </div>
                            </p>
                          </ListItem>
                })
              }

            </List>

          </Box>
            </Grid>
          </Grid>
          </React.Fragment>
  }

}


function mapStateToProps(state) {

  const { auth, app } = state
  const { isAuthenticated } = auth
  //const { sort, filter, collection , meta, loading} = conversations

  return {
    app,
    isAuthenticated
  }
}


//export default withRouter(connect(mapStateToProps)(withStyles(styles)(ArticlesNew)))
//export default withRouter(connect(mapStateToProps)(Collections))
export default withRouter(connect(mapStateToProps)(withStyles(styles)(Collections)))
