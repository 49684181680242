import React, {useState} from 'react'
import { connect } from 'react-redux'

import graphql from '../graphql/client'
import {CURRENT_USER} from '../graphql/queries'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import favicon from '../images/favicon'
import right_arrow from '../../../assets/images/rightArrow.png'
import signin_img from '../../../assets/images/signinBack.png'
import linkedin from '../../../assets/images/linkedin.png'
import google from '../../../assets/images/googleIcon.png'
import {getCurrentUser} from '../actions/current_user'

import logo from '../images/logo'

import Snackbar from '../components/snackbar'

import { MuiThemeProvider } from '@material-ui/core/styles';

import lightTheme from '../themes/light/index'
import darkTheme from '../themes/dark/index'

import LoadingView from '../components/loadingView'
import queryString from 'query-string'
import FormHelperText from '@material-ui/core/FormHelperText';
import axios from 'axios'


class EmailConfirmation extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      showSuccess: false
    }
  }

  componentDidMount(){
    this.confirmEmail()
  }

  confirmEmail = () => {
    let token = queryString.parse(this.props.location.search)
    axios({
      url: '/agents/confirmation.json?confirmation_token='+token.confirmation_token,
      method: 'GET'
    }).then(response => {
      this.showSuccess()
    }).catch(data => {
      alert("We encountered an error while processing your request.")
    })
  }

  showSuccess = () => {
    this.setState({showSuccess: true})
  }

  render() {
    return (
      <div>
        {this.state.showSuccess &&
          <Confirmation
            {...this.props}
          />
        }
      </div>
    )
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 50,
  },
  backgroundImg: {
    width: '50%', height: '55%', margin: '22%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    width: '60px',
    height: '60px',
    borderRadius: '0%'
  },
  logo: {
    height: '100%',
    width: '100%'
  },
}));

function Confirmation(props) {
  const classes = useStyles();
  const theme = props.theme === "light" ? lightTheme : darkTheme;

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container>
        <Grid item xs={12} sm={6}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <img className={classes.logo} src={favicon}/>
          </Avatar>
          <Typography component="h2" variant="h2" style={{fontSize: 64, fontWeight: 'bold', marginTop: 50, color: '#222'}}>
            Congratulations! You email has been confirmred.
          </Typography>
          <Typography component="h3" variant="h3" style={{fontSize: 64, fontWeight: 'bold', marginTop: 50, color: '#ffb600'}}>
            <Link color="inherit" href="/">
              Login Here!
            </Link>
          </Typography>
          <Snackbar/>
        </div>
      </Grid>
      </Grid>
    </MuiThemeProvider>
  );
}

export default EmailConfirmation
