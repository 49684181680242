import Avatar from '@material-ui/core/Avatar'
import { connect } from 'react-redux'
import { countries } from 'country-data';
import ChatIcon from '@material-ui/icons/Chat'
import CheckIcon from '@material-ui/icons/Check'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList'
import { green } from '@material-ui/core/colors'
import Hidden from '@material-ui/core/Hidden'
import InboxIcon from '@material-ui/icons/Inbox'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ReplayIcon from '@material-ui/icons/Replay'
import Paper from '@material-ui/core/Paper'
import React, { Component } from 'react'
import { Route, Link, Switch, withRouter } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html'
import SearchBar from 'material-ui-search-bar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import AssignmentRules from '../components/conversation/assignmentRules'
import ConversationContainerShow from '../components/conversation/container'
import ConversationHeader from '../components/ConversationHeader'
import {
  clearConversations, getConversations, updateConversationsData
} from '../actions/conversations'
import { CONVERSATION, SHOPIFY_ORDERS } from "../graphql/queries"
import { CREATE_QUALIFICATION } from '../graphql/mutations'
import { APP_USER_UPDATE } from '../graphql/mutations'
import FilterMenu from '../components/conversation/filterMenu'
import { getAppUser } from '../actions/app_user'
import { LocationIcon } from '../../src/components/icons'
import Progress from '../shared/Progress'
import {
  RowColumnContainer, ColumnContainer, GridElement, UserInfoContainer,
  FixedHeader, ConversationsButtons, Overflow
} from '../components/conversation/styles'
import { setCurrentSection, setCurrentPage } from '../actions/navigation'
import UserListItem from '../components/conversation/UserListItem'
import image from '../../../assets/images/conversation.svg'
import map_icon from '../../../assets/images/mapIcon.png'
import user_icon from '../../../assets/images/userIcon.png'
import envelope_icon from '../../../assets/images/envelopeIcon.png'
import transfer_icon from '../../../assets/images/transferIcon.png'
import face_icon from '../../../assets/images/faceIcon.png'
import browser from '../../../assets/images/browser.jpg'
import calender from '../../../assets/images/calender.jpg'
import graphql from '../graphql/client'

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


class ConversationContainer extends Component {
  constructor(props) {
    super(props)
    this.fetching = false;
    this.props.dispatch(clearConversations([]));
    this.state = {
      expanded: false,
      addQualification: [],
      qualificationValues: {},
      data: {},
      searchValue: '',
      filterConversations: "opened",
      shopifyOrders: []
    }
  }

  componentDidMount() {
    this.getConversations({ page: 1 }, this.firstConv)

    this.props.dispatch(setCurrentSection('Conversations'))
  }

  firstConv = () => {
    const { conversations, history } = this.props
    const { collection } = conversations

    if (collection.length > 0) {
      history.push(`/apps/${this.props.app.key}/conversations/${collection[0].key}`)
    }
  }

  handleScroll = ({ target }) => {
    let element = target
    let currentValue = element.scrollHeight - element.scrollTop
    let minMargin = currentValue - 10
    let maxMargin = currentValue + 10

    if (minMargin <= element.clientHeight && maxMargin >= element.clientHeight) {
      if (this.props.conversations.meta.next_page && !this.fetching) {
        this.fetching = true
        this.getConversations({}, () => {
          this.fetching = false;
        })
      }
    }
  }

  getShopifyOrders = (participant) => {
    let shopifyOrders = this.state.shopifyOrders
    let email = participant.email
    graphql(
      SHOPIFY_ORDERS, {
        appKey: this.props.app.key,
        email: participant.email,
      }, {
        success: (res) => {
          if(shopifyOrders.length === 0 && res.shopifyOrders.length > 0){
            if(email === res.shopifyOrders[0].email){
              this.setState({ shopifyOrders: res.shopifyOrders })
            }
          }
          else if(shopifyOrders.length > 0 && res.shopifyOrders.length > 0){
            if(email === res.shopifyOrders[0].email){
              if(shopifyOrders[0].id !== res.shopifyOrders[0].id){
                this.setState({ shopifyOrders: res.shopifyOrders })
              }
            }
            else{
              this.setState({ shopifyOrders: [] })
            }
          }
          else if(shopifyOrders.length > 0 && res.shopifyOrders.length == 0){
            this.setState({ shopifyOrders: [] })
          }
        }
      }
    )
  };

  getConversations = (options, cb) => {
    this.props.dispatch(
      getConversations(options, () => {
        cb && cb();
      })
    )
  }

  getConversation = (options) => {
    graphql(CONVERSATION, {
        appKey: this.props.app.key,
        id: options.key,
        page: 1
      }, {
      success: (data) => {
        let mainParticipant = data.app.conversation.mainParticipant
        let properties = {phone: mainParticipant.phone, companyName: mainParticipant.companyName,
                          companySize: mainParticipant.companySize, companyWebsite: mainParticipant.companyWebsite,
                          companyIndustry: mainParticipant.companyIndustry, numberOfEmails: mainParticipant.numberOfEmails
                         }
        if (JSON.stringify(properties) !== JSON.stringify(this.state.qualificationValues)) {
          this.setState({
            qualificationValues: {
              id: mainParticipant.id,
              phone: properties.phone,
              companyName: properties.companyName,
              companySize: properties.companySize,
              companyWebsite: properties.companyWebsite,
              companyIndustry: properties.companyIndustry,
              numberOfEmails: properties.numberOfEmails
            }
          })
        }
      },
      error: (error)=>{
      }
    })
  }

  setSort = (option) => {
    this.props.dispatch(updateConversationsData({ sort: option }))
    this.setState({ sort: option })
  }

  setFilter = (option) => {
    this.props.dispatch(updateConversationsData({ filter: option }))
  }

  filterButton = handleClick => (
    <Tooltip title='filter conversations'>
      <a
        className='btn-dropdown'
        aria-label='More'
        aria-haspopup='true'
        variant={'outlined'}
        onClick={handleClick}
        size='small'
      >
        {/*<MoreVertIcon />*/}
        { this.props.conversations.filter }
      </a>
    </Tooltip>
  )

  sortButton = handleClick => (
    <Tooltip title='sort conversations'>
      <a
        className='btn-dropdown'
        aria-label='More'
        aria-controls='long-menu'
        aria-haspopup='true'
        variant={'outlined'}
        onClick={handleClick}
        size="small"
      >
        {/*<MoreVertIcon />*/}
        { this.props.conversations.sort }
      </a>
    </Tooltip>
  )

  expandedText = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  appendPropertyFields = (mainParticipant) => {
    let propertyArray = []
    for (let [key, value] of Object.entries(mainParticipant.properties)) {
      if (key !== "email" && key !== "name")
        propertyArray.push({key: key, value: value})
    }
    return (
      <React.Fragment>
        {
          propertyArray.map((data) => {
            return(
              <div><img src={ transfer_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  {data.key}:    </span>{data.value}</div>
            )
          })
        }
      </React.Fragment>
    )
  }

  getMoreTextDiv = (mainParticipant, currentPage) => {
    let country = mainParticipant.country

    if (country && countries[country]) {
      country = countries[country].name
    }

    return (
      <React.Fragment>
        <div><img src={ transfer_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  OS:    </span>{mainParticipant.os}</div>
        <div><img src={ transfer_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  OS Version:    </span>{mainParticipant.osVersion}</div>
        <div><img src={ transfer_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Type:    </span>{mainParticipant.type}</div>
        <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  State:    </span>{mainParticipant.state}</div>
        <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  IP:    </span>{mainParticipant.ip}</div>
        {/*<div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Latitude:    </span>{mainParticipant.lat}</div>
        <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Longitude:    </span>{mainParticipant.lng}</div>
        <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Timezone:    </span>{mainParticipant.timezone}</div>*/}
        <div><img src={ browser } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Browser:    </span>{mainParticipant.browser}</div>
        <div><img src={ browser } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Browser Version:    </span>{mainParticipant.browserVersion}</div>
        <div><img src={ browser } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Browser Language:    </span>{mainParticipant.browserLanguage}</div>
        <div><img src={ browser } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Web Sessions:    </span>{mainParticipant.webSessions}</div>
        <div><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Last Seen:    </span>{mainParticipant.lastSeen}</div>
        <div><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  First Seen:    </span>{mainParticipant.firstSeen}</div>
        <div><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Signed Up:    </span>{mainParticipant.signedUp}</div>
        <div><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Last Contacted:    </span>{mainParticipant.lastContacted}</div>
        <div><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Last Heard From:    </span>{mainParticipant.lastHeardFrom}</div>
        <div><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  last Visited:    </span>{mainParticipant.lastVisitedAt}</div>
        <div style={{overflowWrap: 'anywhere'}}><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Referrer:    </span>{mainParticipant.referrer}</div>
        <div style={{overflowWrap: 'anywhere'}}><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Page:    </span>{currentPage}</div>
        {this.appendPropertyFields(mainParticipant)}
      </React.Fragment>
    )
  }

  removeClosedConversation = (id, value) => {
    if (value) {
      const i = this.props.conversations.collection.findIndex((e) => e.id === id)
      if( this.props.conversations.collection.length > i + 1) {
        const { appId } = this.props.match.params
        this.props.history.push(`/apps/${appId}/conversations/${this.props.conversations.collection[i+1].key}`)
      }
    }
    this.getConversations({ page: 1 })
  }

  filterConversations = (options, cb) => {
    this.setState({ filterConversations: options.id })
    this.props.dispatch(
      updateConversationsData({ filter: options.id, collection: [] }, () => {
        this.getConversations({ page: 1 }, cb)
      })
    )
  }

  searchConversations = (value) => {
    this.props.dispatch(
      updateConversationsData({ search: value, collection: [] }, () => {
        this.getConversations({ page: 1 });
      })
    );
  };

  resetSearch = (value) => {
    this.setState({ searchValue: value })
    this.searchConversations("")
  };

  sortConversations = (options, cb) => {
    this.props.dispatch(
      updateConversationsData({ sort: options.id, collection: [] }, () => {
        this.getConversations({ page: 1 }, cb)
      })
    )
  }

  renderConversationContent = (o) => {
    const message = o.lastMessage.message
    if (message.htmlContent)
      return sanitizeHtml(message.htmlContent).substring(0, 250)
  }

  renderUserConversationDetail = detail => {
    let participant
    let country
    let currentPage

    if (detail && detail.collection) {
      participant = detail.mainParticipant
      this.getShopifyOrders(participant);
      country = participant.country
      currentPage = detail.currentPage
      this.state.participant && this.setState({ participant })

      if (country && countries[country]) {
        country = countries[country].name
      }
    }

    const qualificationList = [
      { text: 'Phone', label: 'phone', type: 'number' },
      { text: 'Company Name', label: 'companyName' },
      { text: 'Company Size', label: 'companySize' },
      { text: 'Company Website', label: 'companyWebsite', isLink: true },
      { text: 'Company industry', label: 'companyIndustry' },
      { text: 'Number of Emails', label: 'numberOfEmails' },
    ]

    const handleAddClick = value => {
      let newArray = this.state.addQualification
      newArray.push(value)

      this.setState({ addQualification: newArray })
    }

    const handleFieldChange = (event, value) => {
      let newValues = {...this.state.qualificationValues}

      newValues[value] = event.target.value

      this.setState({ qualificationValues: newValues })
    }

    const handleBlurOnFields = value => {
      graphql(
        APP_USER_UPDATE, {
          appKey: this.props.app.key,
          id: this.props.conversation.mainParticipant.id,
          options: {},
          phone: this.state.qualificationValues.phone ? this.state.qualificationValues.phone : '',
          companyName: this.state.qualificationValues.companyName ? this.state.qualificationValues.companyName : '',
          companySize: this.state.qualificationValues.companySize ? parseInt(this.state.qualificationValues.companySize, 10) : 0,
          companyWebsite: this.state.qualificationValues.companyWebsite ? this.state.qualificationValues.companyWebsite : '',
          companyIndustry: this.state.qualificationValues.companyIndustry ? this.state.qualificationValues.companyIndustry : '',
          numberOfEmails: this.state.qualificationValues.numberOfEmails ? parseInt(this.state.qualificationValues.numberOfEmails, 10) : 0,
        }, {
          success: data => {
            this.setState({
              qualificationValues: {
                id: data.updateAppUser.appUser.id,
                phone: data.updateAppUser.appUser.phone,
                companyName: data.updateAppUser.appUser.companyName,
                companySize: data.updateAppUser.appUser.companySize,
                companyWebsite: data.updateAppUser.appUser.companyWebsite,
                companyIndustry: data.updateAppUser.appUser.companyIndustry,
                numberOfEmails: data.updateAppUser.appUser.numberOfEmails
              },
              addQualification: this.state.addQualification.filter(a => !['phone', 'companyName', 'companySize', 'companyWebsite', 'companyIndustry', 'numberOfEmails'].includes(a))
            })
          }
        }
      )
    }

    const handleResetClick = value => {
      let newValues = {...this.state.qualificationValues}
      delete newValues[value]

      this.setState({
        qualificationValues: newValues
      }, () => {
        handleBlurOnFields('')
      })
    }

    const handleKeyClick = event => {
      if (event.key == 'Enter') {
        handleBlurOnFields('')
      }
    }

    const { addQualification, qualificationValues } = this.state

    const renderAddLink = value => (
      <a style={{ marginLeft: 20, padding: 0, color: 'black', alignSelf: 'center' }} href='javascript:void(0);' onClick={() => handleAddClick(value)}>{ qualificationValues[value] ? qualificationValues[value] : "Add" }</a>
    )

    const renderInput = value => (
      <input name='value' style={{ margin: 0, marginLeft: 20 }} onChange={(e) => handleFieldChange(e, value)} onKeyDown={event => handleKeyClick(event)} onBlur={event => handleBlurOnFields(event)} value={qualificationValues[value]}/>
    )

    const resetIcon = value => (
      <ReplayIcon onClick={() => handleResetClick(value)} style={{ margin: '3px 5px', cursor: 'pointer', alignSelf: 'center' }} />
    )

    const openInNewWindow = value => (
      <OpenInNewIcon onClick={() => window.open(`https://www.${qualificationValues[value]}`, '_blank')} style={{ margin: '3px 5px', cursor: 'pointer', alignSelf: 'center' }} />
    )

    const isChecked = value => qualificationValues[value] ? true : false

    return (
      <GridElement style={{ display: 'flex', justifyContent: 'space-around' }} >
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: 'calc(100vh - 67px)' }}>
          <FixedHeader style={{ height: "80px", width: '100%', borderBottom: 'none' }}>
            <b style={{ fontSize: 22 }}>Details</b>
          </FixedHeader>

          <div className='text-center'>
            {
              participant &&
                <React.Fragment>
                  <div style={{ width: 150, height: 150, margin: 'auto', marginTop: 50 }}>
                    <Avatar
                      style={{ width: '100%', height: '100%' }}
                      alt={participant.email}
                      src={participant.avatarUrl}
                    />
                  </div>

                  <div style={{ margin: 20, fontSize: 18, textAlign: 'center' }}>
                    <b>{participant.displayName}</b>
                  </div>

                  <UserInfoContainer className='text-left'>
                    <div><img src={ user_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className='user-details'>{participant.displayName}</span></div>
                    <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className='user-details'> Location:   </span>{participant.city ? participant.city : ''}</div>
                    <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className='user-details'>  Country:    </span>{country}</div>
                    {/*<div><img src={ face_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Owner:    </span> {participant.owner ? participant.owner : 'no owner'}</div>*/}
                    <div><img src={ envelope_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className='user-detail'>  Email:    </span> {participant.email}</div>
                    {/*<div><img src={ transfer_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  CustomerId:    </span>{participant.id}</div>*/}
                    {
                      this.state.expanded ? this.getMoreTextDiv(participant, currentPage) : null
                    }
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                      <div onClick={this.expandedText}>
                        <a href='javascript:void(0);' style={{ color: 'black', textDecoration: 'none' }}>{ this.state.expanded ? 'Show Less' : 'Show more' }</a>
                      </div>
                    </div>
                  </UserInfoContainer>

                  <div className='text-left' style={{ margin: 12, border: 'solid 1px #E5E5E5', borderRadius: 5, padding: '35px 30px 25px 35px', paddingLeft: 30, lineHeight: 1.2 }}>
                    <span style={{ fontSize: 18 }}>Shopify Order Info</span>
                    {
                      this.state.shopifyOrders && this.state.shopifyOrders.map((order) => {
                        return Object.keys(order).map((key) => {
                          return (
                            <div className='qualification-check' style={{ paddingTop: 15 }}>
                              <div>
                                <span style={{ fontSize: 14, padding: '5px 15px 10px 5px', color: '#777777' }}>{key}</span>
                                <span style={{ fontSize: 14 }}>{order[key]}</span>
                              </div>
                            </div>
                          )
                        })
                      })
                    }
                  </div>
                  <div className='text-left' style={{ margin: 12, border: 'solid 1px #E5E5E5', borderRadius: 5, padding: '35px 30px 25px 35px', paddingLeft: 30, lineHeight: 1.2 }}>
                    <span style={{ fontSize: 18 }}>Qualification</span>
                    <div className='qualification-check' style={{ paddingTop: 15 }}>
                      <FormControlLabel
                        control={<GreenCheckbox checked name='checkName' />}
                        label={<div><span style={{ fontSize: 14, padding: '5px 15px 10px 5px', color: '#777777' }}>Name</span>
                          <span style={{ fontSize: 14 }}>{participant.displayName}</span></div>
                        }
                        style={{ fontSize: 12 }}
                      />
                    </div>

                    <div className='qualification-check'>
                      <FormControlLabel
                        control={<GreenCheckbox checked name='checkName' />}
                        label={
                          <div>
                            <span style={{ fontSize: 14, padding: '5px 15px 10px 5px', color: '#777777' }}>Email</span>
                            <span style={{ fontSize: 14 }}>{ participant.email}</span>
                          </div>
                        }
                        style={{ fontSize: 12 }}
                      />
                    </div>

                    {
                      qualificationList.map(({ text, label, isLink }) => (
                        <div className='qualification-check'>
                          <FormControlLabel
                            disabled
                            control={<GreenCheckbox name='checkName' checked={isChecked(label)} />}
                            label={
                              <span style={{ fontSize: 14, display: 'flex' }}>
                                <span style={{ alignSelf: 'center' }}>{text}</span>
                                { !addQualification.includes(label) ? renderAddLink(label) : renderInput(label) }
                                { qualificationValues[label] && isLink ? openInNewWindow(label) : '' }
                                { qualificationValues[label] ? resetIcon(label) : '' }
                              </span>
                            }
                            style={{ fontSize: 12 }}
                          />
                        </div>
                      ))
                    }
                  </div>
                </React.Fragment>
            }
          </div>
        </div>
      </GridElement>
    )
  }

  renderConversations = appId => {
    const { conversations } = this.props
    const { collection } = conversations
    let collections = []

    if (conversations && collection && collection.length > 0) {
      collections = collection
    } else {
      collections = [{
        mainParticipant: 'Admin',
        key: 'admin-channel',
        lastMessage: {
          appUser: 'admin',
          createdAt: new Date()
        }
      }]
    }

    return (
      <GridElement
        noFlex
        style={{ overflow: 'hidden' }}
      >
        <FixedHeader style={{ height: '80px' }}>
          <div className='searchContainer'>
            <SearchBar
              value={this.state.searchValue}
              onChange={(newValue) => this.setState({ searchValue: newValue })}
              placeholder='Search...'
              onRequestSearch={() => this.searchConversations(this.state.searchValue)}
              onCancelSearch={(newValue) => this.resetSearch(newValue)}
              style={{
                boxShadow: 'none',
                border: 'none',
                borderRadius: 5,
                position: 'relative',
                addingLeft: 40,
              }}
              iconButtonStyle={{
                position: 'absolute',
                left: 10,
                top: 19,
              }}
            />
          </div>
        </FixedHeader>

        <FixedHeader style={{ height: '77px' }}>
          <ConversationsButtons>
            <FilterMenu
              options={[
                { id: 'opened', name: 'opened', count: 1, icon: <InboxIcon /> },
                { id: 'closed', name: 'closed', count: 2, icon: <CheckIcon /> },
              ]}
              value={this.props.conversations.filter}
              filterHandler={this.filterConversations}
              triggerButton={this.filterButton}
            />

            <FilterMenu
              options={[
                { id: 'newest', name: 'newest', count: 1, selected: true },
                { id: 'oldest', name: 'oldest', count: 1 },
                { id: 'waiting', name: 'waiting', count: 1 },
                { id: 'priority-first', name: 'priority first', count: 1 },
                { id: 'unfiltered', name: 'all', count: 1 },
              ]}
              value={this.props.conversations.sort}
              filterHandler={this.sortConversations}
              triggerButton={this.sortButton}
            />
          </ConversationsButtons>
        </FixedHeader>

        <Overflow onScroll={this.handleScroll}>
          {
            this.props.conversations.collection.map((o, i) => (
              <div
                key={o.id}
                onClick={(e) => this.props.history.push(`/apps/${appId}/conversations/${o.key}`)}
              >
                <UserListItem
                  value={this.props.conversation.key}
                  mainUser={o.mainParticipant}
                  object={o.key}
                  messageUser={o.lastMessage.appUser}
                  showUserDrawer={() =>
                    this.props.actions.showUserDrawer(o.mainParticipant.id)
                  }
                  messageObject={o.lastMessage}
                  filterConversations={this.state.filterConversations}
                  conversation={o}
                  createdAt={o.lastMessage.message.createdAt}
                  message={this.renderConversationContent(o)}
                  appKey={appId}
                  getConversations = {this.getConversations}
                  removeClosedCon = {this.removeClosedConversation}
                />
              </div>
            ))
          }

          { this.props.conversations.loading ? <Progress /> : null }
        </Overflow>
      </GridElement>
    )
  }

  render() {
    const { appId } = this.props.match.params

    const currentHeader = {
      id: I18n.t("navigator.conversations"),
      url: `/apps/${appId}/conversations`,
      children: [
        {
          id: "Conversations",
          url: `/apps/${appId}/conversations`,
        },
        {
          id: "Assignment Rules",
          url: `/apps/${appId}/conversations/assignment_rules`,
        },
      ],
    }

    return (
      <RowColumnContainer>
        <ConversationHeader history={this.props.history} appId={appId} category={currentHeader} />
        <ColumnContainer>
          <Hidden smUp>
            <Route
              exact
              path={`/apps/${appId}/conversations`}
              render={(props) => this.renderConversations(appId)}
            />
          </Hidden>

          <Hidden smDown>{this.renderConversations(appId)}</Hidden>

          {/*
                <Drawer
                open={this.state.displayMode === "conversations"}
                onClose={this.hideDrawer}>
                {this.renderConversations()}
                </Drawer>
              */}

          <Switch>
            <Route
              exact
              path={`/apps/${appId}/conversations`}
              render={(props) => (
                <EmptyConversation dispatch={this.props.dispatch} />
              )}
            />

            <Route
              exact
              path={`/apps/${appId}/conversations/assignment_rules`}
              render={(props) => (
                <GridElement
                  grow={2}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <FixedHeader style={{ height: "80px" }}/>
                  <AssignmentRules />
                </GridElement>
              )}
            />

            <Route
              exact
              path={`/apps/${appId}/conversations/:id`}
              render={(props) => (
                <React.Fragment>
                  <ConversationContainerShow
                    appId={appId}
                    app={this.props.app}
                    events={this.props.events}
                    conversation={this.props.conversation}
                    showUserDrawer={this.props.actions.showUserDrawer}
                    currentUser={this.props.currentUser}
                    {...props}
                  />
                  <Hidden smDown>{this.renderUserConversationDetail(this.props.conversation)}</Hidden>
                </React.Fragment>
              )}
            />
          </Switch>
        </ColumnContainer>
      </RowColumnContainer>
    );
  }
}

class EmptyConversation extends Component {
  componentDidMount() {
    this.props.dispatch(setCurrentPage("Conversations"));
  }

  render() {
    return (
      <Hidden smDown>
        <FixedHeader style={{ height: "65px" }}/>
        <GridElement
          grow={2}
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div style={{ alignSelf: "center" }}>
            <Paper style={{ padding: "2em" }}>
              <ChatIcon fontSize="large" />
              <Typography variant="h5">Conversations</Typography>

              <Typography component="p">Select a conversation</Typography>

              <img width="300px" src={image} />
            </Paper>
          </div>
        </GridElement>
      </Hidden>
    );
  }
}

function mapStateToProps(state) {
  const { auth, app, conversations, conversation, app_user } = state;
  const { loading, isAuthenticated } = auth;
  //const { sort, filter, collection , meta, loading} = conversations

  return {
    conversations,
    conversation,
    app_user,
    app,
    isAuthenticated,
  };
}

export default withRouter(connect(mapStateToProps)(ConversationContainer));
