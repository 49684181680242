import React from "react"
import { connect } from 'react-redux'
import Content from '../../components/Content'
import EmptyView from '../../components/emptyView'
import {setCurrentSection} from '../../actions/navigation'
import { withRouter } from 'react-router-dom'
import ConversationHeader from '../../components/ConversationHeader'
import image from '../../../../assets/images/delivery-icon8.png'

function CampaignHome({dispatch, history, app}){

  React.useEffect(()=>{
    dispatch(
      setCurrentSection("Campaigns")
    )
  }, [])
  const currentHeader = {
    id: I18n.t("navigator.campaigns"),
    url: `/apps/${app.key}/campaigns`,
    children: [
      {
        id: "Mailing Campaigns",
        url: `/apps/${app.key}/campaigns`,
      },
      {
        id: "In App messages",
        url: `/apps/${app.key}/messages/user_auto_messages`,
      },
      {
        id: "Guided tours",
        url: `/apps/${app.key}/messages/tours`,
      },
    ],
  }
  return (
    <div>
      <ConversationHeader history={history} appId={app.key} category={currentHeader} />
      <Content style={{ paddingTop: 70 }}>
        <EmptyView
          content=""
          page="campaign"
          title={"campaigns"}
          subtitle={
            <div>
              This is the campaigns section, you can create newsletters
              , popup messages and guided tours


              <img src={image} width={"100%"}/>
            </div>

          }/>
      </Content>
    </div>
  )
}



function mapStateToProps(state) {

  const { auth, app } = state
  const { loading, isAuthenticated } = auth

  return {
    app,
    loading,
    isAuthenticated
  }
}

export default withRouter(connect(mapStateToProps)(CampaignHome))
