import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ContentHeader from '../components/ContentHeader'
import Content from '../components/Content'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import FieldRenderer from '../shared/FormFields'
import TextField from '@material-ui/core/TextField';
import favicon from '../images/favicon'
import right_arrow from '../../../assets/images/rightArrow.png'
import new_app_img from '../../../assets/images/newAppBack.png'
import serialize from 'form-serialize'
import {SettingsForm} from './AppSettings'
import timezones from '../shared/timezones'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import graphql from "../graphql/client";
import {
  CREATE_APP
 } from '../graphql/mutations'

 import {errorMessage, successMessage} from '../actions/status_messages'

 import {
  clearApp
} from '../actions/app'
import Snackbar from '../components/snackbar'

import image from '../../../assets/images/up-icon8.png'

const styles = theme => ({
  addUser: {
    marginRight: theme.spacing(1),
  },
  root: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  itemContent: {
    display: 'flex',
    alignItems: 'center'
   },
   pad: {
     margin: '3em'
   },
   backgroundImg: {
     width: '50%', height: '55%', margin: '22%'
   },
   paper: {
     marginTop: theme.spacing(8),
     display: 'flex',
     flexDirection: 'column',
     alignItems: 'flex-start',
     padding: 50,
   },
   avatar: {
     margin: theme.spacing(1),
     backgroundColor: 'transparent',
     width: '60px',
     height: '60px',
     borderRadius: '0%'
   },
   logo: {
     height: '100%',
     width: '100%'
   },
   form: {
     width: '100%', // Fix IE 11 issue.
     marginTop: theme.spacing(3),

     "& input": {
       boxShadow: 'none',
     }
   },
   submit: {
     margin: '40px 0px 16px', borderRadius: 0, padding: '10px 80px',
   },
   border: {
     position: 'absolute', border: '1px solid #000', height: '100%', width: '100%', top: 4, left: 4,
   },
   rightArrow: {
     position: 'absolute', right: '-30px'
   },
   paperPad: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
    },
   }
});

class NewApp extends Component {

  state = {
    data: {},
  };

  componentDidMount(){
    this.props.dispatch(clearApp())
  }

  definitionsForSettings = () => {
    return [
      {
        name: "name",
        type: 'string',
        grid: { xs: 12, sm: 8 }
      },
      {
        name: "domainUrl",
        type: 'string',
        grid: { xs: 12, sm: 8 }
      },
      {name: "timezone", type: "timezone",
        options: timezones,
        multiple: false,
        grid: {xs: 12, sm: 8 }
      },
      {
        name: "gatherSocialData",
        type: 'bool',
        label: "Collect social data about your users",
        hint: "Collect social profiles (e.g. LinkedIn, Twitter, etc.) for my users via a third party",
        grid: { xs: 12, sm: 12 }
      },
    ]
  }

  handleSuccess = ()=>{
    this.props.dispatch(successMessage("app created successfully"))
    this.props.history.push(`/apps/${this.state.data.app.key}`)
  }

  handleResponse = ()=>{
    this.state.data.app.key ? this.handleSuccess() : null
  }


  handleData = () => {
    const {data} = this.state;
    let appData = { app: { preferences: {domain_url: data.domainUrl, active_messenger: true}, name: data.name } }
    graphql(CREATE_APP, {
      appParams: appData.app,
      operation: "create"
    },{
      success: (data)=>{
        this.setState({
          data: data.appsCreate
        }, ()=> (this.handleResponse(data)))
      },
      error: (error)=>{
        this.props.dispatch(errorMessage("server error"))
      }
    }
    )
  }

  setName = (v, key) => {
    let { data } = this.state;
    data[key] = v;
    this.setState({ data: data })
  }


  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <img className={classes.backgroundImg} src={new_app_img}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.paper} style={{padding: 50, alignItems: 'flex-start'}}>
          <Avatar className={classes.avatar}>
            <img className={classes.logo} src={favicon}/>
          </Avatar>
          <Typography component="h1" variant="h1" style={{fontSize: 64, fontWeight: 'bold', marginTop: 50}}>
            Create your company
          </Typography>
          <Typography style={{marginTop: 10}} variant="subtitle1">
            Enter your account email and your website URL.
          </Typography>
            <Snackbar/>
              {/*<SettingsForm
                data={this.state.data}
                classes={this.props.classes}
                update={this.handleData}
                definitions={this.definitionsForSettings}
              />*/}
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      required
                      id="name"
                      fullWidth
                      label="Name"
                      name="name"
                      onChange={(e)=> this.setName(e.target.value, 'name')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      name="domainUrl"
                      fullWidth
                      required
                      id="domainUrl"
                      label="Domain Url"
                      autoFocus
                      onChange={(e)=> this.setName(e.target.value, 'domainUrl')}
                    />
                  </Grid>
                  {/*<Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox value="allowExtraEmails" color="primary" />}
                      label="I want to receive some spam , thanks!."
                    />
                  </Grid>*/}
                </Grid>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleData}
                    className={classes.submit}
                  >
                    <div className={classes.border}></div>
                    <img src={right_arrow} className={classes.rightArrow} />
                    Save
                  </Button>

                </div>

              </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}


NewApp.contextTypes = {
  router: PropTypes.object,
  classes: PropTypes.object.isRequired,
};


function mapStateToProps(state) {

  const { auth, app } = state
  const { isAuthenticated } = auth
  //const { sort, filter, collection , meta, loading} = conversations

  return {
    isAuthenticated
  }
}


export default withRouter(connect(mapStateToProps)(withStyles(styles)(NewApp)))
