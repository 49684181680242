import React from 'react'
import langsOptions from '../../shared/langsOptions'
import serialize from 'form-serialize'

import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'


import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import MuiLink from '@material-ui/core/Link'

import graphql from '../../graphql/client'
import {toSnakeCase} from '../../shared/caseConverter'
import FormDialog from '../../components/FormDialog'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  submitButton: {
    backgroundColor: '#FFD300',
    color: '#000000',
    marginTop: 25,
    width: 200,
    height: 50,
    "&:hover": {
      backgroundColor: '#FFD300',
    }
  },
  addLangBtn: {
    padding: '15px 45px',
    borderRadius: 8,
  },
  tableCellRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    border: "unset",
  },
  langName: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
    border: "unset",
    textTransform: 'uppercase',
    fontSize: 18,
    fontWeight: 700,
  },
  textFieldRoot: {
    width: '100%',
    "& .MuiOutlinedInput-notchedOutline": {
      paddingLeft: 8,
      border: '1px solid #000',
      borderRadius: 7,
      marginRight: 20,
    },
    "& .MuiOutlinedInput-input": {
      boxShadow: 'none',
      padding: '18px 35px',
    },
  }
}));

export default function LanguageForm({settings, update, namespace, fields}){

  const classes = useStyles();

  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedLang, setSelectedLang] = React.useState(null)
  const formRef = React.createRef();
  const default_messages = {greetings: "Hello There", intro: "We're here to answer your questions, Ask us anything."}

  function handleChange(value){
    const val = value.currentTarget.dataset.value
    const serializedData = serialize(formRef.current, { hash: true, empty: true })
    const data = toSnakeCase(serializedData)

    let next = {}

    fields.map((field)=>{
      next[`${field}_${val}`] = ""
    })

    // const newData = Object.assign({}, data.settings, next)
    const newData = Object.assign({}, {key: settings.key}, next)

    console.log(settings)
    console.log("UPDATEATE", newData)

    update({app: newData})
    toggleDialog()
  }

  function renderLangDialog(){
    return isOpen && (
      <FormDialog
        open={isOpen}
        //contentText={"lipsum"}
        titleContent={"Save Assignment rule"}
        formComponent={
          //!loading ?
            <form>

              <Select
                value={selectedLang}
                onChange={handleChange}
                inputProps={{
                  name: 'age',
                  id: 'age-simple',
                }}>

                {
                  langsOptions.map((o)=>(
                    <MenuItem value={o.value}>
                      {o.label}
                    </MenuItem>
                  ))
                }


              </Select>

            </form>
            //: <CircularProgress/>
        }
        dialogButtons={
          <React.Fragment>
            <Button onClick={toggleDialog} color="secondary">
              Cancel
            </Button>

            <Button //onClick={this.submitAssignment }
              color="primary">
              Update
            </Button>

          </React.Fragment>
        }
        //actions={actions}
        //onClose={this.close}
        //heading={this.props.title}
        >
      </FormDialog>
    )
  }

  function toggleDialog(){
    setIsOpen(!isOpen)
  }

  function handleSubmit(){
    const serializedData = serialize(formRef.current, { hash: true, empty: true })
    const data = toSnakeCase(serializedData)
    update(data)
  }

  return (
    <Grid
       alignContent={'space-around'}
       style={{ border: "1px solid #E5E5E5", borderRadius: "7px", margin: '0px 50px' }}
       justify={'space-around'}>
      <Grid item xs={12}  style={{ borderBottom: "1px solid #E5E5E5", display: "flex", justifyContent: "space-between", padding: 20}}>
       <Box m={2}>
        <Typography style={{"fontSize": "18px", "marginTop": "10px"}} >
          Translation
        </Typography>
       </Box>
      </Grid>

      <Grid item xs={12} style={{ padding: 10, margin: "20px", minHeight: 600, borderRadius: "8px"}}>
        <Paper
          elevation={0}
          classes={{
            root: classes.root
        }}>

          <form ref={formRef}>

            <Button onClick={toggleDialog} variant={"contained"} color={"primary"} className={classes.addLangBtn}>
              <AddIcon style={{ marginRight: 5 }} />
              Add language
            </Button>

            <Box mt={2} mb={2}>
              <Table>
                <TableBody>
                  {settings.translations.map(row => (
                    <TableRow key={row.locale}>
                      <TableCell component="th" scope="row" classes={{root: classes.langName}} >
                        {row.locale}
                      </TableCell>

                      {
                        fields.map((field)=>{
                          return <TableCell align="left" classes={{root: classes.tableCellRoot}}>
                                  <TextField
                                    //id="standard-name"
                                    label={field}
                                    variant={'outlined'}
                                    defaultValue={row[field] == null ? default_messages[field] : row[field]}
                                    className={classes.textFieldRoot}
                                    name={`${namespace}[${field}_${row.locale}]`}
                                    margin="normal"
                                  />
                                </TableCell>
                        })
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

            </Box>

            <Grid container alignContent={"flex-end"}>
              <Button onClick={handleSubmit} variant={"contained"} classes={{root: classes.submitButton}}>
                Submit
              </Button>
            </Grid>

          </form>

          {renderLangDialog()}

        </Paper>
      </Grid>
    </Grid>
  )
}
