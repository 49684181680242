import React, { useState, useEffect } from "react"
import styled from "@emotion/styled";
import defaultFields from "../../shared/defaultFields";
import ExposurePlus2Icon from "@material-ui/icons/ExposurePlus2";
import { uniqBy, filter } from "lodash"
import graphql from "../../graphql/client";
import { UPDATE_CAMPAIGN, CACULATE_CAMPAIGN_GOAL } from "../../graphql/mutations";
import {
    Grid,
    Button,
    Menu,
    MenuItem,
    ClickAwayListener,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
    Typography,
    withStyles
  } from "@material-ui/core";


const TextField1 = withStyles({
  filterButton: {
    background: '#FFD300',
    border: 0,
    flex: 1,
    padding: 10,
    marginLeft: 10
  }
})(TextField);


const ContentMatchTitle = styled.h3`
  font-size: 21px;
  font-weight: 400;
`;

const ContentMatch = styled.div`
  overflow: auto;
  /* width: 400px; */
  /* padding-left: 40px; */
  /* margin-bottom: 7px; */
  padding: 0 20px;
  padding-right: 40px;
  text-transform: capitalize;
`;

const ContentMatchFooter = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;

  bottom: 1px;
  padding: 1em;
  background: #F9F7F2;
  padding-left: 0;
  padding-right: 25px;
  width: 100%;

  button {
    background: #FFD300;
    border: 0;
    flex: 1;
    padding: 10px;
    margin-left: 10px;
  }
`;

const ContentMatchWrapper = styled.div`
  /* margin-bottom: 3em; */
`;

const CampaignGoal = (props) => {

  const { appKey, customFields, campaignId, goalSegment, updateData, successMessage } = props

  const [attributeFilter, setAttributeFilter] = useState(false)
  const [goalFilter, setGoalFilter] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [attribute, setAttribute] = useState(goalSegment && goalSegment.attribute)
  const [comparison, setComparison] = useState(goalSegment && goalSegment.comparison)
  const [value, setValue] = useState(goalSegment && goalSegment.value)
  const [goalAverage, setGoalAverage] = useState(0)


  const openFilterHandle = (e) => {
    setAttributeFilter(true)
    setAnchorEl(e.currentTarget)
  }

  useEffect(() => {
    updateGoalCalculations()
  },[])

  const updateGoalCalculations = () => {
    graphql(CACULATE_CAMPAIGN_GOAL, { appKey, id: campaignId }, {
      success: (data) => {
        setGoalAverage(data.calculateCampaignGoal.average)
      },
      error: (error) => {
        console.log("error", error)
      },
    });
  }

  const availableFields = () => {
    return filter(uniqBy(customFields ? customFields.concat(defaultFields) : defaultFields), function (o) {
      return (o.type === "integer" || o.type === "number")
    })
  };

  const filterMenuItem = (fieldObject) => {
    return (
      <span style={{ display: "inline-flex", alignItems: "center" }}>
        <ExposurePlus2Icon variant="small" style={{ marginRight: "10px" }} />
        {fieldObject.name}
      </span>
    );
  };

  const handleAttributeClick = (attributeName) => {
    setAttribute(attributeName)
    setAttributeFilter(false)
    setGoalFilter(true)
  }

  const editGoalFilter = (e) => {
    setGoalFilter(true)
    setAnchorEl(e.currentTarget)
  }

  const closeAll = () => {
    setAttributeFilter(false)
    setGoalFilter(false)
    setAnchorEl(null)
  }

  const attributeFilterMenu = () => {
    return(
      <ClickAwayListener onClickAway={() => closeAll()}>
        <ContentMatchWrapper>
        <MenuItem disabled={true}>
            <ContentMatchTitle>
              Select attribute for goal
            </ContentMatchTitle>
          </MenuItem>
          <div>
            <div className="list-scroll-menu" style={{ maxHeight: "500px", width: "360px", overflow: "auto" }}>
              {availableFields()
                .map((o) => (
                  <MenuItem
                    key={o.name}
                    onClick={() => handleAttributeClick(o.name)}
                  >
                    {filterMenuItem(o)}
                  </MenuItem>
                ))}
            </div>
          </div>
        </ContentMatchWrapper>
      </ClickAwayListener>
    );
  };

  const onRadioChange = (value) => setComparison(value)

  const handleSubmit = (actionType) => {
    const params = {
      appKey,
      id: campaignId,
      campaignParams: {
        goal_segment: actionType === "delete" ? null : { attribute, comparison, value }
      },
    };

    graphql(UPDATE_CAMPAIGN, params, {
      success: (data) => {
        const result = data.campaignUpdate;
        const goalSegmentValue = result.campaign.goalSegment
        updateData(result.campaign);
        successMessage();
        closeAll()
        setValue(goalSegmentValue ? goalSegmentValue.value : null)
        setComparison(goalSegmentValue ? goalSegmentValue.comparison : null)
        setAttribute(goalSegmentValue ? goalSegmentValue.attribute : null)
        updateGoalCalculations()
      },
      error: (error) => {
        console.log("error", error)
      },
    });
  }

  const isGoalPresent = () => comparison && value


  const goalFilterMenu = () => {

    const relative = [
      { label: "exactly"     , value: "eq"   },
      { label: "more than"   , value: "gt"   },
      { label: "more than eq", value: "gteq" },
      { label: "less than"   , value: "lt"   },
      { label: "less than eq", value: "lteq" },
    ];

    return (
      <ClickAwayListener onClickAway={() => closeAll()}>
        <ContentMatchWrapper>
          <MenuItem disabled={true}>
            <ContentMatchTitle>
              Select the integer filter for {attribute}
            </ContentMatchTitle>
          </MenuItem>

          <ContentMatch>
            <RadioGroup
              aria-label="options"
              name="options"
              onChange={(e) => {
                onRadioChange(e.target.value);
              }}
            >
              {relative.map((o, i) => (
                <div
                  key={`${o.name}-${i}`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio checked={comparison === o.value} />
                    }
                    value={o.value}
                    label={o.label}
                  />

                  {comparison &&
                  comparison === o.value ? (
                    <TextField1
                      autoFocus
                      id="name"
                      name="name"
                      label="name"
                      type="number"
                      helperText={"any number"}
                      defaultValue={value}
                      onChange={(e) => setValue(e.target.value)}
                      label={"value"}
                      margin="normal"
                    />
                  ) : null}
                </div>
              ))}
            </RadioGroup>
          </ContentMatch>

          <ContentMatchFooter>
            {comparison && (
              <Button
                variant="outlined"
                color="primary"
                size={"small"}
                onClick={handleSubmit}
              >
                Apply
              </Button>
            )}

            {goalSegment && (
              <Button
              variant="outlined"
              color="primary"
              size={"small"}
              onClick={() => handleSubmit("delete")}
            >
              Delete
            </Button>
            )}
          </ContentMatchFooter>
        </ContentMatchWrapper>
      </ClickAwayListener>
    );
  };

  return (
    <Grid style={{paddingTop: "25px"}}>
      <Typography variant="h3" style={{ margin: '0 0 40px 7px', fontSize: 38, fontWeight: '500' }}>
        Set Campaign Goal
      </Typography>
      <Grid style={{position: 'relative', padding: '10px 0'}}>
        <Button
          style={{
            padding: '12px',
            border: '1px solid'
          }}
          onClick={goalSegment ? editGoalFilter : openFilterHandle}
        >
          {isGoalPresent() ?
            <>
              {`${attribute} ${comparison} ${value}`}
            </>
            : <>Add a Goal</>
          }
        </Button>
        <Menu
          style={{
            borderRadius: '4px',
            border: '1px solid #f1f1f1',
            zIndex: '12'
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
        >
          {attributeFilter && attributeFilterMenu()}
          {goalFilter && goalFilterMenu()}
        </Menu>

        <Grid
          container
          justify='center'
          style={{
            padding: "10px",
            marginTop: "10px",
            borderRadius: "4px",
            border: "1px solid black",
            width: "100px"
          }}
        >
          Goal: {goalAverage}%
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CampaignGoal;
