import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import SettingsIcon from "@material-ui/icons/Settings";
import EmailIcon from "@material-ui/icons/Email";
import BookIcon from "@material-ui/icons/Book";
import SmsIcon from "@material-ui/icons/Sms";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import MessageIcon from "@material-ui/icons/Message";
import FilterFramesIcon from "@material-ui/icons/FilterFrames";
import FolderIcon from "@material-ui/icons/Folder";

import WebhookIcon from "../icons/webhookIcon";
import Switch from "@material-ui/core/Switch";
import Avatar from "@material-ui/core/Avatar";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import HomeOutlined from "@material-ui/icons/HomeOutlined";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import QuestionAnswerOutlined from "@material-ui/icons/QuestionAnswerOutlined";
import Map from "@material-ui/icons/Map";
import FlagOutlined from "@material-ui/icons/FlagOutlined";
import BookOutlined from "@material-ui/icons/BookOutlined";
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import DomainOutlined from "@material-ui/icons/DomainOutlined";
import DeviceHubOutlined from "@material-ui/icons/DeviceHubOutlined";
import WidgetsIcon from "@material-ui/icons/Widgets";

import icon_home from "../icons/bxs-home.svg";
import icon_platform from "../icons/bx-sidebar.svg";
import icon_conversation from "../icons/bxs-conversation.svg";
import icon_plane from "../icons/bx-paper-plane.svg";
import icon_bot from "../icons/bx-bot.svg";
import icon_book from "../icons/bx-book-bookmark.svg";
import icon_setting from "../icons/bx-cog.svg";

import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Tooltip from "@material-ui/core/Tooltip";

import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import WebSetup from "./webSetup";
import ListMenu from "./ListMenu";
import { signout } from "../actions/auth";

//import I18n from '../i18n.js.erb'

const styles = (theme) => ({
  logo: {
    background: `url(${theme.palette.primary.logo})`,
    width: "100%",
    height: "52px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  itemLogo: {
    padding: 4,
    cursor: "pointer",
  },
  itemCategoryIcon: {
    display: "flex",
    justifyItems: "center",
    margin: "0px auto",
    marginTop: 16,
    marginBottom: 16,
  },
  upsend: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: theme.palette.sidebar.hoverBackground,
      borderRadius: "14px",
    },
  },
  itemActiveItem: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.sidebar.activeBackground,
    borderRadius: "11px",
  },
  itemPrimary: {
    color: "inherit",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
    fontWeight: "600",
    textTransform: "Capitalize",
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: "rgba(250, 250, 250, 0.9)",
  },
  expansionPanelDetails: {
    display: "inherit",
    padding: "0px",
  },
  categoryHeaderMini: {
    justifyContent: "center",
    marginTop: "1em",
  },
  iconMini: {
    minWidth: "22px",
  },
  iconButtonMini: {
    color: "white",
    backgroundColor: "black",
    borderRadius: 4,
  },
  iconButtonMiniActive: {
    color: "black",
    backgroundColor: "white",
    borderRadius: 4,
    "&:hover": {
      color: "black",
      backgroundColor: "white",
    },
  },
  listStyle: {
    borderRight: `1px solid ${theme.palette.sidebar.color}`,
    display: "flex",
    flexDirection: "column",
  },
  list100: {
    width: "60px",
    backgroundColor: `${theme.palette.sidebar.darkColor}`,
  },
  sectionTitle: {
    marginTop: "8px",
    marginBottom: "44px",
    fontSize: "24px",
    marginLeft: "15px",
  },

  paperSecondaryMenu: {
    width: "240px",
    height: "100vh",
    alignSelf: "flex-end",
    padding: ".5em",
    backgroundColor: `${theme.palette.background.default}`,
  },
});

function Navigator(props, context) {
  const {
    classes,
    app,
    match,
    location,
    visitApp,
    apps,
    onClose,
    mini,
    current_page,
    current_section,
    toggleTheme,
    themeValue,
    current_user,
    dispatch,
    ...other
  } = props;

  const appid = `/apps/${app.key}`;

  const [expanded, setExpanded] = useState(current_section);

  useEffect(() => {
    setExpanded(current_section);
  }, [current_section]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function isActivePage(page) {
    return current_page === page;
  }

  function isActiveSection(section) {
    return current_section === section;
  }

  function handleSignout() {
    dispatch(signout());
  }

  const NavIcon = (props) => {
    return(
      <img src={props.src} style={{height: '30px'}} />
    );
  }

  const categories = [
    {
      id: "Dashboard",
      icon: <HomeOutlined style={{ fontSize: 30 }} />,
      label: `${app.name} Overview`,
      url: `/apps/${app.key}`,
      active: isActiveSection("Dashboard"),
      children: [
        {
          render: (props) => [
            <ListItem>
              <Typography variant={"caption"}>
                👋 Hey!, you are viewing the <strong>{app.name}'s</strong>{" "}
                dashboard!
                <br />
                Get you installation snippet for the{" "}
                <WebSetup classes={classes} />.
              </Typography>
            </ListItem>,
          ],
        },
      ],
    },
    {
      id: "User",
      render: () => (
        <ListMenu
          currentUser={current_user}
          //handleClick={visitApp}
          button={
            <IconButton
              //onClick={()=>{
              //  signout()
              //}}
              className={classes.itemCategoryIcon}
              color="inherit"
            >
              <Avatar className={classes.avatar} src={current_user.avatarUrl} />
            </IconButton>
          }
          options={[
            {
              key: "new-app",
              name: "Create new app1",
              onClick: () => {
                //this.props.dispatch(clearApp())
                context.router.history.push(`/apps/new`);
              },
            },
            //{type: "divider"},
            //{key: "terms", name: "Terms", onClick: ()=>{ alert("oe") }},
            //{key: "docs", name: "Upsend documentation", onClick: ()=>{ alert("oe") }},
            { type: "divider" },
            //{key: "profile", name: "Profile", onClick: ()=>{ context.router.history.push(`/apps/${props.app.key}/agents/${props.currentUser.id}`) }},
            {
              key: "signout",
              name: "Log out",
              onClick: () => {
                handleSignout();
              },
            },
          ]}
        />
      ),
    },
  ];

  function renderMiniItemList() {
    //console.log("kategories", categories, navigation )
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          height: "calc( 100% - 60px )"
        }}
      >
        <div>
          <ListItem className={classes.categoryHeaderMini}>
            <Tooltip
              title={`${app.name} Overview` || "Dashboard"}
              placement="right"
            >
              <ListItemIcon className={classes.iconMini}>
                <IconButton
                  className={
                    isActiveSection("Dashboard")
                      ? classes.iconButtonMiniActive
                      : classes.iconButtonMini
                  }
                  onClick={() => {
                    context.router.history.push(`/apps/${app.key}`);
                  }}
                >
                  <HomeOutlined style={{ fontSize: 30 }} />
                </IconButton>
              </ListItemIcon>
            </Tooltip>
          </ListItem>

          <ListItem className={classes.categoryHeaderMini}>
            <Tooltip title={I18n.t("navigator.platform")} placement="right">
              <ListItemIcon className={classes.iconMini}>
                <IconButton
                  className={
                    isActiveSection(I18n.t("navigator.platform"))
                      ? classes.iconButtonMiniActive
                      : classes.iconButtonMini
                  }
                  onClick={() => {
                    context.router.history.push(
                      `/apps/${app.key}/segments/${
                        app.segments && app.segments.length > 0 ? app.segments[0].id : ""
                      }`
                    );
                  }}
                >
                  <DomainOutlined style={{ fontSize: 30 }} />
                </IconButton>
              </ListItemIcon>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.categoryHeaderMini}>
            <Tooltip
              title={I18n.t("navigator.conversations")}
              placement="right"
            >
              <ListItemIcon className={classes.iconMini}>
                <IconButton
                  className={
                    isActiveSection(I18n.t("navigator.conversations"))
                      ? classes.iconButtonMiniActive
                      : classes.iconButtonMini
                  }
                  onClick={() => {
                    context.router.history.push(
                      `/apps/${app.key}/conversations`
                    );
                  }}
                >
                  <QuestionAnswerOutlined style={{ fontSize: 30 }} />
                </IconButton>
              </ListItemIcon>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.categoryHeaderMini}>
            <Tooltip title={I18n.t("navigator.campaigns")} placement="right">
              <ListItemIcon className={classes.iconMini}>
                <IconButton
                  className={
                    isActiveSection(I18n.t("navigator.campaigns"))
                      ? classes.iconButtonMiniActive
                      : classes.iconButtonMini
                  }
                  onClick={() => {
                    context.router.history.push(`/apps/${app.key}/messages/campaigns`);
                  }}
                >
                  <FlagOutlined style={{ fontSize: 30 }} />
                </IconButton>
              </ListItemIcon>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.categoryHeaderMini}>
            <Tooltip
              title={I18n.t("navigator.routing_bots") || "Bot"}
              placement="right"
            >
              <ListItemIcon className={classes.iconMini}>
                <IconButton
                  className={
                    isActiveSection("Bot")
                      ? classes.iconButtonMiniActive
                      : classes.iconButtonMini
                  }
                  onClick={() => {
                    context.router.history.push(
                      `/apps/${app.key}/bots/settings`
                    );
                  }}
                >
                  <DeviceHubOutlined style={{ fontSize: 30 }} />
                </IconButton>
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </div>

        <div>
          <ListItem className={classes.categoryHeaderMini}>
            <Tooltip
              title={I18n.t("navigator.help_center") || "HelpCenter"}
              placement="right"
            >
              <ListItemIcon className={classes.iconMini}>
                <IconButton
                  className={
                    isActiveSection("HelpCenter")
                      ? classes.iconButtonMiniActive
                      : classes.iconButtonMini
                  }
                  onClick={() => {
                    context.router.history.push(`/apps/${app.key}/articles`);
                  }}
                >
                  <BookOutlined style={{ fontSize: 30 }} />
                </IconButton>
              </ListItemIcon>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.categoryHeaderMini}>
            <Tooltip
              title={I18n.t("navigator.settings") || "Settings"}
              placement="right"
            >
              <ListItemIcon className={classes.iconMini}>
                <IconButton
                  className={
                    isActiveSection("Settings")
                      ? classes.iconButtonMiniActive
                      : classes.iconButtonMini
                  }
                  onClick={() => {
                    context.router.history.push(`/apps/${app.key}/settings`);
                  }}
                >
                  <SettingsOutlined style={{ fontSize: 30 }} />
                </IconButton>
              </ListItemIcon>
            </Tooltip>
          </ListItem>

          <ListMenu
            currentUser={current_user}
            button={
              <IconButton className={classes.itemCategoryIcon} color="inherit">
                <Avatar
                  className={classes.avatar}
                  src={current_user.avatarUrl}
                />
              </IconButton>
            }
            // options={[
            //   {
            //     key: "new-app",
            //     name: "Create new app",
            //     onClick: () => {
            //       context.router.history.push(`/apps/new`);
            //     },
            //   },
            //   { type: "divider" },
            //   {
            //     key: "signout",
            //     name: "Log out",
            //     onClick: () => {
            //       handleSignout();
            //     },
            //   },
            // ]}
            avatarDropdown
            onSignout={() => handleSignout()}
          />
        </div>
      </div>
    );
  }

  function renderListHeader() {
    return (
      <React.Fragment>
        <ListItem
          onClick={() => context.router.history.push(
            `/apps/${app.key}/segments/${
              app.segments && app.segments.length > 0 ? app.segments[0].id : ""
            }`
          )}
          className={clsx(classes.upsend, classes.itemLogo)}
        >
          <div className={classes.logo} />
        </ListItem>
      </React.Fragment>
    );
  }

  function renderInner() {
    return categories
      .filter((o) => o.id === current_section)
      .map(({ id, label, icon, children }, index) => {
        //  expanded={expanded === id}
        return (
          <div key={index}>
            <Typography className={classes.sectionTitle} variant={"h4"}>
              {id || label}
            </Typography>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              {children.map(
                ({ id: childId, label, icon, active, url, onClick, render }) =>
                  render ? (
                    render(classes)
                  ) : (
                    <ListItem
                      button
                      dense
                      key={childId}
                      className={clsx(
                        classes.item,
                        classes.itemActionable,
                        active && classes.itemActiveItem
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        //this.setActiveLink(o, ()=>{
                        url ? context.router.history.push(url) : onClick();
                        //})
                      }}
                    >
                      {icon && <ListItemIcon>{icon}</ListItemIcon>}
                      <ListItemText
                        classes={{
                          primary: classes.itemPrimary,
                          dense: classes.textDense,
                        }}
                      >
                        {label || childId}
                      </ListItemText>
                    </ListItem>
                  )
              )}
              <Divider className={classes.divider} />
            </ExpansionPanelDetails>
          </div>
        );
      });
  }

  const newHeaderPages = ['Conversations', 'Bot', 'Platform', 'Settings', 'Campaigns', 'HelpCenter', 'guide', 'Visitors']
  return (
    <Drawer
      PaperProps={props.PaperProps}
      variant={props.variant}
      open={props.open}
      onClose={props.onClose}
      className={classes.listStyle}
    >
      <List disablePadding className={classes.list100}>
        {renderListHeader()}
        {renderMiniItemList()}
      </List>

      {current_section && !newHeaderPages.includes(current_section)  && (
        <div
          className={classes.paperSecondaryMenu}
          style={{ backgroundColor: "#FAF7F2" }}
        >
          {renderInner()}
        </div>
      )}
    </Drawer>
  );
}

Navigator.contextTypes = {
  router: PropTypes.object,
};

/*
Navigator.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  app: PropTypes.object,
  currentUser: PropTypes.object
};*/

//export default withStyles(styles)(Navigator);

function mapStateToProps(state, ownProps) {
  const { auth, app, segment, app_users, navigation, current_user } = state;
  const { loading, isAuthenticated } = auth;

  const { current_page, current_section } = navigation;

  return {
    app,
    current_user,
    current_page,
    current_section,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(Navigator));
