/* eslint-disable no-script-url */

import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  title: {
    fontSize: '15px', fontWeight: '400', textTransform: 'capitalize',
  }
});

export default function Count({data, label, appendLabel, subtitle}) {
  const classes = useStyles();
  const countColor = {
    sent: '#000', opened: '#FFA7B1', goal: '#65AB72', clicked: '#36B6E5', replied: '#FFB600', failed: '#222222'
   }
  return (
    <React.Fragment>
      <Typography className={classes.title} component="h2" variant="h6" style={{ color: label === 'sent' ? '#000' : '#777' }} gutterBottom>
        {label}
      </Typography>
      <Typography component="p" variant="h4" style={{ color: countColor[label], fontSize: 21, fontWeight: 500 }}>
        {data || 0} {appendLabel}
      </Typography>
      {/*<div>
        <Link color="primary" href="javascript:;">
          View Data
        </Link>
      </div>*/}
    </React.Fragment>
  );
}
