import React, {Component, useState} from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import { withRouter, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import {ColorPicker} from '../../shared/FormFields'
import styled from '@emotion/styled'

import { SmileIcon } from '../../../client_messenger/icons'
import ForumSharpIcon from "@material-ui/icons/ForumSharp";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    top: 16,
    right: 32,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const PatternButton = styled.button`
  padding: 0px;
  margin: 3px;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 6px;
  :focus {
    outline: #FFB600;
    border: 2px solid #FFB600;
  }
`

const Image = styled.div`
  width: 54px;
  height: 52px;

  ${(props)=>{
    return props.image ?
    `background-image: url(${props.image});` : ''
  }}

  background-size: 310px 310px,cover;
`

const MessengerTopBack = styled.div`
  &:before {
    content: "";
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    ${(props)=>{
      return props.pattern ?
      `
        opacity: 0.38;
        background-image: url(${props.pattern});
      ` : ''
    }}
    background-size: cover;
    pointer-events: none;
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    margin: '10px!important',
  },
  checkLabel: {
    fontSize: 15,
    fontWeight: 500,
  },
  radioIcon: {
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20
    }
  },submitButton: {
    backgroundColor: '#FFD300',
    marginTop: 25,
    color: '#000000',
    width: 250,
    height: 50,
    "&:hover":{
      backgroundColor: '#FFD300',
    }
  },
  formControl: {
    width: '100%',
    "& .MuiOutlinedInput-notchedOutline": {
      paddingLeft: 8,
      border: '1px solid #000',
      borderRadius: 7,
      marginRight: 20,
    },
    "& .MuiOutlinedInput-input": {
      boxShadow: 'none',
      padding: '18px 35px',
    },
    "& .MuiIconButton-root": {
      paddingRight: 20,
    }
  },
  heading: {
    fontSize: 18,
    marginTop: 30,
    marginBottom: 5,
  }
}));

function CustomizationColors({settings, update, dispatch}){
  const classes = useStyles();
  const [state, setState] = React.useState({
    customization_colors: settings.customizationColors || {},
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  const names = [
    "email-pattern",
    "5-dots",
    "greek-vase",
    "criss-cross",
    "chevron",
    "blue-snow",
    "let-there-be-sun",
    "triangle-mosaic",
    "dot-grid",
    "so-white",
    "cork-board",
    "hotel-wallpaper",
    "trees",
    "beanstalk",
    "fishnets-and-hearts",
    "lilypads",
    "floor-tile",
    "beige-tiles",
    "memphis-mini",
    "christmas-colour",
    "intersection",
    "doodles",
    "memphis-colorful"

   ]

   const pattern_base_url = "https://www.toptal.com/designers/subtlepatterns/patterns/"

   const patterns = names.map((o)=> {
     return {name: o, url: pattern_base_url + o + ".png"}
   })

  function handleSubmit(){
    const {
      customization_colors
    } = state

    const data = {
      app: {
        customization_colors: customization_colors
      }
    }
    update(data)
  }

  function selectPattern(pattern){
    const color = Object.assign({},
      state.customization_colors,
      {pattern: pattern ? pattern.url : null }
    )
    setState({customization_colors: color })
  }

  return (
    <Grid
       alignContent={'space-around'}
       style={{ border: "1px solid #E5E5E5", borderRadius: "7px", margin: '0px 50px' }}
       justify={'space-around'}>
      <Grid item xs={12}  style={{ borderBottom: "1px solid #E5E5E5", display: "flex", justifyContent: "space-between", padding: 20}}>
       <Box m={2}>
        <Typography style={{"fontSize": "18px", "marginTop": "10px"}} >
          Messenger Style
        </Typography>
       </Box>
      </Grid>

      <Grid item xs={12} style={{ padding: 10, margin: "20px", marginTop: 0, minHeight: 600, borderRadius: "8px"}}>
        <Paper
            elevation={0}
            classes={{
              root: classes.root
            }}>
          <Box mb={2}>
            <Typography variant={"h4"} classes={classes.heading}>
              Customize chat window
            </Typography>
          </Box>

          <Grid container direction={'column'}>

            <Grid container direction={'row'}>
              <Grid item  xs={12} sm={2} style={{marginRight: 20}}>
                <ColorPicker
                  color={state.customization_colors.primary}
                  colorHandler={(hex)=> {
                    const color = Object.assign({}, state.customization_colors, {primary: hex})
                    setState({customization_colors: color })
                  }}
                  className={classes.formControl}
                  label={"Primary color"}
                />
              </Grid>

              <Grid item  xs={12} sm={2} >
                <ColorPicker
                  color={state.customization_colors.secondary}
                  colorHandler={(hex)=>{
                    const color = Object.assign({}, state.customization_colors, {secondary: hex})
                    setState({customization_colors: color })
                  }}
                  className={classes.formControl}
                  label={"Secondary color"}
                />
              </Grid>

              <Grid item  xs={12} sm={2} >
                <ColorPicker
                  color={state.customization_colors.badge}
                  colorHandler={(hex)=>{
                    const color = Object.assign({}, state.customization_colors, {badge: hex})
                    setState({customization_colors: color })
                  }}
                  className={classes.formControl}
                  label={"Badge Color"}
                />
              </Grid>
            </Grid>

            <Grid container direction={'row'}>

              <Grid item  xs={12} sm={7} >

                <Typography variant={"h6"} className={classes.heading} style={{ marginBottom: 10}}>
                  Choose a pattern From 
                  <Link target={"blank"} href={"https://www.toptal.com/designers/subtlepatterns/"}>
                  Subtle patterns
                  </Link>
                </Typography>

                <PatternButton onClick={(e)=>selectPattern(null)}>
                  <Image />
                </PatternButton>

                {
                  patterns.map((o)=>{
                    return <PatternButton onClick={(e)=>selectPattern(o)}>
                              <Image image={o.url} />
                            </PatternButton>
                  })
                }
                <Grid container>
                  <Button onClick={handleSubmit}
                    variant={"contained"} color={"primary"}
                    classes={{root: classes.submitButton}}
                  >
                    Save Settings
                  </Button>
                </Grid>
              </Grid>


              { state.customization_colors.pattern &&
                <Grid item  xs={12} sm={6} >

                  <Typography variant={"h6"}>
                    Selected pattern
                  </Typography>

                    <img src={state.customization_colors.pattern} />


                  <br/>

                  <Typography variant={"overline"}>
                    From subtle patterns
                  </Typography>
                </Grid>
              }
              <Grid item  xs={12} sm={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ width: 420, position: 'relative', marginBottom: 70 }}>
                  <div
                    style={{
                      maxHeight: 740,
                      width: 400,
                      backgroundColor: "rgb(250, 246, 241)",
                      marginBottom: 10,
                      zIndex: 1000,
                      borderRadius: 5,
                      height: "calc(80vh - 50px)",
                      border: "solid 1px",
                    }}
                  >
                    <MessengerTopBack
                      style={{
                        backgroundColor: state.customization_colors.primary,
                        color: "#FFF",
                        textAlign: "center",
                        fontSize: 25,
                        fontWeight: "bold",
                        paddingTop: 40,
                        paddingBottom: 10,
                        position: 'relative',
                      }}
                      pattern={state.customization_colors.pattern}
                    >
                      Hi, Brian
                    </MessengerTopBack>
                    <MessengerTopBack
                      style={{
                        backgroundColor: state.customization_colors.primary,
                        color: "#FFF",
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bold",
                        paddingTop: 20,
                        paddingBottom: 80,
                        position: 'relative',
                      }}
                      pattern={state.customization_colors.pattern}
                    >
                      We help your business grow by
                      <br />
                      connecting you to your customers.
                    </MessengerTopBack>
                    <div
                      style={{
                        width: "calc(100% - 40px)",
                        marginLeft: 20,
                        backgroundColor: "#fff",
                        transform: "translateY(-55px)",
                        padding: 20,
                        borderRadius: 10,
                      }}
                    >
                      <div style={{ fontSize: 15 }}>Your Conversation</div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <Avatar
                            style={{ width: 32, height: 32, marginRight: 10 }}
                            src="../../../assets/user1.jpg"
                          />
                          <Avatar
                            style={{ width: 32, height: 32, marginRight: 10 }}
                            src="../../../assets/user2.jpg"
                          />
                          <Avatar
                            style={{ width: 32, height: 32, marginRight: 10 }}
                            src="../../../assets/user3.jpg"
                          />
                        </div>
                        <div
                          style={{
                            marginTop: 20,
                            marginBottom: 20,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button variant="contained" color="primary" style={{ backgroundColor: state.customization_colors.secondary }}>
                            <ForumSharpIcon
                              style={{
                                marginRight: 10,
                                fontSize: 18,
                                width: 18,
                                height: 18,
                              }}
                            />
                            Start a conversation
                          </Button>
                          <span style={{ lineHeight: "35px" }}>See previous</span>
                        </div>
                        <div style={{ display: "flex" }}>
                          <StyledBadge
                            overlap="circle"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            variant="dot"
                          >
                            <Avatar
                              alt="Remy Sharp"
                              style={{
                                borderRadius: "50%",
                                border: "solid 1px black",
                              }}
                              src="/assets/user3.jpg"
                            />
                          </StyledBadge>
                          <div
                            style={{
                              marginLeft: 20,
                              maxWidth: 200,
                              lineHeight: "12px",
                              fontSize: 12,
                            }}
                          >
                            <small>Bot</small>
                            <br />
                            <small>
                              Ok, let us know if you need something and An agent
                              will reply as soon as possible
                            </small>
                          </div>
                          <div>
                            <small style={{ fontSize: 9 }}>6 mins ago</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: -45,
                        width: "calc(100% - 40px)",
                        marginLeft: 20,
                        backgroundColor: "#FFF",
                        padding: 20,
                        borderRadius: 10,
                      }}
                    >
                      <div>Find answers in our article's repository</div>
                      <FormControl
                        variant="outlined"
                        style={{
                          border: "solid 1px black",
                          borderRadius: 5,
                          marginTop: 20,
                        }}
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={
                            <InputAdornment>
                              <IconButton
                                edge="end"
                                style={{
                                  width: 53,
                                  height: 53,
                                  borderRadius: 5,
                                  backgroundColor: "#000",
                                  color: "#FFF",
                                }}
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={0}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div style={{ height: "65px", width: "70px", position: 'absolute', right: '12px' }}>
                    <SmileIcon
                      palette={{badge: state.customization_colors.badge}}
                      style={{
                        height: "43px",
                        width: "36px",
                        margin: "8px 0px",
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>

          </Grid>

        </Paper>
      </Grid>
    </Grid>
  )
}

function mapStateToProps(state) {
  const { drawer } = state
  return {
    drawer
  }
}

export default withRouter(connect(mapStateToProps)(CustomizationColors))
