
import React from "react"

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { 
  updateDataOfBlock, 
  addNewBlockAt , 
  resetBlockWithType
} from 'Dante2/package/es/model/index.js'

import Giphy from './giphy'

const giphyApiKey = "97g39PuUZ6Q49VdTRBvMYXRoKZYd1ScZ"

// const GiphyLogo = ()=>{
//   return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
//     <path id="bxs-file-gif" d="M14,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8ZM11.333,17.772A4.512,4.512,0,0,1,9.984,18a2.257,2.257,0,0,1-1.644-.546,1.958,1.958,0,0,1-.569-1.469,2.086,2.086,0,0,1,2.309-2.111,2.8,2.8,0,0,1,1.121.2l-.191.731a2.239,2.239,0,0,0-.941-.174,1.224,1.224,0,0,0-1.338,1.308A1.215,1.215,0,0,0,10,17.262a1.2,1.2,0,0,0,.45-.061v-.846H9.828v-.713h1.505Zm1.634.186h-.918V13.916h.918Zm3.262-3.292H14.676v.923h1.451v.744H14.676v1.625h-.918V13.916h2.471ZM14,9H13V4l5,5Z" transform="translate(-4 -2)" fill="#b2b2b2"/>
//   </svg>
// }

const GiphyLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
      <path id="bxs-file-gif" d="M14,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8ZM11.333,17.772A4.512,4.512,0,0,1,9.984,18a2.257,2.257,0,0,1-1.644-.546,1.958,1.958,0,0,1-.569-1.469,2.086,2.086,0,0,1,2.309-2.111,2.8,2.8,0,0,1,1.121.2l-.191.731a2.239,2.239,0,0,0-.941-.174,1.224,1.224,0,0,0-1.338,1.308A1.215,1.215,0,0,0,10,17.262a1.2,1.2,0,0,0,.45-.061v-.846H9.828v-.713h1.505Zm1.634.186h-.918V13.916h.918Zm3.262-3.292H14.676v.923h1.451v.744H14.676v1.625h-.918V13.916h2.471ZM14,9H13V4l5,5Z" transform="translate(-4 -2)" fill="#b2b2b2"/>
    </svg>
  )
}

export default class GiphyBlock extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      embed_data: this.defaultData(),
      open: true
    }
  }

  componentDidMount(){
    console.log(this.props)
    this.props.blockProps.toggleEditable()
  }

  defaultData =() =>{
    const existing_data = this.props.block.getData().toJS()
    return existing_data.embed_data || {}
  }

  deleteSelf = (e)=>{
    e.preventDefault()
    const { block, blockProps } = this.props
    const { getEditorState, setEditorState } = blockProps
    const data = block.getData()
    const newData = data.merge(this.state)
    return setEditorState(resetBlockWithType(getEditorState(), 'unstyled', {}))
  }

  getAspectRatio = (w, h)=> {
    let maxWidth = 1000
    let maxHeight = 1000
    let ratio = 0
    let width = w // Current image width
    let height = h // Current image height

    // Check if the current width is larger than the max
    if (width > maxWidth) {
      ratio = maxWidth / width // get ratio for scaling image
      height = height * ratio // Reset height to match scaled image
      width = width * ratio // Reset width to match scaled image

      // Check if current height is larger than max
    } else if (height > maxHeight) {
      ratio = maxHeight / height // get ratio for scaling image
      width = width * ratio // Reset width to match scaled image
      height = height * ratio // Reset height to match scaled image
    }

    let fill_ratio = height / width * 100
    let result = { width, height, ratio: fill_ratio }
    // console.log result
    return result
  }

  selectImage = (giphyblock)=>{
    const { block, blockProps } = this.props
    const { getEditorState, setEditorState } = blockProps
    const data = block.getData()

    const {url, height, width} = giphyblock.images.original
    const newData = {
      url: url,
      aspect_ratio: this.getAspectRatio(width, height),
      forceUpload: true
    } 

    this.props.blockProps.toggleEditable()
    //data.merge(this.state)
    return setEditorState(
      resetBlockWithType(getEditorState(), 
      'image', 
      newData)
     )
  }


  render(){
    //console.log(this.state.collection)
    return <div 
            className="dante-giphy-wrapper">
              <Dialog
                open={this.state.open}
                onClose={()=>{
                  this.setState({
                    open: !this.state.open
                  }, this.props.blockProps.toggleEditable ) 
                }}
                maxWidth={'sm'}
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Compose a new message
                </DialogTitle>
                
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  
                  <Giphy 
                    apiKey={giphyApiKey}
                    handleSelected={(data)=>{
                      this.selectImage(data)
                    }}
                  />

                  </DialogContentText>
                </DialogContent>
        
                <DialogActions>
                  
                </DialogActions>
        
              </Dialog>     
          </div>
  }
}

export const GiphyBlockConfig = (options={})=>{

  let config =  {
    title: 'add an image',
    type: 'giphy',
    icon: GiphyLogo,
    block: GiphyBlock,
    editable: false,
    renderable: true,
    breakOnContinuous: true,
    wrapper_class: "graf graf--figure",
    selected_class: "is-selected is-mediaFocused",
    widget_options: {
      displayOnInlineTooltip: true,
      insertion: "insertion",
      insert_block: "giphy",
      //insertion: "func",
      //funcHandler: options.handleFunc,
    },
    options: {
      placeholder: 'Search any gif on giphy'
    }
  }
  
  return Object.assign(config, options)
}


