export function setupScript(app) {
  const hostname = window.location.hostname
  const port = window.location.port ? ":"+window.location.port : ""
  const secure = window.location.protocol === "https:"
  const httpProtocol = window.location.protocol
  const wsProtocol = secure ? "wss" : "ws"
  const hostnamePort = `${hostname}${port}`

  return `
      <script>
        (function(d,t) {
          var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
          g.src="${httpProtocol}//${hostnamePort}/embed.js"
          s.parentNode.insertBefore(g,s);
          g.onload=function(){
            new window.UpsendMessengerEncrypted({
              domain: '${httpProtocol}//${hostnamePort}',
              ws:  '${wsProtocol}://${hostnamePort}/cable',
              app_id: "${app ? app.key : ''}",
              lang: "USER_LANG_OR_DEFAULTS_TO_BROWSER_LANG",
              name: "USER_NAME (YOUR ATTRIBUTES)",
              email: "EMAIL (YOUR ATTRIBUTES)",
            })
          }
        })(document,"script");
      </script>
    `
}
