import React, {useState} from 'react';
import { signup } from '../actions/auth';
import {getCurrentUser} from '../actions/current_user';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import favicon from '../images/favicon'
import right_arrow from '../../../assets/images/rightArrow.png'
import signup_img from '../../../assets/images/signupBack.png'
import lightTheme from '../themes/light/index'
import darkTheme from '../themes/dark/index'
import { connect } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles';
import Snackbar from '../components/snackbar';
import {errorMessage, successMessage} from '../actions/status_messages'

function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Built with love by the '}
      <Link color="inherit" href="https://upsend.io/">
        Upsend
      </Link>
      {' team.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  /*'@global': {
    body: {
      backgroundColor: theme.palette.common.black,
    },
  },*/
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 50,
  },
  backgroundImg: {
    width: '50%', height: '55%', margin: '22%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    width: '60px',
    height: '60px',
    borderRadius: '0%'
  },
  logo: {
    height: '100%',
    width: '100%'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),

    "& .MuiGrid-grid-sm-8": {
      "& .MuiFormControl-root": {
        width: '80%',
      }
    },
    "& .MuiGrid-grid-xs-12": {
      "& .MuiFormControl-root": {
        width: '90%',
      }
    },
    "& input": {
      boxShadow: 'none',
    }
  },
  submit: {
    margin: '40px 0px 16px', borderRadius: 0, padding: '10px 80px',
  },
  border: {
    position: 'absolute', border: '1px solid #000', height: '100%', width: '100%', top: 4, left: 4,
  },
  rightArrow: {
    position: 'absolute', right: '-30px'
  },
}));

function SignUp(props) {
  const classes = useStyles();
  const theme = props.theme === "light" ? lightTheme : darkTheme;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e)=>{
    e.preventDefault();

    props.dispatch(signup(firstName, lastName, email, password, ()=>{
      props.dispatch(successMessage("A comfirmation email has been sent to your email. Please verify your email"))
      props.dispatch(getCurrentUser())
      props.history.push('/apps/new')
    }))
  }

  return (
    <MuiThemeProvider theme={theme}>
    <CssBaseline />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <img className={classes.backgroundImg} src={signup_img}/>
        </Grid>
        <Grid item xs={12} sm={6}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <img className={classes.logo} src={favicon}/>
          </Avatar>
          <Typography component="h1" variant="h1" style={{fontSize: 64, fontWeight: 'bold', marginTop: 50}}>
            Sign up
          </Typography>
          <Typography style={{marginTop: 10}} variant="subtitle1">
            Enter your details to register for an account. 
          </Typography>
          <Snackbar/>

          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <TextField
                  required
                  id="email"
                  fullWidth
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e)=> setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  fullWidth
                  required
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={firstName}
                  onChange={(e)=> setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  required
                  id="lastName"
                  fullWidth
                  label="Last Name"
                  autoFocus
                  name="lastName"
                  autoComplete="lname"
                  value={lastName}
                  onChange={(e)=> setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  required
                  autoFocus
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e)=> setPassword(e.target.value)}
                />
              </Grid>
              {/*<Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive some spam , thanks!."
                />
              </Grid>*/}
            </Grid>
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                <div className={classes.border}></div>
                <img src={right_arrow} className={classes.rightArrow} />
                Sign up
              </Button>

            </div>
            <Grid container justify="flex-start">
              <Grid item>
                <Link href="/signin" variant="body2" style={{textDecoration: 'underline'}}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          {/*<MadeWithLove />*/}
        </Box>
      </Grid>
      </Grid>
    </MuiThemeProvider>
  );
}

function mapStateToProps(state) {
  const { auth, current_user, theme } = state
  const { loading, isAuthenticated } = auth

  return {
    current_user,
    loading,
    isAuthenticated,
    theme
  }
}

export default connect(mapStateToProps)(SignUp)
