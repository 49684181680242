import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'

import graphql from '../../graphql/client'
import { EDITOR_APP_PACKAGES, AUTO_REPLY } from '../../graphql/queries'
import { CREATE_AUTO_REPLY, UPDATE_AUTO_REPLY, DELETE_AUTO_REPLY } from '../../graphql/mutations'
import {
  getConversation,
  typingNotifier,
  insertComment,
  insertAppBlockComment,
  insertNote,
  setLoading,
  clearConversation,
  appendMessage,
  updateConversationState,
  updateConversationPriority,
  assignAgent,
} from '../../actions/conversation'
import NewEditor from './repliesEditor'
import TextEditor from "../../textEditor";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  button: {
    margin: '0px',
    borderRadius: '5px',
    backgroundColor: '#FFD300',
    width: 100,
    height: 30,
    color: '#777777',
    alignSelf: 'center'
  },
  svgIcon: {
    fill: '#000',
  },
  disabledSvgIcon: {
    fill: '#ccc',
  }
}))

const EditorWrapper = styled.div`
  width: 100%;
  border: 1px solid rgb(179, 179, 179);
  margin-top: 15px;
  padding: 6px;
  border-radius: 5px;
`

const EditorContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 272px;
  min-height: 71px;
  max-height: 250px;
  height: auto;
  overflow: auto;
  background-color: white;
  box-sizing: border-box;
  max-width: inherit;
  word-wrap: break-word;
  border-top: 1px solid rgb(223, 225, 230);
  border-image: initial;
  -webkit-animation: none;
  animation: none;
  border-radius: 5px;
  border: none;
`

const EditorActions = styled.div`
  box-sizing: border-box;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  padding: 12px 1px;
`

const RepliesPanel = props => {
  const [open, setOpen] = useState(props.open)
  const [provider, setProvider] = useState(null)
  const [providers, setProviders] = useState([])
  const [autoReplies, setAutoReplies] = useState([])
  const [values, setValues] = useState({})
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [serializedContent, setSerializedContent] = useState("")
  const [htmlContent, setHtmlContent] = useState("")
  const [id, setId] = useState("")
  const [title, setTitle] = useState('')
  const [hover, setHover] = useState({})
  const classes = useStyles()

  const getAutoReplies = () => {
    graphql(AUTO_REPLY, {
      appKey: props.app.key
    }, {
      success: (data) => {
        setAutoReplies(data.autoReply)
      },
      error: () => {

      }
    })
  }

  const handleOnHover = (id) => {
    let tempHover = {};

    Object.keys(hover).forEach((key) => {
      if (key != id) {
        tempHover[key] = false
      }
    })
    tempHover[id] = true
    setHover(tempHover)
  }

  const getAppPackages = () => {
    graphql(EDITOR_APP_PACKAGES, {
      appKey: props.app.key
    }, {
      success: (data) => {
        setProviders(data.app.editorAppPackages)
      },
      error: (e) => {
        console.error(e)
      }
    })
  }

  React.useEffect(()=>{
    getAppPackages()
    getAutoReplies()
  }, [])

  React.useEffect( () => {
    setOpen(props.open)
  }, [ props.open ])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
    props.close()
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const renderItem = o => {
    const { requires } = o.editorDefinitions

    return <Box mt={2}>
            <Typography variant='h3'>
              {o.name}
            </Typography>
            { requires.map((r) => renderRequirement(r)) }
          </Box>
  }

  const renderRequirement = item => {
    switch (item.type) {
      case 'input':
        return <TextField
                  label={item.name}
                  value={values[item.name]}
                  onChange={handleChange(item.name)}
                  placeholder={item.placeholder}
                  helperText={item.hint}
                  margin='normal'
                />
      default:
        return <p>no input</p>
    }
  }

  const handleClick = o => {
    setProvider(o)
  }

  const resetAutoReplyData = () => {
    setSerializedContent("")
    setHtmlContent("")
    setTitle("")
    setId("")
    setOpenCreateModal(false)
    setOpenUpdateModal(false)
  }

  const handleSend = () => {
    if (openUpdateModal)
      updateAutoReply()
    else
      createAutoReply()
    resetAutoReplyData()
  }

  const createAutoReply = () => {
    graphql(CREATE_AUTO_REPLY, {
      appKey: props.app.key,
      htmlContent: htmlContent,
      serializedContent: serializedContent,
      title: title
    }, {
      success: (data) => {
        setAutoReplies([...autoReplies, data.createAutoReply.autoReply])
      },
      error: (e) => {
        console.error(e);
      }
    })
  }

  const updateAutoReply = () => {
    graphql(UPDATE_AUTO_REPLY, {
      id: id,
      appKey: props.app.key,
      htmlContent: htmlContent,
      serializedContent: serializedContent,
      title: title
    }, {
      success: (data) => {
        let updatedAutoReply = data.updateAutoReply.autoReply
        setAutoReplies(autoReplies.map(autoReply =>
          autoReply.id === updatedAutoReply.id ? updatedAutoReply : autoReply
        ))
      },
      error: (e) => {
        console.log("error", e)
      }
    })
  }

  const openEditAutoReplyModal = (reply) => {
    setSerializedContent(reply.serializedContent)
    setId(reply.id)
    setTitle(reply.title)
    setHtmlContent(reply.htmlContent)
    setOpenUpdateModal(true)
  }

  const deleteAutoReply = (id) => {
    graphql(DELETE_AUTO_REPLY, {
      appKey: props.app.key,
      id: parseInt(id)
    }, {
      success: () => {
        setAutoReplies(autoReplies.filter((a) => a.id != id));
      },
      error: (err) => {
        console.error('ERROR', err)
      }
    })
  }

  const handleCreateClick = () => {
    setOpenCreateModal(true)
  }

  const handleCloseCreateModal = () => {
    resetAutoReplyData()
  }

  const handleTextChange = event => {
    setTitle(event.target.value)
  }

  const submitData = (formats, options) => {
    // DANTE does not provide a way to update contentState from outside ?
    // hide and show editor hack
    this.setState(
      {
        loading: true,
      },
      () => {
        options.note
          ? this.props.insertNote(formats, this.enable)
          : this.props.insertComment(formats, this.enable);
      }
    );
  };

  const handleTyping = content => {
    if (content.html === '<p class="graf graf--p"></p>') return;

    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.props.typingNotifier();
    }, 400)
  }

  const insertComment = (comment, callbacks) => {
    callbacks['before'] && callbacks['before']()

    if(this.state.conversation.key && this.state.conversation.key != 'volatile'){
      this.createComment(comment, callbacks['sent'])
    }else{
      this.createCommentOnNewConversation(comment, callbacks['sent'])
    }
  }

  const loadReplyInEditor = (reply) => {
    props.setAutoReply(reply.htmlContent, reply.serializedContent)
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth={true}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <h3 style={{ fontFamily: 'Roboto, sans-serif', fontSize: '30px', paddingLeft: '50px', fontWeight: '500', marginBottom: 5 }}>Saved Replies</h3>
        <DialogContent style={{height: '290px', display: 'flex', overflow: 'hidden'}}>
          <Button onClick={handleCreateClick} className={classes.button} color='primary' style={{ alignSelf: 'flex-start', height: '65px', width: '10px', marginRight: '25px', marginLeft: '25px' }}>
            <AddIcon />
          </Button>
          <DialogContentText id='alert-dialog-description' style={{ width: '100%', overflowY: 'auto', paddingRight: 25, marginBottom: 0 }}>
            {
              autoReplies && autoReplies.map(reply => {
                const serializedContent = JSON.parse(reply.serializedContent);

                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      boxShadow: hover[reply.id] ? '1px 3px 10px -3px rgba(100, 100, 100, 0.2)' : '',
                      padding: '5px 15px',
                      border: '1px solid rgba(100, 100, 100, 0.07)',
                      borderRadius: 5,
                      marginBottom: 5,
                    }}
                    onMouseOver={() => handleOnHover(reply.id)}
                    onMouseLeave={() => handleOnHover('')}
                  >
                    <div style={{cursor: "pointer", padding: "5px 0", width: '80%'}} onClick={() => loadReplyInEditor(reply)}>
                      <div style={{ fontSize: '14px', color: 'black', fontWeight: '600', textTransform: 'capitalize' }}>
                        {reply.title}
                      </div>
                      <div style={{ fontSize: '13px', fontWeight: '400', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%', whiteSpace: 'nowrap' }}>
                        {serializedContent.blocks[0].text}
                      </div>
                    </div>
                    <button
                      onClick={() => openEditAutoReplyModal(reply)}
                      style={{ display: `${hover[reply.id] ? '' : 'none'}`, backgroundColor: 'white', border: 'none', cursor: 'pointer' }}
                    >
                      <EditIcon className='deleteIcon' />
                    </button>
                    <button
                      onClick={() => deleteAutoReply(reply.id)}
                      style={{ display: `${hover[reply.id] ? '' : 'none'}`, backgroundColor: 'white', border: 'none', cursor: 'pointer' }}
                    >
                      <DeleteIcon className='deleteIcon' />
                    </button>
                  </div>
                )}
              )
            }
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ padding: '0px 48px 20px 0' }}>
          <Button onClick={handleClose} color='secondary' style={{ border: '1px solid', padding: '10px 20px' }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {
        (openCreateModal || openUpdateModal) &&
          <Dialog
            open={openCreateModal || openUpdateModal}
            onClose={handleClose}
            maxWidth='sm'
            fullWidth={true}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title' disableTypography='false' style={{ padding: '30px 40px' }} >
              <div style={{ fontFamily: 'roboto, sans-serif', fontWeight: '500', fontSize: '30px' }}>{openUpdateModal ? "Update" : "New" } Saved Reply</div>
            </DialogTitle>

            <DialogContent style={{ fontSize: 12, padding: "8px 40px" }}>
              <input
                onChange={handleTextChange}
                placeholder='Enter Name'
                value={title}
                style={{
                  width: '100%',
                  maxWidth: 'none',
                  border: '1px solid #b3b3b3',
                  boxShadow: 'none', marginTop: 1,
                  marginBottom: 0,
                  fontFamily: 'roboto, sans-serif',
                  fontSize: '13px'
                }}
              />
              <EditorWrapper style={{ height: "130px" }}>
                <EditorContainer
                  note={null}
                >
                  {console.log("serializedContent",serializedContent)}
                  <TextEditor
                    campaign={true}
                    data={{
                      serialized_content: serializedContent,
                    }}
                    serializedContent={serializedContent}
                    styles={{
                      lineHeight: "1.7em",
                      position: "relative",
                      fontSize: "15px",
                      padding: "10px"
                    }}
                    updateState={({status, statusButton, content})=> {
                      setHtmlContent(content.html)
                      setSerializedContent(content.serialized)
                    }}
                  />
                </EditorContainer>
              </EditorWrapper>
            </DialogContent>

            <DialogActions style={{ padding: '20px 40px' }}>
              <Button onClick={handleCloseCreateModal} color='secondary' style={{ padding: '7px 15px', border: '1px solid' }}>Cancel</Button>
              <Button onClick={handleSend} color='primary' style={{ backgroundColor: '#ffb600', padding: '7px 30px' }}>Save</Button>
            </DialogActions>
          </Dialog>
      }
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { app_user, app } = state
  return {
    app_user,
    app
  }
}

export default withRouter(connect(mapStateToProps)(RepliesPanel))
