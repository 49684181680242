export const APPS = `
query Apps{
  apps{
    key
    name
    state
  }
}
`;

export const SHOPIFY_ORDERS = `query ShopifyOrders($appKey: String!, $email: String!) {
  shopifyOrders(appKey: $appKey, email: $email) {
    id
    email
    createdAt
    orderNumber
    totalPrice
  }
}`;


export const APPS_KEY = `
query Apps {
  apps {
    key
  }
}
`;

export const APP = `
  query App($appKey: String!){
    app(key: $appKey) {
      encryptionKey
      key
      name
      preferences
      logo
      enableArticlesOnWidget
      inlineNewConversations
      timezone
      domainUrl
      activeMessenger
      theme
      translations
      availableLanguages
      teamSchedule
      replyTime
      customizationColors
      inboundSettings
      emailRequirement
      leadTasksSettings
      userTasksSettings
      gatherSocialData
      registerVisits
      domainUrl
      outgoingEmailDomain
      customFields
      segments {
        name
        id
        properties
      }
      state
      stripePlanId
      emailForwardingAddress
      appUsers {
        type
        createdAt
      }
      hasAccess
    }
  }
`;

export const APP_VISITS = `
  query App($appKey: String!, $page: Int, $per: Int, $forMap: Boolean, $filter: String){
    app(key: $appKey) {
      key
      name
      visits(page: $page, per: $per, forMap: $forMap, filter: $filter){
        collection{
          url
          title
          osVersion
          os
          browserName
          browserVersion
          country
          createdAt
        }
        meta
      }
    }
  }
`;

export const AGENTS = `
  query App($appKey: String!){
    app(key: $appKey) {
      agents{
        id
        email
        avatarUrl
        name
        signInCount
        lastSignInAt
        invitationAcceptedAt
        emailStatus
      }
    }
  }
`;

export const PENDING_AGENTS = `
  query App($appKey: String!){
    app(key: $appKey) {
      upgradeToAddAgents
      notConfirmedAgents{
        id
        email
        avatarUrl
        name
        signInCount
        lastSignInAt
        invitationAcceptedAt
        invitationSentAt
        emailStatus
      }
    }
  }
`;

export const AGENT = `
  query App($appKey: String!, $id: Int!, $page: Int, $per: Int){
    app(key: $appKey) {
      agent(id: $id){
        id
        email
        avatarUrl
        name
        emailStatus
        conversations(page: $page , per: $per ){
          collection{
            id
            key
            state
            readAt
            priority
            lastMessage{
              source
              createdAt
              readAt
              message{
                blocks
                data
                state
                htmlContent
                textContent
                serializedContent
              }
              privateNote
              messageSource{
                id
                type
              }
              appUser {
                id
                email
                avatarUrl
                kind
                displayName
                emailStatus
              }
            }
            mainParticipant{
              id
              email
              avatarUrl
              displayName
              properties
              emailStatus
            }
          }
          meta
        }
      }
    }
  }
`;

export const SEGMENT = `
  query App($appKey: String!, $id: Int!){
    app(key: $appKey) {
      segment(id: $id ) {
        name
        id
        predicates {
          type
          attribute
          comparison
          value
        }
        configTableColumns
      }
    }
  }
`;


export const CONVERSATIONS = `
  query App($appKey: String!, $page: Int!, $sort: String, $filter: String, $search: String){
    app(key: $appKey) {
      encryptionKey
      key
      name
      conversations(page: $page, sort: $sort, filter: $filter, search: $search){
        collection{
          id
          key
          state
          readAt
          priority
          assignee {
            displayName
            email
            avatarUrl
          }
          lastMessage{
            source
            createdAt
            stepId
            triggerId
            fromBot
            readAt
            message{
              blocks
              data
              state
              htmlContent
              textContent
              serializedContent
            }
            privateNote
            messageSource{
              id
              type
            }
            appUser {
              id
              email
              avatarUrl
              kind
              displayName
            }
          }
          mainParticipant{
            id
            email
            avatarUrl
            displayName
            properties
          }
        }
        meta

      }
    }
  }
`;

export const CONVERSATION=`
  query App($appKey: String!, $id: String!, $page: Int){
    app(key: $appKey) {
      encryptionKey
      key
      name
      conversation(id: $id){
        id
        key
        state
        readAt
        priority
        currentPage
        assignee {
          id
          email
          avatarUrl
        }
        mainParticipant{
          id
          email
          avatarUrl
          displayName
          country
          lastVisitedAt
          state
          ip
          city
          region
          lat
          lng
          postal
          timezone
          webSessions
          type
          browser
          browserVersion
          os
          osVersion
          browserLanguage
          lang
          lastSeen
          firstSeen
          signedUp
          lastContacted
          lastHeardFrom
          phone
          companyName
          companySize
          companyWebsite
          companyIndustry
          numberOfEmails
          emailStatus
          properties
          referrer
          sessionId
        }

        messages(page: $page){
          collection{
            id
            stepId
            triggerId
            fromBot
            message{
              blocks
              data
              state
              htmlContent
              textContent
              serializedContent
              action
            }
            source
            readAt
            createdAt
            privateNote
            appUser{
              id
              email
              avatarUrl
              kind
              displayName
            }
            source
            messageSource {
              name
              state
              fromName
              fromEmail
              serializedContent
            }
            emailMessageId
          }
          meta
        }
    }
  }
}
`;

export const CONVERSATION_WITH_LAST_MESSAGE=`
  query App($appKey: String!, $id: String!){
    app(key: $appKey) {
      encryptionKey
      key
      name
      conversation(id: $id){
        id
        key
        state
        readAt
        priority
        createdAt
        lastMessage{
          createdAt
          source
          triggerId
          fromBot
          readAt
          message{
            blocks
            data
            state
            htmlContent
            textContent
            serializedContent
          }
          privateNote
          messageSource{
            id
            type
          }
          appUser {
            id
            avatarUrl
            email
            kind
            displayName
          }
        }
        mainParticipant{
          id
          email
          avatarUrl
          displayName
          properties
        }
      }
    }
  }
`;

export const CURRENT_USER = `
  query CurrentUser {
    userSession {
      email
      avatarUrl
      firstName
      lastName
      createdAt
      id
      notifyConversation
    }
  }
`;

export const APP_USERS = `
query AppUsers($appKey: String!, $searchQuery: String!, $page: Int, $perPage: Int) {
  appUsers(appKey: $appKey, searchQuery: $searchQuery, page: $page, perPage: $perPage) {
    totalRecords,
    appUsers {
      id
      avatarUrl
      email
      city
      country
      lat
      lng
      displayName
      name
    }
  }
}
`;

export const GOOGLE_MAPS_KEY = `
query GoogleMapsKey($appKey: String!) {
  googleMapsKey(appKey: $appKey) {
    key
  }
}
`;

export const APP_USER = `
query AppUser($appKey: String!, $id: Int! ) {
  app(key: $appKey) {
    appUser(id: $id ) {
      id
      email
      avatarUrl
      lastVisitedAt
      referrer
      state
      ip
      city
      region
      country
      lat
      lng
      postal
      webSessions
      timezone
      browser
      browserVersion
      os
      osVersion
      browserLanguage
      online
      lang
      displayName
      name
      phone
      companyName
      companySize
      companyWebsite
      companyIndustry
      numberOfEmails
      properties
      emailStatus
      externalProfiles {
        id
        provider
        data
      }
    }
  }
}
`;

export const APP_USER_CONVERSATIONS=`
query Campaigns($appKey: String!, $id: Int!, $page: Int, $per: Int){
  app(key: $appKey ){
    name
    key
    appUser(id: $id){
      displayName
      conversations(page: $page, per: $per){

        collection{
          id
          key
          mainParticipant{
            id
            email
            avatarUrl
          }
          lastMessage{
            createdAt
            readAt
            appUser{
              email
              avatarUrl
              id
              kind
              displayName
            }
            message{
              blocks
              data
              state
              serializedContent
              htmlContent
              textContent
            }
          }
        }
      }
    }
  }
}
`;

export const APP_USER_VISITS=`
query AppUserVisits($appKey: String!, $id: Int!, $page: Int, $per: Int){
  app(key: $appKey ){
    name
    key
    appUser(id: $id){
      displayName
      visits(page: $page, per: $per){
        collection{
          url
          title
          osVersion
          os
          browserName
          browserVersion
          country
          createdAt
        }
        meta
      }
    }
  }
}
`;

export const CAMPAIGNS = `
query Campaigns($appKey: String!, $mode: String!, $page: Int, $perPage: Int){
  app(key: $appKey){
    upgradeToCreateCampaigns
    campaigns(mode: $mode, page: $page, perPage: $perPage){
      collection {
        name
        id
        type
        url
        serializedContent
        segments
        scheduledAt
        scheduledTo
        state
        paused
        subject
        timezone
        description
        textMessage
        contentType
        messageType
        htmlContent
        fromName
        fromEmail
        replyEmail
        counts
      }
      meta
    }
  }
}
`;

export const CAMPAIGN = `
query Campaign($appKey: String!, $mode: String!, $id: String!){
  app(key: $appKey){
    campaign(mode: $mode, id: $id){
      name
      id
      type
      url
      serializedContent
      segments
      scheduledAt
      scheduledTo
      scheduleType
      sendCriteria
      state
      paused
      subject
      description
      textMessage
      contentType
      messageType
      htmlContent
      timezone
      statsFields
      configFields
      hiddenConstraints
      fromName
      fromEmail
      replyEmail
      steps
      configTableColumns
      template
      counts
      companyAddress
      companyEmailAddress
      companyPhoneNumber
      htmlEmailContent
      goalSegment
      urlSegmentRule
      stayTime
    }
  }
}
`;

export const CAMPAIGN_METRICS = `
query Campaign($appKey: String!, $mode: String!, $id: String!, $page: Int, $per: Int, $type: String!){
  app(key: $appKey){
    campaign(mode: $mode, id: $id){
      name
      counts
      metrics(page: $page, per: $per, type: $type) {
        collection {
          action
          host
          data
          messageId
          email
          name
          state
          os
          referrer
          browserLang
          appUserId
          updatedAt
          createdAt
          type
        }
        meta
      }
    }
  }
}
`;

export const ASSIGNMENT_RULES = `
  query AssingmentRules($appKey: String!){
    app(key: $appKey){
      assignmentRules {
        id
        agent{
          id
          email
          avatarUrl
          emailStatus
        }
        state
        title
        conditions
      }
    }
  }
`;

export const ARTICLE_SETTINGS = `
  query App($appKey: String!){
    app(key: $appKey) {
      articleSettings{
        id
        color
        credits
        facebook
        googleCode
        headerImage
        linkedin
        logo
        siteDescription
        siteTitle
        subdomain
        twitter
        website
        langs
        translations
        availableLanguages
        defaultUrl
        domain
      }
    }
  }
`;

export const ARTICLES = `
  query App($appKey: String!, $page: Int!, $per: Int, $lang: String, $mode: String){
    app(key: $appKey) {
      articles(page: $page, per: $per, lang: $lang, mode: $mode){
        collection {
          id
          title
          slug
          content
          state
          description
          author{
            email
            avatarUrl
            id
            name
          }
          collection{
            slug
            title
            id
          }
        }
        meta
      }
    }
  }
`;

export const ARTICLES_UNCATEGORIZED = `
  query App($appKey: String!, $page: Int!, $per: Int){
    app(key: $appKey) {
      articlesUncategorized(page: $page, per: $per){
        collection {
          id
          title
          slug
          content
          state
          description
          author{
            email
            avatarUrl
            id
            name
          }
          collection{
            title
            id
          }
        }
        meta
      }
    }
  }
`;

export const ARTICLE = `
  query App($appKey: String!, $id: String!, $lang: String){
    app(key: $appKey) {
      article(id: $id, lang: $lang){
        id
        title
        slug
        content
        state
        description
        collection{
          slug
          title
          id
        }
        section{
          slug
          title
          id
        }
        author{
          email
          avatarUrl
          id
          name
        }
      }
    }
  }
`;


export const ARTICLE_COLLECTIONS = `
  query App($appKey: String!, $lang: String){
    app(key: $appKey){
      collections(lang: $lang) {
        slug
        id
        title
        description
        baseArticles {
          id
        }
      }
    }
  }
`;

export const ARTICLE_COLLECTION = `
  query ArticleCollections($appKey: String!, $id: String!, $lang: String){
    app(key: $appKey){
      collection(id: $id, lang: $lang) {
        id
        title
        slug
        description
      }
    }
  }
`;

export const ARTICLE_COLLECTION_WITH_SECTIONS = `
  query ArticleCollections($appKey: String!, $id: String!, $lang: String){
    app(key: $appKey){
      collection(id: $id, lang: $lang) {
        id
        slug
        title
        description
        baseArticles{
          id
          title
          slug
          author{
            id
            email
            avatarUrl
            name
          }
        }
        sections{
          slug
          id
          title
          description
          articles{
            id
            title
            slug
            author{
              id
              email
              avatarUrl
              name
            }
          }

        }
      }
    }
  }
`;


export const BOT_TASKS = `
  query BotTasks($appKey: String!, $lang: String, $mode: String){
    app(key: $appKey){
      upgradeToCreateBotTasks
      botTasks(lang: $lang, mode: $mode){
        title
        id
        state
      }
    }
  }
`;

export const BOT_TASK = `
  query BotTask($appKey: String!, $id: String!, $lang: String){
    app(key: $appKey){
      botTask(id: $id, lang: $lang){
        id
        state
        title
        segments
        scheduling
        urls
        statsFields
        paths{
          id
          steps
          title
          followActions
        }
      }
    }
  }
`;

export const BOT_TASK_METRICS = `
  query BotTask($appKey: String!, $id: String!, $lang: String,  $page: Int, $per: Int){
    app(key: $appKey){
      botTask(id: $id, lang: $lang){
        id
        counts
        statsFields
        metrics(page: $page, per: $per){
          collection {
            action
            host
            data
            messageId
            email
            appUserId
            updatedAt
            createdAt
          }
          meta
        }
      }
    }
  }
`;

export const DASHBOARD = `
  query Dashboard($appKey: String!, $range: Json!, $kind: String! )  {
    app(key: $appKey){
      dashboard(range: $range, kind: $kind)
    }
  }
`;

export const APP_PACKAGES = `
  query App($appKey: String!){
    app(key: $appKey) {
      appPackages{
        name
        state
        definitions
        icon
        description
      }
    }
  }
`;

export const EVENT_TYPES = `
  query App($appKey: String!){
    app(key: $appKey) {
      eventTypes
    }
  }
`;

export const OUTGOING_WEBHOOKS = `
  query App($appKey: String!){
    app(key: $appKey) {
      upgradeToAddWebhooks
      outgoingWebhooks
    }
  }
`;


export const EDITOR_APP_PACKAGES = `
  query App($appKey: String!){
    app(key: $appKey) {
      editorAppPackages{
        name
        state
        definitions
        editorDefinitions
        icon
        description
      }
    }
  }
`;

export const APP_PACKAGE_INTEGRATIONS = `
  query App($appKey: String!){
    app(key: $appKey) {
      upgradeForApiIntegrations
      appPackageIntegrations{
        id
        name
        settings
        definitions
        icon
        state
        description
        importStatus
        url
      }
    }
  }
`;


export const SES_IDENTITIES = `
  query App($appKey: String!){
    app(key: $appKey) {
      agentSesIdentities{
        id
        address
        identityType
        status
        agentId
        cnameRecords
        verificationRecord
      }
    }
  }
`;

export const AVAILABLE_PLANS = `
  query App($appKey: String!){
    app(key: $appKey) {
      availablePlans{
        id
        name
        amount
        seats
        stripePlanId
      }
      hasLifeTimeDeal
    }
  }
`;


export const BILLING_INFO = `
  query App($appKey: String!){
    app(key: $appKey) {
      billing
    }
  }
`;


export const IMPORT_USER_FIELDS = `
  query App($appKey: String!){
    app(key: $appKey) {
      appUserFields
    }
  }
`;

export const EXPORT_USERS = `
  query ExportUsers($appKey: String!, $usersId: [Int!]!, $allSelected: Boolean!){
    exportUsers(appKey: $appKey, usersId: $usersId, allSelected: $allSelected)
  }
`;

export const AUTO_REPLY = `
  query AutoReply($appKey: String!) {
    autoReply(appKey: $appKey) {
      id
      htmlContent
      serializedContent
      title
    }
  }
`;

export const AGENT_DISCOUNT = `
  {
    agentDiscount
  }
  `;
