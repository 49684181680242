import BackIcon from '@material-ui/icons/ArrowBackIos'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import Chip from '@material-ui/core/Chip'
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import InboxIcon from '@material-ui/icons/Inbox'
import { last } from 'lodash'
import MailIcon from '@material-ui/icons/Mail'
import Moment from 'react-moment'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { ThemeProvider } from 'emotion-theming'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router-dom'

import { AGENTS } from '../../graphql/queries'
import Avatar from '@material-ui/core/Avatar'
import ConversationEditor from './Editor.js'
import DraftRenderer from '../../textEditor/draftRenderer'
import EditorContainer from '../../textEditor/editorStyles'
import FilterMenu from './filterMenu'
import {
  getConversation, typingNotifier, insertComment,
  insertAppBlockComment, insertNote, setLoading, mailConversation,
  clearConversation, appendMessage, updateConversationState,
  updateConversationPriority, assignAgent,
} from '../../actions/conversation'
import graphql from '../../graphql/client'
import {
  GridElement, ChatContainer, HeaderTitle,
  ConversationButtons, FixedHeader, ChatMessageItem,
  ChatAvatar, StatusItem, ChatOverflow, MessageBox,
} from './styles'
import OptionMenu from './optionMenu'
import Progress from '../../shared/Progress'
import { setCurrentPage, setCurrentSection } from '../../actions/navigation'
import theme from '../../textEditor/theme'
import themeDark from '../../textEditor/darkTheme'
import { toCamelCase } from '../../shared/caseConverter'
import actioncable from "actioncable"


const EditorContainerMessageBubble = styled(EditorContainer)`
  // this is to fix the image on message bubbles
  .aspect-ratio-fill {
    display: none;
  }
  .aspectRatioPlaceholder.is-locked .graf-image {
    position: inherit;
  }
`

class ConversationContainerShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appUser: {},
      subscription: false,
      agent_typing: null
    }

    this.fetching = false
  }

  componentDidMount() {
    this.props.dispatch(
      clearConversation(() => {
        this.getMessages(this.scrollToLastItem);
      })
    )

    //this.getMessages( this.scrollToLastItem )

    this.props.dispatch(setCurrentPage('Conversations'))
    this.props.dispatch(setCurrentSection('Conversations'))
  }

  componentDidUpdate(prevProps, prevState) {
    const { conversation, match } = this.props

    if (prevProps.match && prevProps.match.params.id !== match.params.id) {
      this.props.dispatch(
        clearConversation(() => {
          this.getMessages(this.scrollToLastItem);
        })
      );
    }

    if (prevProps.conversation.collection && conversation.collection && conversation.collection.length != prevProps.conversation.collection.length) {
      this.scrollToLastItem()
    }
    this.defaultCableData = {
      app: this.props.appId,
      enc_data: "",
      session_id: this.props.conversation.mainParticipant && this.props.conversation.mainParticipant.sessionId,
      user_data: `{"domain": ${window.location.origin},"ws":"" ,"name":" " ,"email":${this.props.current_user.email}}`
    }
  }

  handleScroll = e => {
    let element = e.target
    let currentValue = element.scrollHeight - element.scrollTop
    let minMargin = currentValue - 10
    let maxMargin = currentValue + 10

    if (minMargin <= element.clientHeight && maxMargin >= element.clientHeight) {
      if (this.props.conversation.meta.next_page && !this.props.conversation.loading) {
        this.getMessages((item) => {
          this.scrollToItem(item)
        })
      }
    }
  }

  scrollToItem = item => {
    if (item) {
      this.refs.overflow.scrollTop = document.querySelector(`#message-id-${item}`).offsetHeight
    } else {
      this.scrollToLastItem()
    }
  }

  scrollToLastItem = () => {
    if (!this.refs.overflow) return

    this.refs.overflow.scrollTop = 0
  }

  getMessages = cb => {
    const opts = { id: this.props.match.params.id }
    const lastItem = last(this.props.conversation.collection)

    this.props.dispatch(
      getConversation(opts, () => { cb ? cb(lastItem ? lastItem.id : null) : null })
    )
  }

  typingNotifier = cb => {
    this.props.dispatch(
      typingNotifier(() => { cb ? cb() : null })
    )
    this.eventsSubscriber()
  }

  insertComment = (comment, cb) => {
    this.props.dispatch(
      insertComment(comment, () => { cb ? cb() : null })
    )
  }

  insertNote = (comment, cb) => {
    this.props.dispatch(
      insertNote(comment, () => { cb ? cb() : null })
    )
  }

  insertAppBlockComment = (comment, cb) => {
    this.props.dispatch(
      insertAppBlockComment(comment, () => { cb ? cb() : null })
    )
  }

  getAgents = cb => {
    graphql(AGENTS, { appKey: this.props.appId }, {
      success: (data) => { cb(data.app.agents) },
      error: (error) => {},
    })
  }

  setAgent = (id, cb) => {
    this.props.dispatch(assignAgent(id, cb))
  }

  updateConversationState = (state, cb) => {
    this.props.dispatch(
      updateConversationState(state, () => {
        cb ? cb(data.updateConversationState.conversation) : null
      })
    )
  }

  toggleConversationPriority = (e, cb) => {
    this.props.dispatch(
      updateConversationPriority(() => {
        cb ? cb(data.updateConversationState.conversation) : null;
      })
    )
  }

  cableDataFor =(opts)=>{
    return Object.assign({}, this.defaultCableData, opts)
  }

  eventsSubscriber = ()=>{
    let App = {
      cable: actioncable.createConsumer('/cable')
    }
    App.events = App.cable.subscriptions.create(this.cableDataFor({channel: "MessengerEventsChannel"}),
      {
        connected: ()=> {
          console.log("connected to events")
        },
        disconnected: ()=> {
          console.log("disconnected from events")
        },
        received: (data)=> {
          switch (data.type) {
            case "conversations:typing":
              this.handleTyping(data.data)
              break
            default:
              return
          }
        },
        notify: ()=>{
          console.log(`notify event!!`)
        },
        handleMessage: (message)=>{
          console.log(`handle event message`)
        }
      }
    )
  }

  handleTyping = (data)=>{
    console.log("data", data)
    if(this.props.conversation.key === data.conversation){
      this.setState({agent_typing: data}, ()=>{
        this.delayTimer = setTimeout(()=> {
          this.setState({agent_typing: null})
        }, 3000);
      })
    }
  }

  renderTyping = () => {
    if(this.props.conversation.key !== this.state.agent_typing.conversation)
      return;
    return (
      <div
        style={{
          fontSize: "0.7rem",
          color: "black",
          position: 'absolute',
          top: '5px',
          left: '40px',
        }}
      >
        {this.state.agent_typing.author.name} is typing...
      </div>
    );
  };

  urlify = text => {
    var urlRegex = /^(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])$/ig

    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>'
    })
  }

  renderMessage = (o, userOrAdmin) => {
    const key = `conversation-${this.props.conversation.id}-message-${o.id}`
    const content = JSON.parse(o.message.serializedContent)
    let htmlContent = ''

    if (!content) return

    if (content.blocks[0].type === 'button') {
      htmlContent = o.message.htmlContent.replace('undefined', content.blocks[0].text)
    }

    if (content.blocks[0].type === 'unstyled') {
      if (!o.message.htmlContent.includes('<a')) {
        htmlContent = this.urlify(o.message.htmlContent)
      } else {
        htmlContent = o.message.htmlContent;
      }
    }

    return (o.message.serializedContent && !['unstyled', 'button'].includes(content.blocks[0].type)) ? (
      <MessageBox>
        <DraftRenderer
          key={key}
          raw={JSON.parse(o.message.serializedContent)}
          html={o.message.htmlContent}
        />
      </MessageBox>
    ) : (
      <MessageBox>
        <div
          style={{transform: 'scaleY(1)', color: '#222'}}
          key={key}
          dangerouslySetInnerHTML={{
            __html: htmlContent,
          }}
        />
      </MessageBox>
    )
  }

  renderBlockRepresentation = block => {
    const { blocks, data } = block.message

    switch (blocks['type']) {
      case 'app_package':
        return (
          <div>
            <Typography variant='overline'>{blocks['appPackage']}</Typography>

            <br />

            <Typography variant={'caption'}>
              {data && (
                <span
                  dangerouslySetInnerHTML={{ __html: data.formattedText }}
                />
              )}
            </Typography>
          </div>
        )
      case 'ask_option':
        return <p>ask option</p>;
      case 'data_retrieval':
        return <p style={{ transform: 'scaleY(-1)' }}>data retrieval</p>;
    }
  }

  renderBlocks = o => {
    const block = toCamelCase(o)
    const { blocks, data } = block.message

    if (o.message.state != 'replied')
      return this.renderBlockRepresentation(block)

    const item = o.message.data
    if (!item) return 'replied'

    switch (item.element) {
      case 'button':
        return (
          <p>
            <strong>reply button:</strong>
            {item.label}
          </p>
        )
      default:
        if (blocks.type === 'app_package') {
          /*return Object.keys(o.message.data).map((k)=>{
            const val = o.message.data[k]
            if(typeof(val) != "string") return
            return <p>{k}: {val}</p>
          })*/

          return (
            <div>
              <Typography variant='overline'>{blocks['appPackage']}</Typography>

              <br />

              <Typography variant={'caption'}>
                {data && (
                  <span
                    dangerouslySetInnerHTML={{ __html: data.formattedText }}
                  />
                )}
              </Typography>
            </div>
          )
        }

        if (o.message.blocks.type === 'data_retrieval') {
          const dataList = Object.keys(o.message.data).map((k) => (
            <p style={{transform: 'scaleY(-1)'}}>
              {k}: {o.message.data[k]}
            </p>
          ))
          return (
            <React.Fragment>
              <strong>replied:</strong>
              {dataList}
            </React.Fragment>
          )
        } else {
          return <p>{JSON.stringify(o.message.data)}</p>
        }
    }
  }

  renderEventBlock = o => (
    <p style={{transform: 'scaleY(-1)'}}>
      {o.message.action} {o.message.data.name || o.message.data.email}
    </p>
  )

  mailConversation = (id, participantId) => {
    this.props.dispatch(mailConversation({ id: id, participantId: participantId }))
  }

  render() {
    const { app, conversation, history, loading } = this.props
    const participant = conversation.mainParticipant ? conversation.mainParticipant : null

    return (
      <GridElement grow={3} style={{ overflow: 'hidden' }}>
        {
          conversation.id ?
            <ChatContainer>
              <FixedHeader style={{ height: '77px' }}>
                <Hidden smUp>
                  <IconButton onClick={() => history.push(`/apps/${app.key}/conversations`) }>
                    <BackIcon />
                  </IconButton>
                </Hidden>

                <HeaderTitle>
                  {
                    participant &&
                      <Avatar
                        onClick={() => props.showUserDrawer(participant.id)}
                        style={{ width: 40, height: 40 }}
                        alt={participant.email}
                        src={participant.avatarUrl}
                      />
                  }

                  <span>
                    {
                      conversation.mainParticipant ?
                        <b style={{ fontSize: 18, textTransform: 'capitalize' }}>{conversation.mainParticipant.displayName}</b>
                        : null
                    }
                  </span>
                </HeaderTitle>

                <ConversationButtons>
                  <OptionMenu
                    getAgents={this.getAgents.bind(this)}
                    setAgent={this.setAgent.bind(this)}
                    conversation={this.props.conversation}
                  />

                  {
                    conversation.state != 'closed' ?
                    <Tooltip title='Close conversation'>
                      <IconButton onClick={() => { this.updateConversationState('close') }}>
                        <CheckIcon />
                      </IconButton>
                    </Tooltip>
                    : null
                  }

                  {
                    conversation.state != 'opened' ?
                      <Tooltip title='Reopen conversation'>
                        <IconButton onClick={() => { this.updateConversationState('reopen') }} >
                          <InboxIcon />
                        </IconButton>
                      </Tooltip>
                      : null
                  }

                  {
                    conversation.state != 'closed' ?
                    <Tooltip title='mail conversation'>
                      <IconButton onClick={() => this.mailConversation(conversation.id, conversation.mainParticipant.id)}>
                        <MailIcon />
                      </IconButton>
                    </Tooltip>
                    : null
                  }

                  <Tooltip title={!conversation.priority ? 'Priorize conversation' : 'Remove priority'}>
                    <IconButton onClick={this.toggleConversationPriority}>
                      <PriorityHighIcon color={conversation.priority ? 'primary' : 'inherit'} />
                    </IconButton>
                  </Tooltip>
                </ConversationButtons>
              </FixedHeader>

              <div
                className='box-container'
                style={{ paddingTop: '10px', backgroundColor: '#FCF9F6', position: 'relative' }}
              >
                <ChatOverflow
                  className='overflow'
                  ref='overflow'
                  onScroll={this.handleScroll}
                >
                  {
                    conversation.collection.map((o, i) => {
                      const isReplied = o.message.state === 'replied'
                      const userOrAdmin = !isReplied && o.appUser && o.appUser.kind === 'agent' ? 'admin' : 'user'
                      const appuserId = this.props.conversation.mainParticipant.id
                      return (
                        <MessageItemWrapper
                          key={`message-item-${this.props.conversation.key}-${o.id}`}
                          data={o}
                          events={this.props.events}
                          conversation={this.props.conversation}
                          email={this.props.current_user.email}
                        >
                          <ChatMessageItem
                            id={`message-id-${o.id}`}
                            message={o}
                            className={userOrAdmin}
                            style={o.privateNote ? { backgroundColor: '#fefad2'} : {}}
                          >
                            {
                              o.appUser && (
                                <ChatAvatar
                                  onClick={(e) => this.props.showUserDrawer(appuserId)}
                                  className={userOrAdmin}
                                >
                                  <div className='avatarContainer'>
                                    {
                                      (!isReplied && conversation.mainParticipant.id !== o.appUser.id) &&
                                      <b>{o.appUser.displayName}</b>
                                    }
                                    <img src={!isReplied ? o.appUser.avatarUrl : conversation.mainParticipant.avatarUrl} />
                                    {
                                      (isReplied || conversation.mainParticipant.id === o.appUser.id) &&
                                      <b>{o.appUser.displayName}</b>
                                    }
                                  </div>

                                </ChatAvatar>
                              )
                            }

                            <ThemeProvider
                              theme={ userOrAdmin === 'admin' ? o.privateNote ? theme : themeDark : theme }
                              style={{ marginTop: 40 }}
                            >
                              <EditorContainerMessageBubble>
                                { o.message.blocks ? this.renderBlocks(o, userOrAdmin) : o.message.action ? this.renderEventBlock(o, userOrAdmin) : this.renderMessage(o, userOrAdmin) }
                              </EditorContainerMessageBubble>
                            </ThemeProvider>

                            <StatusItem>
                              {
                                userOrAdmin != 'admin'?
                                  <Moment className="chat-status-user" fromNow ago>{o.createdAt}</Moment>
                                  : <span className={'chat-status-admin'}>
                                    {' - '}
                                    {
                                      o.readAt ? (
                                        <span>
                                          {'seen '}
                                          {/*<Moment fromNow>
                                                            {o.readAt}
                                                          </Moment>*/}
                                        </span>
                                      ) : o.privateNote ? (
                                        <span style={{background: 'lemonchiffon'}}>"NOTE"</span>
                                      ) : (
                                        <span>not seen</span>
                                      )
                                    }
                                  </span>
                              }
                            </StatusItem>
                          </ChatMessageItem>
                        </MessageItemWrapper>
                      )
                    })
                  }
                  {this.state.agent_typing && this.renderTyping()}

                  { loading ? <Progress /> : null }
                </ChatOverflow>
              </div>
              <div className='input'>
                <ConversationEditor
                  data={{}}
                  app={this.props.app}
                  insertAppBlockComment={this.insertAppBlockComment}
                  insertComment={this.insertComment}
                  typingNotifier={this.typingNotifier}
                  insertNote={this.insertNote}
                />
              </div>
            </ChatContainer>
            : <Progress />
        }
      </GridElement>
    )
  }
}

class MessageItemWrapper extends Component {
  componentDidMount() {
    // console.log(this.props.data.readAt ? "yes" : "EXEC A READ HERE!")
    // mark as read on first render
    if (!this.props.data.readAt) {
      //console.log(this.props.email)
      this.props.events &&
        this.props.events.perform(
          "receive_conversation_part",
          Object.assign(
            {},
            {
              conversation_id: this.props.conversation.id,
              message_id: this.props.data.id,
            },
            { email: this.props.email }
          )
        );
      /*App.conversations.perform("receive",
        Object.assign({}, this.props.data, {email: this.props.email})
      )*/
    }
  }
  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

function mapStateToProps(state) {
  const { auth, app, conversation, app_user, current_user } = state;
  const { isAuthenticated } = auth;
  const { messages, loading } = conversation;
  const { jwt } = auth;

  return {
    jwt,
    conversation,
    current_user,
    messages,
    loading,
    app_user,
    app,
    isAuthenticated,
  };
}

export default withRouter(connect(mapStateToProps)(ConversationContainerShow));
