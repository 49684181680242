import React, { Component } from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";

import graphql from "../graphql/client";
import { CREATE_DIRECT_UPLOAD, INVITE_AGENTS } from "../graphql/mutations";

import { ColorPicker } from "../shared/FormFields";

import ContentHeader from "../components/ContentHeader";

import {
  getFileMetadata,
  directUploadWithProgress,
} from "../shared/fileUploader";
import { setCurrentPage, setCurrentSection } from "../actions/navigation";
import Guide from "../components/guide";
import ForumSharpIcon from "@material-ui/icons/ForumSharp";

import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from '@material-ui/icons/Delete';
import { successMessage } from "../actions/status_messages";
import AddIcon from '@material-ui/icons/Add';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ConversationHeader from '../components/ConversationHeader'
import Typography from '@material-ui/core/Typography';
import { SmileIcon } from '../../client_messenger/icons'
import styled from '@emotion/styled'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import code_icon from '../../../assets/images/code.png'
import group_icon from '../../../assets/images/group.png'
import group_invite_icon from '../../../assets/images/GroupInvite.png'
import upSendLogo from '../../../assets/images/logo/Uplogo.png'

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    top: 16,
    right: 32,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    margin: "50px!important",
    marginTop: "35px!important",
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  appSettingTitleContainer: {
    marginTop: 52,
    marginLeft: 44,
    backgroundColor: "transparent",
    color: "#000000",
    width: "calc(100% - 140px)",
  },
  appSettingTitle: {
    fontSize: 42,
    fontWeight: "bold",
  },
  appSettingMenu: {
    marginBottom: 10,
  },
  tabIndicator: {
    display: "none",
  },
  tabButton: {
    padding: "0 20px",
    "&:hover": {
      backgroundColor: "#FAF6F1",
    },
  },
  tabSelectedButton: {
    backgroundColor: "#FAF6F1",
  },
  imageButton: {
    backgroundColor: "#000000",
    color: "#ffffff",
    width: 150,
    height: 50,
  },
  submitButton: {
    backgroundColor: "#FFD300",
    marginTop: 25,
    color: "#000000",
    width: 250,
    height: 50,
  },
  mainContentHeader: {
    display: "none",
  },
  field: {
    "& input": {
      boxShadow: "none",
    },
    "& .MuiOutlinedInput-root":{
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: '#000',
    }
  },
  colorField: {
    "& .MuiOutlinedInput-adornedEnd": {
      borderRadius: 8,
    },
    "& input": {
      boxShadow: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: '#000',
    }
  },
  tabButton: {
    borderRadius: 10,
    margin: 0,
    backgroundColor: 'transparent',
  },
  tabSelectedButton: {
    backgroundColor: '#FAF6F1',
  },
  tabRoot: {
    "& .MuiTabs-scroller": {
      position: 'unset'
    },
  },
  belowTag: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    fontSize: 13,
    justifyContent: 'center',
    width: '94%',
    margin: '10px 8px',
    background: 'rgb(255, 255, 255) none repeat scroll 0% 0%',
    padding: '5px',
    borderRadius: 8,
    zIndex: 1000,

    "& a": {
      textDecoration: 'none',
      color: '#000',
      display: 'flex',
    },
    "& img": {
      width: 13,
      height: 15,
      marginRight: 5,
    },
    "& span": {
      color: '#777',
    },
    "& strong": {
      paddingLeft: 5,
    }
  }
});

const Image = styled.div`
  width: 54px;
  height: 52px;

  ${(props)=>{
    return props.image ?
    `background-image: url(${props.image});` : ''
  }}

  background-size: 310px 310px, cover;
`

const PatternButton = styled.button`
  padding: 0px;
  margin: 3px;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 6px;
  :focus {
    outline: #FFB600;
    border: 2px solid #FFB600;
  }
`

const MessengerTopBack = styled.div`
  &:before {
    content: "";
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    ${(props)=>{
      return props.pattern ?
      `
        opacity: 0.38;
        background-image: url(${props.pattern});
      ` : ''
    }}
    background-size: cover;
    pointer-events: none;
  }
`

class GuideContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 2,
      tabs: [
        { name: "What you can do with upsend", key: 1 },
        { name: "Customize your live chat", key: 2 },
        { name: "Add the chat box to your website", key: 3 },
        { name: "Invite your team to join you in Upsend", key: 4 },
      ],
      teamMembers: [""],
      tabValue: 0,
      primaryColor: '#000',
      btnsColor: '#000',
      badgeColor: '#FFB600',
      pattern: null,
      copySuccess: false,
    };
  }

  componentDidMount() {
    //this.fetchApp()
    this.props.dispatch(setCurrentPage("guide"));
    this.props.dispatch(setCurrentSection("guide"));
  }

  url = () => {
    return `/apps/${this.props.match.params.appId}.json`;
  };

  // Form Event Handlers
  update = (data) => {
    this.props.dispatch(
      this.props.updateApp(data.app, (d) => {
        console.log(d);
      })
    );
  };

  uploadHandler = (file, kind) => {
    getFileMetadata(file).then((input) => {
      graphql(CREATE_DIRECT_UPLOAD, input, {
        success: (data) => {
          const {
            signedBlobId,
            headers,
            url,
            serviceUrl,
          } = data.createDirectUpload.directUpload;
          const progressCallback = (event) => {
            if (event.lengthComputable) {
              var percentComplete = event.loaded / event.total;
              this.setState({ completed: parseInt(percentComplete * 100) });
            }
          };
          directUploadWithProgress(
            url,
            JSON.parse(headers),
            file,
            progressCallback
          ).then(() => {
            let params = {};
            params[kind] = signedBlobId;
            this.update({ app: params });
          });
        },
        error: (error) => {
          console.log("error on signing blob", error);
        },
      });
    });
  };

  handleTabChange = (e, i) => {
    this.setState({ tabValue: i });
  };

  selectItem = (index) => {
    this.setState({ selected: index });
  };

  handleInputChange = (index, e) => {
    const { teamMembers } = this.state;
    const values = [...teamMembers];
    values[index] = e.target.value;
    this.setState({ teamMembers: values });
    console.log('teamMembers', values)
  };
  handleAddFields = () => {
    const { teamMembers } = this.state;
    const values = [...teamMembers];
    values.push( "" );
    this.setState({ teamMembers: values });
  };

  handleRemoveFields = index => {
    const { teamMembers } = this.state;
    const values = [...teamMembers];
    values.splice(index, 1);
    this.setState({ teamMembers: values });
  };

  inviteAgents = () => {
    graphql(INVITE_AGENTS, {
      appKey: this.props.app.key,
      agentEmails: this.state.teamMembers
    }, {
      success: (data)=>{
        this.setState({ teamMembers: [] })
        if(data.inviteAgents.data)
          this.props.dispatch(successMessage("Agents Invited"))
      },
      error: (errors)=>{
        console.log("errors", errors)
      }
    })
  }

  setupScript = () => {
    const { app } = this.props;
    const hostname = window.location.hostname
    const port = window.location.port ? ":"+window.location.port : ""
    const secure = window.location.protocol === "https:"
    const httpProtocol = window.location.protocol
    const wsProtocol = secure ? "wss" : "ws"
    const hostnamePort = `${hostname}${port}`

    const code = `
      <script>\n
        (function(d,t) {\n
          var g=d.createElement(t),s=d.getElementsByTagName(t)[0];\n
          g.src="${httpProtocol}//${hostnamePort}/embed.js"\n
          s.parentNode.insertBefore(g,s);\n
          g.onload=function(){\n
            new window.UpsendMessengerEncrypted({\n
              domain: '${httpProtocol}//${hostnamePort}',\n
              ws:  '${wsProtocol}://${hostnamePort}/cable',\n
              app_id: "${app ? app.key : ''}",\n
              lang: "USER_LANG_OR_DEFAULTS_TO_BROWSER_LANG",\n
              name: "USER_NAME (YOUR ATTRIBUTES)",\n
              email: "EMAIL (YOUR ATTRIBUTES)",\n
            })\n
          }\n
        })(document,"script");\n
      </script>\n
    `
    return code;
  }

  selectPattern = (pattern) => {
    this.setState({pattern: pattern ? pattern.url : null})
  }

  copyCodeToClipboard = () => {
    const el = this.textArea
    el.select()
    document.execCommand("copy")
    this.setState({copySuccess: true})
  }

  renderContent = (i) => {
    const { classes, app } = this.props;
    const { teamMembers, tabValue } = this.state;

    function handleChange(event, newValue) {
      setValue(newValue);
    }

    const names = [
      "email-pattern",
      "5-dots",
      "greek-vase",
      "criss-cross",
      "chevron",
      "blue-snow",
      "let-there-be-sun",
      "triangle-mosaic",
      "dot-grid",
      "so-white",
      "cork-board",
      "hotel-wallpaper",
      "trees",
      "beanstalk",
      "fishnets-and-hearts",
      "lilypads",
      "floor-tile",
      "beige-tiles",
      "memphis-mini",
      "christmas-colour",
      "intersection",
      "doodles",
      "memphis-colorful"

     ]

     const pattern_base_url = "https://www.toptal.com/designers/subtlepatterns/patterns/"

     const patterns = names.map((o) => {
       return {name: o, url: pattern_base_url + o + ".png"}
     })

    switch (i) {
      case 1:
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginRight: 90 }}>
              <Typography variant="body1" style={{ fontSize: 13, marginBottom: 40, width: '60%' }}>
                Here, your relation with your customers will be improved. The next steps will help you to set up UpSend for your business, and what you can do.
              </Typography>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    width: 142,
                    height: 60,
                    backgroundColor: "#FFD300",
                    color: "#000",
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
            <div style={{ width: 420 }}>
            <iframe width="400" height="345" src="https://www.youtube.com/embed/HpxBU-Xy11s">
            </iframe>
            </div>
          </div>
        );
      case 2:
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginRight: 90 }}>
              <div
                style={{ marginTop: 35, marginBottom: 35, lineHeight: "32px" }}
              >
                <Typography variant="body1" style={{ fontSize: 13, marginBottom: 40, width: '60%' }}>
                  From colors to languages, to positions; Customize your chat box to fit with your business brand.
                </Typography>
              </div>
              <div style={{ fontSize: 15}}>
                <span>Add my company logo</span>
              </div>
              <div style={{ marginTop: 20, marginBottom: 20, width: 153, height: 50 }}>
                <Button variant="contained" color="primary">
                  Upload logo
                </Button>
              </div>
              <div
                style={{
                  fontSize: 15,
                  marginTop: 30,
                  marginBottom: 10,
                }}
              >
                <span>Choose your background color for your message</span>
              </div>
              <div style={{ marginTop: 20, marginBottom: 20, maxWidth: 350 }}>
                <ColorPicker
                  color="#000"
                  className={classes.colorField}
                  colorHandler={(hex)=> {
                    this.setState({ primaryColor: hex })
                  }}
                  />
              </div>
              <div
                style={{
                  fontSize: 15,
                  marginTop: 30,
                  marginBottom: 10,
                }}
              >
                <span>Choose a color for buttons and links</span>
              </div>
              <div style={{ marginTop: 20, marginBottom: 20, maxWidth: 350 }}>
                <ColorPicker
                  color="#000"
                  className={classes.colorField}
                  colorHandler={(hex)=> {
                    this.setState({ btnsColor: hex })
                  }}
                />
              </div>
              <div
                style={{
                  fontSize: 15,
                  marginTop: 30,
                  marginBottom: 10,
                }}
              >
                <span>Choose a color for badge</span>
              </div>
              <div style={{ marginTop: 20, marginBottom: 20, maxWidth: 350 }}>
                <ColorPicker
                  color="#000"
                  className={classes.colorField}
                  colorHandler={(hex)=> {
                    this.setState({ badgeColor: hex })
                  }}
                />
              </div>
              <Grid container direction={'row'}>

                <Grid item  xs={12} sm={7} >

                  <Typography variant={"h6"} className={classes.heading} style={{ marginBottom: 10}}>
                    Choose a pattern From
                    <Link target={"blank"} href={"https://www.toptal.com/designers/subtlepatterns/"}>
                    Subtle patterns
                    </Link>
                  </Typography>

                  <PatternButton onClick={(e) => this.selectPattern(null)}>
                    <Image />
                  </PatternButton>

                  {
                    patterns.map((o)=>{
                      return <PatternButton onClick={(e)=> this.selectPattern(o)}>
                                <Image image={o.url} />
                              </PatternButton>
                    })
                  }

                </Grid>
              </Grid>
              <div style={{ marginTop: 60, marginBottom: 10 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    width: 142,
                    height: 60,
                    backgroundColor: "#FFD300",
                    color: "#000",
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
            <div style={{ width: 420, position: 'relative', marginBottom: 30 }}>
              <div
                style={{
                  maxHeight: 740,
                  width: 400,
                  backgroundColor: "rgb(250, 246, 241)",
                  marginBottom: 10,
                  borderRadius: 5,
                  height: "calc(80vh - 50px)",
                  border: "solid 1px",
                  position: 'relative'
                }}
              >
                <MessengerTopBack
                  style={{
                    backgroundColor: this.state.primaryColor,
                    color: "#FFF",
                    textAlign: "center",
                    fontSize: 25,
                    fontWeight: "bold",
                    paddingTop: 40,
                    paddingBottom: 10,
                    position: 'relative',
                  }}
                  pattern={this.state.pattern}
                >
                  Hi, Brian
                </MessengerTopBack>
                <MessengerTopBack
                  style={{
                    backgroundColor: this.state.primaryColor,
                    color: "#FFF",
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                    paddingTop: 20,
                    paddingBottom: 80,
                    position: 'relative',
                  }}
                  pattern={this.state.pattern}
                >
                  We help your business grow by
                  <br />
                  connecting you to your customers.
                </MessengerTopBack>
                <div
                  style={{
                    width: "calc(100% - 40px)",
                    marginLeft: 20,
                    backgroundColor: "#fff",
                    transform: "translateY(-55px)",
                    padding: 20,
                    borderRadius: 10,
                  }}
                >
                  <div style={{ fontSize: 15 }}>Your Conversation</div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <Avatar
                        style={{ width: 32, height: 32, marginRight: 10 }}
                        src="../../../assets/user1.jpg"
                      />
                      <Avatar
                        style={{ width: 32, height: 32, marginRight: 10 }}
                        src="../../../assets/user2.jpg"
                      />
                      <Avatar
                        style={{ width: 32, height: 32, marginRight: 10 }}
                        src="../../../assets/user3.jpg"
                      />
                    </div>
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button variant="contained" color="primary" style={{ background: this.state.btnsColor }}>
                        <ForumSharpIcon
                          style={{
                            marginRight: 10,
                            fontSize: 18,
                            width: 18,
                            height: 18,
                          }}
                        />
                        Start a conversation
                      </Button>
                      <span style={{ lineHeight: "35px" }}>See previous</span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar
                          alt="Remy Sharp"
                          style={{
                            borderRadius: "50%",
                            border: "solid 1px black",
                          }}
                          src="/assets/user3.jpg"
                        />
                      </StyledBadge>
                      <div
                        style={{
                          marginLeft: 20,
                          maxWidth: 200,
                          lineHeight: "12px",
                          fontSize: 12,
                        }}
                      >
                        <small>Bot</small>
                        <br />
                        <small>
                          Ok, let us know if you need something and An agent
                          will reply as soon as possible
                        </small>
                      </div>
                      <div>
                        <small style={{ fontSize: 9 }}>6 mins ago</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: -45,
                    width: "calc(100% - 40px)",
                    marginLeft: 20,
                    backgroundColor: "#FFF",
                    padding: 20,
                    borderRadius: 10,
                  }}
                >
                  <div>Find answers in our article's repository</div>
                  <FormControl
                    variant="outlined"
                    style={{
                      border: "solid 1px black",
                      borderRadius: 5,
                      marginTop: 20,
                    }}
                  >
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment>
                          <IconButton
                            edge="end"
                            style={{
                              width: 53,
                              height: 53,
                              borderRadius: 5,
                              backgroundColor: "#000",
                              color: "#FFF",
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={0}
                    />
                  </FormControl>
                </div>
                <div className={classes.belowTag}>
                  <a href="https://upsend.io/" target="_blank">
                    <img src={ upSendLogo } />
                    <span>We use </span>
                    <strong> Upsend</strong>
                  </a>
                </div>
              </div>
              <div style={{ height: "65px", width: "70px", position: 'absolute', right: '-5px' }}>
                <SmileIcon
                  palette={{badge: this.state.badgeColor}}
                  style={{
                    height: "43px",
                    width: "36px",
                    margin: "8px 0px",
                  }}
                />
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div style={{ display: "flex", flexDirection: 'column' }}>
            <div style={{ marginRight: 90 }}>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 35,
                  lineHeight: "32px",
                }}
              >
                <Typography variant="body1" style={{ fontSize: 13, marginBottom: 40, width: '60%' }}>
                  Setting up the chat box and start chat with people when they are on your websites.
                </Typography>

                <div style={{ display: "flex", paddingTop: 20, flex: 1 }}>
                <Tabs
                  value={tabValue}
                  onChange={this.handleTabChange}
                  className={classes.tabRoot}
                  variant="fullWidth"
                  style={{ width: '50%', border: '1px solid #E5E5E5', borderRadius: 8 }}
                >
                  <Tab
                    classes={{root: classes.tabButton, selected: classes.tabSelectedButton}}
                    label={ (<div
                    style={{
                      height: 157,
                      textAlign: 'left',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <div>
                      <img src={code_icon} />
                    </div>

                    <div style={{fontSize: 18, fontWeight: 600}}>
                      <span>With code</span>
                    </div>
                    <div style={{fontSize: 15}}>
                      <span>javascript, rails</span>
                    </div>
                  </div>)} />
                  <Tab
                  classes={{root: classes.tabButton, selected: classes.tabSelectedButton}}
                  label={(
                    <div
                      style={{
                        height: 157,
                        width: "80%",
                        textAlign: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      <div>
                        <img src={group_icon} />
                      </div>

                      <div style={{fontSize: 18, fontWeight: 600}}>
                        <span>One-off</span>
                      </div>
                      <div style={{fontSize: 15}}>
                        <span>Only send to people who match your rules right now</span>
                      </div>
                    </div>
                  )} />
                </Tabs>
                </div>
              </div>
            </div>
            <div style={{ width: '93%' }}>
              {tabValue === 0 && (
                <div style={{ padding: 30, boxShadow: '1px 3px 10px -3px rgba(100, 100, 100, 0.2)', borderRadius: 8 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 20 }}>
                    <Typography variant="body1" style={{ fontSize: 13 }}>
                      {`Put the following script on the before </body> tag on all pages you want chat appear on it!`}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.copyCodeToClipboard}
                      style={{
                        padding: '0px 6px',
                        height: 30,
                      }}
                    >
                      { this.state.copySuccess ? 'Copied!' : 'Copy the snippet' }
                    </Button>
                  </div>
                  <div style={{ fontSize: 13, margin: '15px auto 10px 0px', backgroundColor: '#F8F8FD', padding: '40px 20px', borderRadius: 8 }}>
                    <textarea
                      ref={(textarea) => this.textArea = textarea}
                      value={this.setupScript()}
                      style={{position: 'relative', zIndex: -1 }}
                    />
                    <pre style={{ lineHeight: '12px' }}>{this.setupScript()}</pre>
                  </div>
                </div>
              )}
              {tabValue === 1 && <div style={{ margin: '20px auto' }}>Coming Soon</div>}
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  width: 142,
                  height: 60,
                  backgroundColor: "#FFD300",
                  color: "#000",
                  marginTop: 30,
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        );
      default:
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginRight: 90 }}>
              <div style={{ fontSize: 17, marginBottom: 15 }}>
                <span>Now, it's time to invite your team to help you.</span>
              </div>
              <Typography variant="body1" style={{ fontSize: 13, marginBottom: 40, width: '60%' }}>
                Now, it's time to invite your team to help you.
              </Typography>
              { teamMembers.map((email, index) => {
                return (
                <div>
                  <TextField
                    id="outlined-search"
                    label="Email"
                    placeholder="teammate@domain.com"
                    className={classes.field}
                    margin="normal"
                    variant="outlined"
                    value={email}
                    onChange={e => this.handleInputChange(index, e)}
                  />
                  { index !== 0 && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => this.handleRemoveFields(index)}
                      style={{
                        height: 50,
                        color: "#000",
                        marginTop: 15,
                        border: 0,
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </div>
              )})}
              <div style={{ display: "flex", flexDirection: 'column', marginTop: 20 }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={this.handleAddFields}
                  style={{
                    width: 200,
                    height: 50,
                    color: "#000",
                    border: 0,
                    marginBottom: 25,
                    backgroundColor: "#FAF6F1",
                  }}
                >
                  <AddIcon style={{ marginRight: 5, width: 20 }} />
                  Add teammate
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.inviteAgents}
                  style={{
                    width: 210,
                    height: 60,
                    backgroundColor: "#FFD300",
                    color: "#000",
                    marginTop: 20,
                  }}
                >
                  Send and Continue
                </Button>
              </div>
            </div>
            <div style={{ width: 420 }}>
              <img src={ group_invite_icon } style={{ width: '100%' }} />
            </div>
          </div>
        );
    }
  };

  render() {
    const { classes, app } = this.props;
    const { tabs, selected } = this.state;
    const currentHeader = {
      id: "Upsend start guide",
      label: `${app.name} Guide`,
      url: `/apps/${app.key}/guide`,
      children: [
      ],
    }

    return (
      <div>
        {this.props.app ? (
          <React.Fragment>
            <ConversationHeader history={this.props.history} appId={app.key} category={currentHeader} />
            <div
              style={{ margin: '0 70px', paddingTop: 90 }}
            >
              <div style={{color: '#777777'}}>
                <Typography variant="body1">Alex here,</Typography>
                <Typography variant="body1">I’m here to help you to step you account on UpSend.</Typography>
                <Typography variant="body1">Let’s go 💪🏼!</Typography>
              </div>
              <div
                style={{ margin: '50px 0', border: "solid 1px rgba(0,0,0,0.1)", borderRadius: 8 }}
              >
                {tabs.map((tab) => {
                  const checked = selected === tab.key;
                  return (
                    <Guide
                      {...tab}
                      onSelect={() => this.selectItem(tab.key)}
                      selected={checked}
                    >
                      {checked && this.renderContent(tab.key)}
                    </Guide>
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}



GuideContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles, { withTheme: true })(GuideContainer);
