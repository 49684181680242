import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { connect } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import { filter } from 'lodash'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Moment from 'react-moment'
import Paper from '@material-ui/core/Paper'
import React, { Component } from 'react'
import styled from '@emotion/styled'

import CampaignChart from './charts/charts.js'
import Count from './charts/count'
import { Datatable } from '../components/datatable/'
import graphql from '../graphql/client'
import gravatar from '../shared/gravatar'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'

const PieContainer = styled.div`
  padding: 0.75em;
  display: grid;
  grid-template-columns: repeat(4, 200px);
  grid-gap: 10px;
  width: 100vw;
  margin: 25px 0 18px 0;
  overflow: auto;
`

const PieItem = styled.div`
  height: 200px;
`

const NameWrapper = styled.span`
  display: flex;
  align-items: center;
`

const AvatarWrapper = styled.div`
  margin-right: 8px;
`

const styles = (theme) => ({
  cardPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    boxShadow: 'none',
    padding: '10px 40px',
    border: '1px solid #E5E5E5',
    borderRadius: 8,
    marginRight: 10,
    boxShadow: '1px 3px 10px -3px rgba(100, 100, 100, 0.2)',
  },

  btnText: {
    fontSize: '12px',
    lineHeight: '1',
    fontWeight: '400',
    textAlign: 'left',
    padding: '4px'
  },

  statCount: {
    lineHeight: '1',
    textAlign: 'left',
    paddingLeft: '4px'

  },
  stateButton: {
    boxShadow: '0px 0px 8px darkgrey',
    backgroundColor: "white",
    color: "black",
    padding: '8px 30px',
    opacity: '0.6',
    '&:hover': {
      backgroundColor: "white",
      color: "black"
    }
  }
})

class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collection: [],
      meta: {},
      counts: {},
      loading: false,
      visible_cols: ["email", "action", "host", "createdAt", "data"],
      selected_rows: [],
      clickedAverage: 0,
      failedAverage: 0,
      openedAverage: 0,
      repliedAverage: 0,
      clickedCount: 0,
      failedCount: 0,
      openedCount: 0,
      repliedCount: 0,
      goalAverage: 0,
      sentCount: 0,
      page: 1,
      selectedType: 'all'
    };
    this.getData = this.getData.bind(this);
  }

  createHead = (withWidth) => {
    return [
      {
        field: "action",
        options: {
          filter: false,
          render: (value, tableMeta, updateValue) => {
            return this.renderLozenge(value);
          },
        },
      },

      {
        field: "email",
        options: {
          filter: false,
        },
      },
      {
        field: "host",
        options: {
          filter: false,
        },
      },

      {
        field: "created_at",
        options: {
          filter: false,
        },
      },

      {
        field: "data",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <p>cccc</p>;
          },
        },
      },
    ];
  };

  renderLozenge = (item) => {
    let kind = "default";

    switch (item) {
      case "click":
        kind = "success";
        break;
      case "viewed":
        kind = "inprogress";
        break;
      case "close":
        kind = "removed";
        break;
      default:
        break;
    }

    return <div appearance={kind}>{item}</div>;
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.getData()
  }

  calculateStats = (metrics) => {
    let counts = this.props.data.counts
    let totalCount = counts['send'] || 1
    let openedCount = counts['open'] || 0
    let clickCount = counts['click'] || 0
    let failedCount = counts['bounce'] || 0

    this.setState({
      clickedAverage: Math.round((clickCount/totalCount)*100),
      failedAverage: Math.round((failedCount/totalCount)*100),
      openedAverage: Math.round((openedCount/totalCount)*100),
      clickedCount: clickCount,
      failedCount: failedCount,
      openedCount: openedCount,
      sentCount: (counts['send'] || 0)
    })
  }

  getData = () => {
    this.props.getStats({
      appKey: this.props.app.key,
      mode: this.props.mode,
      id: this.props.match.params.id,
      page: this.state.page || 1,
      type: this.state.selectedType || 'all'
    }, data => {
      const { counts, metrics } = data

      this.setState({
        meta: metrics.meta,
        collection: metrics.collection
      })

      this.calculateStats(metrics)
    })
  }

  handleNextPage = page => {
    this.setState((prevState) => ({ page: page }), () => {
      this.getData()
    })
  }

  getRateFor = (type) => {renderTabcontent
    renderTabcontent
    return type.keys.map((o) => ({
      id: o.name,
      label: o.name,
      value: this.state.counts[o.name] || 0,
      color: o.color,
    }))
  }

  handleFiltering = event => {
    this.setState({ selectedType: event }, () => {
      this.getData()
    })
  }

  render() {
    const { classes } = this.props
    const columns = [{
        name: 'Name',
        selector: 'email',
        sortable: true,
        width: '260px',
        cell: (row) => row.name
      }, {
        name: 'Type',
        selector: 'type',
        sortable: true,
        width: '260px',
        cell: (row) => row.type
      }, {
        name: 'Email',
        selector: 'email',
        sortable: true,
        width: '260px',
        cell: (row) => row.email
      }, {
        name: 'Status',
        selector: 'status',
        sortable: true,
        width: '260px',
        cell: (row) => row.action
      }, {
        name: 'Browser lang',
        selector: 'online',
        sortable: true,
        width: '260px',
        cell: (row) => row.browserLang
      }, {
        name: 'Referrer',
        selector: 'online',
        sortable: true,
        width: '260px',
        cell: (row) => row.referrer
      }, {
        name: 'OS',
        selector: 'online',
        sortable: true,
        width: '260px',
        cell: (row) => row.os
      }
    ]

    return (
      <div>
        {
          !this.props.mode === 'counter_blocks' && (
            <PieContainer>
              {
                this.props.data && this.props.data.statsFields.map((o) => (
                  <PieItem>
                    <CampaignChart data={this.getRateFor(o)} />
                  </PieItem>
                ))
              }
            </PieContainer>
          )
        }
        <Grid container
          alignContent='space-around'
          style={{ border: "1px solid #E5E5E5", borderRadius: "7px", }}
          justify='space-around'>
          <Grid item xs={12} style={{ borderBottom: "1px solid #E5E5E5", display: "flex", justifyContent: "space-between", padding: 20}}>
            <Box m={2} style={{ display: 'flex' }}>
              <Box mx={2}>
                <Button
                  onClick={() => this.handleFiltering('send')}
                  variant='contained'
                  color='primary'
                  size='large'
                  className={classes.stateButton}
                  style={{ backgroundColor: (this.state.selectedType == 'all' || this.state.selectedType == 'send' ? "rgb(255,182,0)" : "rgb(255,255,255)"), color: "black", padding: '8px 30px' }}
                >
                  <Grid>
                    <Grid className={classes.btnText}>
                      Sent
                    </Grid>
                    <Grid className={classes.statCount}>
                    { this.state.sentCount }
                    </Grid>
                  </Grid>
                </Button>
              </Box>
              <Box mx={2}>
                <Button
                  onClick={() => this.handleFiltering('open')}
                  variant='contained'
                  color='primary'
                  size='large'
                  className={classes.stateButton}
                  style={{ backgroundColor: (this.state.selectedType == 'open' ? "rgb(255,182,0)" : "rgb(255,255,255)")}}
                >
                  <Grid>
                    <Grid className={classes.btnText} style={{textAlign: 'center'}}>
                      Opened
                    </Grid>
                    <Grid className={classes.statCount} style={{color: 'red', textAlign: 'center'}}>
                      {this.state.openedAverage}%
                    </Grid>
                    <Grid className={classes.statCount} style={{color: 'red', textAlign: 'center'}}>
                      {this.state.openedCount}
                    </Grid>
                  </Grid>
                </Button>
              </Box>
              <Box mx={2}>
                <Button
                  onClick={() => this.handleFiltering('click')}
                  variant='contained'
                  color='primary'
                  size='large'
                  className={classes.stateButton}
                   style={{ backgroundColor: (this.state.selectedType == 'click' ? "rgb(255,182,0)" : "rgb(255,255,255)")}}
                >
                  <Grid>
                    <Grid className={classes.btnText} style={{textAlign: 'center'}}>
                      Clicked
                    </Grid>
                    <Grid className={classes.statCount} style={{ color: '#49aad2', textAlign: 'center' }}>
                      { this.state.clickedAverage }%
                    </Grid>
                    <Grid className={classes.statCount} style={{ color: '#49aad2', textAlign: 'center' }}>
                      { this.state.clickedCount }
                    </Grid>
                  </Grid>
                </Button>
              </Box>
              <Box mx={2}>
                <Button
                  onClick={() => this.handleFiltering('replied')}
                  variant='contained'
                  color='primary'
                  size='large'
                  className={classes.stateButton}
                  style={{ backgroundColor: (this.state.selectedType == 'replied' ? "rgb(255,182,0)" : "rgb(255,255,255)")}}
                >
                  <Grid>
                    <Grid className={classes.btnText} style={{textAlign: 'center'}}>
                      Replied
                    </Grid>
                    <Grid className={classes.statCount} style={{ color: '#d27f00', textAlign: 'center' }}>
                      { this.state.repliedAverage }%
                    </Grid>
                    <Grid className={classes.statCount} style={{ color: '#d27f00', textAlign: 'center' }}>
                      { this.state.repliedCount }
                    </Grid>
                  </Grid>
                </Button>
              </Box>
              <Box mx={2}>
                <Button
                  onClick={() => this.handleFiltering('bounce')}
                  variant='contained'
                  color='primary'
                  size='large'
                  className={classes.stateButton}
                  style={{ backgroundColor: (this.state.selectedType == 'bounce' ? "rgb(255,182,0)" : "rgb(255,255,255)")}}

                >
                  <Grid>
                    <Grid className={classes.btnText} style={{textAlign: 'center'}}>
                      Failed
                    </Grid>
                    <Grid className={classes.statCount} style={{ textAlign: 'center' }}>
                      { this.state.failedAverage }%
                    </Grid>
                    <Grid className={classes.statCount} style={{ textAlign: 'center' }}>
                      { this.state.failedCount }
                    </Grid>
                  </Grid>
                </Button>
              </Box>
              {/* {this.props.mode === "counter_blocks" && this.props.data && (
                <Grid container spacing={3}>
                  {Object.keys(this.state.counts).map((key) => {
                    return (
                      <Paper className={classes.cardPaper} style={{ backgroundColor: key === 'sent' ? '#FFB600' : ''  }} >
                        <Count
                          data={this.state.counts[key]}
                          label={key}
                          appendLabel={""}
                        />
                      </Paper>                    );
                  })}
                </Grid>
              )} */}
            </Box>
          </Grid>
          <Grid item xs={12} style={{ padding: '10px', margin: '20px', minHeight: 600, borderRadius: '8px' }} >
            {
              !this.state.loading &&
                <Datatable
                  columns={columns}
                  data={this.state.collection}
                  pagination
                  onSelectedRowsChange={(s) => this.setState({ selected_rows: s.selectedRows })}
                  paginationServer
                  paginationPerPage={20}
                  paginationRowsPerPageOptions={[20]}
                  paginationDefaultPage={this.state.meta.current_page}
                  paginationTotalRows={this.state.meta.total_count}
                  onChangePage={(e) => this.handleNextPage(e)}
                  sortServer={false}
                />
            }
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { auth, app, segment, app_users, app_user } = state
  const { searching, meta } = app_users
  const { loading, isAuthenticated } = auth

  return {
    app_user,
    app_users,
    searching,
    meta,
    segment,
    app,
    loading
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Stats))
