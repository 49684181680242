import React, { useState, useEffect, useRef } from "react";
import { camelizeKeys } from "../actions/conversation";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from '@material-ui/core/Box'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ConversationHeader from '../components/ConversationHeader'
import Progress from "../shared/Progress";

import ContentHeader from "../components/ContentHeader";
import Content from "../components/Content";
import FormDialog from "../components/FormDialog";
import FieldRenderer from "../shared/FormFields";
import { UpgradeDialog } from "../shared/planUpgradeMessage";
import DeleteDialog from "../components/deleteDialog";

import { errorMessage, successMessage } from "../actions/status_messages";
import { setCurrentPage, setCurrentSection } from "../actions/navigation";

import graphql from "../graphql/client";
import { APP_PACKAGES, APP_PACKAGE_INTEGRATIONS } from "../graphql/queries";
import {
  CREATE_INTEGRATION,
  UPDATE_INTEGRATION,
  DELETE_INTEGRATION,
} from "../graphql/mutations";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import serialize from "form-serialize";
import styled from "@emotion/styled";
import {makeStyles} from "@material-ui/core/styles";
import { setupScript } from '../components/setupScript';

const useStyles = makeStyles(theme => ({
    listItem: {
      alignItems: "center",
      border: '1px solid #000',
      padding: 0,
      maxWidth: '31%',
      display: 'flex',
      minHeight: 150,
      marginBottom: '5%',
    },
    primaryText: {
      fontSize: 21, fontWeight: 'bold',
    },
    secondary: {
      fontSize: 14, color: '#222', lineHeight: '25px',
    },
    textRoot: {
      borderLeft: '1px solid #000',
      marginTop: 0, marginBottom: 0,
      padding: '25px 35px',
      height: '100%'
    },
    avatarRoot: {
      width: 52, height: 53,
      background: 'none',
      margin: '0 auto',
      "& img": {
         width: 52, height: 53,
      }
    },
    borderLeft: {
      backgroundImage: "linear-gradient(to top, rgba(255,255,255,1), rgba(255, 211, 0, 0), rgba(255, 211, 0, 1), rgba(255, 211, 0, 0), rgba(255,255,255,1))",
      width: 1,
      height: 50,
      marginTop: 12,
    },
    secondaryAction: {
      position: 'absolute', background: '#FFB600', width: '100%', height: '100%', display: 'flex', alignItems: 'center', top: '47%', left: '-7px',
      "& .MuiButton-root": {
        borderRadius: 6,
        fontSize: 15,
        padding: '15px 40px',
        background: '#000', color: '#fff'
      }
    },
    tabButton: {
      padding: '10px 20px',
      fontSize: 15,
      '&:hover': {
        backgroundColor: '#FAF6F1'
      }
    },
    tabSelectedButton: {
      backgroundColor: '#FAF6F1',
      borderRadius: 6,
      fontWeight: 600
    },
    integrationTitleContainer: {
      paddingBottom: 40,
      paddingTop: 90,
      margin: '0px 50px',
      backgroundColor: 'transparent',
      color: '#000000',
      width: 'calc(100% - 100px)',
      borderBottom: '1px solid #E5E5E5',
      zIndex: 1,
    },
    integrationTitle: {
      fontWeight: 'normal',
      fontSize: 42,
    },
    modalRoot: {
      padding: '40px', borderRadius: '6',
    },
}));


const BtnTabContainer = styled.div`
  background-color: #fff;

  button {
    margin-left: 0;
    min-width: 140px;
    min-height: unset;

    span {
      border-radius: 4px;
      padding: 10px;
      z-index: 1;
    }
  }

  button.Mui-selected {
    font-weight: 600;

    span {
      background-color: rgb(250, 247, 242);
    }
  }
`;

function Integrations({ history, app, dispatch }) {
  const [open, setOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [requiresUpgrade, setRequiresUpgrade] = useState(false);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false)
  const form = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    dispatch(setCurrentSection("Settings"));
    dispatch(setCurrentPage("integrations"));
  }, []);

  function getAppPackages() {
    setLoading(true);
    graphql(
      APP_PACKAGES,
      {
        appKey: app.key,
      },
      {
        success: (data) => {
          setServices(data.app.appPackages);
          setLoading(false);
        },
        error: () => {
          setLoading(false);
        },
      }
    );
  }

  function getAppPackageIntegration() {
    setLoading(true);
    graphql(
      APP_PACKAGE_INTEGRATIONS,
      {
        appKey: app.key,
      },
      {
        success: (data) => {
          console.log(data);
          setRequiresUpgrade(data.app.upgradeForApiIntegrations);
          setIntegrations(data.app.appPackageIntegrations);
          setLoading(false);
        },
        error: () => {
          setLoading(false);
        },
      }
    );
  }


  function openUpgradeDialog(){
    setShowUpgradeDialog(true);
  }

  function closeUpgradeDialog(){
    setShowUpgradeDialog(false);
  }

  function handleOpen(service) {
    if(requiresUpgrade){
      setShowUpgradeDialog(true);
    }else{
      setOpen(service);
    }
  }

  function close() {
    setOpen(false);
  }

  function submit() {
    const serializedData = serialize(form.current, {
      hash: true,
      empty: true,
    });

    open.id
      ? updateIntegration(serializedData)
      : createIntegration(serializedData);
  }

  function createIntegration(serializedData) {
    graphql(
      CREATE_INTEGRATION,
      {
        appKey: app.key,
        appPackage: open.name,
        params: serializedData.app,
        script: setupScript(app)
      },
      {
        success: (data) => {
          console.log(data);
          const response = data.integrationsCreate

          if (response.url) {
            window.location.href = response.url;
            return;
          }
          setTabValue(0);

          const integration = response.integration;
          const newIntegrations = integrations.map((o) =>
            o.name === integration.name ? integration : o
          );
          setIntegrations(newIntegrations);

          setOpen(null);
          dispatch(successMessage("integration created"));
        },
        error: (err) => {
          console.error('ERROR', err)
          dispatch(errorMessage("error linking integration"));
        },
      }
    );
  }

  function updateIntegration(serializedData) {
    graphql(
      UPDATE_INTEGRATION,
      {
        appKey: app.key,
        appPackage: open.name,
        id: parseInt(open.id),
        params: serializedData.app,
      },
      {
        success: (data) => {
          setTabValue(0);
          const response = data.integrationsUpdate

          if (response.url) {
            window.location.href = response.url;
            return;
          }

          const integration = response.integration;
          const newIntegrations = integrations.map((o) =>
            o.name === integration.name ? integration : o
          );
          setIntegrations(newIntegrations);
          setOpen(null);
          dispatch(successMessage("integration updated"));
        },
        error: (err) => {
          console.error(err)
          dispatch(errorMessage("error updating integration"));
        },
      }
    );
  }

  function removeIntegration() {
    graphql(
      DELETE_INTEGRATION,
      {
        appKey: app.key,
        id: parseInt(openDeleteDialog.id),
      },
      {
        success: (data) => {
          setTabValue(0);
          const integration = data.integrationsDelete.integration;
          const newIntegrations = integrations.filter(
            (o) => o.name != integration.name
          );
          setIntegrations(newIntegrations);
          setOpen(null);
          setOpenDeleteDialog(null);
          dispatch(successMessage("integration removed correctly"));
        },
        error: () => {
          dispatch(errorMessage("error removing integration"));
        },
      }
    );
  }

  function handleTabChange(e, i) {
    setTabValue(i);
  }

  function tabsContent() {
    return (
      <BtnTabContainer>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          textColor="inherit"
          style = {{ margin: '25px 25px 0', paddingBottom: 20 }}
        >
          <Tab textColor="inherit" label="App Integrations" classes={{root: classes.tabButton, selected: classes.tabSelectedButton}} />
          <Tab textColor="inherit" label="API services" classes={{root: classes.tabButton, selected: classes.tabSelectedButton}} />
        </Tabs>
      </BtnTabContainer>
    );
  }

  function renderIntercomStatus(service){
    console.log(service)
    const imp = service.importStatus;
    return (
      <Grid container direction={"column"}>
        <Typography variant={"caption"}>
          {`${imp && imp.errored_with_message && imp.errored_with_message.length > 0 ? "Error: " + imp.errored_with_message : ""}`}
        </Typography>

        <Typography variant="overline">Import Status: {`${imp && imp.state.toUpperCase()}`}</Typography>
        <Typography variant="overline">{`${imp && imp.contacts_imported ? 'Contacts imported succesfully' : '' }`}</Typography>
        <Typography variant="overline">{`${imp && imp.conversations_imported ? 'Conversations imported succesfully' : ''}`}</Typography>

      </Grid>
    )
  }

  function renderTabcontent() {
    switch (tabValue) {
      case 0:
        return (
          <React.Fragment>
            <Grid
               alignContent={'space-around'}
               style={{ border: "1px solid #E5E5E5", borderRadius: "7px" }}
               justify={'space-around'}>
              <Grid item xs={12}  style={{ borderBottom: "1px solid #E5E5E5", display: "flex", justifyContent: "space-between", padding: 20}}>
                 <Box m={2}>
                  <Typography style={{"fontSize": "18px", "marginTop": "10px"}} >
                    API Integrations
                  </Typography>
                 </Box>
              </Grid>

              <Grid item xs={12} style={{ padding: 10, margin: "20px", minHeight: 600, borderRadius: "8px"}}>
                {loading && <Progress />}
              {integrations.length === 0 && !loading && (
                <EmptyCard
                  goTo={() => {
                    setTabValue(1);
                  }}
                />
              )}

              <ServiceIntegration
                services={integrations}
                handleOpen={handleOpen}
                getAppPackages={getAppPackageIntegration}
                setOpenDeleteDialog={setOpenDeleteDialog}
                kind={"integrations"}
              />
              </Grid>
          </Grid>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <UpgradeDialog isOpen={showUpgradeDialog} handleClose={closeUpgradeDialog} />
            <Grid
               alignContent={'space-around'}
               style={{ border: "1px solid #E5E5E5", borderRadius: "7px" }}
               justify={'space-around'}>
              <Grid item xs={12}  style={{ borderBottom: "1px solid #E5E5E5", display: "flex", justifyContent: "space-between", padding: 20}}>
                 <Box m={2}>
                  <Typography style={{"fontSize": "18px", "marginTop": "10px"}} >
                    Available API Services
                  </Typography>
                 </Box>
              </Grid>

              <Grid item xs={12} style={{ padding: 10, margin: "20px", minHeight: 600, borderRadius: "8px"}}>
                {loading && <Progress />}

                <APIServices
                    services={services}
                    handleOpen={handleOpen}
                    getAppPackages={getAppPackages}
                    kind={"services"}
                  />
              </Grid>
            </Grid>
          </React.Fragment>
        );
    }
  }

  const currentHeader = {
    id: "Settings",
    label: I18n.t("navigator.settings"),
    url: `/apps/${app.key}/settings`,
    children: [
      {
        id: "App Settings",
        url: `/apps/${app.key}/settings`,
      },
      {
        id: "Team",
        url: `/apps/${app.key}/team`,
      },
      {
        id: "Integrations",
        url: `/apps/${app.key}/integrations`,
      },
      {
        id: "Webhooks",
        url: `/apps/${app.key}/webhooks`,
      },
      {
        id: 'Domains',
        url: `/apps/${app.key}/domains`,
      },
    ],
  }

  return (
    <React.Fragment>
      <ConversationHeader history={history} appId={app.key} category={currentHeader} />
      <ContentHeader
        title={"Third party integrations"}
        headerClass={classes.integrationTitleContainer}
        headerTitleClass={classes.integrationTitle}
        tabsContent={tabsContent()}
      />
      <div
        style={{
          margin: "0 50px 27px",
        }}
      >
        {renderTabcontent()}
      </div>

      {open && (
        <FormDialog
          open={open}
          classes = {classes.modalRoot}
          titleContent={`${open.id ? "Update" : "Add"} ${
            open.name
          } integration`}
          formComponent={
            <form ref={form}>
              <Grid container spacing={3}>
                {open.definitions.map((field) => {
                  return (
                    <Grid
                      item
                      key={field.name}
                      xs={field.grid.xs}
                      sm={field.grid.sm}
                    >
                      <FieldRenderer
                        namespace={"app"}
                        data={camelizeKeys(field)}
                        props={{
                          data: open.settings
                            ? camelizeKeys(open.settings)
                            : {},
                        }}
                        errors={{}}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              {open.id && open.name.toLowerCase().includes("intercom") && renderIntercomStatus(open)}

              {open.id && !open.name.toLowerCase().includes("intercom") && (
                <Grid container direction={"column"}>
                  <Typography variant="overline">
                    This integration will receive webhook at:
                  </Typography>

                  <Typography variant={"caption"}>
                    {`${window.location.origin}/api/v1/hooks/${
                      app.key
                    }/${open.name.toLocaleLowerCase()}/${open.id}`}
                  </Typography>

                  <Typography variant="overline">Oauth callback:</Typography>

                  <Typography variant={"caption"}>
                    {`${window.location.origin}/api/v1/oauth/${
                      app.key
                    }/${open.name.toLocaleLowerCase()}/${open.id}`}
                  </Typography>
                </Grid>
              )}
            </form>
          }
          dialogButtons={
            <React.Fragment>
              <Button onClick={close} variant="outlined" style={{ padding: '15px 30px' }} color="secondary">
                Cancel
              </Button>

              <Button onClick={submit} color="primary" variant="contained" style={{ padding: '15px 30px', backgroundColor: '#FFD300', color: '#000' }} disabled={open && open.importStatus && open.importStatus.state != "failed"}>
                {open ? "Update" : "Create"}
              </Button>
            </React.Fragment>
          }
        ></FormDialog>
      )}

      {openDeleteDialog && (
        <DeleteDialog
          open={openDeleteDialog}
          title={`Delete "${openDeleteDialog.name}" integration ?`}
          closeHandler={() => {
            this.setOpenDeleteDialog(null);
          }}
          deleteHandler={() => {
            removeIntegration(openDeleteDialog);
          }}
        >
          <Typography variant="subtitle2">
            The integration with {openDeleteDialog.dialog} service will be
            disabled immediately
          </Typography>
        </DeleteDialog>
      )}
    </React.Fragment>
  );
}

function EmptyCard({ goTo }) {
  return (
    <React.Fragment>
      <CardContent>
        <Typography color="textSecondary" gutterBottom></Typography>
        <Typography variant="h5" component="h2">
          You don't have any api integrations yet
        </Typography>
        <Typography color="textSecondary">
          search for available api services in{" "}
          <Link href="#" onClick={goTo}>
            API Services
          </Link>{" "}
          Tab
        </Typography>
      </CardContent>
    </React.Fragment>
  );
}

function ServiceBlock({ service, handleOpen, kind, setOpenDeleteDialog }) {
  function available() {
    if (kind === "services") return service.state === "enabled";
    if (kind === "integrations")
      return service.id && service.state === "enabled";
  }

  let inputEl = null;

  function showButton() {
    console.log('hello1')
    inputEl.style.display = 'flex'
    console.log(inputEl)
  }
  function hideButton() {
    console.log('hello2')
    inputEl.style.display = 'none'
    console.log(inputEl)
  }
  // const primary = { fontSize: 18, fontWeight: 'bold' }
  // const secondary = { fontSize: 4, color: '#222', lineHeight: 25 }
  const classes = useStyles();

  return (
    <React.Fragment>
        <Grid item xs={12} sm={6} md={4} className={classes.listItem} style={{ position: 'relative'}} onMouseEnter={showButton} onMouseLeave={hideButton}>
        <ListItemAvatar style={{minWidth: 120}}>
          <Avatar className={ classes.avatarRoot } >
            <img src={service.icon} />
          </Avatar>
        </ListItemAvatar>
        <span className={classes.borderLeft}></span>
        <ListItemText
          className={classes.textRoot}
          primary={<span className={ classes.primaryText}>{service.name}</span>}
          secondary={<span className={ classes.secondary }>{service.description}</span>}
        />

        {available() && (
          <ListItemSecondaryAction ref={(ref) => inputEl = ref } className={classes.secondaryAction} style={{ display: 'none' }}>
            <ListItemAvatar style={{minWidth: 120, flex: 1}}>
              <Avatar className={ classes.avatarRoot } >
                <img src={service.icon} />
              </Avatar>
            </ListItemAvatar>
            <div style={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid #000' }}>
              <Button
                onClick={() => handleOpen(service)}
                variant={"contained"}
              >
                {service.id ? 'Edit' : 'Connect'}
              </Button>

              {service.id && (
                <Button
                  onClick={() =>
                    setOpenDeleteDialog && setOpenDeleteDialog(service)
                  }
                  variant={"contained"}
                >
                  Delete
                </Button>
              )}
            </div>
          </ListItemSecondaryAction>
        )}
        </Grid>
      {/*<ListItem
        style={{
          backgroundImage:
            "linear-gradient(to right, rgba(255,255,255,1), rgba(255, 211, 0, 0), rgba(255, 211, 0, 1), rgba(255, 211, 0, 0), rgba(255,255,255,1))",
          width: "100%",
          height: "2px",
          padding: 0
        }}
      ></ListItem>*/}
    </React.Fragment>
  );
}

function ServiceIntegration({
  services,
  handleOpen,
  getAppPackages,
  kind,
  setOpenDeleteDialog,
}) {
  useEffect(() => {
    getAppPackages();
  }, []);

  return (
    <List dense style={{backgroundColor: '#fff'}}>
      {services.map((o) => (
        <ServiceBlock
          kind={kind}
          key={`services-${o.name}`}
          service={o}
          setOpenDeleteDialog={setOpenDeleteDialog}
          handleOpen={handleOpen}
        />
      ))}
    </List>
  );
}

function APIServices({ services, handleOpen, getAppPackages, kind }) {
  useEffect(() => {
    getAppPackages();
  }, []);

  return (
    <Grid
      container
      style={{
        backgroundColor: "#fff",
        margin: "auto",
        marginTop: "50px",
        marginBottom: "50px",
        justifyContent: 'space-between',
      }}
    >
      {services.map((o) => (
        <ServiceBlock
          kind={kind}
          key={`services-${o.name}`}
          service={o}
          handleOpen={handleOpen}
        />
      ))}
    </Grid>
  );
}

function mapStateToProps(state) {
  const { app } = state;

  return {
    app,
  };
}

export default withRouter(connect(mapStateToProps)(Integrations));
