import React, {
  useState,
  useEffect,
  useRef
} from 'react'

import {
  camelizeKeys
} from '../actions/conversation'

import {isEmpty} from 'lodash'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Progress from '../shared/Progress'

import ContentHeader from '../components/ContentHeader'
import Content from '../components/Content'
import FormDialog from '../components/FormDialog'
import FieldRenderer from '../shared/FormFields'
import DeleteDialog from "../components/deleteDialog"
import ConversationHeader from '../components/ConversationHeader'
import {errorMessage, successMessage} from '../actions/status_messages'
import { setCurrentPage, setCurrentSection } from "../actions/navigation";

import graphql from '../graphql/client'
import {
  EVENT_TYPES,
  SES_IDENTITIES
} from '../graphql/queries'
import {
  SES_IDENTITY_CREATE,
  SES_IDENTITY_UPDATE,
  SES_IDENTITY_DELETE
} from '../graphql/mutations'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import serialize from 'form-serialize'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    tabButton: {
      padding: '10px 20px',
      fontSize: 15,
      '&:hover': {
        backgroundColor: '#FAF6F1'
      }
    },
    tabSelectedButton: {
      backgroundColor: '#FAF6F1',
      borderRadius: 6,
      fontWeight: 600
    },
    domainTitleContainer: {
      paddingBottom: 40,
      paddingTop: 90,
      margin: '0px 50px',
      backgroundColor: 'transparent',
      color: '#000000',
      width: 'calc(100% - 100px)',
      borderBottom: '1px solid #E5E5E5',
      transform: 'none',
    },
    domainTitle: {
      fontWeight: 'normal',
      fontSize: 42,
    },
    container: {
      width: '45%',
    },
    domainContainer: {
      width: '100%',
    },
    secondary: {
      flex: 0.5,
      color: '#000',
    },
    verified: {
      "color":"#479055","backgroundColor":"#D0E6D4","borderRadius":"8px","padding":"0 6px","textTransform":"capitalize", fontSize: 15, marginRight: 20,
    },
    unVerified: {
      "borderRadius":"8px","padding":"0 6px","textTransform":"capitalize", fontSize: 15, backgroundColor: '#FFD3D8', color: '#FF5265', marginRight: 20,
    },
    verifyBtn: {
      border: '1px solid #000',
      padding: '0 15px',
      borderRadius: 8,
      marginRight: 20,
    },
    addEmailButton: {
      width: '15%',
      marginLeft: 25,
      marginBottom: 25,
      padding: '15px 0'
    },
    heading: {
      fontSize: 28, margin: '0px 0 40px 25px', fontWeight: '500'
    },
    domainVerifyBtn: {
      backgroundColor: '#FFD300',
      padding: '15px 35px',
      color: '#000',
      "&:hover": {
          backgroundColor: '#FFD300',
      },
    },
    domainPrimary: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #E5E5E5', padding: 30,
    },
    pendingContainer: {
      border: '1px solid #E5E5E5', borderRadius: 8,
    },
}));

function DomainIntegrations({history, app, dispatch}){

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [eventTypes, setEventTypes] = useState([])
  const [errors, setErrors] = useState([])
  const [domainAddresses, setDomainAddresses] = useState([])
  const [tabValue, setTabValue] = useState(0)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const form = useRef(null);
  const classes = useStyles();

  useEffect(()=>{
    dispatch(setCurrentSection("Settings"))
    dispatch(setCurrentPage("domains"))
    fetchIdentities()
  }, [])

  function fetchIdentities(){
    setLoading(true)
    setDomainAddresses([])

    graphql(SES_IDENTITIES, { appKey: app.key  }, {
      success: (data)=>{
        setDomainAddresses(data.app.agentSesIdentities)
        setLoading(false)
      },
      error: ()=>{
        setLoading(false)
      }
    })
  }

  function handleOpen(service){
    setOpen(service)
  }

  function close(){
    setOpen(false)
  }

  function submit(){
    const serializedData = serialize(form.current, {
      hash: true, empty: true
    })

    createSesIdentity(serializedData)
  }

  function definitions(){
    return [
      {
        name: 'address',
        type: 'string',
        grid: { xs: 12, sm: 12 }
      }
    ]
  }

  function newDomainAddress(){
    setOpen({
      name: "address",
      description: 'Domain',
      definitions: definitions()
    })
  }

  function newEmailAddress(){
    setOpen({
      name: "address",
      description: 'Email Address',
      definitions: definitions()
    })
  }

  function createSesIdentity(serializedData){
    console.log(serializedData);
    const {address} = serializedData.identity
    setLoading(true);
    const identityType = tabValue == 0 ? 'domain' : 'email'

    graphql(SES_IDENTITY_CREATE, {
      appKey: app.key,
      address: address,
      identityType: identityType
    }, {
      success: (data)=>{
        setLoading(false);


        setTabValue(tabValue)

        const sesIdentity = data.createSesIdentity.sesIdentity
        const errors = data.createSesIdentity.errors
        if(!isEmpty(errors)) {
          console.log("errors", errors);
          setErrors(errors)
          return
        }

        const newIntegrations = domainAddresses.concat(sesIdentity)
        setDomainAddresses(newIntegrations)
        setOpen(null)
        const message = (tabValue == 0) ? "Domain added successfully" : "Email address added successfully "
        dispatch(successMessage(message))
        fetchIdentities()
      },
      error: (data)=>{
        setLoading(false);
        dispatch(errorMessage("Something went wrong."))
      }
    })
  }

  function updateSesIdentityToVerified(serializedData){
    const {id} = serializedData
    setLoading(true);

    graphql(SES_IDENTITY_UPDATE, {
      appKey: app.key,
      id: id,
      address: serializedData.address,
      status: "verified"
    }, {
      success: (data)=>{
        setLoading(false);
        setTabValue(tabValue)
        const sesIdentity = data.updateSesIdentity.sesIdentity
        const errors = data.updateSesIdentity.errors
        if(!isEmpty(errors)) {
          console.log("errors", errors);
          setErrors(errors)
          return
        }

        const newIntegrations = domainAddresses.map(
          (o)=> o.id === sesIdentity.id ? sesIdentity : o
        )

        setDomainAddresses(newIntegrations)
        setOpen(null)
        const message = (tabValue == 0) ? "Domain updated successfully" : "Email address updated successfully "
        dispatch(successMessage(message))
        fetchIdentities()


      },
      error: ()=>{
        //setLoading(false);
        dispatch(errorMessage("Something went wrong."))
      }
    })
  }



  function removeSesIdentity(){
    graphql(SES_IDENTITY_DELETE, {
      id: parseInt(openDeleteDialog.id),
    }, {
      success: (data)=>{
        setTabValue(tabValue)
        const sesIdentity = data.deleteSesIdentity.sesIdentity
        const newIntegrations = domainAddresses.filter(
          (o)=> o.id != sesIdentity.id
        )
        const errors = data.deleteSesIdentity.errors
        if(!isEmpty(errors)) {
          setErrors(errors)
          return
        }
        setDomainAddresses(newIntegrations)
        setOpen(null)
        setOpenDeleteDialog(null)
        const message = (tabValue == 0) ? "Domain removed successfully" : "Email address removed successfully "
        dispatch(successMessage(message))
      },
      error: ()=>{
        dispatch(errorMessage("Something went wrong."))
      }
    })
  }

  function handleTabChange(e, i){
    setTabValue(i)
  }

  function tabsContent(){
    return <Tabs value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              style = {{ margin: '25px 25px 0', paddingBottom: 20 }}
              textColor="inherit">
              <Tab textColor="inherit" label="Domains" classes={{root: classes.tabButton, selected: classes.tabSelectedButton}} />
              <Tab textColor="inherit" label="Email Addresses" classes={{root: classes.tabButton, selected: classes.tabSelectedButton}} />
            </Tabs>
  }

  function renderTabcontent(){

    function allDomainAddresses(){
      return domainAddresses.filter((o)=> o.identityType === "domain")
    }

    function allEmailAddresses(){
      return domainAddresses.filter((o)=> o.identityType === "email")
    }

    switch (tabValue){
      case 0:
        return <React.Fragment>

                <Grid container justify={"space-between"} style={{flexDirection: 'column' }}>
                  <Typography variant={"h3"} className={classes.heading}>
                    Domains
                  </Typography>

                  {loading && <Progress/>}


                  <Button
                    variant={"contained"}
                    color={'primary'}
                    onClick={newDomainAddress}
                    className={classes.addEmailButton}
                    disabled={allDomainAddresses().length > 0 ? true : false}
                    >
                    <AddIcon style={{ marginRight: 5 }} />
                    Add Domain
                  </Button>

                </Grid>

                  <List style={{background: 'none'}}>
                    {
                      allDomainAddresses().map((o) =>
                        <DomainAddressItem
                          sesIdentity={o}
                          key={`domain-address-${o.id}`}
                          handleDelete={(o)=> setOpenDeleteDialog(o)}
                          updateSesIdentityToVerified={(o) => updateSesIdentityToVerified(o)}
                        />
                      )
                    }
                  </List>

                  {
                    allDomainAddresses().length === 0 && !loading &&
                    <EmptyCard
                      tabValue={tabValue}
                      goTo={()=>{setTabValue(0)}}
                    />
                  }


                </React.Fragment>
      case 1:
        return <React.Fragment>

                <Grid container justify={"space-between"} style={{flexDirection: 'column' }}>
                  <Typography variant={"h3"} className={classes.heading}>
                    Email Addresses
                  </Typography>

                  {loading && <Progress/>}

                  <Button
                    variant={"contained"}
                    color={'primary'}
                    className={classes.addEmailButton}
                    onClick={newEmailAddress}>
                    <AddIcon style={{ marginRight: 5 }} />
                    Add Email Address
                  </Button>

                </Grid>

                <List style={{background: 'none'}}>
                  {
                    allEmailAddresses().map((o) =>
                      <EmailAddressItem
                        sesIdentity={o}
                        key={`email-address-${o.id}`}
                        handleDelete={(o)=> setOpenDeleteDialog(o)}
                        updateSesIdentityToVerified={(o) => updateSesIdentityToVerified(o)}
                      />
                    )
                  }
                </List>

                  {
                    allEmailAddresses().length === 0 && !loading &&
                    <EmptyCard
                      tabValue={tabValue}
                      goTo={()=>{setTabValue(1)}}
                    />
                  }

                </React.Fragment>
    }
  }
  const currentHeader = {
    id: "Settings",
    label: I18n.t("navigator.settings"),
    url: `/apps/${app.key}/settings`,
    children: [
      {
        id: "App Settings",
        url: `/apps/${app.key}/settings`,
      },
      {
        id: "Team",
        url: `/apps/${app.key}/team`,
      },
      {
        id: "Integrations",
        url: `/apps/${app.key}/integrations`,
      },
      {
        id: "Webhooks",
        url: `/apps/${app.key}/webhooks`,
      },
      {
        id: 'Domains',
        url: `/apps/${app.key}/domains`,
      },
    ],
  }
  return <React.Fragment>
            <ConversationHeader history={history} appId={app.key} category={currentHeader} />
            <ContentHeader
              title={ 'Domain Settings' }
              headerClass={classes.domainTitleContainer}
              headerTitleClass={classes.domainTitle}
              tabsContent={ tabsContent() }
            />
            <Content style={{margin: '0 50px'}}>
              {renderTabcontent()}

            </Content>

            {open && (
              <FormDialog
                open={open}
                titleContent={`${tabValue == 0 ? 'Add Domain' : 'Add Email Address'}`}
                formComponent={
                    <form ref={form}>
                      <Grid container spacing={3}>
                        {
                          definitions().map((field) => {
                            return <Grid item
                                      key={field.name}
                                      xs={field.grid.xs}
                                      sm={field.grid.sm}>
                                      <FieldRenderer
                                        namespace={'identity'}
                                        data={camelizeKeys(field)}
                                        props={{
                                          data: open
                                        }}
                                        errors={ errors }
                                      />
                                  </Grid>
                          })
                        }
                      </Grid>

                    </form>
                }
                dialogButtons={
                  <React.Fragment>
                    <Button
                      onClick={close}
                      color="secondary">
                      Cancel
                    </Button>

                    <Button onClick={ submit }
                      color="primary">
                      {'Create'}
                    </Button>

                  </React.Fragment>
                }
                >
              </FormDialog>
            )}

            {
              openDeleteDialog && <DeleteDialog
               open={openDeleteDialog}
               title={tabValue == 0 ? 'Delete domain ?' : 'Delete Email Address'}
               closeHandler={()=>{
                 setOpenDeleteDialog(null)
               }}
               deleteHandler={()=> {
                 removeSesIdentity(openDeleteDialog)
                }}>
               <Typography variant="subtitle2">
                 The {tabValue == 0 ? 'domain' : 'Email Address'} will be removed immediately. Are you sure?
               </Typography>
             </DeleteDialog>
            }

        </React.Fragment>
  }

function CnameRecords({sesIdentity,updateSesIdentityToVerified}){
  const classes = useStyles();
  // const sesIdentityRecords = [...sesIdentity.verificationRecord, ...sesIdentity.cnameRecords];
  return (
        <React.Fragment>
          <Typography variant='body1' style={{ fontSize: 13, marginBottom: 20 }}>
            To be able to send emails from this domain, we need to verify that you are allowed to do it. You need to add the below DNS Record to your domain {sesIdentity.address}
          </Typography>
          <div style={sesIdentity.verificationRecord || sesIdentity.cnameRecords ? {border: '1px solid #000', borderTop: 0} : {}}>
            {(sesIdentity.verificationRecord || sesIdentity.cnameRecords) && (
              <div style={{display: 'flex', borderTop: '1px solid #000', padding: '20px', paddingBottom: 10 }}>
                <Typography style={{ fontSize: 15, flex: '0.2', wordWrap: 'anywhere', paddingRight: 30 }} variant='subtitle1'>Name</Typography>
                <Typography style={{ fontSize: 15, flex: '0.2' }} variant='subtitle1'>Type</Typography>
                <Typography style={{ fontSize: 15, flex: '0.6', wordWrap: 'anywhere', paddingRight: 30 }} variant='subtitle1'>Value</Typography>
              </div>
            )}
            {sesIdentity.verificationRecord && sesIdentity.verificationRecord.map((row) => (
              <div style={{display: 'flex', borderTop: '1px solid #000', padding: '20px', paddingBottom: 10 }}>
                <Typography style={{ fontSize: 15, marginBottom: 15, flex: '0.2', wordWrap: 'anywhere', paddingRight: 30  }} variant='h6'>{row.name}</Typography>
                <Typography style={{ fontSize: 15, marginBottom: 15, flex: '0.2' }} variant='h6'>{row.type}</Typography>
                <Typography style={{ fontSize: 15, marginBottom: 15, flex: '0.6', wordWrap: 'anywhere', paddingRight: 30 }} variant='h6'>{row.value}</Typography>
              </div>
            ))}
            {sesIdentity.cnameRecords && sesIdentity.cnameRecords.map((row) => (
              <div style={{display: 'flex', borderTop: '1px solid #000', padding: '20px', paddingBottom: 10 }}>
                <Typography style={{ fontSize: 15, marginBottom: 15, flex: '0.2', wordWrap: 'anywhere', paddingRight: 30 }} variant='h6'>{row.name}</Typography>
                <Typography style={{ fontSize: 15, marginBottom: 15, flex: '0.2' }} variant='h6'>{row.type}</Typography>
                <Typography style={{ fontSize: 15, marginBottom: 15, flex: '0.6', wordWrap: 'anywhere', paddingRight: 30}} variant='h6'>{row.value}</Typography>
              </div>
            ))}
          </div>
          <br/>
          {
            sesIdentity.status == "pending" ?
            (
              <Button variant="contained" color="primary" className={classes.domainVerifyBtn} onClick={() => updateSesIdentityToVerified(sesIdentity)}>
                Verify
              </Button>
            ) : null
          }
        </React.Fragment>
      )

}




function EmailAddressItem({sesIdentity, handleDelete, updateSesIdentityToVerified}){
  const classes = useStyles();
  return <ListItem classes={{container: classes.container}}>
            <ListItemText
              style={{display: 'inline-flex', alignItems: 'center'}}
              classes={{ secondary: classes.secondary }}
              primary={
                  <Typography variant="h6" style={{ flex: 1 }}>
                    {sesIdentity.address}
                  </Typography>

                }
              secondary={<div>
                <Chip
                  size="small"
                  className={ sesIdentity.status === 'pending' ? classes.unVerified : classes.verified }
                  style={{ flex: 1 }}
                  label={sesIdentity.status}
                  color="primary"
                />
              </div>}
             />

            <ListItemSecondaryAction>

              <div>
                {
                sesIdentity.status == "pending" ?
                  (
                    <Button variant="outlined" className={classes.verifyBtn} onClick={() => updateSesIdentityToVerified(sesIdentity)}>
                      Verify
                    </Button>
                  ) : null
                }

                {
                  sesIdentity.id && <IconButton
                    onClick={()=> handleDelete(sesIdentity) }
                    edge="end" aria-label="add">
                    <DeleteIcon  />
                  </IconButton>
                }

              </div>
            </ListItemSecondaryAction>
          </ListItem>

        {/*sesIdentity.id*/}
}

function DomainAddressItem({sesIdentity, handleDelete, updateSesIdentityToVerified}){
  const classes = useStyles();
  return <ListItem classes={{container: classes.domainContainer}}>
            <ListItemText
              style={{display: 'inline-flex', justifyContent: 'flex-start', flexDirection: 'column'}}
              classes={{ secondary: classes.secondary }}
              className={classes.pendingContainer}
              primary={
                  <Typography variant="h6" className={classes.domainPrimary}>
                    {sesIdentity.address}
                    <div style={{ flex: '0.8' }}>
                    <Chip
                      size="small"
                      label={sesIdentity.status}
                      className={ sesIdentity.status === 'pending' ? classes.unVerified : classes.verified }
                      color="primary"
                    />

                    {
                      sesIdentity.id && <IconButton
                        onClick={()=> handleDelete(sesIdentity) }
                        edge="end" aria-label="add">
                        <DeleteIcon  />
                      </IconButton>
                    }
                    </div>
                  </Typography>

                }
              secondary={

                <div style={{ padding: 30 }}>
                  <CnameRecords sesIdentity={sesIdentity} updateSesIdentityToVerified={() => updateSesIdentityToVerified(sesIdentity)}/>
                </div>
            } />

            <ListItemSecondaryAction>


            </ListItemSecondaryAction>
          </ListItem>

        {/*sesIdentity.id*/}
}

function EmptyCard({goTo, tabValue}){
  return (
    <Card style={{marginTop: '2em'}}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
        </Typography>
        <Typography variant="h5" component="h2">
          You don't have any {(tabValue == 0) ? 'domains' : 'email addresses'} yet
        </Typography>
        <Typography color="textSecondary">
          To be able to send emails you need to add and verify domains or email addresses.
        </Typography>
      </CardContent>
    </Card>
  )
}


function mapStateToProps(state) {
  const { app } = state
  return {
    app,
  }
}

export default withRouter(connect(mapStateToProps)(DomainIntegrations))
