import React from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import styled from "@emotion/styled";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import Moment from "react-moment";
import { lighten } from "polished";
import { toCamelCase } from "../../shared/caseConverter";
import graphql from '../../graphql/client';
import UnreadIcon from '@material-ui/icons/FiberManualRecord';
import { UPDATE_CONVERSATION_STATE } from '../../graphql/mutations';
import CheckIcon from "@material-ui/icons/Check";


const useStyles = theme => ({
  root: {
    width: "100%",
    padding: "0px",
  },
  inline: {
    display: "inline",
  },

  tinyAvatar: {
    width: "20px",
    height: "20px",
    marginRight: "8px",
  },
  participantAvatar: {
    //margin: 10,
    width: "2.5rem",
    height: "2.5rem",
    border: '1px solid #777',
    borderRadius: '50%',
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  textMargin: {
    color: "rgba(0, 0, 0, 0.54)",
    margin: "0em",
    //marginTop: '0.5em',
    //marginBottom: '.5em'
    color: "rgba(0, 0, 0, 0.54)",
    margin: "0em",
    fontSize: "14px",
    display: "inline-block",
    overflow: "hidden",
    width: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  avatarMargin: {
    marginRight: "10px",
  },
  time: {
    fontSize: "14px",
    margin: "5px",
    textTransform: 'lowercase',
  },
  displayName: {
    width: "calc(100% - 9px)",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  highlightedItem: {
    flexFlow: "column",
    paddingTop: ".7em",
    paddingBottom: "1.2em",
    cursor: "pointer",
    backgroundImage: 'linear-gradient(to right, rgb(250, 247, 242), rgb(255, 255, 255))',
    boxShadow: '1px 4px 10px -1px rgba(100, 100, 100, 0.2)',
    borderRight: 'solid 6px rgba(255, 211, 0)',
    marginBottom: 7,
  },
  defaultItem: {
    flexFlow: "column",
    paddingTop: ".7em",
    paddingBottom: "1.2em",
    borderBottom: '1px solid #ece9e9',
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: lighten(0.05, theme.palette.background.default),
    },
  },
  contentContent: {
    color: theme.palette.common.gray,
    fontSize: "0.9em",
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  readIcon: {
    width: '10px',
    height: '10px',
    color: 'dodgerblue'
  },
  patientName: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: '#777777',
  },
  fontBold: {fontWeight: 'bold'},
  font15: {
    fontSize: 15
  },
  dot: {
    height: 6,
    width: 6,
    backgroundColor: '#000',
    borderRadius: '50%',
    marginTop: 3,
  },
});

const PrivateNoteIndicator = styled.div`
  width: 5px;
  height: 19px;
  background-color: #feedaf;
  margin-right: 7px;
  border: 1px solid #f1e1a9;
`;

const ContentContent = styled(Typography)`
  max-width: 17vw;
  font-size: 0.9em;

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 61vw;
  }

  p {
    margin: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 17vw;
    @media (min-width: 320px) and (max-width: 480px) {
      max-width: 61vw;
    }
  }
`;

class UserListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showCloseButton: false,
    }
    this[`${props.conversation.id}_ref`] = React.createRef()
  }

  showCLoseButton = () =>  {
    this.setState({showCloseButton: true});
  }

  hideCLoseButton = () => {
    this.setState({showCloseButton: false});
  }

  renderAppBlock = () => {
    const message = toCamelCase(this.props.messageObject.message);
    const { blocks } = message;

    if (blocks) {
      const replied = message.state === "replied";

      const data = message.data;
      const { classes } = this.props;
      return (
        <div className={classes.contentContent}>
          <Typography variant="overline" style={{ lineHeight: 2 }}>
            {blocks.app_package || blocks.appPackage}
          </Typography>

          <br />

          <Typography variant={"caption"}>
            {data && (
              <span
                dangerouslySetInnerHTML={{
                  __html: data.formatted_text || data.formattedText,
                }}
              />
            )}
          </Typography>
        </div>
      );
    }

    return null;
  };

  updateConversationState = (e, state) => {
    // this[`${this.props.conversation.id}_ref`].current.style.transition = 'all .2s ease-in-out';
    graphql(UPDATE_CONVERSATION_STATE, {
      appKey: this.props.appKey, 
      conversationId: this.props.conversation.id,
      state: state
    }, {
      success: (data)=>{
        const conversation = data.updateConversationState.conversation
        this.props.removeClosedCon(this.props.conversation.id, this.props.value)
      },
      error: (error)=>{
      }
    })
  };

  render() {
    const { classes } = this.props;
    const participant = this.props.conversation.mainParticipant;
    const props = this.props;
    const { showCloseButton } = this.state;

    return (
      <List className={classes.root} ref={ this[`${props.conversation.id}_ref`] }>
        <ListItem
          onMouseEnter={this.showCLoseButton}
          onMouseLeave={this.hideCLoseButton}
          //alignItems="flex-start"
          className={
            props.value === props.object
              ? classes.highlightedItem
              : classes.defaultItem
          }
        >
          <Grid
            container
            alignItems={"center"}
            wrap="nowrap"
            justify={"space-between"}
          >
            <Grid item item xs={1}>
              <Avatar
                className={classes.participantAvatar}
                onClick={() => props.showUserDrawer(participant.id)}
                alt={participant.email}
                src={participant.avatarUrl}
              />
            </Grid>
  
            <Grid item item xs={11} zeroMinWidth style={{ paddingLeft: 20 }}>
              <Typography
                noWrap
                component="span"
                variant="overline"
                display={"block"}
                className={classes.displayName}
                color="textPrimary"
              >
                <div className={classes.patientName}>
                  <span className={ classes.dot }></span>
                  <span
                    style={{ flex: '1 1 auto', marginLeft: 10, textTransform: 'capitalize' }}
                  >
                    {participant.displayName || "Site visitor"}
                  </span>
                  {props.messageObject.createdAt && (
  
                      <Typography
                          component="span"
                          variant="subtitle2"
                          className={classes.time}
                          color="textSecondary"
                      >
                        {props.filterConversations == "opened" && showCloseButton ? (<div style = {{ position: 'relative'}}>
                            <Button variant="outlined"
                              style={{ padding: '0px 14px 0 15px', fontSize: 11, marginTop: '-2px' }}
                              onClick = {(e) => this.updateConversationState(e, "close")}>
                              <CheckIcon style={{ width: 14, marginRight: 4 }} />
                              Close
                            </Button>
                          </div>
                        ) : (
                          <Moment fromNow>
                            {props.messageObject.createdAt}
    
                          </Moment>
                        )}
                      </Typography>
                  )}
  
                </div>
  
              </Typography>
  
              <div>
                <div className={classes.flexContainer}>
                  {props.messageUser && props.messageUser.id != participant.id ? (
                      <Avatar
                          onClick={() => props.showUserDrawer(props.messageUser.id)}
                          className={classes.tinyAvatar}
                          alt={props.messageUser.email}
                          src={props.messageUser.avatarUrl}
                      />
                  ) : null}
                  {/* <span className={classes.fontBold}>
                    {props.messageUser.displayName || "Site visitor"}
                  </span> */}
  
                  {props.messageObject.privateNote ? (
                    <PrivateNoteIndicator />
                  ) : null}
  
                  {props.conversation.priority ? (
                    <PriorityHighIcon color={"primary"} />
                  ) : null}
  
                  {this.renderAppBlock()}
  
                  {!props.messageObject.blocks && (
                    <div className={classes.contentContainer}>
                    <ContentContent
                      variant={"body2"}
                      className={classes.contentContent}
                      noWrap
                      //color={"secondary"}
                      dangerouslySetInnerHTML={{ __html: props.message }}
                    />
                    {
                      props.messageObject.appUser.kind != "agent" && !props.messageObject.readAt
                      &&
                      <UnreadIcon className = {classes.readIcon}/>
                    }
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    );
    
  }
}

UserListItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(UserListItem);
