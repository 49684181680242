import PropTypes from "prop-types";
import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import ContentHeader from "../components/ContentHeader";
import Content from "../components/Content";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import CircularProgress from "@material-ui/core/CircularProgress";
import { AnchorLink } from "../shared/RouterLink";
import { UpgradeDialog } from "../shared/planUpgradeMessage";

import MainSection from "../components/MainSection";
import ContentWrapper from "../components/ContentWrapper";
import PageTitle from "../components/PageTitle";
import logo from "../images/logo.png";
import DataTable from "../components/table";
import { Datatable, IconColName } from "../components/datatable/";

import { Link } from "react-router-dom";

import graphql from "../graphql/client";
import { AGENTS, PENDING_AGENTS } from "../graphql/queries";
import { allAgents, loginAsUser } from "../actions/segments";
import { getCurrentUser } from '../actions/current_user'
import { INVITE_AGENT } from "../graphql/mutations";

import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import FormDialog from "../components/FormDialog";
import { setCurrentPage, setCurrentSection } from "../actions/navigation";
import styled from "@emotion/styled";

import Box from '@material-ui/core/Box';
import ConversationHeader from '../components/ConversationHeader'
import login_col from '../icons/login.svg'

class LoginAs extends React.Component {
  state = {
    collection: [],
    loading: true,
    selected_users: [],
  };

  componentDidMount() {
    this.props.allAgents(this.allocateAgents)
  }

  allocateAgents = (response) => {
    this.setState({
      collection: response,
      loading: false,
    });
  }

  handleLoginAsClick = (row) => {
    this.props.loginAsUser(row.id, row.attributes.email, this.relogin)
  }

  relogin = () => {
    this.props.getCurrentUser()
    this.props.history.push('/apps')
  }

  render() {
    const columns = [
      {
        name: "Email",
        selector: "attributes.email",
        sortable: true,
        width: "260px"
      },
      {
        name: <IconColName icon={login_col}>Login as</IconColName>,
        selector: 'loginAs',
        sortable: false,
        width: '180px',
        cell: (row) => <Button color='primary' size='large' onClick={() => this.handleLoginAsClick(row)}>Login As</Button>,
      }
    ];
    return (
      <div
        style={{ margin: "0 50px", borderTop: "solid 1px rgba(0,0,0,.125)" }}
      >
        {!this.state.loading ? (
          <Datatable
            columns={columns}
            data={this.state.collection}
            pagination
            onSelectedRowsChange={(s) =>
              this.setState({ selected_users: s.selectedRows })
            }
          />
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    allAgents: (allocateAgents) => dispatch(allAgents(allocateAgents)),
    loginAsUser: (id, email, relogin) => dispatch(loginAsUser(id, email, relogin)),
    getCurrentUser: () => dispatch(getCurrentUser())
  }
}
export default connect(null, mapDispatchToProps) (LoginAs);
