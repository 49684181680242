import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { deleteApp } from "../actions/app";
import { withRouter } from 'react-router-dom';

function AppDestroy(props) {
  const {selectedApp, app, dispatch } = props
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const afterAppRemoval = () => {
    props.history.push('/apps')
  };

  const removeApp = () => {
    props.dispatch(deleteApp({}, afterAppRemoval))
  };
  
  return (
    <div className="MuiButtonBase-root MuiButton-root AppSettingsContainer-submitButton-61 MuiButton-contained MuiButton-containedPrimary" style={{backgroundColor: "black"}}>
      <Button variant="contained" color="primary" onClick={handleClickOpen} style={{width: '100%'}}>
        Delete
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="Delete"
      >
        <DialogTitle id="responsive-dialog-title">{"App Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete this {app && app.name}? Once the app is delete successfully you will be redirected to the apps pag
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={()=>removeApp()} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withRouter(AppDestroy);
