import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { InlineFilterDialog, SaveSegmentModal } from "./index";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import Map from "../map/index.js";
import { dispatchSegmentUpdate } from "../../actions/segments";
import { getCurrentUser } from '../../actions/current_user';
import { setCurrentSection, setCurrentPage } from "../../actions/navigation";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Container from '@material-ui/core/Container';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SegmentItemButton from "../segmentManager/itemButton";
import NewUserOrLead from "../segmentManager/newUserOrLead";
import TextEditor from '../../textEditor'
import moment from 'moment'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import Input from "@material-ui/core/Input";
import Typography from '@material-ui/core/Typography'
import TelegramIcon from '@material-ui/icons/Telegram';
import Badge from '@material-ui/core/Badge';

import { isEmpty, find } from 'lodash'
import graphql from '../../graphql/client'
import { Datatable, IconColName } from "../../components/datatable/";
import { green } from '@material-ui/core/colors';

import icon_face from "../../icons/bxs-face.svg";
import icon_email from "../../icons/bxs-envelope.svg";
import icon_state from "../../icons/bx-check.svg";
import icon_phone from "../../icons/bxs-phone.svg";
import icon_calc from "../../icons/bxs-calendar.svg";
import icon_map from "../../icons/bxs-map-alt.svg";
import icon_msg from "../../icons/bxs-message-square-detail.svg";
import icon_col from "../../icons/bx-columns.svg";
import icon_type from "../../icons/bxs-user-detail.svg";
import icon_owner from "../../icons/bxs-lock.svg";
import icon_conversation from "../../icons/bxs-conversation.svg";
import icon_id from "../../icons/bxs-id-card.svg";
import icon_downArrow from "../../../../assets/images/bxs-down-arrow.svg";
import icon_users from "../../../../assets/images/friends.svg";
import icon_chat from "../../../../assets/images/botChatIcon.png";
import icon_save from "../../icons/bx-save.svg";

import {errorMessage, successMessage} from '../../actions/status_messages'
import {
  CREATE_CAMPAIGN,
  DELIVER_USER_AUTO_MESSAGE,
  CREATE_NEW_USER,
  CREATE_NEW_LEAD,
  UPDATE_SEGMENET,
} from '../../graphql/mutations'
import ConversationHeader from '../ConversationHeader'

import ExportUsers from './exportUsers'
import ArchiveUsers from './archiveUsers'

import Moment from 'react-moment';
import 'moment-timezone';
import { countries } from 'country-data';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position:relative;
`;

const DataTableWrapper = styled.div`
  padding: 20px 40px 100px;
  border: 1px solid rgba(0,0,0,0.12);
  border-radius: 7px;
  min-height: 450px;
  width: 100%;
  /* @media screen and (min-width: 480px) {
    width: 400px;
  }
  @media screen and (min-width: 600px) {
    width: 550px;
  }
  @media screen and (min-width: 768px) {
    width: 750px;
  }
  @media screen and (min-width: 900px) {
    width: 850px;
  }
  @media screen and (min-width: 1024px) {
    width: 1000px;
  }
  @media screen and (min-width: 1200) {
    width: 1150px;
  }
  @media screen and (min-width: 1400) {
    width: 1350px;
  }
  @media screen and (min-width: 1600) {
    width: 1350px;
  } */
`;

const ButtonGroup = styled.div`
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 1em;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  margin-bottom: 1em;
  button {
    margin-right: 5px !important;
    margin: 2px;
    padding: 15px 25px;
    position:relative;
  }
  span {
    fontWeight: 400;
  }
`;

const EditColumnButtons = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  button {
    margin-right: 5px !important;
    margin: 2px;
    padding: 15px 25px;
    position:relative;
  }
`

const ButtonGroup1 = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  position:relative;
`;
const MuiToolbarRegular = styled.div`
  min-height: 0;
`;

const MessageTextEditor = styled.div`
  .danteEditorControls {
     div.inlineTooltip:last-child {
      top: 350px !important;
      left: 0 !important;
      opacity: 1;
      .inlineTooltip-button.control {
        display: none !important;
      }
      .inlineTooltip-menu {
        pointer-events: none;
        width: 300px !important;
        button {
          transform: scale(1);
          border: 0;
        }
      }
    }
    div.inlineTooltip.is-active:last-child {
      .inlineTooltip-menu {
        pointer-events: auto;
      }
    }
  }
`;

class AppContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewLead: false,
      openNewUser: false,
      openImportContact: false
    };
    this.getSegment = this.getSegment.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(setCurrentSection("Platform"));

    this.props.dispatch(
      setCurrentPage(`segment-${this.props.match.params.segmentID}`)
    );

    this.props.dispatch(
      dispatchSegmentUpdate({
        id: this.props.match.params.segmentID,
        jwt: this.props.match.params.Jwt,
      })
    );

    this.getSegment(() => {
      this.props.actions.search();
    });
  }

  getSegment() {
    const segmentID = this.props.match.params.segmentID;
    segmentID ? this.props.actions.fetchAppSegment(segmentID) : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params &&
      prevProps.match.params.segmentID !== this.props.match.params.segmentID
    ) {
      this.props.dispatch(
        dispatchSegmentUpdate({
          id: this.props.match.params.segmentID,
          jwt: this.props.match.params.Jwt,
        })
      );

      this.props.dispatch(
        setCurrentPage(`segment-${this.props.match.params.segmentID}`)
      );

      this.getSegment(() => {
        this.props.actions.search();
      });
    }

    if (prevProps.segment.jwt !== this.props.segment.jwt) {
      // console.log("cambio jwt")
      this.props.actions.search();
    }

    // check empty token , used when same sagment changes jwt
    if (
      prevProps.match.params.Jwt !== this.props.match.params.Jwt &&
      !this.props.match.params.Jwt
    ) {
      this.props.dispatch(
        dispatchSegmentUpdate({
          jwt: this.props.match.params.Jwt,
        })
      );

      this.getSegment(() => {
        this.props.actions.search();
      });
    }
  }

  render() {
    const app = this.props.app
    const currentHeader =  {
      id: I18n.t("navigator.platform"),
      url: `/apps/${app.key}/segments/${
        app.segments && app.segments.length > 0 ? app.segments[0].id : ""
      }`,
      children: app.segments.map((o) => ({
        id: o.name,
        url: `/apps/${app.key}/segments/${o.id}`,
      })),
    }

    const { searching, collection, meta } = this.props.app_users;
    return (
      <div>
        <ConversationHeader history={this.props.history} appId={app.key} category={currentHeader} />
        {this.props.app.key && this.props.segment && this.props.segment.id ? (
          <AppUsers
            currentUser={this.props.currentUser}
            actions={this.props.actions}
            history={this.props.history}
            match={this.props.match}
            dispatch={this.props.dispatch}
            app={this.props.app}
            segment={this.props.segment}
            app_users={collection}
            app_user={this.props.app_user}
            meta={meta}
            searching={searching}
            openNewLead={this.state.openNewLead}
            openNewUser={this.state.openNewUser}
            openImportContact={this.state.openImportContact}
            handleCloseNewUserOpen={() => this.setState({ openNewUser: false })}
            handleCloseNewLeadOpen={() => this.setState({ openNewLead: false })}
            handleCloseImportContact={() => this.setState({ openImportContact: false })}
            handleNewUserOpen={() => this.setState({ openNewUser: true })}
            handleNewLeadOpen={() => this.setState({ openNewLead: true })}
            handleImportContact={() => this.setState({ openImportContact: true })}
          />
        ) : null}
      </div>
    );
  }
}

class AppUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map_view: false,
      rightDrawer: false,
      app_users: props.app_users,
      selected_users: [],
      visible_cols: [
        "displayName",
        "type",
        "email",
        "phone",
        "firstSeen",
        "lastSeen",
        "emailStatus"
      ],
      allSelected: false,
      dialogOpen: false,
      name: '',
      email: '',
      message: '',
      currentUser: this.props.currentUser,
      content: null,
      read_only: false,
    };
    this.toggleMap = this.toggleMap.bind(this);
    this.toggleList = this.toggleList.bind(this);
  }

  toggleMap = (e) => {
    this.setState({ map_view: false });
  };

  toggleList = (e) => {
    this.setState({ map_view: true });
  };

  toggleMapView = (e) => {
    this.setState({ map_view: !this.state.map_view });
  };

  handleClickOnSelectedFilter = (jwtToken) => {
    const url = `/apps/${this.props.app.key}/segments/${this.props.segment.id}/${jwtToken}`;
    this.props.history.push(url);
  };

  displayName = (o) => {
    let name = o.attribute.split("_").join(" ");
    return name == 'type' ? 'people' : name
  };

  getTextForPredicate = (o) => {
    if (o.type === "match") {
      return `Match ${o.value === "and" ? "all" : "any"} criteria`;
    } else {
      return `${this.displayName(o)} ${o.comparison ? o.comparison : ""} ${
        o.value ? o.value : ""
      }`;
    }
  };

  getStatePredicate = () => {
    return this.props.actions
      .getPredicates()
      .find((o) => o.attribute === "subscription_state");
  };



  saveSegment(vc){

    graphql(
      UPDATE_SEGMENET,
      {
        id: this.props.segment.id,
        appKey: this.props.app.key,
        segmentParams: {
          config_table_columns: vc,
        },
      },
      {
        success: (data) => {
          console.log("success", data);

          // this.setState({
          //   data: data.campaignUpdate.campaign,
          // });
          // this.props.dispatch(successMessage("campaign updated"));
        },
        error: (data) => {
          console.log("error", data);
          //this.props.dispatch(errorMessage("error updating campaign"));
        },
      }
    );

  }

  handleToggleColumnVisible(v) {
    const vc = this.getVisibleColumns();
    let i = vc.indexOf(v);
    if (i === -1) vc.push(v);
    else vc.splice(i, 1);
    this.setState({
      visible_cols: vc,
    });
    this.saveSegment(vc)
  }

  getVisibleColumns() {
    return (this.props.segment && this.props.segment.configTableColumns) ? this.props.segment.configTableColumns : this.state.visible_cols
  }

  caption = (searchFilterColumn) => {
    return (
      <ButtonGroup>
        <div style={{ display: "flex", flexWrap: 'wrap', width: '100%', justifyContent: 'flex-start' }}>
          { this.props.actions
            .getPredicates()
            .map((o, i) => {
              return (
                <SegmentItemButton
                  key={`segment-item-button-${i}`}
                  index={i}
                  predicate={o}
                  predicates={this.props.actions.getPredicates()}
                  open={!o.comparison}
                  appearance={o.comparison ? "primary" : "default"}
                  text={this.getTextForPredicate(o)}
                  updatePredicate={this.props.actions.updatePredicate}
                  predicateCallback={(jwtToken) =>
                    this.handleClickOnSelectedFilter.bind(this)(jwtToken)
                  }
                  deletePredicate={this.props.actions.deletePredicate}
                />
              );
            })}

          <InlineFilterDialog
            {...this.props}
            searchFilterColumn={searchFilterColumn}
            handleClick={this.handleClickOnSelectedFilter.bind(this)}
            addPredicate={this.props.actions.addPredicate}
          />

          <SaveSegmentModal
            title="Save Segment"
            segment={this.props.segment}
            savePredicates={this.props.actions.savePredicates}
            predicateCallback={() => {
              const url = `/apps/${this.props.app.key}/segments/${this.props.segment.id}`;
              this.props.history.push(url);
            }}
            deleteSegment={this.props.actions.deleteSegment}
          />
        </div>

      </ButtonGroup>
    );
  };

  showUserDrawer = (o) => {
    this.props.actions.showUserDrawer(o.id);
  };

  getUserData = (id) => {
    this.props.actions.setAppUser(id);
  };

  handleUserSubmit = (name, email) => {
    const options = {}
    options["name"] = name
    options["email"] = email
    graphql(CREATE_NEW_USER, {
      appKey: this.props.app.key,
      options: options
    }, {
      success: (data)=>{
        if(isEmpty(data.createAppUser.errors)){
          dispatch(successMessage("New User Created"))
        }
        else{
          dispatch(errorMessage(data.createAppUser.errors[0]))
        }
      },
      error: (error)=>{
        console.log("ERRR Creating User", error)
      }
    })
    this.props.handleCloseNewUserOpen()
    window.location.reload();
  }

  handleLeadSubmit = (name, email) => {
    const options = {}
    options["name"] = name
    options["email"] = email
    graphql(CREATE_NEW_LEAD, {
      appKey: this.props.app.key,
      options: options
    }, {
      success: (data)=>{
        if(isEmpty(data.createNewLead.errors)){
          dispatch(successMessage("New Lead Created"))
        }
        else{
          dispatch(errorMessage(data.createNewLead.errors[0]))
        }
      },
      error: (error)=>{
        console.log("ERRR Creating Lead", error)
      }
    })
    this.props.handleCloseNewLeadOpen()
    window.location.reload();
  }

  beautifyName = (name) => {
    let finName = ""
    if (name && !name.startsWith('visitor')){
      finName = name
    }
    return finName
  }

  handleSendInApp = (row) => {
    this.setState({ dialogOpen: true, email: row.email, name: row.displayName })
  }

  render() {
    const visible_cols = this.props.segment.configTableColumns || this.state.visible_cols;
    const columns = [
      {
        name: <IconColName icon={icon_face}>Name</IconColName>,
        selector: "displayName",
        sortable: true,
        width: "200px",
        grow: 2,
        omit: visible_cols.indexOf("displayName") === -1,
        cell: (row) => (
          <>
            <Avatar
              alt="Remy Sharp"
              src={row.avatarUrl}
              style={{
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                marginRight: 8,
              }}
              onClick={() => this.showUserDrawer(row)}
            />
            &nbsp;
            <a href="#" onClick={() => this.showUserDrawer(row)} style={{ color: '#000', fontSize: 15, textTransform: 'capitalize' }}>{this.beautifyName(row.properties.name)}</a>
            <TelegramIcon onClick={() => this.handleSendInApp(row)} />
          </>
        ),
      },
      {
        name: <IconColName icon={icon_type}>Type</IconColName>,
        selector: "type",
        sortable: true,
        width: "100px",
        grow: 2,
        omit: visible_cols.indexOf("type") === -1,
        cell: (row) => "User",
      },
      {
        name: <IconColName icon={icon_owner}>Owner</IconColName>,
        selector: "owner",
        sortable: true,
        width: "110px",
        grow: 2,
        omit: visible_cols.indexOf("owner") === -1,
        cell: (row) =>
          typeof row.owner != "undefined" && row.owner !== null ? (
            row.owner
          ) : (
            <span style={{ color: "#ccc" }}>Unknown</span>
          ),
      },
      {
        name: (
          <IconColName icon={icon_conversation}>
            Conversation rating
          </IconColName>
        ),
        selector: "conversationRating",
        sortable: true,
        width: "200px",
        grow: 2,
        omit: visible_cols.indexOf("conversationRating") === -1,
        cell: (row) =>
          typeof row.conversationRating != "undefined" &&
          row.conversationRating !== null ? (
            row.conversationRating
          ) : (
            <span style={{ color: "#ccc" }}>Unknown</span>
          ),
      },
      {
        name: <IconColName icon={icon_email}>Email</IconColName>,
        selector: "email",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("email") === -1,
      },
      {
        name: <IconColName icon={icon_phone}>Phone</IconColName>,
        selector: "phone",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("phone") === -1,
        cell: (row) =>
          typeof row.phone != "undefined" && row.phone !== null ? (
            row.phone
          ) : (
            <span style={{ color: "#ccc" }}>Unknown</span>
          ),
      },
      {
        name: <IconColName icon={icon_state}>State</IconColName>,
        selector: "state",
        sortable: true,
        width: "120px",
        omit: visible_cols.indexOf("state") === -1,
        // cell: row => {
        //   if(row.state == 'subscribed')
        //     return <Button color='primary' style={{textTransform: 'capitalize'}}>{row.state}</Button>
        //   if(row.state == 'passive')
        //     return <Button disabled style={{textTransform: 'capitalize'}}>{row.state}</Button>
        // }
      },
      {
        name: "Online",
        selector: "online",
        sortable: true,
        width: "80px",
        omit: visible_cols.indexOf("online") === -1,
        cell: (row) =>
          row.online === true ? (
            <RadioButtonCheckedIcon
              fontSize="small"
              style={{ color: green[500] }}
            />
          ) : (
            <RadioButtonUncheckedIcon fontSize="small" color="disabled" />
          ),
      },
      {
        name: "Browser Lang",
        selector: "browserLanguage",
        sortable: true,
        width: "130px",
        omit: visible_cols.indexOf("browserLanguage") === -1,
      },
      {
        name: "Referrer",
        selector: "referrer",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("referrer") === -1,
      },
      {
        name: "OS",
        selector: "os",
        sortable: true,
        width: "100px",
        omit: visible_cols.indexOf("os") === -1,
      },

      {
        name: "City",
        selector: "city",
        sortable: true,
        width: "100px",
        omit: visible_cols.indexOf("city") === -1,
      },
      {
        name: "Country",
        selector: "country",
        sortable: true,
        width: "100px",
        omit: visible_cols.indexOf("country") === -1,
        cell: row => (
          <div>
            {countries[row.country] ? countries[row.country].name : row.country}
          </div>
        )
      },

      {
        name: "OS version",
        selector: "osVersion",
        sortable: true,
        width: "120px",
        omit: visible_cols.indexOf("osVersion") === -1,
      },
      {
        name: "Lang",
        selector: "lang",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("lang") === -1,
      },
      {
        name: "Web sessions",
        selector: "webSessions",
        sortable: true,
        width: "130px",
        omit: visible_cols.indexOf("webSessions") === -1,
      },
      {
        name: <IconColName icon={icon_calc}>Created at</IconColName>,
        selector: "createdAt",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("createdAt") === -1,
        cell: (row) => (row && row.createdAt) ? <Moment fromNow>{row.createdAt}</Moment> : undefined,
      },
      {
        name: <IconColName icon={icon_calc}>First seen</IconColName>,
        selector: "firstSeen",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("firstSeen") === -1,
        cell: (row) => (row && row.firstSeen) ? <Moment fromNow>{row.firstSeen}</Moment> : undefined,
      },
      {
        name: <IconColName icon={icon_calc}>Last seen</IconColName>,
        selector: "lastSeen",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("lastSeen") === -1,
        cell: (row) => (row && row.lastSeen) ? <Moment fromNow>{row.lastSeen}</Moment> : undefined,
      },
      {
        name: <IconColName icon={icon_calc}>Last visited at</IconColName>,
        selector: "lastVisitedAt",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("lastVisitedAt") === -1,
        cell: (row) => (row && row.lastVisitedAt) ? <Moment fromNow>{row.lastVisitedAt}</Moment> : undefined,
      },
      {
        name: <IconColName icon={icon_email}>Email Status</IconColName>,
        selector: "emailStatus",
        sortable: true,
        width: "180px",
        omit: visible_cols.indexOf("emailStatus") === -1,
      }
    ];

    //const app_users = this.props.app_users.filter((o) => { return ( o.email) });
    const app_users = this.props.app_users;
    const searchFilterColumn = []

    app_users.map((appUser, index) => {
      for (let [key, value] of Object.entries(appUser.properties)) {
        app_users[index][key] = value
        if (!find(searchFilterColumn, {"name": key}) && key != "name")
          searchFilterColumn.push({"name": key, "type": typeof(value)})
      }
    })

    visible_cols.map((column) => {
      if(!find(columns, {"selector": column })) {
        columns.push({
          name: column,
          selector: column,
          sortable: true,
          width: "180px",
          omit: visible_cols.indexOf(column) === -1,
          cell: (row) => row[column]
        })
      }
    })


    const handlePageChange = async page => {
      console.log("handlePageChange", page)
      this.props.actions.search(page);
      // const { perPage } = this.state;

      // this.setState({ loading: true });

      // const response = await axios.get(
      //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
      // );

      // this.setState({
      //   loading: false,
      //   data: response.data.data,
      // });
    }

    const handlePerRowsChange = async (perPage, page) => {
      console.log("handlePerRowsChange", page)

      // this.setState({ loading: true });

      // const response = await axios.get(
      //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
      // );

      // this.setState({
      //   loading: false,
      //   data: response.data.data,
      //   perPage,
      // });
    }

    const handleSelectAll = async ({allSelected, selectedCount, selectedRows}) => {
      this.setState({ selected_users: selectedRows })
      this.setState({ allSelected: allSelected })
    }

    const screenWidth = window.innerWidth - 130;

    const handleClose = () => {
      this.setState({ dialogOpen: false })
    }

    const saveContent = (content) => {
      this.setState({
        content: {
          html: content.html,
          serialized: content.serialized
        }
      })
    }

    const uploadHandler = ({serviceUrl, signedBlobId, imageBlock}) => {
      // graphql(ARTICLE_BLOB_ATTACH, {
      //   appKey: this.props.app.key ,
      //   id: parseInt(this.state.article.id),   change the article id to below message id
      //   blobId: signedBlobId
      // }, {
      //   success: (data)=>{
      //     imageBlock.uploadCompleted(serviceUrl)
      //   },
      //   error: (err)=>{
      //     console.log("error on direct upload", err)
      //   }
      // })
    }

    const handleSend = () => {
      const data = {
        id: null,
        name: 'In app message',
        content_type: 'email',
        message_type: 'fullMessage',
        text_message: [],
        from_name: this.state.currentUser.firstName,
        from_email: this.state.currentUser.email,
        reply_email: this.state.currentUser.email,
        template: 'plain',
        html_content: this.state.content.html,
        serialized_content: this.state.content.serialized,
        scheduled_to: moment().format(),
        scheduled_at: moment().format(),
        timezone: moment.tz.guess(),
      }
      graphql(
        CREATE_CAMPAIGN,
        {
          appKey: this.props.app.key,
          mode: 'user_auto_messages',
          operation: "create",
          campaignParams: data,
          email: this.state.email,
        },
        {
          success: (successData) => {
            const id = successData.campaignCreate.campaign.id

            if (successData.errors) {
              this.props.dispatch(errorMessage("error updating campaign"));
            } else {
              graphql(
                DELIVER_USER_AUTO_MESSAGE,
                {
                  appKey: this.props.app.key,
                  id,
                }, {
                  success: (success) => {
                    this.props.dispatch(successMessage('In-app mesage delivered successfully'));
                    handleClose();
                  }
                })
            }
          },
          error: (error) => {
            console.error('ERROR', error)
          }
        },
      );
    }
    return (
      <>
        <Dialog
          open={this.state.dialogOpen}
          onClose={handleClose}
          maxWidth='sm'
          fullWidth={true}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <Typography variant="subtitle1" style={{ fontWeight: '600', padding: '20px 30px', borderBottom: '1px solid rgba(0,0,0,.1)', fontSize: 18 }}>In app</Typography>
          <Typography variant="body1" style={{ fontWeight: '400', padding: '10px 30px', borderBottom: '1px solid rgba(0,0,0,.1)', fontSize: 15 }}>
            To:
            <span>
              <Badge>
                <Avatar
                  name={this.state.email}
                  size="medium"
                  style={{ background: '#f0b3fc', color: '#fff', fontSize: 14, textTransform: 'uppercase', margin: '0 8px' }}
                >
                  {this.state.name.substring(0, 2)}
                </Avatar>
              </Badge>
              {this.state.name}
            </span>
          </Typography>
          <DialogContent style={{height: '200px', display: 'flex', overflow: 'hidden', padding: '8px 30px'}}>
            <DialogContentText id='alert-dialog-description' style={{ width: '100%', overflowY: 'auto' }}>
              <MessageTextEditor>
                <TextEditor
                  campaign={true}
                  uploadHandler={uploadHandler}
                  loading={false}
                  read_only={this.state.read_only}
                  toggleEditable={() => {
                    this.setState({ read_only: !this.state.read_only });
                  }}
                  serializedContent={this.state.content ? this.state.content.serializedContent : null}
                  data={{
                    serialized_content: this.state.content ? this.state.content.serializedContent : null,
                  }}
                  styles={{ lineHeight: '1.2em', fontSize: '13px' }}
                  updateState={({ status, statusButton, content }) => {
                    saveContent(content);
                  }}
                />
              </MessageTextEditor>
            </DialogContentText>
          </DialogContent>

          <DialogActions style={{ padding: '15px 40px', borderTop: '1px solid rgba(0,0,0,.1)' }}>
            <Button onClick={handleClose} color='secondary' variant="outlined" style={{ padding: '10px 15px' }}>
              Cancel
            </Button>
            <Button onClick={handleSend} color='primary' variant="contained" style={{ background: "#FFB600", color: "#000", padding: '11px 25px' }}>
              Send
            </Button>
          </DialogActions>
        </Dialog>
        <Wrapper style={{ padding: '70px 40px 0 40px' }}>
          <div style={{ display: 'flex', marginBottom: 30, marginTop: '20px', width: '100%' }}>
            <span
              style={{
                margin: "unset",
                marginBottom: "10px",
                fontSize: "42px",
                fontWeight: "400",
              }}
            >
              {this.props.meta.total_count}
            </span>
            <span style={{ textTransform: 'capitalize', margin: "unset", fontWeight: "400", fontSize: 20, padding: '25px 15px'}}>
              {this.props.segment.name || "All"}
            </span>
            <EditColumnList
              visible_cols={visible_cols}
              options={userFormat(this.showUserDrawer, this.props.app, searchFilterColumn)}
              onChange={(v) => this.handleToggleColumnVisible(v)}
            />
            <NewUserOrLead
              app={this.props.app}
              openNewLead={this.props.openNewLead}
              openNewUser={this.props.openNewUser}
              openImportContact={this.props.openImportContact}
              handleUserSubmit={this.handleUserSubmit}
              handleLeadSubmit={this.handleLeadSubmit}
              handleCloseNewUserOpen={this.props.handleCloseNewUserOpen }
              handleCloseNewLeadOpen={this.props.handleCloseNewLeadOpen}
              handleCloseImportContact={this.props.handleCloseImportContact}
              handleNewUserOpen={this.props.handleNewUserOpen}
              handleNewLeadOpen={this.props.handleNewLeadOpen}
              handleImportContact={this.props.handleImportContact}
            />
          </div>
          {this.caption(searchFilterColumn)}

          {/*{this.state.map_view &&*/}
          {/*!this.props.searching &&*/}
          {/*this.props.app.key &&*/}
          {/*this.props.segment &&*/}
          {/*this.props.segment.id ? (*/}
          {/*  <Map*/}
          {/*    interactive={true}*/}
          {/*    segment={this.props.segment}*/}
          {/*    data={app_users}*/}
          {/*  />*/}
          {/*) : null}*/}
          <Container component="main" style={{ maxWidth: screenWidth, padding: 0 }}>
          <DataTableWrapper>
            <ButtonGroup1 style={{ margin: '20px 0' }} >
              <ExportUsers selectedUsers={this.state.selected_users} app={this.props.app} dispatch={this.props.dispatch} allSelected={this.state.allSelected} />
              <ArchiveUsers selectedUsers={this.state.selected_users} app={this.props.app} dispatch={this.props.dispatch} allSelected={this.state.allSelected} />
            </ButtonGroup1>

            {!this.props.searching && (
              <Datatable
                columns={columns}
                data={app_users}
                onSelectedRowsChange={(s) =>
                  this.setState({ selected_users: s.selectedRows })
                }
                pagination
                paginationServer
                paginationPerPage={20}
                paginationRowsPerPageOptions={[20]}
                paginationDefaultPage={this.props.meta.current_page}
                paginationTotalRows={this.props.meta.total_count}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                sortServer={false}
                onSelectedRowsChange={handleSelectAll}
              />
            )}

            {this.props.searching && <CircularProgress />}
          </DataTableWrapper>
          </Container>
        </Wrapper>
      </>
    );
  }
}

function EditColumnList(props) {
  const [columnSearch, setColumnSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  //const [options, setOptions] = React.useState(props.options);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChange(o) {
    const item = Object.assign({}, o, { hidden: !o.hidden });
    const columns = props.options.map((o) => {
      return o.title === item.title ? item : o;
    });
    //setOptions(columns)
    //console.log(item)
    props.handleChange(columns);
    //setOptions()
  }

  function handleColumnSearchChange(e) {
    setColumnSearch(e.target.value);
  }

  //React.useEffect(() => console.log('value changed!'), [props.options]);

  return (
    <EditColumnButtons>
      <Button
          isLoading={false}
          variant="contained"
          onClick={null}
      >

        &nbsp;&nbsp;&nbsp;
        <img src={icon_chat} style={{ height: "20px" }} />
        &nbsp;&nbsp;New Message&nbsp;&nbsp;&nbsp;
      </Button>

      <Button
        variant="outlined"
        color="primary"
        onClick={handleClick}
        aria-controls="simple-menu"
        aria-haspopup="true"
        justifyContent="flex-end"

      >
        &nbsp;&nbsp;&nbsp;
        <img src={icon_col} style={{ height: "20px" }} />
        &nbsp;&nbsp;Edit columns&nbsp;&nbsp;&nbsp;
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Input
          placeholder="Search column"
          onChange={handleColumnSearchChange}
        />
        <div className="list-scroll-menu" style={{ height: "500px", width: '360px', overflow: 'auto' }}>
          {props.options
            .filter((o) => {
              return (
                o.title.toLowerCase().indexOf(columnSearch.toLowerCase()) > -1
              );
            })
            .map((o) => (
              <MenuItem
                key={`simple-menu-${o.title}`}
                style={{ paddingLeft: "7px 20px" }}
              >
                <Checkbox
                  checked={props.visible_cols.indexOf(o.field) !== -1}
                  inputProps={{
                    "aria-label": "primary checkbox",
                  }}
                  onChange={() => props.onChange(o.field)}
                />
                {o.title}
              </MenuItem>
            ))}
        </div>
      </Menu>
    </EditColumnButtons>
  );
}

const userFormat = function (showUserDrawer, app, searchFilterColumn) {
  let opts = [
    //{field: 'id', title: 'id' },
    {
      field: "displayName",
      title: "Name",
      render: (row) => {
        return (
          row && (
            <NameWrapper onClick={(e) => showUserDrawer && showUserDrawer(row)}>
              <AvatarWrapper>
                <UserBadge row={row} />
              </AvatarWrapper>

              <Grid container direction={"column"}>
                <Typography variant="overline" display="block">
                  {row.displayName}
                </Typography>

                <Typography variant={"caption"}>{row.email}</Typography>
              </Grid>
            </NameWrapper>
          )
        );
      },
    },
    { field: "type", title: "Type" },
    { field: "owner", title: "Owner", hidden: true  },
    { field: "conversationRating", title: "Conversation Rating", hidden: true  },
    { field: "email", title: "email" },
    { field: "phone", title: "Phone" },
    {
      field: "state",
      title: "State",
      render: (row) => {
        return (
          <Chip
            color={row.state === "subscribed" ? "primary" : "secondary"}
            label={row.state}
            clickable={false}
          />
        );
      },
    },
    { field: "online", title: "Online", hidden: true },
    { field: "browserLanguage", title: "Browser Language", hidden: true },
    { field: "referrer", title: "Referrer", hidden: true },
    { field: "os", title: "OS", hidden: true },
    { field: "osVersion", title: "OS Version", hidden: true },
    { field: "lang", title: "Lang", hidden: true },
    { field: "webSessions", title: "Web sessions" },
    { field: "lastSeen", title: "Last seen" },
    { field: "firstSeen", title: "First seen" },
    { field: "city", title: "City" },
    { field: "country", title: "Country" },

    {
      field: "createdAt",
      title: "Created at",
      render: (row) =>
        row ? <Moment utc fromNow>{row.createdAt}</Moment> : undefined,
    },
    {
      field: "lastVisitedAt",
      title: "Last visited at",
      render: (row) =>
        row ? <Moment utc fromNow>{row.lastVisitedAt}</Moment> : undefined,
    },
  ];
  searchFilterColumn.map((column) => { opts.push({field: column.name, title: column.name}) })

  if (app.customFields && app.customFields.length > 0) {
    const other = app.customFields.map((o) => ({
      hidden: true,
      field: o.name,
      title: o.name,
      render: (row) => row && row.properties[o.name],
    }));
    opts = opts.concat(other);
  }

  return opts;
};

function mapStateToProps(state) {
  const { auth, app, segment, app_users, app_user } = state;
  const { loading, isAuthenticated } = auth;

  const { searching, meta } = app_users;

  return {
    app_user,
    app_users,
    searching,
    meta,
    segment,
    app,
    loading,
    isAuthenticated,
  };
}

export default withRouter(connect(mapStateToProps)(AppContent));
