import React, {
  useState,
  useEffect,
  useRef
} from 'react'

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import group_icon from '../../../assets/images/bxs-group.svg';
import ConversationHeader from '../components/ConversationHeader'

import Progress from '../shared/Progress'
import {errorMessage, successMessage} from '../actions/status_messages'
import { setCurrentPage, setCurrentSection } from "../actions/navigation";

import graphql from '../graphql/client'
import {
  AVAILABLE_PLANS,
  BILLING_INFO,
  AGENT_DISCOUNT
} from '../graphql/queries'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import serialize from 'form-serialize'

import SplitForm from "../components/StripeForm"


import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PlanPayment from "./payment"
import moment from 'moment'
//import dotenv from  'dotenv'

function Pricing({history, app, dispatch}){
   useEffect(()=>{
      dispatch(setCurrentSection("Settings"))
      dispatch(setCurrentPage("pricing"))
      fetchPlans()
      fetchBilling()
    }, [])

  const [existingPlan, setExistingPlan] = React.useState({});
  const [selectedPlan, setSelectedPlan] = React.useState({});
  const [planCategories, setPlanCategories] = useState([])
  const [plans, setPlans] = useState([])
  const [lifeTimeDeal, setLifeTimeDeal] = useState(false)
  const [value, setValue] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [viewBilling, setViewBilling] = useState(false);
  const [billingInfo, setBillingInfo] = useState({first_name:'', last_name:'', email:''});
  const useStyles = makeStyles(theme => ({
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
      ul: {
        margin: 0,
        padding: 0,
      },
      li: {
        listStyle: 'none',
      },
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      flexGrow: 1,
      color: 'black'
    },
    link: {
      margin: theme.spacing(1, 1.5),
    },
    heroContent: {
      padding: theme.spacing(2, 0, 3),
    },
    cardHeader: {
      border: 0,
      padding: 11,
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
    },

    cardSelected:{
      background: 'rgba(255,182,0,.1)'
    },
    seatColor: {
      color: '#777777'
    },
    boldColor: {
      color: '#000000'
    },
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
    sliderContainer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(1),
      },
    },
    paymentSummaryHeader: {
      backgroundColor: '#000', color: 'white', padding: '35px 28px',
    },
    listHeading: {
      fontSize: 18,
      paddingBottom: 20,
      "& .MuiButton-label": {fontWeight: 800}
    },
    root: {
      height: 120,
      width: '100%',
    },
    slider: {
      position: 'relative',
      width: '100%',
      minHeight: '10px'
    },
    cardRoot: {
      borderRadius: 7, boxShadow: 'none', border: '1px solid #E5E5E5'
    },
    upgradeBtn: {
      backgroundColor: '#FFB600',
    },
  }));

  const freeDescriptions = ['All the basics for businesses that are just getting started.'];
  const standardDescriptions = ['Better insights for growing businesses that want more customers.'];
  const premiumDescriptions = ['Advanced features for pros who need more customization..'];


  const summary = {
      title: 'Payment Summary',
      price: '30',
      description: [
        'Plan ',
        'Details'
      ],
      buttonText: 'UPGRADE',
      buttonVariant: 'contained',
    }

  const footers = [
    {
      title: `${lifeTimeDeal ? "Life Time Deal" : "Start"}`,
      description: [,
                    'Manage inbound conversations',
                    'Live chat',
                    'Mobile App',
                    'Collaborate as a team',
                    '1 seats',
                    'Team Inbox',
                    'Notes',
                    'Saved replies',
                    'Support',
                    'Email support',
                    'Chat support'],
    },
    {
      title: 'Grow',
      description: [
                    'Manage inbound conversations',
                    'Live chat',
                    'Mobile App',
                    'Email',
                    'Email customization',
                    'Automation workflows',
                    'Email collector',
                    'Lead and customer profiles',
                    'Team availability and away mode',
                    'Automated lead qualification',
                    'View from inbox',
                    'Customer conversation data',
                    'Collaborate as a team',
                    '3 seats',
                    'Team Inbox',
                    'Notes @ mentions',
                    'Saved replies',
                    'Assignment rules',
                    'General team activity logs',
                    'Send proactive messages',
                    'Auto and manual messages',
                    'Audience targeting',
                    'Message triggers and scheduling',
                    'Smart Campaign',
                    'Customer email templates',
                    'Custom email domains',
                    'Message version history',
                    'Reports',
                    'Summary dashboard',
                    'Lead dashboard',
                    'Message insights',
                    'CSV export',
                    'Integrations',
                    'Slack',
                    'Messenger',
                    'Zoom',
                    'Calendly',
                    'Twitter',
                    'Support',
                    'Email support',
                    'Chat support'],
    },
    {
      title: 'Pro',
      description: [
                    'Manage inbound conversations',
                    'Live chat',
                    'Mobile App',
                    'Email',
                    'Email customization',
                    'Automation workflows',
                    'Email collector',
                    'Lead and customer profiles',
                    'Team availability and away mode',
                    'Automated lead qualification',
                    'View from inbox',
                    'Customer conversation data',
                    'Support triage bot',
                    'Custom bots',
                    'Task bots',
                    'Round robin',
                    'Collaborate as a team',
                    'Unlimited seats',
                    'Team Inbox',
                    'Notes @ mentions',
                    'Saved replies',
                    'Assignment rules',
                    'General team activity logs',
                    'Send proactive messages',
                    'Auto and manual messages',
                    'Audience targeting',
                    'Message triggers and scheduling',
                    'Smart Campaign',
                    'Customer email templates',
                    'Custom email domains',
                    'Message version history',
                    'Reports',
                    'Summary dashboard',
                    'Lead dashboard',
                    'Message insights',
                    'CSV export',
                    'Integrations',
                    'Unlimited integrations',
                    'Access to roadmap for more integrations',
                    'Support',
                    'Email support',
                    'Chat support',
                    'Phone and Video calls with our team',
                    'Prioritized Support'],
    },
  ];

  const PrettoSlider = withStyles({
    root: {
      color: '#FFB600',
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);


  const classes = useStyles();
// const state = {
//    values: defaultValues.slice(),
//    update: defaultValues.slice(),
//  }

  const [discountTime, setDiscountTime] = React.useState(null)
  const checkAgentDiscount = () => {
    graphql(AGENT_DISCOUNT, {}, {
      success: (data)=>{
        let time = data.agentDiscount
        let timeDifference = moment(time).add(1, 'day')-moment()
        if(time && timeDifference > 0)
          setDiscountTime(timeDifference)
        else
          setDiscountTime(null)
      },
      error: (err)=>{
        console.log("err", err)
      }
    })
  }

  function kFormatter(num) {
      return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
  }

  function planChanged(plan){
    setSelectedPlan(plan);
  }



  function onUpdate(update){
    console.log("update", update);
    //this.setState({ update })
  }

  function onChange(values){
    console.log("onChange", values);
    //this.setState({ values })
  }

  function valuetext(value) {
    return `${value}°C`;
  }

  function fetchPlans(){
    setLoading(true)
    setPlans([]);

    graphql(AVAILABLE_PLANS, { appKey: app.key  }, {
      success: (data)=>{
        setLoading(false);
        console.log("app", data.app );
        setDefaults(data.app.availablePlans, app.stripePlanId);
        let plansData = data.app.availablePlans
        if(data.app.hasLifeTimeDeal) {
          plansData[0].name = "Life Time Deal"
          setLifeTimeDeal(true)
        }
        setPlans(plansData);

      },
      error: (data)=>{
        setLoading(false);
        console.log("error data", data);

      }
    })
  }

  function setDefaults(plans, currentStripePlanId){
    const plan = plans.find(x => x.stripePlanId === currentStripePlanId);
    setSelectedPlan(plan);
    setExistingPlan(plan);
    checkAgentDiscount();
  }



  function fetchBilling(){
    setLoading(true)
    setBillingInfo([]);
    graphql(BILLING_INFO, { appKey: app.key  }, {
      success: (data)=>{
        setLoading(false);
        console.log("billing",data.app.billing );
        setBillingInfo(data.app.billing );
      },
      error: (data)=>{
        setLoading(false);
        console.log("error data", data);

      }
    })
  }

  var defaultPlans = plans;
  var newTiers = []

  defaultPlans && defaultPlans.forEach(function(plan) {
    var desc = plan.name == "Life Time Deal" ? premiumDescriptions : freeDescriptions;
    if(plan && ((plan.name.toLowerCase() == 'pro' && plan.amountCents === 2500) || plan.name.toLowerCase() == 'grow'))
      desc = standardDescriptions;
    else if(plan && (plan.name.toLowerCase() == 'unlimited' || plan.name.toLowerCase() == 'pro'))
      desc = premiumDescriptions;
    if(plan){
      var planObj = plan;
      planObj.description = desc;
      newTiers.push(planObj)
    }
  });

  const currentHeader ={
    id: `Current Plan: ${selectedPlan ? selectedPlan.name : ""}`,
    children: [],
  }

  const isLifeTimeDeal = (title) =>  title == "Life Time Deal"

  return (
    <React.Fragment>

      <CssBaseline />
      <ConversationHeader history={history} appId={app.key} category={currentHeader} />
      <Container maxWidth="lg" component="main" style={{paddingTop: '170px'}}>
        {viewBilling && <Button variant="outlined" color="primary" style={{marginLeft: 25, padding: '15px 40px'}} onClick={(e) => setViewBilling(false)}  > Back </Button> }
        {viewBilling && <Grid item xs={12} ><PlanPayment selectedPlan={selectedPlan} dispatch={dispatch} billingInfo={billingInfo} discountTime={discountTime} /></Grid>}
        {!viewBilling && <Grid container alignItems="flex-start">
            <Grid item xs={12} md={9} >


                <Container maxWidth="lg" component="main">
                  <Grid container spacing={4} alignItems="flex-end">
                    {newTiers && newTiers.map(tier => (
                      // Enterprise card is full width at sm breakpoint
                      <Grid item key={tier.name} xs={12} md={4} >
                        <Card className={ ((selectedPlan && selectedPlan.name == tier.name) ? classes.cardSelected : '')}  style={{minHeight: '425px', padding: '20px 10px', border: '1px solid #E5E5E5', borderRadius: 7, boxShadow: 'none'}} >
                          <img src={group_icon} style={{padding: '30px 47% 0'}} />
                          <CardHeader
                            title={tier.name}
                            subheader={tier.subheader}
                            titleTypographyProps={{ align: 'center' }}
                            subheaderTypographyProps={{ align: 'center' }}
                            action={(tier.name === 'Unlimited' || tier.name === 'Pro') ? <StarIcon /> : null}
                            className={classes.cardHeader}
                          />
                          <ButtonBase
                                onClick={e => { planChanged(tier)}}
                                style={{width: '100%', height: '100%'}}
                            >
                            <CardContent style={{ paddingTop: 0 }}>
                              <ul style={{minHeight: '50px', borderBottom: '1px solid #E5E5E5', marginBottom: '30px', paddingBottom: '20px'}}>
                                {tier.description.map(line => (
                                  <Typography component="li" variant="subtitle1" align="left" key={line} style={{ fontSize: 15, lineHeight: '20px', textAlign: 'center' }}>
                                    {line}
                                  </Typography>
                                ))}
                              </ul>

                              {!isLifeTimeDeal(tier.name) &&
                                <>
                                  <Typography  variant="h6" color="textPrimary">
                                    You Pay
                                  </Typography>
                                  {tier.amount !== "0.00" && discountTime ?
                                    <Box className={classes.cardPricing}>
                                      <Typography  variant="h4" color="textPrimary" style={{ fontSize: '38px', fontWeight: '500', textDecoration: 'line-through' }}>
                                        {`$${tier.amount}`}
                                      </Typography>
                                    </Box>
                                    :
                                    <Box className={classes.cardPricing}>
                                      <Typography  variant="h4" color="textPrimary" style={{ fontSize: '38px', fontWeight: '500' }}>
                                        {`$${tier.amount}`}
                                      </Typography>
                                    </Box>
                                  }
                                  {tier.amount !== "0.00" && discountTime &&
                                    <Typography  variant="h4" color="textPrimary" style={{ fontSize: '38px', fontWeight: '500' }}>
                                      {`$${tier.amount/2}`}
                                    </Typography>
                                  }
                                  <Typography variant="h6" color="textPrimary" style={{ fontSize: '15px', fontWeight: '400' }}>
                                    Month / Per App
                                  </Typography>
                                </>
                              }
                            </CardContent>
                          </ButtonBase>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Container>



                {/* Footer */}
                <Container maxWidth="lg" component="footer" className={classes.footer}>
                  <Typography variant="h6" align="left" color="primary" component="p" style={{fontSize: 38, fontWeight: 400}}>
                    Compare plan features
                  </Typography>
                  <br/>
                  <Grid container spacing={2} justify="space-evenly" mt={5}>
                    {footers.map(footer => (
                      <Grid item xs={12} md={4} key={footer.title}>

                        <ul>
                          <li key={'f-title'}>
                            <Link href="#" variant="h6" color="primary" className={classes.listHeading}>
                             {footer.title}
                            </Link>
                          </li>
                          {(isLifeTimeDeal(footer.title) ? footers[footers.length-1].description : footer.description).map(item => (
                            <li key={item}>
                              <Link href="#" variant="subtitle1" color="textSecondary">
                              <CheckCircleIcon />&nbsp;{item}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </Grid>
                    ))}
                  </Grid>
                </Container>
                {/* End footer */}

            </Grid>
            <Grid item xs={12} md={3} >

              <Grid container  alignItems="flex-start">
                <Grid item key={summary.title} xs={12}  md={12}>
                  { selectedPlan && <Card className={classes.cardRoot} >
                    <CardHeader
                      title={summary.title}
                      subheader={summary.subheader}
                      titleTypographyProps={{ align: 'center' }}
                      subheaderTypographyProps={{ align: 'center' }}
                      action={summary.title === 'Pro' ? <StarIcon /> : null}
                      className={classes.paymentSummaryHeader}
                    />
                    <ButtonBase
                          style={{width: '100%', height: '100%'}}
                      >
                      <CardContent style={{padding: '30px'}}>
                        <div className={classes.cardPricing}>
                          {selectedPlan.amount !== "0.00" && discountTime ?
                            <Typography component="h2" variant="h3" color="textPrimary" style={{fontSize: 38, fontWeight: '500'}}>
                              ${selectedPlan.amount/2}
                            </Typography>
                          :
                            <Typography component="h2" variant="h3" color="textPrimary" style={{fontSize: 38, fontWeight: '500'}}>
                              ${selectedPlan.amount}
                            </Typography>
                          }
                          <Typography variant="h6" color="textPrimary" style={{ fontSize: 15, fontWeight: '400', paddingLeft: 7 }}>
                            Month
                          </Typography>
                        </div>
                        <ul>
                          <Typography component="li" variant="subtitle1" align="center" style={{fontSize: 18, padding: '25px 0px'}}>
                            {selectedPlan.name} Plan
                          </Typography>




                        </ul>


                      </CardContent>
                    </ButtonBase>
                    <CardActions style={{padding: '16px 30px 30px'}}>
                      <Button fullWidth variant={summary.buttonVariant} className={classes.upgradeBtn} style={{ padding: '20px 20px', color: '#000' }} onClick={(e) => setViewBilling(true)} disabled={(selectedPlan && existingPlan && selectedPlan.name == existingPlan.name)} >
                        {summary.buttonText}
                      </Button>


                    </CardActions>
                  </Card>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      </Container>


    </React.Fragment>
  );
}




function mapStateToProps(state) {
  const { app } = state
  return {
    app,
  }
}


export default withRouter(connect(mapStateToProps)(Pricing))
