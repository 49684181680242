import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { IconButton, InputAdornment } from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { isEmpty } from "lodash";
import axios from "axios";
import serialize from "form-serialize";

import graphql from "../../graphql/client";
import { UPDATE_CAMPAIGN, CREATE_CAMPAIGN } from "../../graphql/mutations";

import FieldRenderer from "../../shared/FormFields";
import { toSnakeCase } from "../../shared/caseConverter";
import CampaignEditor from "./editor";
import group_icon from '../../../../assets/images/group.png'
import rotate_icon from '../../../../assets/images/rotateLeft.svg'
import styled from "@emotion/styled";
import CampaignGoal from './CampaignGoal'

//import moment from 'moment-timezone';

const TabContainer = styled.div`
  .MuiTabs-scroller {
    position: unset;
  }
  .MuiTab-textColorInherit {
    margin: 0;
    border-radius: 10px;
    background-color: transparent;
  }
  .MuiTab-textColorInherit.Mui-selected {
    background-color: #FAF6F1;
  }
`;

const DatePickerWrapper = styled.div`
  .MuiFormControl-root {
    border: 1px solid #E5E5E5;
    width: 15%;
    border-radius: 8px;
    margin-right: 20px;
  }
  label {
    display: none;
  }
  .MuiInput-formControl {
    margin-top: 0;

    &:after, &:before, hover:not(.Mui-disabled):before {
      border-bottom: 0 !important;
    }
  }
  .MuiInput-formControl.Mui-focused {
    background: #FAF6F1;
  }
  input {
    box-shadow: none;
    font-weight: 600;
    padding: 15px 30px;
  }

`

const TextField1 = withStyles({
  filterButton: {
    background: '#FFD300',
    border: 0,
    flex: 1,
    padding: 10,
    marginLeft: 10
  }
})(TextField);


const ContentMatchTitle = styled.h3`
  font-size: 21px;
  font-weight: 400;
`;

const ContentMatch = styled.div`
  overflow: auto;
  display: flex;
  align-items: center;
  /* width: 400px; */
  /* padding-left: 40px; */
  /* margin-bottom: 7px; */
  padding: 0 20px;
  padding-right: 40px;
  text-transform: capitalize;
`;

const ContentMatchFooter = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;

  bottom: 1px;
  padding: 1em;
  background: #F9F7F2;
  padding-left: 0;
  padding-right: 25px;
  width: 100%;

  button {
    background: #FFD300;
    border: 0;
    flex: 1;
    padding: 10px;
    margin-left: 10px;
  }
`;

const ContentMatchWrapper = styled.div`
  /* margin-bottom: 3em; */
`;
export default class CampaignSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventResult:
        "Click into and out of the input above to trigger onBlur & onFocus in the Fieldbase",
      data: this.props.data,
      errors: {},
      tabValue: this.props.data.sendCriteria || 'ongoing',
      scheduleValue: this.props.data.scheduleType || 'immediately',
      scheduled_at: this.props.data.scheduledAt || new Date,
      scheduled_to: this.props.data.scheduledTo || new Date,
      openUrlRule: false,
      openFilter: false,
      openUrlTime: false,
      urlSegmentRule: this.props.data.urlSegmentRule || [],
      comparison: null,
      value: null,
      urlTimeValue: this.props.data.stayTime,
      anchorEl: null,
      ruleIndex: null
    }
    //window.tz = moment.tz
  }

  formRef;

  // Footer Button Handlers
  submitClickHandler = () => {
    this.formRef.submit()
  }

  onSubmitHandler = (e) => {
    e.preventDefault();

    this.update({
      send_criteria: this.state.tabValue,
      schedule_type: this.state.scheduleValue,
      scheduled_at: this.state.scheduled_at,
      scheduled_to: this.state.scheduled_to
    });
  };

  updateData = (campaign) => {
    this.setState({ data: campaign })
    this.props.updateData(campaign)
  }

  // Form Event Handlers
  create = (data) => {
    graphql(
      CREATE_CAMPAIGN,
      {
        appKey: this.props.app.key,
        mode: this.props.mode,
        operation: "create",
        campaignParams: data,
      },
      {
        success: (data) => {
          this.setState(
            {
              data: data.campaignCreate.campaign,
              errors: data.campaignCreate.errors,
            },
            () => {
              if (!isEmpty(this.state.errors)) {
                return;
              }

              this.props.history.push(
                `/apps/${this.props.app.key}/messages/${this.props.mode}/${this.state.data.id}`
              );
              this.props.handleTabChange(null, this.props.currentTab + 1)
              this.props.updateData(this.state.data);
            }
          );
        },
      }
    );
  };

  // Form Event Handlers
  update = (data) => {
    const params = {
      appKey: this.props.app.key,
      id: this.state.data.id,
      campaignParams: data,
    };

    graphql(UPDATE_CAMPAIGN, params, {
      success: (data) => {
        const result = data.campaignUpdate;
        this.setState({ data: result.campaign, errors: result.errors }, () => {
          this.props.updateData(result.campaign);
          this.props.successMessage();
          this.props.handleTabChange(null, this.props.currentTab + 1)
        });
      },
      error: (error) => {},
    });
  };

  url = () => {
    return `/apps/${this.props.app.key}/campaigns/new`;
  };

  handleTabChange = (e, i) => {
    this.setState({ tabValue: i });
  };

  handleScheduleChange = (e) => {
    this.setState({ scheduleValue: e.target.value });
  }

  handleScheduleAt = (date) => {
    this.setState({ scheduled_at: date });
  }

  handleScheduleTo = (date) => {
    this.setState({ scheduled_to: date });
  }

  onRadioChange = (target, cb) => {
    const { value } = target;

    window.target = target;

    this.setState(
      {
        comparison: value,
      },
      () => {
        cb ? cb() : null;
      }
    );
  };

  closeAllFilters = () => {
    this.setState({
      openUrlRule: false,
      openFilter : false,
      openUrlTime: false,
      anchorEl   : null,
      ruleIndex  : null,
      comparison : null,
      value      : null
    })
  }

  handleSubmit = (condition) => {
    let comparison = this.state.comparison.replace("relative:", "");
    let value = `${this.relative_url_input ? this.relative_url_input.value : this.state.value}`
    let rule = this.state.urlSegmentRule
    let ruleIndex = this.state.ruleIndex

    const data = {
      comparison: comparison,
      value: value
    };

    switch(condition) {
      case "create":
        rule.push(data)
        break;
      case "update":
        rule[ruleIndex].comparison = comparison
        rule[ruleIndex].value = value
        break;
      case "delete":
        rule.splice(ruleIndex, 1)
        break;
    }

    const params = {
      appKey: this.props.app.key,
      id: this.props.data.id,
      campaignParams: {
        url_segment_rule: rule
      },
    };

    graphql(UPDATE_CAMPAIGN, params, {
      success: (data) => {
        this.closeAllFilters()
        this.props.successMessage();
      },
      error: (e) => {
        console.error(e)
      },
    });
  }

  updateTime =  () => {
    let time = `${this.relative_time_input ? this.relative_time_input.value : this.state.urlTimeValue}`
    const params = {
      appKey: this.props.app.key,
      id: this.props.data.id,
      campaignParams: {
        stay_time: time
      },
    };
    graphql(UPDATE_CAMPAIGN, params, {
      success: (data) => {
        this.props.successMessage();
        this.closeAllFilters()
        this.setState({urlTimeValue: time})
      },
      error: (e) => {
        console.error(e)
      },
    });
  }

  contentString = () => {

    const relative = [
      { label: "is", value: "eq", defaultSelected: false },
      { label: "is not", value: "not_eq", defaultSelected: false },
      { label: "starts with", value: "contains_start", defaultSelected: false },
      { label: "ends with", value: "contains_ends", defaultSelected: false },
      { label: "contains", value: "contains", defaultSelected: false },
      {
        label: "does not contain",
        value: "not_contains",
        defaultSelected: false,
      },
      { label: "is unknown", value: "is_null", defaultSelected: false },
      { label: "has any value", value: "is_not_null", defaultSelected: false },
    ];

    return (
      <ClickAwayListener onClickAway={() => { this.closeAllFilters() }}>
        <ContentMatchWrapper>
          {
            <MenuItem>
              <ContentMatchTitle>
                Select the filter for
              </ContentMatchTitle>
            </MenuItem>
          }

          <ContentMatch ref={this.blockStyleRef}>
            <RadioGroup
              aria-label="options"
              name="options"
              onChange={(e) => {
                this.onRadioChange(e.target);
              }}
            >
              {relative.map((o, i) => (
                <div
                  key={`${o.name}-${i}`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio checked={this.state.comparison === o.value} />
                    }
                    value={o.value}
                    label={o.label}
                  />

                  {this.state.comparison &&
                  this.state.comparison === o.value ? (
                    <TextField1
                      defaultValue={this.state.value}
                      inputRef={(input) => (this.relative_url_input = input)}
                      placeholder={"Value"}
                      margin="normal"
                    />
                  ) : null}
                </div>
              ))}
            </RadioGroup>
          </ContentMatch>

          <ContentMatchFooter>
            {this.state.comparison &&
              (this.state.comparison !== "is_null" ||
                this.state.comparison !== "is_not_null") && (
                <Button
                  variant="outlined"
                  color="primary"
                  size={"small"}
                  onClick={() => { this.handleSubmit(this.state.ruleIndex == null ? "create" : "update") }}
                >
                  Apply
                </Button>
              )}
            {
              this.state.ruleIndex != null &&
              <Button
                variant="outlined"
                color="primary"
                size={"small"}
                onClick={() => { this.handleSubmit("delete") }}
              >
                Delete
              </Button>
            }

            {/* {this.deleteButton()} */}
          </ContentMatchFooter>
        </ContentMatchWrapper>
      </ClickAwayListener>
    );
  };

  openUrlHandle = () => {
    this.setState({
      openUrlRule: false,
      openFilter: true,
      openUrlTime: false,
    })
  }

  openTimeHandle = () => {
    this.setState({
      openUrlRule: false,
      openFilter: false,
      openUrlTime: true,
    })
  }


  openUrlRuleHandle = (e) => {
    this.setState({
      openUrlRule: true,
      openFilter: false,
      openUrlTime: false,
      anchorEl: e.currentTarget,
      comparison: null,
      value: null,
      ruleIndex: null
    })
  }

  editSegmentRule = (e, { comparison, value }, ruleIndex) => {
    this.setState({
      openFilter: true,
      openUrlRule: false,
      openUrlTime: false,
      anchorEl: e.currentTarget,
      comparison,
      value,
      ruleIndex
    })
  }

  openUrlRuleMenu = () => {
    return (
      <ClickAwayListener onClickAway={this.closeAllFilters}>
        <ContentMatchWrapper>
          <MenuItem
            onClick={this.openUrlHandle}
          >
            Add a URL rule
          </MenuItem>
          <MenuItem
            onClick={this.openTimeHandle}
          >
            Add a time on page rule
          </MenuItem>
        </ContentMatchWrapper>
      </ClickAwayListener>
    );
  };

  openUrlTimeMenu = () => {
    return (
      <ClickAwayListener onClickAway={this.closeAllFilters}>
        <ContentMatchWrapper>
          <MenuItem>
            <ContentMatchTitle>
              is equal to
            </ContentMatchTitle>
          </MenuItem>
          <ContentMatch>
            <TextField1
              defaultValue={this.state.urlTimeValue}
              inputRef={(input) => (this.relative_time_input = input)}
              placeholder={"0"}
              margin="normal"
            />
            <Grid>
              seconds
            </Grid>
          </ContentMatch>

          <ContentMatchFooter>
            <Button
              variant="outlined"
              color="primary"
              size={"small"}
              onClick={this.updateTime}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size={"small"}
              onClick={this.closeAllFilters}
            >
              Close
            </Button>
          </ContentMatchFooter>
        </ContentMatchWrapper>
      </ClickAwayListener>
    );
  };

  closeAllFilters = () => this.setState({anchorEl: null})


  render() {
    const {tabValue, scheduleValue, scheduled_at, scheduled_to} = this.state;
    const notIncluded = ["name", "fromName", "fromEmail", "replyEmail", "description", "subject"]
    return (
      <Grid
        alignContent='space-around'
        style={{ borderRadius: '7px', border: 'solid 1px rgba(0,0,0,0.12)' }}
        justify='space-around'
      >
        <Grid item xs={12} style={{ borderBottom: '1px solid #E5E5E5', display: 'flex', justifyContent: 'space-between', padding: 20 }}>
           <Box m={2}>
            <Typography style={{ fontSize: '18px', marginTop: '10px'}} >
              Schedule your message
            </Typography>
           </Box>
           <Box m={2} align='right'>
             <Button
               color='primary'
               size='large'
               variant='contained'
               style={{ background: '#FFB600', color: '#000' }}
               onClick={this.onSubmitHandler}
              >
               Go Live
             </Button>
           </Box>
        </Grid>
        <Grid item xs={12} style={{ padding: "25px 35px", margin: '20px', minHeight: 600, position: 'relative', borderRadius: '8px' }}>
          <Typography variant="h3" style={{ margin: '0 0 40px 7px', fontSize: 38, fontWeight: '500' }}>
            How to send this message
          </Typography>
          <TabContainer>
            <Tabs
              value={tabValue}
              onChange={this.handleTabChange}
              style={{ width: '50%', border: '1px solid #E5E5E5', borderRadius: 8 }}
              variant="fullWidth"
            >
              <Tab
                value="ongoing"
                label={ (<div
                style={{
                  height: 157,
                  width: '55%',
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <div>
                  <img src={rotate_icon} />
                </div>

                <div style={{fontSize: 18, fontWeight: 600}}>
                  <span>Ongoing</span>
                </div>
                <div style={{fontSize: 15}}>
                  <span>Keep sending to people who match your rules un the future</span>
                </div>
              </div>)} />
              <Tab
                value="one_off"
                label={(
                  <div
                    style={{
                      height: 157,
                      width: "55%",
                      textAlign: 'left',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <div>
                      <img src={group_icon} />
                    </div>

                    <div style={{fontSize: 18, fontWeight: 600}}>
                      <span>One-off</span>
                    </div>
                    <div style={{fontSize: 15}}>
                      <span>Only send to people who match your rules right now</span>
                    </div>
                  </div>
                )}
              />
            </Tabs>
          </TabContainer>
          <CampaignGoal
            appKey={this.props.app.key}
            customFields={this.props.app.customFields}
            campaignId = {this.state.data.id}
            goalSegment={this.state.data.goalSegment}
            updateData={this.updateData}
            successMessage={this.props.successMessage}
          />
          { this.props.mode == 'user_auto_messages' &&
            <Grid>
              <Typography variant="h3" style={{ margin: '40px 0 20px 7px', fontSize: 38, fontWeight: '500' }}>
                When to send this
              </Typography>
              <Grid
                style={{
                  display: 'flex',
                  maxWidth: '600px',
                  flexWrap: 'wrap'
                }}
              >
                {
                  this.state.urlSegmentRule.map((urlSegment, index) => {
                    return(
                      <Grid>
                        <Button
                          style={{
                            padding: '12px',
                            border: '1px solid',
                            marginRight: '5px'
                          }}
                          onClick={(e) => { this.editSegmentRule(e, urlSegment, index) }}
                        >
                            {`Url ${urlSegment.comparison} ${urlSegment.value}`}
                        </Button>
                      </Grid>
                    )
                  })
                }
                <Grid style={{position: 'relative'}}>
                  <Button
                    style={{
                      padding: '12px',
                      border: '1px solid'
                    }}
                    onClick={(e) => { this.openUrlRuleHandle(e) }}
                  >
                    + Add a rule
                  </Button>
                  <Menu
                    style={{
                      borderRadius: '4px',
                      border: '1px solid #f1f1f1',
                      zIndex: '12'
                    }}
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.closeAllFilters}
                  >
                    {this.state.openFilter && this.contentString()}
                    {this.state.openUrlTime && this.openUrlTimeMenu()}
                    {this.state.openUrlRule && this.openUrlRuleMenu()}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          }
          <Typography variant="h3" style={{ margin: '40px 0 20px 7px', fontSize: 38, fontWeight: '500' }}>
            Scheduling
          </Typography>
          <Typography variant="h6" style={{ margin: '0px 0 20px 7px', fontWeight: '400' }}>
            Choose start, stop, and optimal send times for your message
          </Typography>

          <Typography variant="subtitle1" style={{ margin: '20px 0 15px 7px', fontWeight: '400' }}>
            Start sending
          </Typography>
          <RadioGroup
            aria-label="scheduling"
            name="schedule"
            value={scheduleValue}
            onChange={this.handleScheduleChange}
          >
            <FormControlLabel value="immediately" control={<Radio />} label="Immediately" />
            <FormControlLabel value="custom_date" control={<Radio />} label="Custom date" />
          </RadioGroup>
          { scheduleValue === 'custom_date' && (
            <>
              <DatePickerWrapper>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Typography>Scheduled At</Typography>
                  <Grid container justify="flex-start">
                    <KeyboardDatePicker
                      margin="normal"
                      id="mui-pickers-date"
                      label="Date"
                      variant="outlined"
                      value={scheduled_at}
                      onChange={this.handleScheduleAt}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      format="MMM d, yyyy"
                    />
                    <KeyboardTimePicker
                      margin="normal"
                      id="mui-pickers-time"
                      label="Time"
                      variant="outlined"
                      value={scheduled_at}
                      onChange={this.handleScheduleAt}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                      keyboardIcon={<AccessTimeIcon />}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </DatePickerWrapper>
              { tabValue === 'ongoing' &&
                <DatePickerWrapper>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Typography>Scheduled To</Typography>
                    <Grid container justify="flex-start">
                      <KeyboardDatePicker
                        margin="normal"
                        id="mui-pickers-date"
                        label="Date"
                        variant="outlined"
                        value={scheduled_to}
                        onChange={this.handleScheduleTo}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        format="MMM d, yyyy"
                      />
                      <KeyboardTimePicker
                        margin="normal"
                        id="mui-pickers-time"
                        label="Time"
                        variant="outlined"
                        value={scheduled_to}
                        onChange={this.handleScheduleTo}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        keyboardIcon={<AccessTimeIcon />}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </DatePickerWrapper>
              }
            </>
          )}
        </Grid>
      </Grid>

    );
  }
}
