import styled from '@emotion/styled'

import React, {useState} from 'react'


// taken from https://codepen.io/sabin42/pen/odjvKy
const LoadingWrapper = styled.div`

  .wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 100vw;
    //background-color: #c89b7e;
  }
  .card {
    display: flex;
    padding: 24px;
    border-radius: 5px;
    /*box-shadow: 1px 4px 16px rgba(0,0,0,.4);
    min-height: 300px;
    min-width: 400px;
    background-color: #fbfbfb;*/
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader{
    border: 12px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

`


export default function LoadingView({onClick}){
  return <LoadingWrapper>
      <section className="wrapper" onClick={onClick}>
        <div className="card">
          <div className="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </LoadingWrapper>
}