import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Prism from 'prismjs'
import styled from '@emotion/styled'
import graphql from '../graphql/client';
import { setupScript } from './setupScript';

const Pre = styled.pre`
  background: black;
  color: white;
  font-size: 1.5em;
  overflow: auto;
`

function WebSetup({app, classes}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Link onClick={handleClickOpen}
        style={{display: 'block', padding: '15px 25px', background: '#FFE8AD', borderRadius: 8, fontWeight: 600, fontSize: 15, textAlign: 'center', border: '1px solid #FFB600', marginTop: 10, cursor: 'pointer'}}>
        Get The snippet
      </Link>
      <SimpleDialog
        app={app}
        open={open}
        onClose={handleClose}
      />
    </React.Fragment>
  )
}

function SimpleDialog(props) {
  const { onClose, open, app } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Web Messenger Setup
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          put the following script on the end of your html body tag
        </DialogContentText>
        <Pre>
          <div dangerouslySetInnerHTML={{__html: Prism.highlight(setupScript(app), Prism.languages.javascript, 'javascript') }}/>
        </Pre>
      </DialogContent>
    </Dialog>
  );
}

function mapStateToProps(state) {
  const { app } = state
  return {
    app
  }
}

export default withRouter(connect(mapStateToProps)(WebSetup))
