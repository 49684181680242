import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider'
import Table from '@material-ui/core/Table';
import { Link } from "react-router-dom";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Countdown from 'react-countdown';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { green, red } from '@material-ui/core/colors';

//import Chart from './Chart';
import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import DashboardCard from '../components/dashboard/card'
import Title from '../components/dashboard/title';
import Progress from '../shared/Progress'


import HeatMap from '../components/charts/heatMap'
import Pie from '../components/charts/pie'
import Count from '../components/charts/count'

import {DASHBOARD, AGENT_DISCOUNT} from "../graphql/queries"
import graphql from '../graphql/client'

import {setCurrentSection} from '../actions/navigation'
import { withRouter } from 'react-router-dom'
import Content from '../components/Content'
import { connect } from 'react-redux'
import WorldMapVisitorCount from '../shared/WorldMapVisitorCount'
import NewlyCreatedUsersSummary from '../components/dashboard/NewlyCreatedUsersSummary'
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
  paperll: {
    maxWidth: 936,
    //marginTop: '2em',
    //marginBottom: '2em',
    margin: 'auto',
    //overflow: 'hidden',
    //marginBottom: 20,
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  content: {
    flexGrow: 1,
    height: '100vh',
    //overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 500,
  },
  svgIcon: {
    width: '14px',
    height: 'auto',
    fontSize: '30px',
    padding: 0,
    textAlign: 'center',
    '& path' : {
      transform: 'scale(1.9)',
      transformOrigin: '50% 50%'
    }
  },
  iconSpan: {
    paddingLeft: '21px'
  },
  iconDiv: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  banner: {
    background: '#000',
    color: '#FFB600',
    position: 'fixed',
    width: '100%',
    fontSize: 17,
    textAlign: 'center',
    top: 0,
    left: 0,
    zIndex: 10000,
    padding: 10,
    "& svg": {
      float: 'right',
      width: 40,
      marginRight: 30,
      marginTop: 5,
    }
  }
});

function Dashboard(props) {
  const { classes, app, dispatch } = props;

  React.useEffect(()=>{
    dispatch(
      setCurrentSection("Dashboard")
      )
      checkAgentDiscount()
    }, [])

  const initialData =  {
    loading: true,
    from: moment().add(-1, 'week'),
    to: moment(), //.add(-1, 'day')
  }

  const [dashboard, setDashboard] = React.useState(initialData)
  const [discountTime, setDiscountTime] = React.useState(null)

  const checkAgentDiscount = () => {
    graphql(AGENT_DISCOUNT, {}, {
      success: (data)=>{
        let time = data.agentDiscount
        let timeDifference = moment(time).add(1, 'day')-moment()
        if(time && timeDifference > 0)
          setDiscountTime(timeDifference)
        else
          setDiscountTime(null)
      },
      error: (err)=>{
        console.log("err", err)
      }
    })
  }

  const closeTimer = () =>
    setDiscountTime(null)

  const bull = <span className={classes.bullet}>•</span>;
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <Content>
      <div className={classes.contentWrapper}>
      {
        discountTime
        &&  <div className={classes.banner}>
              <Link to={`/apps/${app.key}/pricing`} style={{color: '#FFB600', textDecoration: 'none'}}>
                <span>
                🚀Get <strong>50% OFF</strong> for your first 3 months! ⏳<strong>Click here to SUBSCRIBE</strong> BEFORE &nbsp;
                </span>
             
                <Countdown
                  date={Date.now() + discountTime}
                  daysInHours={true}
                  onComplete={closeTimer}
                />
              </Link>
              <CloseIcon onClick={closeTimer} style={{float: 'right', cursor: 'pointer'}} />
            </div>
      }
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}


            <Grid item xs={12} md={12} lg={12}>

              <DashboardItem
                chartType={"app_packages"} 
                dashboard={dashboard}
                app={app} 
                label={I18n.t('dashboasrd.user_country')}
                kind={'app_packages'}
                classes={classes}
                styles={{}}
              />
            
            </Grid>

            <Grid item xs={6} md={3}>
              <Paper className={classes.paper}>
                <DashboardItem
                  chartType={"count"} 
                  dashboard={dashboard} 
                  app={app} 
                  kind={"first_response_time"}
                  label={I18n.t('dashboard.response_avg')}
                  appendLabel={"Hrs"}
                />
              </Paper>
            </Grid>

            <Grid item xs={6} md={3}>
              <Paper className={classes.paper}>
                <DashboardItem
                  chartType={"count"} 
                  dashboard={dashboard} 
                  app={app} 
                  kind={"opened_conversations"}
                  label={I18n.t('dashboard.new_conversations')}
                  appendLabel={""}
                />
              </Paper>
            </Grid>

            <Grid item xs={6} md={3}>
              <Paper className={classes.paper}>
                <DashboardItem
                  chartType={"count"} 
                  dashboard={dashboard} 
                  app={app} 
                  kind={"solved_conversations"}
                  label={I18n.t('dashboard.resolutions')}
                  appendLabel={""}
                />
              </Paper>
            </Grid>

            <Grid item xs={6} md={3}>
              <Paper className={classes.paper}>
                <DashboardItem
                  chartType={"count"} 
                  dashboard={dashboard} 
                  app={app} 
                  kind={"incoming_messages"}
                  label={I18n.t('dashboard.incoming_messages')}
                  appendLabel={""}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                {/*<Chart />*/}
                <Title>Visit activity</Title>
                 <DashboardItem
                    chartType={"heatMap"} 
                    dashboard={dashboard} 
                    app={app} 
                    kind={"visits"}
                  />
              </Paper>
            </Grid>
            {/* Recent Deposits */}

            <Grid item xs={6} md={3}>
              <Paper className={fixedHeightPaper}>
                <DashboardCard title={"Median response time"}>
                  <DashboardItem
                    chartType={"count"}
                    dashboard={dashboard}
                    app={app}
                    kind={"median_response_time"}
                    appendLabel={"Hrs"}
                  />
                </DashboardCard>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Paper className={fixedHeightPaper.concat(' pie-chart-parent')}>
                <DashboardCard title={"Users browser"}>

                  <DashboardItem
                    chartType={"pie"} 
                    dashboard={dashboard}
                    app={app} 
                    label={I18n.t('dashboasrd.browser')}
                    kind={'browser'}
                  />
                
                </DashboardCard>
                
              </Paper>
            </Grid>
  
            <Grid item xs={12} md={6} lg={6}>
              <Paper className={classes.paper.concat(' pie-chart-parent')}>
              <DashboardCard title={"Lead Os"}>
                  <DashboardItem
                    chartType={"pie"} 
                    dashboard={dashboard}
                    app={app} 
                    label={I18n.t('dashboasrd.lead_os')}
                    kind={'lead_os'}
                  />
                </DashboardCard>
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper.concat(' pie-chart-parent')}>
              <DashboardCard title={"User Os"}>
                  <DashboardItem
                    chartType={"pie"} 
                    dashboard={dashboard}
                    app={app} 
                    label={I18n.t('dashboasrd.user_os')}
                    kind={'user_os'}
                  />
                </DashboardCard>
              </Paper>
            </Grid>

            {/* <Grid item xs={12} md={6} lg={6}>
              <Paper className={classes.paper.concat(' pie-chart-parent')}>
              <DashboardCard title={"User country"}>
                  <DashboardItem
                    chartType={"pie"} 
                    dashboard={dashboard}
                    app={app} 
                    label={I18n.t('dashboasrd.user_country')}
                    kind={'user_country'}
                  />
                </DashboardCard>
              </Paper>
            </Grid> */}

            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper.concat(' world-map-visitor-count ')}>
                <WorldMapVisitorCount {...props}/>
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper} >
                <NewlyCreatedUsersSummary {...props} />
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaper}>
                {/*<Chart />*/}
                <Title>{I18n.t('dashboard.top_viewed_articles')}</Title>
                 <DashboardItem
                    chartType={"top_articles"} 
                    dashboard={dashboard} 
                    app={app} 
                    kind={"top_articles"}
                    classes={classes}
                  />
              </Paper>
            </Grid>

          </Grid>
        </Container>
        {/*<MadeWithLove />*/}
      </main>

      </div>

    </Content>
  );
}


function DashboardItem(
  {
    app, 
    kind, 
    dashboard,
    chartType,
    label,
    appendLabel,
    classes,
    styles
  }){

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(()=>{
    getData()} 
  , [])

  function getData(){

    graphql(DASHBOARD, {
      appKey: app.key,
      range: {
          from: dashboard.from,
          to: dashboard.to
        },
        kind: kind
      }, {
      success: (data)=>{
        setData(data.app.dashboard)
        setLoading(false)
      },
      error: (err)=>{
        setLoading(false)
      }
    })
  }

  function renderChart(){
    switch (chartType) {
      case "heatMap":
        return <HeatMap 
          data={data}
          from={dashboard.from}
          to={dashboard.to}
        />

      case "pie":
        return  <Pie 
          data={data}
          from={dashboard.from}
          to={dashboard.to}
          kind={kind}
        />
      case "count":
        return  <Count 
          data={data}
          from={dashboard.from}
          to={dashboard.to}
          label={label}
          appendLabel={appendLabel}
        />
      case "app_packages":
        return <DashboardAppPackages 
                data={data}
                dashboard={dashboard}
                classes={classes}
               />
      case "top_articles":
        return <TopArticles 
                data={data}
                dashboard={dashboard}
                classes={classes}
              />
      default:
        return <p>no chart type</p>;
    }
  }

  return (
    <div style={styles || {height: '140px'} }>

      {
        loading && <Progress/>
      }
      {
        !loading && renderChart()
      }
    </div>
  )
}
function TopArticles(props){
  const { classes, data} = props
  return(
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell>URL</TableCell>
            <TableCell style={{ width: 160 }} align="right">Converstions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.url}>
              <TableCell component="th" scope="row">
                {row.url}
              </TableCell>
              <TableCell style={{ width: 160 }} align="right">
                <div className={classes.iconDiv}>
                  {row.hits_diff}
                  <span className={classes.iconSpan}>
                    { row.status === "increased" && <ArrowDropUpIcon className={classes.svgIcon} style={{ color: green[500] }}/>}
                    { row.status === "decreased" && <ArrowDropDownIcon className={classes.svgIcon} style={{ color: red[500] }}/>}
                    {row.hits_diff}
                  </span>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function DashboardAppPackages(props){
  const packages  = props.data
  return (
    <div>
      {
        packages && packages.map((o)=>( 
          <DashboardAppPackage 
            package={o} 
            dashboard={props.dashboard}
            classes={props.classes} 
          /> 
        ))
      }
    </div>
  )
}

function DashboardAppPackage(props){
  const dashboard = props.dashboard
  const pkg  = props.package
  const data = pkg.data
  const classes = props.classes

  return (
    <Paper className={classes.paper}>

    <Grid container spacing={3}>
      <Grid item>
        <img src={pkg.icon} width={64}/>
      </Grid>

      <Grid item>
        <Typography variant="h5">
          {pkg.name}: {data.title} 
        </Typography>

        <Typography variant="overline">
          {data.subtitle}
        </Typography>
      </Grid>
    </Grid>

    <Divider/>

      <Grid container spacing={3}>

        {
          data.values && data.values.map((v)=>{
            return <Grid item>
                      
                        <Count 
                          data={v.value}
                          from={dashboard.from}
                          to={dashboard.to}
                          label={v.label}
                          subtitle={`${v.value2}`}
                          //appendLabel={appendLabel}
                        />
                     
                    </Grid>
          })
        }

      </Grid>
    </Paper>
  )
}



Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};


function mapStateToProps(state) {

  const { auth, app, current_user } = state
  const { loading, isAuthenticated } = auth

  return {
    app,
    loading,
    isAuthenticated,
    current_user
  }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Dashboard)))
