import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import { withRouter } from "react-router-dom";
import { AnchorLink } from "../../shared/RouterLink";
import { UpgradeDialog } from "../../shared/planUpgradeMessage";
import { connect } from "react-redux";
import ContentWrapper from "../../components/ContentWrapper";
import PageTitle from "../../components/PageTitle";
//import Tabs from '@atlaskit/tabs';
import Moment from "react-moment";
import styled from "@emotion/styled";
import serialize from "form-serialize";

import CampaignSettings from "./settings";
import CampaignEditor from "./editor";
import SegmentManager from "../../components/segmentManager";
import DeleteDialog from "../../components/deleteDialog";
import CampaignStats from "../../components/stats";

import { isEmpty } from "lodash";

import { parseJwt, generateJWT } from "../../components/segmentManager/jwt";
import TourManager from "../../components/Tour";
import ContentHeader from "../../components/ContentHeader";
import Content from "../../components/Content";
import EmptyView from "../../components/emptyView";

import graphql from "../../graphql/client";
import { CAMPAIGN, CAMPAIGNS, CAMPAIGN_METRICS } from "../../graphql/queries";
import {
  PREDICATES_SEARCH,
  UPDATE_CAMPAIGN,
  CREATE_CAMPAIGN,
  DELIVER_CAMPAIGN,
  PURGE_METRICS,
  DELETE_CAMPAIGN,
} from "../../graphql/mutations";

import { getAppUser } from "../../actions/app_user";
import { toggleDrawer } from "../../actions/drawer";

import Table from "../../components/table/index";
import { Datatable } from "../../components/datatable/";

import SelectMenu from "../../components/selectMenu";

import EmailIcon from "@material-ui/icons/Email";
import MessageIcon from "@material-ui/icons/Message";
import FilterFramesIcon from "@material-ui/icons/FilterFrames";
import ClearAll from "@material-ui/icons/ClearAll";
import DeleteOutlineRounded from "@material-ui/icons/DeleteOutlineRounded";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import CheckCircle from "@material-ui/icons/CheckCircle";
import Pause from "@material-ui/icons/Pause";
import VisibilityRounded from "@material-ui/icons/VisibilityRounded";
import SendRounded from "@material-ui/icons/SendRounded";
import DeleteForeverRounded from "@material-ui/icons/DeleteForeverRounded";

import { setCurrentSection, setCurrentPage } from "../../actions/navigation";

import userFormat from "../../components/table/userFormat";

import { errorMessage, successMessage } from "../../actions/status_messages";
import StatusBadge from "../../components/StatusBadge";
import TextField from '@material-ui/core/TextField';


const BtnTabContainer = styled.div`
  background-color: #fff;

  button {
    padding: 10px 20px;
    font-size: 15px;
    :hover {
      background-color: #FAF6F1;
    }
  }

  button.Mui-selected {
    font-weight: 600;
    background-color: #FAF6F1;
    border-radius: 6px;
    padding: 10px 35px;
  }
`;

const EditButtonsGroup = styled.div`
  flex-direction: row;
  display: flex;
  .MuiButton-containedPrimary {
    background: rgb(255, 182, 0);
    color: rgb(0, 0, 0);
    font-size: 15px;
    padding: 15px 35px;
    margin: 10px;
  }
  .MuiButton-outlinedPrimary {
    font-size: 15px;
    padding: 15px 35px;
    margin: 10px;
  }
`

const options = [
  {
    title: "Enable",
    description: "enables the campaign",
    icon: <CheckCircle />,
    id: "enabled",
    state: "enabled",
  },
  {
    title: "Pause",
    description: "pauses the campaign ",
    icon: <Pause />,
    id: "disabled",
    state: "disabled",
  },
];

class CampaignSegment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jwt: null,
      app_users: [],
      search: false,
      meta: {},
    };
  }
  componentDidMount() {
    /*this.props.actions.fetchAppSegment(
      this.props.app.segments[0].id
    )*/
    this.search();
  }

  handleSave = (e) => {
    const predicates = parseJwt(this.state.jwt);
    //console.log(predicates)

    const params = {
      appKey: this.props.app.key,
      id: this.props.data.id,
      campaignParams: {
        segments: predicates.data,
      },
    };

    graphql(UPDATE_CAMPAIGN, params, {
      success: (data) => {
        this.props.successMessage();
      },
      error: () => {},
    });
  };

  updateData = (data, cb) => {
    const newData = Object.assign({}, this.props.data, { segments: data.data });
    this.props.updateData(newData, cb ? cb() : null);
  };

  updatePredicate = (data, cb) => {
    const jwtToken = generateJWT(data);
    //console.log(parseJwt(jwtToken))
    if (cb) cb(jwtToken);
    this.setState({ jwt: jwtToken }, () =>
      this.updateData(parseJwt(this.state.jwt), this.search)
    );
  };

  addPredicate = (data, cb) => {
    const pending_predicate = {
      attribute: data.name,
      comparison: null,
      type: data.type,
      value: data.value,
    };

    const new_predicates = this.props.data.segments.concat(pending_predicate);
    const jwtToken = generateJWT(new_predicates);
    //console.log(parseJwt(jwtToken))
    if (cb) cb(jwtToken);

    this.setState({ jwt: jwtToken }, () =>
      this.updateData(parseJwt(this.state.jwt))
    );
  };

  deletePredicate(data) {
    const jwtToken = generateJWT(data);
    this.setState({ jwt: jwtToken }, () =>
      this.updateData(parseJwt(this.state.jwt), this.search)
    );
  }

  search = (page) => {
    this.setState({ searching: true });
    // jwt or predicates from segment
    //console.log(this.state.jwt)
    const data = this.state.jwt
      ? parseJwt(this.state.jwt).data
      : this.props.data.segments;
    const predicates_data = {
      data: {
        source: "campaign",
        source_id: this.props.data.id,
        predicates: data.filter((o) => o.comparison),
      },
    };

    graphql(
      PREDICATES_SEARCH,
      {
        appKey: this.props.app.key,
        search: predicates_data,
        page: page || 1,
      },
      {
        success: (data) => {
          const appUsers = data.predicatesSearch.appUsers;
          this.setState({
            app_users: appUsers.collection,
            meta: appUsers.meta,
            searching: false,
          });
        },
        error: (error) => {
          debugger;
        },
      }
    );
  };

  showUserDrawer = (o) => {
    this.props.dispatch(
      toggleDrawer({ rightDrawer: true }, () => {
        this.props.dispatch(getAppUser(o.id));
      })
    );
  };

  render() {
    return (
      <SegmentManager
        {...this.props}
        loading={this.state.searching}
        predicates={this.props.data.segments}
        meta={this.state.meta}
        collection={this.state.app_users}
        updatePredicate={this.updatePredicate.bind(this)}
        addPredicate={this.addPredicate.bind(this)}
        deletePredicate={this.deletePredicate.bind(this)}
        search={this.search.bind(this)}
        loading={this.props.searching}
        columns={userFormat(this.showUserDrawer, this.props.app, this.props.data.configTableColumns)}
        handleTabChange={this.props.handleTabChange}
        currentTab={this.props.currentTab}
        defaultHiddenColumnNames={[
          "id",
          "state",
          "online",
          "lat",
          "lng",
          "postal",
          "browserLanguage",
          "referrer",
          "os",
          "osVersion",
          "lang",
        ]}
        //selection [],
        tableColumnExtensions={[
          //{ columnName: 'id', width: 150 },
          { columnName: "email", width: 250 },
          { columnName: "lastVisitedAt", width: 120 },
          { columnName: "os", width: 100 },
          { columnName: "osVersion", width: 100 },
          { columnName: "state", width: 80 },
          { columnName: "online", width: 80 },
          //{ columnName: 'amount', align: 'right', width: 140 },
        ]}
        leftColumns={["email"]}
        rightColumns={["online"]}
        //toggleMapView={this.toggleMapView}
        //map_view={this.state.map_view}
        //enableMapView={true}
      >
        {this.state.jwt ? (
          <Button
            isLoading={false}
            appearance={"link"}
            onClick={this.handleSave}
          >
            <i className="fas fa-chart-pie"></i> Save Segment
          </Button>
        ) : null}
      </SegmentManager>
    );
  }
}
export default class CampaignForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      data: {},
      tabValue: 0,
      campaignTitle: ""
    };
    this.handleTitleChange = this.handleTitleChange.bind(this);
  }

  url = () => {
    const id = this.props.match.params.id;
    return `/apps/${this.props.app.key}/campaigns/${id}`;
  };

  componentDidMount() {
    this.fetchCampaign();
  }

  deleteCampaign = (cb) => {
    graphql(
      DELETE_CAMPAIGN,
      {
        appKey: this.props.app.key,
        id: this.state.data.id,
      },
      {
        success: (data) => {
          console.log(data);
          cb && cb();
        },
        error: (error) => {
          console.log(error);
        },
      }
    );
  };

  fetchCampaign = (cb) => {
    const id = this.props.match.params.id;

    if (id === "new") {
      graphql(
        CREATE_CAMPAIGN,
        {
          appKey: this.props.app.key,
          mode: this.props.mode,
          operation: "new",
          campaignParams: {},
        },
        {
          success: (data) => {
            this.setState(
              {
                data: data.campaignCreate.campaign,
              },
              cb && cb
            );
          },
        }
      );
    } else {
      graphql(
        CAMPAIGN,
        {
          appKey: this.props.app.key,
          mode: this.props.mode,
          id: id,
        },
        {
          success: (data) => {
            // console.log('campaign data',data);
            this.setState(
              {
                data: data.app.campaign,
              },
              cb && cb
            );
          },
        }
      );
    }
  };

  updateData = (data, cb) => {
    this.setState({ data: data }, cb && cb());
  };


  updateColumnPreferences = (columns, cb) => {
    const params = {
      appKey: this.props.app.key,
      id: this.state.data.id,
      campaignParams: {
        config_table_columns: columns,
      },
    };

    graphql(UPDATE_CAMPAIGN, params, {
      success: (data) => {
        this.setState({
            data: data.campaignUpdate.campaign,
          });
        //this.successMessage();
      },
      error: () => {},
    });
  };


  renderEditorForCampaign = () => {
    switch (this.props.mode) {
      case 'tours':
        return (
          <TourManager
            {...this.props}
            url={this.url()}
            updateData={this.updateData}
            data={this.state.data}
          />
        );
        break;

      default:
        return (
          <CampaignEditor
            {...this.props}
            url={this.url()}
            updateData={this.updateData}
            data={this.state.data}
            viewOnly={true}
          />
        );
        break;
    }
  };

  handleTabChange = (e, i) => {
    this.setState({ tabValue: i });
  };

  tabsContent = () => (
    <BtnTabContainer>
      <Tabs
        value={this.state.tabValue}
        onChange={this.handleTabChange}
        textColor='inherit'
        style={{
          margin: '25px 50px 0', paddingBottom: 20, paddingTop: 25,
          borderTop: 'solid 1px rgba(0,0,0,0.12)',
        }}
      >
        <Tab textColor="inherit" label="Editor" />
        <Tab textColor="inherit" label="Audience" disabled={!this.state.data.id}/>
        <Tab textColor="inherit" label="Settings" disabled={!this.state.data.id}/>
        <Tab textColor="inherit" label="Stats" disabled={!this.state.data.id}/>
      </Tabs>
    </BtnTabContainer>
  )

  getStats = (params, cb) => {
    graphql(CAMPAIGN_METRICS, params, {
      success: (data) => {
        const d = data.app.campaign;
        cb(d);
      },
      error: (error) => {},
    });
  };

  renderTabcontent = () => {
    switch (this.state.tabValue) {
      case 0:
        return this.renderEditorForCampaign()
      case 1:
        return (
          <CampaignSegment
            {...this.props}
            data={this.state.data}
            url={this.url()}
            successMessage={() =>
              this.props.dispatch(successMessage("campaign updated"))
            }
            updateData={this.updateData}
            updateColumnPreferences={this.updateColumnPreferences}
          />
        )
      case 2:
        return (
          <CampaignSettings
            {...this.props}
            data={this.state.data}
            mode={this.props.mode}
            successMessage={() =>
              this.props.dispatch(successMessage("campaign updated"))
            }
            url={this.url()}
            updateData={this.updateData}
          />
        )
      case 3:
        return (
          <CampaignStats
            {...this.props}
            url={this.url()}
            data={this.state.data}
            fetchCampaign={this.fetchCampaign}
            updateData={this.updateData}
            getStats={this.getStats}
          />
        )
    }
  }

  isNew = () => {
    return this.props.match.params.id === "new";
  };

  handleSend = (e) => {
    const params = {
      appKey: this.props.app.key,
      id: this.state.data.id,
    };

    graphql(DELIVER_CAMPAIGN, params, {
      success: (data) => {
        this.updateData(data.campaignDeliver.campaign, null);
        //this.setState({ status: "saved" })
      },
      error: () => {},
    });
  };

  campaignName = (name) => {
    switch (name) {
      case "campaigns":
        return "Mailing Campaign";
        break;
      case "user_auto_messages":
        return "In app messages";

      default:
        return name;
        break;
    }
  };

  toggleCampaignState = () => {
    graphql(
      UPDATE_CAMPAIGN,
      {
        appKey: this.props.app.key,
        id: this.state.data.id,
        campaignParams: {
          state: this.state.data.state === "enabled" ? "disabled" : "enabled",
        },
      },
      {
        success: (data) => {
          this.setState({
            data: data.campaignUpdate.campaign,
          });
          this.props.dispatch(successMessage("campaign updated"));
        },
        error: () => {
          this.props.dispatch(errorMessage("error updating campaign"));
        },
      }
    );
  };

  iconMode = (name) => {
    switch (name) {
      case "campaigns":
        return <EmailIcon />;
      case "user_auto_messages":
        return <MessageIcon />;
      case "tours":
        return <FilterFramesIcon />;
      default:
        return name;
        break;
    }
  };

  optionsForMailing = () => {
    return [
      {
        title: "Preview",
        description: "preview campaign's message",
        icon: <VisibilityRounded />,
        id: "preview",
        onClick: (e) => {
          window.open(
            `${window.location.origin}/apps/${this.props.app.key}/premailer/${this.state.data.id}`,
            "_blank"
          );
        },
      },
      {
        title: "Deliver",
        description: "delivers the campaign",
        icon: <SendRounded />,
        id: "deliver",
        onClick: this.handleSend,
      },
    ];
  };

  deleteOption = () => {
    return {
      title: "Delete",
      description: "delete the campaign",
      icon: <DeleteOutlineRounded />,
      id: "delete",
      onClick: this.openDeleteDialog,
    };
  };

  optionsForData = () => {
    let newOptions = options;
    if (this.props.mode === "campaigns") {
      newOptions = this.optionsForMailing().concat(options);
    }

    newOptions = newOptions.filter((o) => o.state != this.state.data.state);

    newOptions = newOptions.concat(this.purgeMetricsOptions());

    newOptions.push(this.deleteOption());

    return newOptions;
  };

  purgeMetricsOptions = () => {
    return {
      title: "Purge Metrics",
      description: "purges campaigns metrics",
      icon: <ClearAll />,
      id: "deliver",
      onClick: this.purgeMetrics,
    };
  };

  openDeleteDialog = () => {
    this.setState({
      deleteDialog: true,
    });
  };

  purgeMetrics = () => {
    graphql(
      PURGE_METRICS,
      {
        appKey: this.props.app.key,
        id: parseInt(this.props.match.params.id),
      },
      {
        success: (data) => {
          this.fetchCampaign(() => {
            this.props.dispatch(successMessage("campaign updated"));
          });
        },
        error: () => {
          this.props.dispatch(errorMessage("error purging metrics"));
        },
      }
    );
  };

  saveCampaign = () => {
    const params = {
      appKey: this.props.app.key,
      id: this.state.data.id,
      campaignParams: {name: this.state.data.name}
    };


    graphql(UPDATE_CAMPAIGN, params, {
      success: (data) => {
        const result = data.campaignUpdate;
        this.setState({ data: result.campaign, errors: result.errors }, () => {
          this.updateData(result.campaign);
          this.props.dispatch(successMessage("campaign updated"));
        });
      },
      error: (error) => {},
    });
  };

  handleTitleChange = (e) => {
    const title = e.target.value;
    this.setState(prevState => ({
        data: {
            ...prevState.data,
            name: title,
        },
    }));
  }

  render() {
    // console.log("ib tu dek",this.state.data);
    const title = this.state.data.name
      ? `${this.state.data.name}`
      : this.campaignName(this.props.mode);

    return (
      <div>
        {this.state.deleteDialog && (
          <DeleteDialog
            open={this.state.deleteDialog}
            title={`Delete campaign "${this.state.data.name}"`}
            deleteHandler={() => {
              this.deleteCampaign(() => {
                this.setState(
                  {
                    deleteDialog: false,
                  },
                  () => {
                    this.props.history.push(
                      `/apps/${this.props.app.key}/messages/${this.props.mode}`
                    );
                  }
                );
              });
            }}
          >
            <Typography variant="subtitle2">
              we will destroy any content and related data
            </Typography>
          </DeleteDialog>
        )}

        <ContentHeader
          title={
            <Grid container alignContent="space-around" alignItems="center">
              <Grid item style={{ display: "flex", alignItems: "flex-end", padding: '10px 25px 10px 0px', fontWeight: 500, fontSize: 35,  }}>
                <span>{title}</span>
                &nbsp;&nbsp;&nbsp;
                <StatusBadge type="success">
                  {this.state.data.state}
                </StatusBadge>
              </Grid>
            </Grid>
          }
          items={
            <React.Fragment>
              <EditButtonsGroup>
              <Grid item>
                <SelectMenu
                  options={this.optionsForData()}
                  selected={this.state.data.state}
                  handleClick={(e) => this.toggleCampaignState(e.state)}
                  toggleButton={(clickHandler) => {
                    return (
                      <Button
                        onClick={clickHandler}
                        variant="outlined"
                        color="primary"
                        size="large"
                      >
                        More
                      </Button>
                    );
                  }}
                />
              </Grid>

              <Grid item>
                <Button
                  component="button"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.props.history.push(
                      `/apps/${this.props.app.key}/messages/campaigns/${this.state.data.id}/edit`
                    )
                  }}
                >
                 Edit
                </Button>


              </Grid>
              </EditButtonsGroup>
            </React.Fragment>
          }
        />
        {this.isNew() ? null : this.tabsContent()}

        <div style={{ padding: "0 50px 27px", backgroundColor: "#fff" }}>
          {!isEmpty(this.state.data) ? (
            this.isNew() ? (
              <CampaignSettings
                {...this.props}
                data={this.state.data}
                mode={this.props.mode}
                url={this.url()}
                updateData={this.updateData}
                successMessage={() =>
                  this.props.dispatch(successMessage("campaign updated"))
                }
              />
            ) : (
              this.renderTabcontent()
            )
          ) : null}
        </div>
      </div>
    );
  }
}
