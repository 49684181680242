import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import UserData from '../components/UserData'
import styled from '@emotion/styled'
import {isEmpty} from 'lodash'

import graphql from '../graphql/client'
import {
  APP_USER_CONVERSATIONS,
  APP_USER_VISITS,
} from '../graphql/queries'

import {
  START_CONVERSATION,
  APP_USER_UPDATE_STATE,
  APP_USER_UPDATE
} from '../graphql/mutations'


import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import {
  getAppUser
} from '../actions/app_user'

import ContentHeader from '../components/ContentHeader'
import Content from '../components/Content'
import Mapa from '../components/map'
import UserListItem from '../components/conversation/UserListItem'
import sanitizeHtml from 'sanitize-html';
import DataTable from '../components/table'

import DialogEditor from '../components/conversation/DialogEditor'
import UserActionsMenu from '../components/userActionsMenu'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/EditOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'
import ConversationHeader from '../components/ConversationHeader'
import icon_send from "../../../assets/images/bx-send-icon.svg";
import map_icon from "../../../assets/images/mapIcon.png";
import user_icon from "../../../assets/images/userIcon.png";
import envelope_icon from "../../../assets/images/envelopeIcon.png";
import transfer_icon from "../../../assets/images/transferIcon.png";
import face_icon from "../../../assets/images/faceIcon.png";
import browser from "../../../assets/images/browser.jpg";
import calender from "../../../assets/images/calender.jpg";
import { withStyles } from '@material-ui/core/styles';
import {setCurrentSection, setCurrentPage} from '../actions/navigation'

const AppUserHeaderOverlay = styled.div`
  position: absolute;
  z-index: 99;
  color: #fff;
  width: 100%;
  height: 100%;
  background: #F0F0F8;
  //background: linear-gradient(to bottom,rgba(250,250,250,0) 40%,#f6f6f6 100%);
`

const AppUserHeaderInfo = styled.div`
  z-index: 99;
  color: #fff;
  width: 100%;
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  flex: 0.5;
  flex-direction: column;
  margin-left: 80px;
  margin-bottom: 20px;

  .name-description {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    color: #000;

    input{
      color: #000;
    }
    svg {
      width: 20px;
      height: 20px;
    }
    .MuiTypography-h5 {
      font-size: 28px;
      font-weight: 500;
      text-transform: capitalize;
    }
    .MuiTypography-h6 {
      font-weight: 500;
      line-height: 1.2,
    }
  }

  .controls {
    display:flex;
    margin-top: 10px;
    margin-right: 10px;

    .MuiButton-containedSecondary {
      background: #FFB600;
      color: #000;
      padding: 10px 40px;
      border-radius: 8px;
    }
  }

  .user-info {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
  }
`

const ProgressWrapper = styled.div`
  padding: 2em;
  display:flex;
  justify-content: center;
`

export const GridElement = styled.div`
  flex:1;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100vw;
  }

  overflow: scroll;
  //border-right: 1px solid ${(props)=> props.theme.palette.primary.borders };
  h3 {
    margin-left: 20px;
  }
`;

const TitleContainer = styled.div`
  .MuiAppBar-colorPrimary {
    color: #000000;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 40px;
    background-color: transparent;
  }
  h5 {
    font-size: 42px;
    font-weight: normal;
  }
`

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class ProfilePage extends Component {

  state = {
    collection: [],
    meta: {},
    startConversationModal: false,
    editName: false,
    editEmail: false,
    expanded: false,
  }

  componentDidMount(){

    this.props.dispatch(
      setCurrentSection("Platform")
    )

    this.getUser( this.fetchUserConversations )
  }

  getUser = (cb)=>{
    this.props.dispatch(
      getAppUser(
        parseInt(this.props.match.params.id)
      , cb && cb() )
    )
  }

  fetchUserConversations = ()=>{
    graphql(APP_USER_CONVERSATIONS, {
      appKey: this.props.app.key,
      id: this.props.app_user.id,
      page: 1,
      per: 20
    }, {
      success: (data)=>{
        const {collection} = data.app.appUser.conversations
        this.setState({collection: collection })
      },
      error: ()=>{

      }
    })
  }

  openStartConversationModal = ()=>{
    this.setState({startConversationModal: true})
  }

  handleDialogClose = ()=>{
    this.setState({startConversationModal: false})
  }

  handleSubmit = ({html, serialized, text})=> {

    graphql(START_CONVERSATION, {
      appKey: this.props.app.key,
      id: this.props.app_user.id,
      message: {html, serialized, text}
    }, {
      success: (data)=>{
        const {conversation} = data.startConversation
        const url = `/apps/${this.props.app.key}/conversations/${conversation.key}`
        this.props.history.push(url)
      },
      errors: (error)=>{
        debugger
      }
    })
  }

  updateState = (option)=>{

    graphql(APP_USER_UPDATE_STATE, {
      appKey: this.props.app.key,
      id: this.props.app_user.id,
      state: option.id
    }, {
      success: (data)=>{

        this.props.dispatch(
          getAppUser(
            parseInt(this.props.app_user.id)
          )
        )

        //data.appUserUpdateData.appUser
      },
      error: (error)=>{
        debugger
      }
    })

  }

  handleEnter = (e, attrName)=>{
    let attribute = {}
    attribute[attrName] = e.target.value

    if(e.key === "Enter"){
      graphql(APP_USER_UPDATE, {
        appKey: this.props.app.key,
        id: this.props.app_user.id,
        options: attribute
      }, {
        success: (data)=>{
          this.setState({editName: null})
          this.setState({editEmail: null})
          this.getUser()
        },
        error: ()=>{

        }
      })
    }
  }

  toggleNameEdit = ()=>{
    this.setState({editName: !this.state.editName})
  }

  toggleEmailEdit = ()=>{
    this.setState({editEmail: !this.state.editEmail})
  }

  expandedText = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  getMoreTextDiv = (mainParticipant) => {
    return (
      <React.Fragment>
        <div><img src={ transfer_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  OS:    </span>{mainParticipant.os}</div>
        <div><img src={ transfer_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  OS Version:    </span>{mainParticipant.osVersion}</div>
        <div><img src={ transfer_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Type:    </span>{mainParticipant.type}</div>
        <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Country:    </span>{mainParticipant.country}</div>
        <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  State:    </span>{mainParticipant.state}</div>
        <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  IP:    </span>{mainParticipant.ip}</div>
        <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Latitude:    </span>{mainParticipant.lat}</div>
        <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Longitude:    </span>{mainParticipant.lng}</div>
        <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Timezone:    </span>{mainParticipant.timezone}</div>
        <div><img src={ browser } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Browser:    </span>{mainParticipant.browser}</div>
        <div><img src={ browser } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Browser Version:    </span>{mainParticipant.browserVersion}</div>
        <div><img src={ browser } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Browser Language:    </span>{mainParticipant.browserLanguage}</div>
        <div><img src={ browser } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Web Sessions:    </span>{mainParticipant.webSessions}</div>
        <div><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Last Seen:    </span>{mainParticipant.lastSeen}</div>
        <div><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  First Seen:    </span>{mainParticipant.firstSeen}</div>
        <div><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Signed Up:    </span>{mainParticipant.signedUp}</div>
        <div><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Last Contacted:    </span>{mainParticipant.lastContacted}</div>
        <div><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Last Heard From:    </span>{mainParticipant.lastHeardFrom}</div>
        <div><img src={ calender } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  last Visited:    </span>{mainParticipant.lastVisitedAt}</div>
      </React.Fragment>
    )
  }

  renderUserDetail = () => {
    const participant = this.props.app_user;

    return (
        <GridElement
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
        >
          <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <div className='text-center'>
              {
                participant &&
                <>

                  <div className="text-left" style={{margin: 12, border: 'solid 1px #E5E5E5', borderRadius: 5, padding: '35px 30px 25px 35px', fontSize: 14, lineHeight: 1.6}}>
                    <div><img src={ user_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">{participant.displayName}</span></div>
                    <div><img src={ map_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">{participant.location ? participant.location : ''}</span></div>
                    <div><img src={ face_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Owner:    </span> {participant.owner ? participant.owner : 'no owner'}</div>
                    <div><img src={ envelope_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  Email:    </span> {participant.email}</div>
                    <div><img src={ transfer_icon } /> <span style={{ padding: '5px 15px 10px 5px', color: '#777777' }} className="user-details">  CustomerId:    </span>{participant.id}</div>
                    {this.state.expanded ? this.getMoreTextDiv(participant) : null}
                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
                      <div onClick={this.expandedText}>
                        <a href="javascript:void(0);" style={{color: 'black', textDecoration: 'none'}}>{this.state.expanded ? 'Show Less' : 'Show more'}</a>
                      </div>
                      <Button style={{marginLeft: 20, padding: 0, borderRadius: 7, border: '1px solid #000' }} variant="outlined">Edit</Button>
                    </div>
                  </div>
                  <div className="text-left" style={{margin: 12, border: 'solid 1px #E5E5E5', borderRadius: 5, padding: '35px 30px 25px 35px', paddingLeft: 30, lineHeight: 1.2}}>
                    <span style={{fontSize: 18}}>Qualification</span>
                    <div className="qualification-check" style={{ paddingTop: 15 }}>
                      <FormControlLabel
                          control={<GreenCheckbox checked name="checkName" />}
                          label={<div><span style={{ fontSize: 14, padding: '5px 15px 10px 5px', color: '#777777' }}>Name</span>
                            <span style={{ fontSize: 14 }}>{participant.displayName}</span></div>
                          }
                          style={{fontSize: 12}}
                      />
                    </div>
                    <div className="qualification-check">
                      <FormControlLabel
                          control={<GreenCheckbox checked name="checkName" />}
                          label={<div><span style={{ fontSize: 14, padding: '5px 15px 10px 5px', color: '#777777' }}>Email</span>
                              <span style={{ fontSize: 14 }}>{'Email  ' + participant.email}</span></div>
                            }
                          style={{fontSize: 12}}
                      />
                    </div>
                    <div className="qualification-check">
                      <FormControlLabel disabled
                          control={<GreenCheckbox name="checkName" />}
                          label={<span style={{ fontSize: 14 }}>{'Phone'} <a style={{marginLeft: 20, padding: 0, color: 'black' }} href="javascript:0">Add</a></span>}
                          style={{fontSize: 12}}
                      />
                    </div>
                    <div className="qualification-check">
                      <FormControlLabel disabled
                          control={<GreenCheckbox name="checkName" />}
                          label={<span style={{ fontSize: 14 }}>{'Company Name'} <a style={{marginLeft: 20, padding: 0, color: 'black' }} href="javascript:0">Add</a></span>}
                          style={{fontSize: 12}}
                      />
                    </div>
                    <div className="qualification-check">
                      <FormControlLabel disabled
                          control={<GreenCheckbox name="checkName" />}
                          label={<span style={{ fontSize: 14 }}>{'Company Size'} <a style={{marginLeft: 20, padding: 0, color: 'black' }} href="javascript:0">Add</a></span>}
                          style={{fontSize: 12}}
                      />
                    </div>
                    <div className="qualification-check">
                      <FormControlLabel disabled
                          control={<GreenCheckbox name="checkName" />}
                          label={<span style={{ fontSize: 14 }}>{'Company Website'} <a style={{marginLeft: 20, padding: 0, color: 'black' }} href="javascript:0">Add</a></span>}
                          style={{fontSize: 12}}
                      />
                    </div>
                    <div className="qualification-check">
                      <FormControlLabel
                          control={<GreenCheckbox name="checkName" disabled/>}
                          label={<span style={{ fontSize: 14 }}>{'Company industry'} <a style={{marginLeft: 20, padding: 0, color: 'black' }} href="javascript:0">Add</a></span>}
                          style={{fontSize: 12}}
                      />
                    </div>
                    <div className="qualification-check">
                      <FormControlLabel disabled
                          control={<GreenCheckbox name="checkName" />}
                          label={<span style={{ fontSize: 14 }}>{'Number of Emails'} <a style={{marginLeft: 20, padding: 0, color: 'black' }} href="javascript:0">Add</a></span>}
                          style={{fontSize: 12}}
                      />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <Button style={{marginLeft: 20, padding: 0, borderRadius: 7, border: '1px solid #000' }} variant="outlined">Setting</Button>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </GridElement>
    )
  }

  render() {
    if(!this.props.app_user)
      return <ProgressWrapper>
              <CircularProgress/>
            </ProgressWrapper>

    const app = this.props.app;
    const currentHeader = {
      id: I18n.t("navigator.platform"),
      url: `/apps/${app.key}/segments/${
        app.segments && app.segments.length > 0 ? app.segments[0].id : ""
      }`,
      children: app.segments.map((o) => ({
        id: o.name,
        url: `/apps/${app.key}/segments/${o.id}`,
      })),
    }

    currentHeader.children[0].url = `/apps/${app.key}/users/${this.props.app_user.id}`

    return (

      <div>
        <ConversationHeader history={this.props.history} appId={app.key} category={currentHeader} />
        <TitleContainer>
          <ContentHeader title={"Contact profile"} />
        </TitleContainer>

        <div style={{ display: 'flex', borderBottom: '1px solid #E5E5E5', borderTop: '1px solid #E5E5E5', marginBottom: 8, height: 230, }}>
        <AppUserHeaderInfo>


          <div className="user-info">

            <Avatar style={{width: '90px', height: '90px', borderRadius: '10px'}}
              src={this.props.app_user.avatarUrl + "&s=120px"}
            />

            <div className="name-description">
              <Typography variant={"h5"}>

                {
                  this.props.app_user && this.state.editName ?
                  <TextField
                    //className={classes.input}
                    onKeyUp={(e)=>this.handleEnter(e, "name")}
                    defaultValue={this.props.app_user.name}
                    placeholder="enter user's name"
                    inputProps={{ 'aria-label': 'enter user\'s name' }}
                  /> : this.props.app_user.name
                }

                <IconButton onClick={this.toggleNameEdit} color={"inherit"}>
                  <EditIcon/>
                </IconButton>
              </Typography>

              <Typography variant={"h6"}>
                {
                  this.props.app_user && this.state.editEmail ?
                  <TextField
                    //className={classes.input}
                    onKeyUp={(e)=>this.handleEnter(e, "email")}
                    defaultValue={this.props.app_user.name}
                    placeholder="enter user's email"
                    inputProps={{ 'aria-label': 'user agent\'s email' }}
                  /> : this.props.app_user.email
                }

                <IconButton onClick={this.toggleEmailEdit} color={"inherit"}>
                  <EditIcon/>
                </IconButton>

              </Typography>

              <Typography variant={"subtitle1"}>
                {this.props.app_user.city}
                {" "}
                {this.props.app_user.country}
              </Typography>

              <Typography variant={"caption"} style={{ fontSize: 15 }}>
                {this.props.app_user.state}
              </Typography>
            </div>

          </div>

          <div className="controls">

            <Button
              variant="contained"
              color="secondary"
              onClick={this.openStartConversationModal}>
              <img src={icon_send} style={{ height: "20px" }} />&nbsp;&nbsp;&nbsp;
              Send a message
            </Button>

            <UserActionsMenu
              selected={this.props.app_user.state}
              handleClick={(item)=>{  this.updateState(item) }}
            />

          </div>

        </AppUserHeaderInfo>
        <Mapa
          interactive={true}
          data={[this.props.app_user]}
          forceZoom={10}
          wrapperStyle={{
            position: 'relative',
            width: '100%',
            height: '184px',
            marginTop: '0px',
            flex: '1',
            height: '100%',
          }}>

          <AppUserHeaderOverlay/>
        </Mapa>
        </div>
        <Content>

          <Grid container style={{ padding: '40px 80px' }}>

            <Grid item xs={12} sm={4}>
              {/*{
                !isEmpty(this.props.app_user) ?
                <UserData
                  width={"100%"}
                  hideConactInformation={true}
                  appUser={this.props.app_user}
                  app={this.props.app}
                /> : null
              }*/}
              { this.renderUserDetail() }
            </Grid>

            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={4}>
            <Typography
              variant="h2"
              gutterBottom
              style={{ fontSize: 42, fontWeight: '400', borderBottom: '1px solid #ece9e9', paddingBottom: '1em', marginBottom: 0 }}
            >
              Conversations
            </Typography>
            {
              this.state.collection.filter((o)=> o.lastMessage ).map((o)=>{
                const user = o.mainParticipant

                return <div
                key={`user-list-${o.key}`}
                onClick={(e)=> this.props.history.push(`/apps/${this.props.app.key}/conversations/${o.key}`) }>

                <UserListItem
                value={null}
                mainUser={user}
                object={o.key}
                messageUser={o.lastMessage.appUser}
                showUserDrawer={this.showUserDrawer}
                messageObject={o.lastMessage}
                conversation={o}
                //createdAt={o.lastMessage.message.created_at}
                message={sanitizeHtml(o.lastMessage.message.htmlContent).substring(0, 250)}
                appKey={this.props.app.key}
                />
                </div>

              })
            }
            </Grid>

          </Grid>


          <Grid item xs={12} sm={12}>
            {
              this.props.app_user.id ?
              <AppUserVisits {...this.props}/> : null
            }
          </Grid>

        </Content>

        {
          this.state.startConversationModal ?

            <DialogEditor
              handleSubmit={this.handleSubmit}
              close={this.handleDialogClose}
              open={this.state.startConversationModal}
            /> : null
        }

      </div>
    );
  }
}

class AppUserVisits extends React.Component {

  state = {
    collection: [],
    meta: {},
    loading: false
  }
  componentDidMount(){
    this.fetchvisits()
  }

  fetchvisits = (page=null)=>{
    this.setState({loading: true}, ()=>{
      graphql(APP_USER_VISITS, {
        appKey: this.props.app.key,
        id: this.props.app_user.id,
        page: page || this.state.meta.next_page || 1,
        per: 20
      }, {
        success: (data)=>{
          this.setState({
            collection: data.app.appUser.visits.collection,
            meta: data.app.appUser.visits.meta,
            loading: false
          })
        },
        error: ()=>{
          this.setState({
            loading: true
          })
        }
      })
    })
  }




  render(){
    return <div style={{ padding: '10px 50px' }}>

      <DataTable
        title={"Visits"}
        data={this.state.collection}
        columns={[
          {field: "url", title: "url"},
          {field: "title", title: "title"},
          {field: "browserName", title: "browser name"},
          {field: "browserVersion", title: "browser version"},
          {field: "os", title: "os"},
          {field: "osVersion", title: "os version"},
          {field: "createdAt", title: "created at"}
        ]}
        meta={this.state.meta}
        search={(page)=>this.fetchvisits(page)}
      />

    </div>
  }
}



function mapStateToProps(state) {
  const { app_user, app } = state
  return {
    app_user,
    app
  }
}

//export default ShowAppContainer

export default withRouter(connect(mapStateToProps)(ProfilePage))
