import React, {
  useState,
  useEffect,
  useRef
} from 'react'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'

import FormDialog from '../../components/FormDialog'


const TaskSettingsForm = ({app, data, updateData, saveData, errors}) => {

  const [state, setState] = useState(data || {})

  function update(data){
    const newState = Object.assign({}, state, data)
    //setState(newState)
    updateData(newState)
  }


  return (

    <div>

      <Schedule
        app={app}
        updateData={update}
        data={data}
        namespace={"scheduling"}
        submit={()=> saveData(state)}
      />

    </div>
  )
}


function Schedule({app, data, updateData, namespace, submit}){
  const [state, setState] = React.useState(data);

  useEffect(()=>{
    updateData(state)
  }, [state])

  function handleRadioChange(event) {
    setValue(event.target.name, event.target.value);
  }

  const setValue = (name, value)=>{
    setState({ ...state, [name]: value });
  }

  function submitData(){
    submit(state)
  }

  const handleChange = name => event => {
    setValue(name, event.target.checked ? "enabled" : "disabled")
  };

  return (
    <div>
    <Grid container
      alignContent={'space-around'}
      style={{ border: "1px solid #E5E5E5", borderRadius: "7px", }}
      justify={'space-around'}>
      <Grid item xs={12}  style={{ borderBottom: "1px solid #E5E5E5", display: "flex", justifyContent: "space-between", padding: 20}}>
        <Box m={2}>
          <Typography style={{"fontSize": "18px", "marginTop": "10px"}} >
            Schedule your bot
          </Typography>
        </Box>
        <Box m={2} align={"right"}>
          <Button variant={'contained'}
            color={"primary"}
            size="large"
            style={{ background: "#FFB600", color: "#000" }}
            onClick={submit}>
              save
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ padding: "10px", margin: "20px", minHeight: 600, borderRadius: "8px"}} >
        <Grid container direction={"column"}>

          <Typography variant={"h5"}>
            Enable Bot
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={state.state === "enabled"}
                onChange={handleChange('state')}
                value="enabled"
              />
            }
            label="enabled"
          />

          <Typography variant={"h3"} style={{"fontSize":"38px","fontWeight":"500","width":"40%"}}>
            Set specific times to show this bot to your audience.
          </Typography>

          <Typography variant={"overline"} style={{"textTransform":"capitalize","color":"#000","fontSize":"19px"}}>
            Your app's timezone is {app.timezone} See your office hours.
          </Typography>

          <FormControl component="fieldset">
            <FormLabel component="legend" style={{"fontSize":"15px","marginBottom":"25px"}}>
              Set specific times to show this bot to your audience.
            </FormLabel>

            <RadioGroup aria-label="position"
              name="scheduling"
              value={state.scheduling}
              onChange={handleRadioChange}
              >
              <FormControlLabel
                value="inside_office"
                control={<Radio color="primary" />}
                label="During office hours"
                style={{ fontSize: 15, }}
                labelPlacement="end"
              />

              <FormControlLabel
                value="outside_office"
                control={<Radio color="primary" />}
                label="Outside office hours"
                labelPlacement="end"
              />

              <FormControlLabel
                value="custom_time"
                control={<Radio color="primary" />}
                label="Custom time"
                disabled={true}
                labelPlacement="end"
              />
            </RadioGroup>

          </FormControl>

          <UrlPaths
            app={app}
            updateData={updateData}
            data={data}
          />

        </Grid>
      </Grid>
    </Grid>
    </div>
  )
}

function UrlPaths({data, updateData}){
  const [urls, setUrls] = useState(data.urls || [] )
  const inputEl = useRef(null);

  useEffect(()=>{
    updateData({urls: urls})
  }, [urls])

  const [open, setOpen] = useState(false)

  function remove(item){
    const newUrls = urls.filter((o)=> o != item)
    setUrls(newUrls)
  }

  function add(){
    setOpen(true)
  }

  function close(){
    setOpen(false)
  }

  function submit(e){
    const newUrls = urls.concat(inputEl.current.value)
    setUrls(newUrls)
    setOpen(false)
  }

  return (

    <Grid container direction={"column"}>

      <Grid item>

        <Typography variant={"h3"} style={{"fontSize":"38px","fontWeight":"500","width":"40%", marginTop: 30}}>
          Enable Bot
        </Typography>

        {open && (
          <FormDialog
            open={open}
            //contentText={"lipsum"}
            titleContent={"Save Url"}
            formComponent={
              //!loading ?
                <form>
                  <input ref={inputEl} name={"oe"} />
                </form>
                //: <CircularProgress/>
            }
            dialogButtons={
              <React.Fragment>
                <Button onClick={close} color="secondary">
                  Cancel
                </Button>

                <Button onClick={submit }
                  color="primary">
                  Create
                </Button>

              </React.Fragment>
            }>
          </FormDialog>
        )}

      </Grid>

      <Grid item>
        <Button onClick={add} style={{ border: '1px solid #000', padding: '0 10px' }} >
          <AddIcon style={{ marginRight: 5, width: 16, }} /> add
        </Button>
      </Grid>

      <Grid container direction={"column"}>
        {
          urls.map((o)=>(
            <Grid item>
              <p>{o}</p>
              <Button onClick={()=> remove(o)}>remove</Button>
            </Grid>
          ))
        }
      </Grid>

    </Grid>

  )
}

export default TaskSettingsForm
